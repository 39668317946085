import { History } from 'history'
import { Moment } from 'moment'
import { Dispatch } from 'redux'
import { anyError, ValidationErrors } from 'requests/errors'
import {
  FrameContract,
  FrameContractType,
  LeasingStartTrigger,
  FrameContractCategory,
} from '../data/FrameContract'
import * as requests from '../requests/FrameContract'
import { handleError } from './errors'
import { selectFrameContract } from './frameContracts'
import { Maybe, maybeSwitch } from 'types/Maybe'
import * as customersActions from './customers'
import * as customerEntitiesActions from './customerEntities'
import { handleSuccess } from './success'


interface OpenFrameContractDialogAction {
  type: 'OPEN_FRAME_CONTRACT_DIALOG'
  frameContract: Maybe<FrameContract>
}
interface CloseFrameContractDialogAction {
  type: 'CLOSE_FRAME_CONTRACT_DIALOG'
}
interface CreateFrameContractRequestAction {
  type: 'CREATE_FRAME_CONTRACT_REQUEST'
}
interface CreateFrameContractSuccessAction {
  type: 'CREATE_FRAME_CONTRACT_SUCCESS'
  frameContract: FrameContract
}
interface CreateFrameContractErrorAction {
  type: 'CREATE_FRAME_CONTRACT_ERROR'
}
interface UpdateFrameContractRequestAction {
  type: 'UPDATE_FRAME_CONTRACT_REQUEST'
}
interface UpdateFrameContractSuccessAction {
  type: 'UPDATE_FRAME_CONTRACT_SUCCESS'
  frameContract: FrameContract
}
interface UpdateFrameContractErrorAction {
  type: 'UPDATE_FRAME_CONTRACT_ERROR'
}
interface DeleteFrameContractRequestAction {
  type: 'DELETE_FRAME_CONTRACT_REQUEST'
}
interface DeleteFrameContractErrorAction {
  type: 'DELETE_FRAME_CONTRACT_ERROR'
}
interface SetFrameContractIsDeletableAction {
  type: 'SET_FRAME_CONTRACT_IS_DELETABLE'
}
interface SetFrameContractNameAction {
  type: 'SET_FRAME_CONTRACT_NAME'
  name: string
}
interface SetFrameContractTypeAction {
  type: 'SET_FRAME_CONTRACT_TYPE'
  frameContractType: FrameContractType
}
interface SetFrameContractIsActiveAction {
  type: 'SET_FRAME_CONTRACT_IS_ACTIVE'
  isActive: boolean
}
interface SetFrameContractCustomerIdAction {
  type: 'SET_FRAME_CONTRACT_CUSTOMER_ID'
  customerId: number
}
interface SetFrameContractCustomerEntityIdsAction {
  type: 'SET_FRAME_CONTRACT_CUSTOMER_ENTITY_IDS'
  customerEntityIds: number[]
}
interface SetFrameContractContractStartDateAction {
  type: 'SET_FRAME_CONTRACT_CONTRACT_START_DATE'
  contractStartDate: Moment
}
interface SetFrameContractBillingIntervalInMonthsAction {
  type: 'SET_FRAME_CONTRACT_BILLING_INTERVAL_IN_MONTHS'
  billingIntervalInMonths: number
}
interface SetFrameContractContractIntervalInMonthsAction {
  type: 'SET_FRAME_CONTRACT_CONTRACT_INTERVAL_IN_MONTHS'
  contractIntervalInMonths: number
}
interface SetFrameContractNoticePeriodInMonthsAction {
  type: 'SET_FRAME_CONTRACT_NOTICE_PERIOD_IN_MONTHS'
  noticePeriodInMonths: number
}
interface SetFrameContractIsProRataBillingAction {
  type: 'SET_FRAME_CONTRACT_IS_PRO_RATA_BILLING'
  isProRataBilling: boolean
}
interface SetFrameContractLeasingStartTriggerAction {
  type: 'SET_FRAME_CONTRACT_LEASING_START_TRIGGER'
  leasingStartTrigger: LeasingStartTrigger
}
interface SetFrameContractBillOnlyActiveAssetsAction {
  type: 'SET_FRAME_CONTRACT_BILL_ONLY_ACTIVE_ASSETS'
  billOnlyActiveAssets: boolean
}
interface SetFrameContractCreditUnusedPeriodsAction {
  type: 'SET_FRAME_CONTRACT_CREDIT_UNUSED_PERIODS'
  creditUnusedPeriods: boolean
}
interface SetFrameContractCategoriesAction {
  type: 'SET_FRAME_CONTRACT_CATEGORIES'
  categories: FrameContractCategory[]
}
interface ClearFrameContractNameAction {
  type: 'CLEAR_FRAME_CONTRACT_NAME'
}
interface ClearFrameContractContractStartDateAction {
  type: 'CLEAR_FRAME_CONTRACT_CONTRACT_START_DATE'
}
interface ClearFrameContractBillingIntervalInMonthsAction {
  type: 'CLEAR_FRAME_CONTRACT_BILLING_INTERVAL_IN_MONTHS'
}
interface ClearFrameContractContractIntervalInMonthsAction {
  type: 'CLEAR_FRAME_CONTRACT_CONTRACT_INTERVAL_IN_MONTHS'
}
interface ClearFrameContractNoticePeriodInMonthsAction {
  type: 'CLEAR_FRAME_CONTRACT_NOTICE_PERIOD_IN_MONTHS'
}
interface SetFrameContractValidationErrorsAction {
  type: 'SET_FRAME_CONTRACT_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}

export type FrameContractDialogAction =
  | OpenFrameContractDialogAction
  | CloseFrameContractDialogAction
  | CreateFrameContractRequestAction
  | CreateFrameContractSuccessAction
  | CreateFrameContractErrorAction
  | UpdateFrameContractRequestAction
  | UpdateFrameContractSuccessAction
  | UpdateFrameContractErrorAction
  | DeleteFrameContractRequestAction
  | DeleteFrameContractErrorAction
  | SetFrameContractIsDeletableAction
  | SetFrameContractNameAction
  | SetFrameContractTypeAction
  | SetFrameContractIsActiveAction
  | SetFrameContractCustomerIdAction
  | SetFrameContractCustomerEntityIdsAction
  | SetFrameContractContractStartDateAction
  | SetFrameContractBillingIntervalInMonthsAction
  | SetFrameContractContractIntervalInMonthsAction
  | SetFrameContractNoticePeriodInMonthsAction
  | SetFrameContractIsProRataBillingAction
  | SetFrameContractLeasingStartTriggerAction
  | SetFrameContractBillOnlyActiveAssetsAction
  | SetFrameContractCreditUnusedPeriodsAction
  | SetFrameContractCategoriesAction
  | ClearFrameContractNameAction
  | ClearFrameContractContractStartDateAction
  | ClearFrameContractBillingIntervalInMonthsAction
  | ClearFrameContractContractIntervalInMonthsAction
  | ClearFrameContractNoticePeriodInMonthsAction
  | SetFrameContractValidationErrorsAction

const openFrameContractDialogAction = (
  frameContract: Maybe<FrameContract>
): OpenFrameContractDialogAction => ({
  type: 'OPEN_FRAME_CONTRACT_DIALOG',
  frameContract,
})
const closeFrameContractDialogAction = (): CloseFrameContractDialogAction => ({
  type: 'CLOSE_FRAME_CONTRACT_DIALOG',
})
const createFrameContractRequestAction = (): CreateFrameContractRequestAction => ({
  type: 'CREATE_FRAME_CONTRACT_REQUEST',
})
const createFrameContractSuccessAction = (
  frameContract: FrameContract
): CreateFrameContractSuccessAction => ({
  type: 'CREATE_FRAME_CONTRACT_SUCCESS',
  frameContract,
})
const createFrameContractErrorAction = (): CreateFrameContractErrorAction => ({
  type: 'CREATE_FRAME_CONTRACT_ERROR',
})
const updateFrameContractRequestAction = (): UpdateFrameContractRequestAction => ({
  type: 'UPDATE_FRAME_CONTRACT_REQUEST',
})
const updateFrameContractSuccessAction = (
  frameContract: FrameContract
): UpdateFrameContractSuccessAction => ({
  type: 'UPDATE_FRAME_CONTRACT_SUCCESS',
  frameContract,
})
const updateFrameContractErrorAction = (): UpdateFrameContractErrorAction => ({
  type: 'UPDATE_FRAME_CONTRACT_ERROR',
})
const deleteFrameContractRequestAction = (): DeleteFrameContractRequestAction => ({
  type: 'DELETE_FRAME_CONTRACT_REQUEST',
})
const deleteFrameContractErrorAction = (): DeleteFrameContractErrorAction => ({
  type: 'DELETE_FRAME_CONTRACT_ERROR',
})
const setFrameContractIsDeletableAction = (): SetFrameContractIsDeletableAction => ({
  type: 'SET_FRAME_CONTRACT_IS_DELETABLE',
})
const setFrameContractNameAction = (
  name: string
): SetFrameContractNameAction => ({
  type: 'SET_FRAME_CONTRACT_NAME',
  name,
})
const setFrameContractTypeAction = (
  frameContractType: FrameContractType
): SetFrameContractTypeAction => ({
  type: 'SET_FRAME_CONTRACT_TYPE',
  frameContractType,
})
const setFrameContractCustomerIdAction = (
  customerId: number
): SetFrameContractCustomerIdAction => ({
  type: 'SET_FRAME_CONTRACT_CUSTOMER_ID',
  customerId,
})
const setFrameContractCustomerEntityIdsAction = (
  customerEntityIds: number[]
): SetFrameContractCustomerEntityIdsAction => ({
  type: 'SET_FRAME_CONTRACT_CUSTOMER_ENTITY_IDS',
  customerEntityIds,
})
const setFrameContractIsActiveAction = (
  isActive: boolean
): SetFrameContractIsActiveAction => ({
  type: 'SET_FRAME_CONTRACT_IS_ACTIVE',
  isActive,
})
const setFrameContractContractStartDateAction = (
  contractStartDate: Moment
): SetFrameContractContractStartDateAction => ({
  type: 'SET_FRAME_CONTRACT_CONTRACT_START_DATE',
  contractStartDate,
})
const setFrameContractBillingIntervalInMonthsAction = (
  billingIntervalInMonths: number
): SetFrameContractBillingIntervalInMonthsAction => ({
  type: 'SET_FRAME_CONTRACT_BILLING_INTERVAL_IN_MONTHS',
  billingIntervalInMonths,
})
const setFrameContractContractIntervalInMonthsAction = (
  contractIntervalInMonths: number
): SetFrameContractContractIntervalInMonthsAction => ({
  type: 'SET_FRAME_CONTRACT_CONTRACT_INTERVAL_IN_MONTHS',
  contractIntervalInMonths,
})
const setFrameContractNoticePeriodInMonthsAction = (
  noticePeriodInMonths: number
): SetFrameContractNoticePeriodInMonthsAction => ({
  type: 'SET_FRAME_CONTRACT_NOTICE_PERIOD_IN_MONTHS',
  noticePeriodInMonths,
})
const setFrameContractIsProRataBillingAction = (
  isProRataBilling: boolean
): SetFrameContractIsProRataBillingAction => ({
  type: 'SET_FRAME_CONTRACT_IS_PRO_RATA_BILLING',
  isProRataBilling,
})
const setFrameContractLeasingStartTriggerAction = (
  leasingStartTrigger: LeasingStartTrigger
): SetFrameContractLeasingStartTriggerAction => ({
  type: 'SET_FRAME_CONTRACT_LEASING_START_TRIGGER',
  leasingStartTrigger,
})
const setFrameContractBillOnlyActiveAssetsAction = (
  billOnlyActiveAssets: boolean
): SetFrameContractBillOnlyActiveAssetsAction => ({
  type: 'SET_FRAME_CONTRACT_BILL_ONLY_ACTIVE_ASSETS',
  billOnlyActiveAssets,
})
const setFrameContractCreditUnusedPeriodsAction = (
  creditUnusedPeriods: boolean
): SetFrameContractCreditUnusedPeriodsAction => ({
  type: 'SET_FRAME_CONTRACT_CREDIT_UNUSED_PERIODS',
  creditUnusedPeriods,
})
const setFrameContractCategoriesAction = (
  categories: FrameContractCategory[]
): SetFrameContractCategoriesAction => ({
  type: 'SET_FRAME_CONTRACT_CATEGORIES',
  categories,
})
const clearFrameContractNameAction = (): ClearFrameContractNameAction => ({
  type: 'CLEAR_FRAME_CONTRACT_NAME',
})
const clearFrameContractContractStartDateAction = (): ClearFrameContractContractStartDateAction => ({
  type: 'CLEAR_FRAME_CONTRACT_CONTRACT_START_DATE',
})
const clearFrameContractBillingIntervalInMonthsAction = (): ClearFrameContractBillingIntervalInMonthsAction => ({
  type: 'CLEAR_FRAME_CONTRACT_BILLING_INTERVAL_IN_MONTHS',
})
const clearFrameContractContractIntervalInMonthsAction = (): ClearFrameContractContractIntervalInMonthsAction => ({
  type: 'CLEAR_FRAME_CONTRACT_CONTRACT_INTERVAL_IN_MONTHS',
})
const clearFrameContractNoticePeriodInMonthsAction = (): ClearFrameContractNoticePeriodInMonthsAction => ({
  type: 'CLEAR_FRAME_CONTRACT_NOTICE_PERIOD_IN_MONTHS',
})
const setFrameContractValidationErrorsAction = (validationErrors: {
  [field: string]: string[]
}): SetFrameContractValidationErrorsAction => ({
  type: 'SET_FRAME_CONTRACT_VALIDATION_ERRORS',
  validationErrors,
})

export const openDialog = (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContract: Maybe<FrameContract>
): void => {
  dispatch(openFrameContractDialogAction(frameContract))
  customersActions.fetchAll(token, dispatch, history)
  maybeSwitch(
    frameContract,
    fc =>
      customerEntitiesActions.fetchAll(token, dispatch, history, fc.customerId),
    () =>
      dispatch(customerEntitiesActions.fetchCustomerEntitiesSuccessAction([]))
  )
}

export const closeDialog = (dispatch: Dispatch): void => {
  dispatch(closeFrameContractDialogAction())
}

export const createFrameContract = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: requests.FrameContractParams
): Promise<void> => {
  dispatch(createFrameContractRequestAction())

  const errors = requests.checkParams('create', params)
  if (anyError(errors)) {
    dispatch(setFrameContractValidationErrorsAction(errors))
    dispatch(createFrameContractErrorAction())
    return
  }

  try {
    const frameContract = await requests.create(token, params)
    closeDialog(dispatch)
    handleSuccess(dispatch, 'Frame Contract created',null)
    dispatch(createFrameContractSuccessAction(frameContract))
    selectFrameContract(token, dispatch, history, frameContract.id)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      createFrameContractErrorAction,
      'display_error'
    )
  }
}

export const updateFrameContract = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number,
  params: requests.FrameContractParams
): Promise<void> => {
  dispatch(updateFrameContractRequestAction())

  const errors = requests.checkParams('update', params)
  if (anyError(errors)) {
    dispatch(setFrameContractValidationErrorsAction(errors))
    dispatch(updateFrameContractErrorAction())
    return
  }

  try {
    const frameContract = await requests.update(token, frameContractId, params)
    closeDialog(dispatch)
    handleSuccess(dispatch, 'Frame Contract updated',null)
    dispatch(updateFrameContractSuccessAction(frameContract))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      updateFrameContractErrorAction,
      'display_error'
    )
  }
}

export const deleteFrameContract = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number
): Promise<void> => {
  dispatch(deleteFrameContractRequestAction())

  try {
    await requests.remove(token, frameContractId)
    handleSuccess(dispatch, 'Frame Contract deleted',null)
    window.location.reload()
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      deleteFrameContractErrorAction,
      'display_error'
    )
  }
}

export const checkIfIsDeletable = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number
): Promise<void> => {
  try {
    const isDeletable = await requests.isDeletable(token, frameContractId)
    if (isDeletable) {
      dispatch(setFrameContractIsDeletableAction())
    }
  } catch (error) {
    handleError(dispatch, history, error, null, 'display_error')
  }
}

export const setName = (dispatch: Dispatch, name: string): void => {
  dispatch(setFrameContractNameAction(name))
}
export const setType = (
  dispatch: Dispatch,
  frameContractType: FrameContractType
): void => {
  dispatch(setFrameContractTypeAction(frameContractType))
}
export const setIsActive = (dispatch: Dispatch, isActive: boolean): void => {
  dispatch(setFrameContractIsActiveAction(isActive))
}
export const setCustomerId = (
  token: string,
  dispatch: Dispatch,
  history: History,
  customerId: number
): void => {
  dispatch(setFrameContractCustomerIdAction(customerId))
  customerEntitiesActions.fetchAll(token, dispatch, history, customerId)
}
export const setCustomerEntityIds = (
  dispatch: Dispatch,
  customerEntityIds: number[]
): void => {
  dispatch(setFrameContractCustomerEntityIdsAction(customerEntityIds))
}
export const setContractStartDate = (
  dispatch: Dispatch,
  contractStartDate: Moment
): void => {
  dispatch(setFrameContractContractStartDateAction(contractStartDate))
}
export const setBillingIntervalInMonths = (
  dispatch: Dispatch,
  billingIntervalInMonths: number
) => {
  dispatch(
    setFrameContractBillingIntervalInMonthsAction(billingIntervalInMonths)
  )
}
export const setContractIntervalInMonths = (
  dispatch: Dispatch,
  contractIntervalInMonths: number
) => {
  dispatch(
    setFrameContractContractIntervalInMonthsAction(contractIntervalInMonths)
  )
}
export const setNoticePeriodInMonths = (
  dispatch: Dispatch,
  noticePeriodInMonths: number
) => {
  dispatch(setFrameContractNoticePeriodInMonthsAction(noticePeriodInMonths))
}
export const setIsProRataBilling = (
  dispatch: Dispatch,
  isProRataBilling: boolean
) => {
  dispatch(setFrameContractIsProRataBillingAction(isProRataBilling))
}
export const setLeasingStartTrigger = (
  dispatch: Dispatch,
  leasingStartTrigger: LeasingStartTrigger
) => {
  dispatch(setFrameContractLeasingStartTriggerAction(leasingStartTrigger))
}
export const setBillOnlyActiveAssets = (
  dispatch: Dispatch,
  billOnlyActiveAssets: boolean
) => {
  dispatch(setFrameContractBillOnlyActiveAssetsAction(billOnlyActiveAssets))
}
export const setCreditUnusedPeriods = (
  dispatch: Dispatch,
  creditUnusedPeriods: boolean
) => {
  dispatch(setFrameContractCreditUnusedPeriodsAction(creditUnusedPeriods))
}
export const setCategories = (
  dispatch: Dispatch,
  categories: FrameContractCategory[]
) => {
  dispatch(setFrameContractCategoriesAction(categories))
}

export const clearName = (dispatch: Dispatch): void => {
  dispatch(clearFrameContractNameAction())
}
export const clearContractStartDate = (dispatch: Dispatch): void => {
  dispatch(clearFrameContractContractStartDateAction())
}
export const clearBillingIntervalInMonths = (dispatch: Dispatch) => {
  dispatch(clearFrameContractBillingIntervalInMonthsAction())
}
export const clearContractIntervalInMonths = (dispatch: Dispatch) => {
  dispatch(clearFrameContractContractIntervalInMonthsAction())
}
export const clearNoticePeriodInMonths = (dispatch: Dispatch) => {
  dispatch(clearFrameContractNoticePeriodInMonthsAction())
}
