import { Action } from '../actions'
import { ExportTemplateField } from 'data/ExportTemplate'
import { ValidationErrors } from 'requests/errors'
import { Maybe, maybeNothing, maybeJust, maybeMap } from 'types/Maybe'
import { setValidationErrors } from './helpers'

export interface ExportTemplateFieldAliasDialogState {
  open: boolean
  field: Maybe<ExportTemplateField>
  validationErrors: ValidationErrors
}

const defaultState = (): ExportTemplateFieldAliasDialogState => ({
  open: false,
  field: maybeNothing(),
  validationErrors: {},
})

export default (
  state: ExportTemplateFieldAliasDialogState = defaultState(),
  action: Action
): ExportTemplateFieldAliasDialogState => {
  switch (action.type) {
    case 'EDIT_EXPORT_TEMPLATE_FIELD_ALIAS':
      return {
        open: true,
        field: maybeJust(action.field),
        validationErrors: {},
      }
    case 'CLOSE_EXPORT_TEMPLATE_FIELD_ALIAS_DIALOG':
      return defaultState()
    case 'SET_EXPORT_TEMPLATE_FIELD_ALIAS':
      if (state.field) {
        return {
          ...state,
          field: maybeMap(state.field, field => ({
            ...field,
            alias: action.alias,
          })),
        }
      } else {
        return state
      }
    case 'SET_EXPORT_TEMPLATE_FIELD_ALIAS_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    default:
      return state
  }
}
