import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { createMuiTheme, Theme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import store from './store'
import LoginCheck from 'containers/LoginCheck'
import * as appActions from './actions/app'

const brightBlue = '#573EEF'
const brightViolet = '#8727DB'
const white = '#ffffff'

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: brightBlue,
    },
    secondary: {
      main: brightViolet,
    },
    background: { default: white },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

appActions.checkLoginState(store.dispatch)

class App extends React.PureComponent {
  public render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <Router>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <LoginCheck />
            </ThemeProvider>
          </Router>
        </Provider>
      </MuiPickersUtilsProvider>
    )
  }
}

export default App
