import { Action } from '../actions'
import { Maybe, maybeNothing, maybeSwitch, maybeJust } from 'types/Maybe'
import { ValidationErrors } from 'requests/errors'
import { savingRequest, savingResponse, setValidationErrors } from './helpers'

export interface CustomerDialogState {
  open: boolean
  saving: boolean
  updatingId: Maybe<number>
  name: Maybe<string>
  address: Maybe<string>
  validationErrors: ValidationErrors
}

const defaultFormData = {
  updatingId: maybeNothing<number>(),
  name: maybeNothing<string>(),
  address: maybeNothing<string>(),
}

const defaultState = (): CustomerDialogState => ({
  open: false,
  saving: false,
  validationErrors: {},
  ...defaultFormData,
})

export default (
  state: CustomerDialogState = defaultState(),
  action: Action
): CustomerDialogState => {
  switch (action.type) {
    case 'OPEN_CUSTOMER_DIALOG':
      const formData = maybeSwitch(
        action.customer,
        customer => ({
          updatingId: maybeJust(customer.id),
          name: maybeJust(customer.name),
          address: maybeJust(customer.address),
        }),
        () => defaultFormData
      )
      return { ...state, open: true, ...formData }
    case 'CLOSE_CUSTOMER_DIALOG':
      return defaultState()
    case 'CREATE_CUSTOMER_REQUEST':
    case 'UPDATE_CUSTOMER_REQUEST':
      return savingRequest(state)
    case 'CREATE_CUSTOMER_SUCCESS':
    case 'CREATE_CUSTOMER_ERROR':
    case 'UPDATE_CUSTOMER_SUCCESS':
    case 'UPDATE_CUSTOMER_ERROR':
      return savingResponse(state)
    case 'SET_CUSTOMER_NAME':
      return { ...state, name: maybeJust(action.name) }
    case 'CLEAR_CUSTOMER_NAME':
      return { ...state, name: maybeNothing() }
    case 'SET_CUSTOMER_ADDRESS':
      return { ...state, address: maybeJust(action.address) }
    case 'CLEAR_CUSTOMER_ADDRESS':
      return { ...state, address: maybeNothing() }
    case 'SET_CUSTOMER_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    default:
      return state
  }
}
