import * as React from 'react'
import Select from '@material-ui/core/Select'
import { MenuItem, FormControl, InputLabel } from '@material-ui/core'
import FormErrors from './FormErrors'
import { Maybe, maybeWithDefault } from 'types/Maybe'

interface Item {
  value: string
  name: string
}

export interface Props {
  value: Maybe<string[]>
  label: string
  items: Item[]
  required: boolean
  onChange: (value: string[]) => void
  validationErrors: string[]
  disabled: boolean
}

export default class FormMultipleSelect extends React.PureComponent<Props> {
  render() {
    const {
      value,
      label,
      items,
      onChange,
      required,
      validationErrors,
      disabled,
    } = this.props
    const hasErrors = validationErrors && validationErrors.length > 0
    return (
      <FormControl
        fullWidth={true}
        required={required}
        margin="normal"
        error={hasErrors}
      >
        <InputLabel error={hasErrors}>{label}</InputLabel>

        <Select
          multiple
          value={maybeWithDefault(value, [])}
          onChange={(e: any) => onChange(e.target.value)}
          error={hasErrors}
          disabled={disabled}
        >
          {items.map(({ value, name }: Item) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormErrors errors={validationErrors} />
      </FormControl>
    )
  }
}
