import { JsonDecoder } from 'ts.data.json'

export type DeviceStatus =
  | 'Leased'
  | 'Extended'
  | 'Stolen'
  | 'Lost'
  | 'Broken'
  | 'Disposed'
  | 'Repurchased'
  | 'Retired'

export const deviceStatuses: Set<DeviceStatus> = new Set<DeviceStatus>([
  'Leased',
  'Extended',
  'Stolen',
  'Lost',
  'Broken',
  'Disposed',
  'Repurchased',
  'Retired',
])

export const decoder: JsonDecoder.Decoder<
  DeviceStatus
> = JsonDecoder.string.then((value: string) => {
  if ((deviceStatuses as Set<string>).has(value)) {
    return JsonDecoder.constant(value as DeviceStatus)
  }
  return JsonDecoder.fail('Invalid device status "' + value + '"')
})
