import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import actions from '../actions'
import { State } from '../reducers'
import { Device } from '../data/Device'
import DeviceTable, {
  DeviceTableColumn,
  Order,
} from '../components/DeviceTable'
import StatusChip from '../components/StatusChip'
import { RouteChildrenProps, withRouter } from 'react-router'
import LoadingIndicator from 'components/LoadingIndicator'
import { DeviceSelection } from 'reducers/devices'
import { CountParams } from 'requests/endpoints'
import { maybeWithDefault, maybeMap, Maybe, maybeSwitch2, maybeNothing } from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
  filterBy: CountParams
}

interface StateProps {
  selectedFrameContractId: Maybe<number>
  fetching: boolean
  data: Device[]
  selection: DeviceSelection
  order: Order
  orderBy: string
  page: number
  rowsPerPage: number
  totalDataCount: number
}

interface DispatchProps {
  fetchDevices: (
    page: number,
    rowsPerPage: number,
    order: Order,
    orderBy: string
  ) => void
  openDetails: (key: string) => void
  toggleSelect: (key: string) => void
  selectAll: () => void
  unselectAll: () => void
  sortBy: (column: string) => void
  setPage: (page: number) => void
  setRowsPerPage: (rowsPerPage: number) => void
  exportDevices: () => void
}

interface Props extends StateProps, DispatchProps, OwnProps {}

const DATE_FORMAT = 'MMMM Do YYYY'
const NULL_PLACEHOLDER = '–'

class Devices extends React.PureComponent<Props> {
  public componentDidMount() {
    if (this.props.page === 0) {
      this.props.fetchDevices(
        0,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy
      )
    } else {
      this.props.setPage(0)
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      !maybeSwitch2(
        this.props.selectedFrameContractId,
        prevProps.selectedFrameContractId,
        (id1, id2) => id1 === id2,
        () => false
      ) ||
      this.props.page !== prevProps.page ||
      this.props.rowsPerPage !== prevProps.rowsPerPage ||
      this.props.order !== prevProps.order ||
      this.props.orderBy !== prevProps.orderBy
    ) {
      this.props.fetchDevices(
        this.props.page,
        this.props.rowsPerPage,
        this.props.order,
        this.props.orderBy
      )
    }
  }

  public componentWillUnmount() {
    this.props.unselectAll()
  }

  private columns = (): DeviceTableColumn[] =>
    [
      this.props.filterBy.type === 'leasing'
        ? null
        : // {
          //     id: 'billingStatus',
          //     label: 'Billing status',
          //     render: (device: Device) => (
          //       <StatusChip
          //         statusInfo={{
          //           type: 'billingStatus',
          //           status: device.billingStatus,
          //         }}
          //       />
          //     ),
          //   }
          {
            id: 'status',
            label: 'Status',
            render: (device: Device) => (
              <StatusChip
                statusInfo={{ type: 'deviceStatus', status: device.status }}
              />
            ),
          },
      {
        id: 'name',
        label: 'Name',
        render: (device: Device) => device.name,
      },
      {
        id: 'deliveryDate',
        label: 'Delivery date',
        render: (device: Device) =>
          maybeWithDefault(
            maybeMap(device.deliveryDate, date => date.format(DATE_FORMAT)),
            NULL_PLACEHOLDER
          ),
      },
      {
        id: 'warrantyStartDate',
        label: 'Warranty start date',
        render: (device: Device) =>
          maybeWithDefault(
            maybeMap(device.warrantyStartDate, date =>
              date.format(DATE_FORMAT)
            ),
            NULL_PLACEHOLDER
          ),
      },
      {
        id: 'warrantyEndDate',
        label: 'Warranty end date',
        render: (device: Device) =>
          maybeWithDefault(
            maybeMap(device.warrantyEndDate, date => date.format(DATE_FORMAT)),
            NULL_PLACEHOLDER
          ),
      },
      {
        id: 'ownerName',
        label: 'Owner name',
        render: (device: Device) => device.ownerName,
      },
      // {
      //   id: 'leasingRent',
      //   label: 'Leasing rent',
      //   render: (device: Device) =>
      //     device.leasingRent !== null
      //       ? device.leasingRent.toString()
      //       : NULL_PLACEHOLDER,
      // },
      // {
      //   id: 'residualValue',
      //   label: 'Residual value',
      //   render: (device: Device) =>
      //     device.residualValue !== null
      //       ? device.residualValue.toString()
      //       : NULL_PLACEHOLDER,
      // },
    ].filter(v => v !== null)

  public render() {
    const {
      fetching,
      data,
      selection,
      openDetails,
      toggleSelect,
      selectAll,
      unselectAll,
      order,
      orderBy,
      sortBy,
      filterBy,
      page,
      rowsPerPage,
      setPage,
      setRowsPerPage,
      totalDataCount,
      exportDevices,
    } = this.props

    if (fetching) {
      return <LoadingIndicator size={maybeNothing()} />
    }

    return (
      <DeviceTable
        columns={this.columns()}
        data={data}
        status={filterBy.status}
        selection={selection}
        onRowClick={(device: Device) => openDetails(device.name)}
        toggleSelect={(device: Device) => toggleSelect(device.name)}
        selectAll={selectAll}
        unselectAll={unselectAll}
        order={order}
        orderBy={orderBy}
        sortBy={sortBy}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        totalDataCount={totalDataCount}
        exportDevices={exportDevices}
      />
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  selectedFrameContractId: state.frameContracts.selectedFrameContractId,
  fetching: state.devices.fetching,
  data: state.devices.data,
  selection: state.devices.selection,
  order: state.devices.order,
  orderBy: state.devices.orderBy,
  page: state.devices.page,
  rowsPerPage: state.devices.rowsPerPage,
  totalDataCount: state.devices.totalDataCount,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  fetchDevices: (
    page: number,
    rowsPerPage: number,
    order: Order,
    orderBy: string
  ) =>
    actions.devices.fetchByStatus(ownProps.token, dispatch, ownProps.history, {
      filterBy: ownProps.filterBy,
      page,
      rowsPerPage,
      order,
      orderBy,
    }),
  openDetails: (id: string) =>
    actions.deviceDetailsDialog.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history,
      id
    ),
  toggleSelect: (id: string) =>
    actions.devices.toggleSelectDevice(dispatch, id),
  selectAll: () => actions.devices.selectAllDevices(dispatch),
  unselectAll: () => actions.devices.unselectAllDevices(dispatch),
  sortBy: (column: string) => actions.devices.sortDevicesBy(dispatch, column),
  setPage: (page: number) => actions.devices.setPage(dispatch, page),
  setRowsPerPage: (rowsPerPage: number) =>
    actions.devices.setRowsPerPage(dispatch, rowsPerPage),
  exportDevices: () =>
    actions.exportTemplateDialog.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history
    ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Devices)
)
