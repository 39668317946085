import { History } from 'history'
import { Moment } from 'moment'
import { Dispatch } from 'redux'
import { anyError, ValidationErrors } from 'requests/errors'
import { LeasingCategory } from '../data/LeasingCategory'
import * as requests from '../requests/LeasingCategory'
import { handleError } from './errors'
import { Maybe } from 'types/Maybe'
import { handleSuccess } from './success'


interface OpenLeasingCategoriesDialogAction {
  type: 'OPEN_LEASING_CATEGORIES_DIALOG'
}
interface CloseLeasingCategoriesDialogAction {
  type: 'CLOSE_LEASING_CATEGORIES_DIALOG'
}
interface OpenLeasingCategoryFormAction {
  type: 'OPEN_LEASING_CATEGORY_FORM'
  leasingCategory: Maybe<LeasingCategory>
}
interface OpenLeasingCategoryViewAction {
  type: 'OPEN_LEASING_CATEGORY_VIEW'
  leasingCategory: LeasingCategory
}
interface CloseLeasingCategoryFormAction {
  type: 'CLOSE_LEASING_CATEGORY_FORM'
}
interface CreateLeasingCategoryRequestAction {
  type: 'CREATE_LEASING_CATEGORY_REQUEST'
}
interface CreateLeasingCategorySuccessAction {
  type: 'CREATE_LEASING_CATEGORY_SUCCESS'
  leasingCategory: LeasingCategory
}
interface CreateLeasingCategoryErrorAction {
  type: 'CREATE_LEASING_CATEGORY_ERROR'
}
interface UpdateLeasingCategoryRequestAction {
  type: 'UPDATE_LEASING_CATEGORY_REQUEST'
}
interface UpdateLeasingCategorySuccessAction {
  type: 'UPDATE_LEASING_CATEGORY_SUCCESS'
  leasingCategory: LeasingCategory
}
interface UpdateLeasingCategoryErrorAction {
  type: 'UPDATE_LEASING_CATEGORY_ERROR'
}
interface SetLeasingCategoryNameAction {
  type: 'SET_LEASING_CATEGORY_NAME'
  name: string
}
interface SetLeasingCategoryDescriptionAction {
  type: 'SET_LEASING_CATEGORY_DESCRIPTION'
  description: string
}
interface SetLeasingCategoryNumberOfLeasingPeriodsAction {
  type: 'SET_LEASING_CATEGORY_NUMBER_OF_LEASING_PERIODS'
  numberOfLeasingPeriods: number
}
interface SetLeasingCategoryLeasingCoefficient {
  type: 'SET_LEASING_CATEGORY_LEASING_COEFFICIENT'
  leasingCoefficient: number
}
interface SetLeasingCategoryPurchaseOption {
  type: 'SET_LEASING_CATEGORY_PURCHASE_OPTION'
  purchaseOption: number
}
interface SetLeasingCategoryPurchaseOptionUser {
  type: 'SET_LEASING_CATEGORY_PURCHASE_OPTION_USER'
  purchaseOptionUser: number
}
interface SetLeasingCategoryNormalLeasingPercentage {
  type: 'SET_LEASING_CATEGORY_NORMAL_LEASING_PERCENTAGE'
  normalLeasingPercentage: number
}
interface SetLeasingCategoryExtensionLeasingPercentage {
  type: 'SET_LEASING_CATEGORY_EXTENSION_LEASING_PERCENTAGE'
  extensionLeasingPercentage: number
}
interface SetLeasingCategoryEarlyPurchaseReductionPercentage {
  type: 'SET_LEASING_CATEGORY_EARLY_PURCHASE_REDUCTION_PERCENTAGE'
  earlyPurchaseReductionPercentage: number
}
interface SetLeasingCategoryExtensionPurchaseReductionPercentage {
  type: 'SET_LEASING_CATEGORY_EXTENSION_PURCHASE_REDUCTION_PERCENTAGE'
  extensionPurchaseReductionPercentage: number
}
interface SetLeasingCategoryEarlyReturnReductionPercentage {
  type: 'SET_LEASING_CATEGORY_EARLY_RETURN_REDUCTION_PERCENTAGE'
  earlyReturnReductionPercentage: number
}
// interface SetLeasingCategoryEndOfLifeProRation {
//   type: 'SET_LEASING_CATEGORY_END_OF_LIFE_PRO_RATION'
//   endOfLifeProRation: number
// }
interface SetLeasingCategoryPao6Percentage {
  type: 'SET_LEASING_CATEGORY_PAO6_PERCENTAGE'
  pao6Percentage: number
}
// interface SetLeasingCategoryFinancialRateAction {
//   type: 'SET_LEASING_CATEGORY_FINANCIAL_RATE'
//   financialRate: number
// }
interface SetLeasingCategoryValidFromAction {
  type: 'SET_LEASING_CATEGORY_VALID_FROM'
  validFrom: Moment
}
interface SetLeasingCategoryValidToAction {
  type: 'SET_LEASING_CATEGORY_VALID_TO'
  validTo: Moment
}
interface SetLeasingCategoryReportingCategoryAction {
  type: 'SET_LEASING_CATEGORY_REPORTING_CATEGORY'
  reportingCategory: string
}
interface SetLeasingCategoryBillingCategoryAction {
  type: 'SET_LEASING_CATEGORY_BILLING_CATEGORY'
  billingCategory: string
}
interface ClearLeasingCategoryNameAction {
  type: 'CLEAR_LEASING_CATEGORY_NAME'
}
interface ClearLeasingCategoryDescriptionAction {
  type: 'CLEAR_LEASING_CATEGORY_DESCRIPTION'
}
interface ClearLeasingCategoryNumberOfLeasingPeriodsAction {
  type: 'CLEAR_LEASING_CATEGORY_NUMBER_OF_LEASING_PERIODS'
}
interface ClearLeasingCategoryLeasingCoefficient {
  type: 'CLEAR_LEASING_CATEGORY_LEASING_COEFFICIENT'
}
interface ClearLeasingCategoryPurchaseOption {
  type: 'CLEAR_LEASING_CATEGORY_PURCHASE_OPTION'
}
interface ClearLeasingCategoryPurchaseOptionUser {
  type: 'CLEAR_LEASING_CATEGORY_PURCHASE_OPTION_USER'
}
interface ClearLeasingCategoryNormalLeasingPercentage {
  type: 'CLEAR_LEASING_CATEGORY_NORMAL_LEASING_PERCENTAGE'
}
interface ClearLeasingCategoryExtensionLeasingPercentage {
  type: 'CLEAR_LEASING_CATEGORY_EXTENSION_LEASING_PERCENTAGE'
}
interface ClearLeasingCategoryEarlyPurchaseReductionPercentage {
  type: 'CLEAR_LEASING_CATEGORY_EARLY_PURCHASE_REDUCTION_PERCENTAGE'
}
interface ClearLeasingCategoryExtensionPurchaseReductionPercentage {
  type: 'CLEAR_LEASING_CATEGORY_EXTENSION_PURCHASE_REDUCTION_PERCENTAGE'
}
interface ClearLeasingCategoryEarlyReturnReductionPercentage {
  type: 'CLEAR_LEASING_CATEGORY_EARLY_RETURN_REDUCTION_PERCENTAGE'
}
// interface ClearLeasingCategoryEndOfLifeProRation {
//   type: 'CLEAR_LEASING_CATEGORY_END_OF_LIFE_PRO_RATION'
// }
interface ClearLeasingCategoryPao6Percentage {
  type: 'CLEAR_LEASING_CATEGORY_PAO6_PERCENTAGE'
}
// interface ClearLeasingCategoryFinancialRateAction {
//   type: 'CLEAR_LEASING_CATEGORY_FINANCIAL_RATE'
// }
interface ClearLeasingCategoryValidFromAction {
  type: 'CLEAR_LEASING_CATEGORY_VALID_FROM'
}
interface ClearLeasingCategoryValidToAction {
  type: 'CLEAR_LEASING_CATEGORY_VALID_TO'
}
interface ClearLeasingCategoryReportingCategoryAction {
  type: 'CLEAR_LEASING_CATEGORY_REPORTING_CATEGORY'
}
interface ClearLeasingCategoryBillingCategoryAction {
  type: 'CLEAR_LEASING_CATEGORY_BILLING_CATEGORY'
}
interface SetLeasingCategoryValidationErrorsAction {
  type: 'SET_LEASING_CATEGORY_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}
interface CopyLeasingCategoryRequestAction {
  type: 'COPY_LEASING_CATEGORY_REQUEST'
}
interface CopyLeasingCategorySuccessAction {
  type: 'COPY_LEASING_CATEGORY_SUCCESS'
  leasingCategory: LeasingCategory
}
interface CopyLeasingCategoryErrorAction {
  type: 'COPY_LEASING_CATEGORY_ERROR'
}
interface DeleteLeasingCategoryRequestAction {
  type: 'DELETE_LEASING_CATEGORY_REQUEST'
}
interface DeleteLeasingCategorySuccessAction {
  type: 'DELETE_LEASING_CATEGORY_SUCCESS'
  leasingCategoryId: number
}
interface DeleteLeasingCategoryErrorAction {
  type: 'DELETE_LEASING_CATEGORY_ERROR'
}

export type LeasingCategoriesDialogAction =
  | OpenLeasingCategoriesDialogAction
  | CloseLeasingCategoriesDialogAction
  | OpenLeasingCategoryFormAction
  | OpenLeasingCategoryViewAction
  | CloseLeasingCategoryFormAction
  | CreateLeasingCategoryRequestAction
  | CreateLeasingCategorySuccessAction
  | CreateLeasingCategoryErrorAction
  | UpdateLeasingCategoryRequestAction
  | UpdateLeasingCategorySuccessAction
  | UpdateLeasingCategoryErrorAction
  | SetLeasingCategoryNameAction
  | SetLeasingCategoryDescriptionAction
  | SetLeasingCategoryNumberOfLeasingPeriodsAction
  | SetLeasingCategoryLeasingCoefficient
  | SetLeasingCategoryPurchaseOption
  | SetLeasingCategoryPurchaseOptionUser
  | SetLeasingCategoryNormalLeasingPercentage
  | SetLeasingCategoryExtensionLeasingPercentage
  | SetLeasingCategoryEarlyPurchaseReductionPercentage
  | SetLeasingCategoryExtensionPurchaseReductionPercentage
  | SetLeasingCategoryEarlyReturnReductionPercentage
  // | SetLeasingCategoryEndOfLifeProRation
  | SetLeasingCategoryPao6Percentage
  // | SetLeasingCategoryFinancialRateAction
  | SetLeasingCategoryValidFromAction
  | SetLeasingCategoryValidToAction
  | SetLeasingCategoryReportingCategoryAction
  | SetLeasingCategoryBillingCategoryAction
  | ClearLeasingCategoryNameAction
  | ClearLeasingCategoryDescriptionAction
  | ClearLeasingCategoryNumberOfLeasingPeriodsAction
  | ClearLeasingCategoryLeasingCoefficient
  | ClearLeasingCategoryPurchaseOption
  | ClearLeasingCategoryPurchaseOptionUser
  | ClearLeasingCategoryNormalLeasingPercentage
  | ClearLeasingCategoryExtensionLeasingPercentage
  | ClearLeasingCategoryEarlyPurchaseReductionPercentage
  | ClearLeasingCategoryExtensionPurchaseReductionPercentage
  | ClearLeasingCategoryEarlyReturnReductionPercentage
  // | ClearLeasingCategoryEndOfLifeProRation
  | ClearLeasingCategoryPao6Percentage
  // | ClearLeasingCategoryFinancialRateAction
  | ClearLeasingCategoryValidFromAction
  | ClearLeasingCategoryValidToAction
  | ClearLeasingCategoryReportingCategoryAction
  | ClearLeasingCategoryBillingCategoryAction
  | SetLeasingCategoryValidationErrorsAction
  | CopyLeasingCategoryRequestAction
  | CopyLeasingCategorySuccessAction
  | CopyLeasingCategoryErrorAction
  | DeleteLeasingCategoryRequestAction
  | DeleteLeasingCategorySuccessAction
  | DeleteLeasingCategoryErrorAction

const openLeasingCategoriesDialogAction = (): OpenLeasingCategoriesDialogAction => ({
  type: 'OPEN_LEASING_CATEGORIES_DIALOG',
})
const closeLeasingCategoriesDialogAction = (): CloseLeasingCategoriesDialogAction => ({
  type: 'CLOSE_LEASING_CATEGORIES_DIALOG',
})
const openLeasingCategoryFormAction = (
  leasingCategory: Maybe<LeasingCategory>
): OpenLeasingCategoryFormAction => ({
  type: 'OPEN_LEASING_CATEGORY_FORM',
  leasingCategory,
})
const openLeasingCategoryViewAction = (
  leasingCategory: LeasingCategory
): OpenLeasingCategoryViewAction => ({
  type: 'OPEN_LEASING_CATEGORY_VIEW',
  leasingCategory,
})
const closeLeasingCategoryFormAction = (): CloseLeasingCategoryFormAction => ({
  type: 'CLOSE_LEASING_CATEGORY_FORM',
})
const createLeasingCategoryRequestAction = (): CreateLeasingCategoryRequestAction => ({
  type: 'CREATE_LEASING_CATEGORY_REQUEST',
})
const createLeasingCategorySuccessAction = (
  leasingCategory: LeasingCategory
): CreateLeasingCategorySuccessAction => ({
  type: 'CREATE_LEASING_CATEGORY_SUCCESS',
  leasingCategory,
})
const createLeasingCategoryErrorAction = (): CreateLeasingCategoryErrorAction => ({
  type: 'CREATE_LEASING_CATEGORY_ERROR',
})
const updateLeasingCategoryRequestAction = (): UpdateLeasingCategoryRequestAction => ({
  type: 'UPDATE_LEASING_CATEGORY_REQUEST',
})
const updateLeasingCategorySuccessAction = (
  leasingCategory: LeasingCategory
): UpdateLeasingCategorySuccessAction => ({
  type: 'UPDATE_LEASING_CATEGORY_SUCCESS',
  leasingCategory,
})
const updateLeasingCategoryErrorAction = (): UpdateLeasingCategoryErrorAction => ({
  type: 'UPDATE_LEASING_CATEGORY_ERROR',
})
const setLeasingCategoryNameAction = (
  name: string
): SetLeasingCategoryNameAction => ({
  type: 'SET_LEASING_CATEGORY_NAME',
  name,
})
const setLeasingCategoryDescriptionAction = (
  description: string
): SetLeasingCategoryDescriptionAction => ({
  type: 'SET_LEASING_CATEGORY_DESCRIPTION',
  description,
})
const setLeasingCategoryNumberOfLeasingPeriodsAction = (
  numberOfLeasingPeriods: number
): SetLeasingCategoryNumberOfLeasingPeriodsAction => ({
  type: 'SET_LEASING_CATEGORY_NUMBER_OF_LEASING_PERIODS',
  numberOfLeasingPeriods,
})
const setLeasingCategoryLeasingCoefficient = (
  leasingCoefficient: number
): SetLeasingCategoryLeasingCoefficient => ({
  type: 'SET_LEASING_CATEGORY_LEASING_COEFFICIENT',
  leasingCoefficient,
})
const setLeasingCategoryPurchaseOption = (
  purchaseOption: number
): SetLeasingCategoryPurchaseOption => ({
  type: 'SET_LEASING_CATEGORY_PURCHASE_OPTION',
  purchaseOption,
})
const setLeasingCategoryPurchaseOptionUser = (
  purchaseOptionUser: number
): SetLeasingCategoryPurchaseOptionUser => ({
  type: 'SET_LEASING_CATEGORY_PURCHASE_OPTION_USER',
  purchaseOptionUser,
})
const setLeasingCategoryNormalLeasingPercentage = (
  normalLeasingPercentage: number
): SetLeasingCategoryNormalLeasingPercentage => ({
  type: 'SET_LEASING_CATEGORY_NORMAL_LEASING_PERCENTAGE',
  normalLeasingPercentage,
})
const setLeasingCategoryExtensionLeasingPercentage = (
  extensionLeasingPercentage: number
): SetLeasingCategoryExtensionLeasingPercentage => ({
  type: 'SET_LEASING_CATEGORY_EXTENSION_LEASING_PERCENTAGE',
  extensionLeasingPercentage,
})
const setLeasingCategoryEarlyPurchaseReductionPercentage = (
  earlyPurchaseReductionPercentage: number
): SetLeasingCategoryEarlyPurchaseReductionPercentage => ({
  type: 'SET_LEASING_CATEGORY_EARLY_PURCHASE_REDUCTION_PERCENTAGE',
  earlyPurchaseReductionPercentage,
})
const setLeasingCategoryExtensionPurchaseReductionPercentage = (
  extensionPurchaseReductionPercentage: number
): SetLeasingCategoryExtensionPurchaseReductionPercentage => ({
  type: 'SET_LEASING_CATEGORY_EXTENSION_PURCHASE_REDUCTION_PERCENTAGE',
  extensionPurchaseReductionPercentage,
})
const setLeasingCategoryEarlyReturnReductionPercentage = (
  earlyReturnReductionPercentage: number
): SetLeasingCategoryEarlyReturnReductionPercentage => ({
  type: 'SET_LEASING_CATEGORY_EARLY_RETURN_REDUCTION_PERCENTAGE',
  earlyReturnReductionPercentage,
})
// const setLeasingCategoryEndOfLifeProRation = (
//   endOfLifeProRation: number
// ): SetLeasingCategoryEndOfLifeProRation => ({
//   type: 'SET_LEASING_CATEGORY_END_OF_LIFE_PRO_RATION',
//   endOfLifeProRation,
// })
const setLeasingCategoryPao6Percentage = (
  pao6Percentage: number
): SetLeasingCategoryPao6Percentage => ({
  type: 'SET_LEASING_CATEGORY_PAO6_PERCENTAGE',
  pao6Percentage,
})
// const setLeasingCategoryFinancialRateAction = (
//   financialRate: number
// ): SetLeasingCategoryFinancialRateAction => ({
//   type: 'SET_LEASING_CATEGORY_FINANCIAL_RATE',
//   financialRate,
// })
const setLeasingCategoryValidFromAction = (
  validFrom: Moment
): SetLeasingCategoryValidFromAction => ({
  type: 'SET_LEASING_CATEGORY_VALID_FROM',
  validFrom,
})
const setLeasingCategoryValidToAction = (
  validTo: Moment
): SetLeasingCategoryValidToAction => ({
  type: 'SET_LEASING_CATEGORY_VALID_TO',
  validTo,
})
const setLeasingCategoryReportingCategoryAction = (
  reportingCategory: string
): SetLeasingCategoryReportingCategoryAction => ({
  type: 'SET_LEASING_CATEGORY_REPORTING_CATEGORY',
  reportingCategory,
})
const setLeasingCategoryBillingCategoryAction = (
  billingCategory: string
): SetLeasingCategoryBillingCategoryAction => ({
  type: 'SET_LEASING_CATEGORY_BILLING_CATEGORY',
  billingCategory,
})

const clearLeasingCategoryNameAction = (): ClearLeasingCategoryNameAction => ({
  type: 'CLEAR_LEASING_CATEGORY_NAME',
})
const clearLeasingCategoryDescriptionAction = (): ClearLeasingCategoryDescriptionAction => ({
  type: 'CLEAR_LEASING_CATEGORY_DESCRIPTION',
})
const clearLeasingCategoryNumberOfLeasingPeriodsAction = (): ClearLeasingCategoryNumberOfLeasingPeriodsAction => ({
  type: 'CLEAR_LEASING_CATEGORY_NUMBER_OF_LEASING_PERIODS',
})
const clearLeasingCategoryLeasingCoefficient = (): ClearLeasingCategoryLeasingCoefficient => ({
  type: 'CLEAR_LEASING_CATEGORY_LEASING_COEFFICIENT',
})
const clearLeasingCategoryPruchaseOption = (): ClearLeasingCategoryPurchaseOption => ({
  type: 'CLEAR_LEASING_CATEGORY_PURCHASE_OPTION',
})
const clearLeasingCategoryPurchaseOptionUser = (): ClearLeasingCategoryPurchaseOptionUser => ({
  type: 'CLEAR_LEASING_CATEGORY_PURCHASE_OPTION_USER',
})
const clearLeasingCategoryNormalLeasingPercentage = (): ClearLeasingCategoryNormalLeasingPercentage => ({
  type: 'CLEAR_LEASING_CATEGORY_NORMAL_LEASING_PERCENTAGE',
})
const clearLeasingCategoryExtensionLeasingPercentage = (): ClearLeasingCategoryExtensionLeasingPercentage => ({
  type: 'CLEAR_LEASING_CATEGORY_EXTENSION_LEASING_PERCENTAGE',
})
const clearLeasingCategoryEarlyPurchaseReductionPercentage = (): ClearLeasingCategoryEarlyPurchaseReductionPercentage => ({
  type: 'CLEAR_LEASING_CATEGORY_EARLY_PURCHASE_REDUCTION_PERCENTAGE',
})
const clearLeasingCategoryExtensionPurchaseReductionPercentage = (): ClearLeasingCategoryExtensionPurchaseReductionPercentage => ({
  type: 'CLEAR_LEASING_CATEGORY_EXTENSION_PURCHASE_REDUCTION_PERCENTAGE',
})
const clearLeasingCategoryEarlyReturnReductionPercentage = (): ClearLeasingCategoryEarlyReturnReductionPercentage => ({
  type: 'CLEAR_LEASING_CATEGORY_EARLY_RETURN_REDUCTION_PERCENTAGE',
})
// const clearLeasingCategoryEndOfLifeProRation = (): ClearLeasingCategoryEndOfLifeProRation => ({
//   type: 'CLEAR_LEASING_CATEGORY_END_OF_LIFE_PRO_RATION',
// })
const clearLeasingCategoryPao6Percentage = (): ClearLeasingCategoryPao6Percentage => ({
  type: 'CLEAR_LEASING_CATEGORY_PAO6_PERCENTAGE',
})
// const clearLeasingCategoryFinancialRateAction = (): ClearLeasingCategoryFinancialRateAction => ({
//   type: 'CLEAR_LEASING_CATEGORY_FINANCIAL_RATE',
// })
const clearLeasingCategoryValidFromAction = (): ClearLeasingCategoryValidFromAction => ({
  type: 'CLEAR_LEASING_CATEGORY_VALID_FROM',
})
const clearLeasingCategoryValidToAction = (): ClearLeasingCategoryValidToAction => ({
  type: 'CLEAR_LEASING_CATEGORY_VALID_TO',
})
const clearLeasingCategoryReportingCategoryAction = (): ClearLeasingCategoryReportingCategoryAction => ({
  type: 'CLEAR_LEASING_CATEGORY_REPORTING_CATEGORY',
})
const clearLeasingCategoryBillingCategoryAction = (): ClearLeasingCategoryBillingCategoryAction => ({
  type: 'CLEAR_LEASING_CATEGORY_BILLING_CATEGORY',
})
const setLeasingCategoryValidationErrorsAction = (validationErrors: {
  [field: string]: string[]
}): SetLeasingCategoryValidationErrorsAction => ({
  type: 'SET_LEASING_CATEGORY_VALIDATION_ERRORS',
  validationErrors,
})

const copyLeasingCategoryRequestAction = (): CopyLeasingCategoryRequestAction => ({
  type: 'COPY_LEASING_CATEGORY_REQUEST',
})
const copyLeasingCategorySuccessAction = (
  leasingCategory: LeasingCategory
): CopyLeasingCategorySuccessAction => ({
  type: 'COPY_LEASING_CATEGORY_SUCCESS',
  leasingCategory,
})
const copyLeasingCategoryErrorAction = (): CopyLeasingCategoryErrorAction => ({
  type: 'COPY_LEASING_CATEGORY_ERROR',
})

const deleteLeasingCategoryRequestAction = (): DeleteLeasingCategoryRequestAction => ({
  type: 'DELETE_LEASING_CATEGORY_REQUEST',
})
const deleteLeasingCategorySuccessAction = (
  leasingCategoryId: number
): DeleteLeasingCategorySuccessAction => ({
  type: 'DELETE_LEASING_CATEGORY_SUCCESS',
  leasingCategoryId,
})
const deleteLeasingCategoryErrorAction = (): DeleteLeasingCategoryErrorAction => ({
  type: 'DELETE_LEASING_CATEGORY_ERROR',
})

export const openDialog = (dispatch: Dispatch): void => {
  dispatch(openLeasingCategoriesDialogAction())
}

export const closeDialog = (dispatch: Dispatch): void => {
  dispatch(closeLeasingCategoriesDialogAction())
}

export const openForm = (
  dispatch: Dispatch,
  leasingCategory: Maybe<LeasingCategory>
) => {
  dispatch(openLeasingCategoryFormAction(leasingCategory))
}

export const openView = (
  dispatch: Dispatch,
  leasingCategory: LeasingCategory
) => {
  dispatch(openLeasingCategoryViewAction(leasingCategory))
}

export const closeForm = (dispatch: Dispatch) => {
  dispatch(closeLeasingCategoryFormAction())
}

export const createLeasingCategory = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: requests.LeasingCategoryParams
): Promise<void> => {
  dispatch(createLeasingCategoryRequestAction())

  const errors = requests.checkParams('create', params)
  if (anyError(errors)) {
    dispatch(setLeasingCategoryValidationErrorsAction(errors))
    dispatch(createLeasingCategoryErrorAction())
    return
  }

  try {
    const leasingCategory = await requests.create(token, params)
    dispatch(createLeasingCategorySuccessAction(leasingCategory))
    closeForm(dispatch)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      createLeasingCategoryErrorAction,
      'display_error'
    )
  }
}

export const updateLeasingCategory = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  leasingCategoryId: number,
  params: requests.LeasingCategoryParams
): Promise<void> => {
  dispatch(updateLeasingCategoryRequestAction())

  const errors = requests.checkParams('update', params)
  if (anyError(errors)) {
    dispatch(setLeasingCategoryValidationErrorsAction(errors))
    dispatch(updateLeasingCategoryErrorAction())
    return
  }

  try {
    const leasingCategory = await requests.update(
      token,
      leasingCategoryId,
      params
    )
    dispatch(updateLeasingCategorySuccessAction(leasingCategory))
    closeForm(dispatch)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      updateLeasingCategoryErrorAction,
      'display_error'
    )
  }
}

export const setName = (dispatch: Dispatch, name: string): void => {
  dispatch(setLeasingCategoryNameAction(name))
}

export const setDescription = (
  dispatch: Dispatch,
  description: string
): void => {
  dispatch(setLeasingCategoryDescriptionAction(description))
}

export const setNumberOfLeasingPeriods = (
  dispatch: Dispatch,
  numberOfLeasingPeriods: number
): void => {
  dispatch(
    setLeasingCategoryNumberOfLeasingPeriodsAction(numberOfLeasingPeriods)
  )
}
export const setLeasingCoefficient = (
  dispatch: Dispatch,
  leasingCoefficient: number
): void => {
  dispatch(setLeasingCategoryLeasingCoefficient(leasingCoefficient))
}
export const setPurchaseOption = (
  dispatch: Dispatch,
  purchaseOption: number
): void => {
  dispatch(setLeasingCategoryPurchaseOption(purchaseOption))
}
export const setPurchaseOptionUser = (
  dispatch: Dispatch,
  purchaseOptionUser: number
): void => {
  dispatch(setLeasingCategoryPurchaseOptionUser(purchaseOptionUser))
}
export const setNormalLeasingPercentage = (
  dispatch: Dispatch,
  normalLeasingPercentage: number
): void => {
  dispatch(setLeasingCategoryNormalLeasingPercentage(normalLeasingPercentage))
}
export const setExtensionLeasingPercentage = (
  dispatch: Dispatch,
  extensionLeasingPercentage: number
): void => {
  dispatch(
    setLeasingCategoryExtensionLeasingPercentage(extensionLeasingPercentage)
  )
}
export const setEarlyPurchaseReductionPercentage = (
  dispatch: Dispatch,
  earlyPurchaseReductionPercentage: number
): void => {
  dispatch(
    setLeasingCategoryEarlyPurchaseReductionPercentage(
      earlyPurchaseReductionPercentage
    )
  )
}
export const setExtensionPurchaseReductionPercentage = (
  dispatch: Dispatch,
  extensionPurchaseReductionPercentage: number
): void => {
  dispatch(
    setLeasingCategoryExtensionPurchaseReductionPercentage(
      extensionPurchaseReductionPercentage
    )
  )
}
export const setEarlyReturnReductionPercentage = (
  dispatch: Dispatch,
  earlyReturnReductionPercentage: number
): void => {
  dispatch(
    setLeasingCategoryEarlyReturnReductionPercentage(
      earlyReturnReductionPercentage
    )
  )
}
// export const setEndOfLifeProRation = (
//   dispatch: Dispatch,
//   endOfLifeProRation: number
// ): void => {
//   dispatch(setLeasingCategoryEndOfLifeProRation(endOfLifeProRation))
// }
export const setPao6Percentage = (
  dispatch: Dispatch,
  pao6Percentage: number
): void => {
  dispatch(setLeasingCategoryPao6Percentage(pao6Percentage))
}
// export const setFinancialRate = (
//   dispatch: Dispatch,
//   financialRate: number
// ): void => {
//   dispatch(setLeasingCategoryFinancialRateAction(financialRate))
// }
export const setValidFrom = (dispatch: Dispatch, validFrom: Moment): void => {
  dispatch(setLeasingCategoryValidFromAction(validFrom))
}
export const setValidTo = (dispatch: Dispatch, validTo: Moment): void => {
  dispatch(setLeasingCategoryValidToAction(validTo))
}
export const setReportingCategory = (
  dispatch: Dispatch,
  reportingCategory: string
): void => {
  dispatch(setLeasingCategoryReportingCategoryAction(reportingCategory))
}
export const setBillingCategory = (
  dispatch: Dispatch,
  billingCategory: string
): void => {
  dispatch(setLeasingCategoryBillingCategoryAction(billingCategory))
}
export const clearName = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryNameAction())
}

export const clearDescription = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryDescriptionAction())
}

export const clearNumberOfLeasingPeriods = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryNumberOfLeasingPeriodsAction())
}
export const clearLeasingCoefficient = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryLeasingCoefficient())
}
export const clearPurchaseOption = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryPruchaseOption())
}
export const clearPurchaseOptionUser = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryPurchaseOptionUser())
}
export const clearNormalLeasingPercentage = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryNormalLeasingPercentage())
}
export const clearExtensionLeasingPercentage = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryExtensionLeasingPercentage())
}
export const clearEarlyPurchaseReductionPercentage = (
  dispatch: Dispatch
): void => {
  dispatch(clearLeasingCategoryEarlyPurchaseReductionPercentage())
}
export const clearExtensionPurchaseReductionPercentage = (
  dispatch: Dispatch
): void => {
  dispatch(clearLeasingCategoryExtensionPurchaseReductionPercentage())
}
export const clearEarlyReturnReductionPercentage = (
  dispatch: Dispatch
): void => {
  dispatch(clearLeasingCategoryEarlyReturnReductionPercentage())
}
// export const clearEndOfLifeProRation = (dispatch: Dispatch): void => {
//   dispatch(clearLeasingCategoryEndOfLifeProRation())
// }
export const clearPao6Percentage = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryPao6Percentage())
}
// export const clearFinancialRate = (dispatch: Dispatch): void => {
//   dispatch(clearLeasingCategoryFinancialRateAction())
// }
export const clearValidFrom = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryValidFromAction())
}
export const clearValidTo = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryValidToAction())
}
export const clearReportingCategory = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryReportingCategoryAction())
}
export const clearBillingCategory = (dispatch: Dispatch): void => {
  dispatch(clearLeasingCategoryBillingCategoryAction())
}

export const copyLeasingCategory = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  leasingCategoryId: number
): Promise<void> => {
  dispatch(copyLeasingCategoryRequestAction())

  try {
    const leasingCategory = await requests.copy(token, leasingCategoryId)
    dispatch(copyLeasingCategorySuccessAction(leasingCategory))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      copyLeasingCategoryErrorAction,
      'display_error'
    )
  }
}

export const deleteLeasingCategory = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  leasingCategoryId: number
): Promise<void> => {
  dispatch(deleteLeasingCategoryRequestAction())

  try {
    await requests.remove(token, leasingCategoryId)
    handleSuccess(dispatch, 'Leasing Category deleted',null)
    dispatch(deleteLeasingCategorySuccessAction(leasingCategoryId))
    closeForm(dispatch)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      deleteLeasingCategoryErrorAction,
      'display_error'
    )
  }
}
