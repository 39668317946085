import { Action } from '../actions'
import { Moment } from 'moment'
import { LeasingCategory } from 'data/LeasingCategory'
import { ValidationErrors } from 'requests/errors'
import { Maybe, maybeNothing, maybeJust, maybeSwitch } from 'types/Maybe'
import {
  savingRequest,
  savingResponse,
  deletingRequest,
  deletingResponse,
  setValidationErrors,
} from './helpers'

export type ViewOrForm = 'closed' | 'form' | 'view'

export interface LeasingCategoriesDialogState {
  open: boolean
  saving: boolean
  copying: boolean
  deleting: boolean
  viewOrForm: ViewOrForm
  editingId: Maybe<number>
  name: Maybe<string>
  description: Maybe<string>
  numberOfLeasingPeriods: Maybe<number>
  leasingCoefficient: Maybe<number>
  purchaseOption: Maybe<number>
  purchaseOptionUser: Maybe<number>
  normalLeasingPercentage: Maybe<number>
  extensionLeasingPercentage: Maybe<number>
  earlyPurchaseReductionPercentage: Maybe<number>
  extensionPurchaseReductionPercentage: Maybe<number>
  earlyReturnReductionPercentage: Maybe<number>
  reportingCategory: Maybe<string>
  billingCategory: Maybe<string>
  validFrom: Maybe<Moment>
  validTo: Maybe<Moment>
  // financialRate: Maybe<number>
  // endOfLifeProRation: Maybe<number>
  pao6Percentage: Maybe<number>
  validationErrors: ValidationErrors
}

const defaultFormData = {
  editingId: maybeNothing<number>(),
  name: maybeNothing<string>(),
  description: maybeNothing<string>(),
  numberOfLeasingPeriods: maybeNothing<number>(),
  leasingCoefficient: maybeNothing<number>(),
  purchaseOption: maybeNothing<number>(),
  purchaseOptionUser: maybeNothing<number>(),
  normalLeasingPercentage: maybeNothing<number>(),
  extensionLeasingPercentage: maybeNothing<number>(),
  earlyPurchaseReductionPercentage: maybeNothing<number>(),
  extensionPurchaseReductionPercentage: maybeNothing<number>(),
  earlyReturnReductionPercentage: maybeNothing<number>(),
  reportingCategory: maybeNothing<string>(),
  billingCategory: maybeNothing<string>(),
  validFrom: maybeNothing<Moment>(),
  validTo: maybeNothing<Moment>(),
  // financialRate: maybeNothing<number>(),
  // endOfLifeProRation: maybeNothing<number>(),
  pao6Percentage: maybeNothing<number>(),
}

const defaultState = (): LeasingCategoriesDialogState => ({
  open: false,
  saving: false,
  copying: false,
  deleting: false,
  viewOrForm: 'closed',
  ...defaultFormData,
  validationErrors: {},
})

const openState = (): LeasingCategoriesDialogState => ({
  ...defaultState(),
  open: true,
})

const formDataFromLeasingCategory = (leasingCategory: LeasingCategory) => ({
  editingId: maybeJust(leasingCategory.id),
  name: maybeJust(leasingCategory.name),
  description: maybeJust(leasingCategory.description),
  numberOfLeasingPeriods: maybeJust(leasingCategory.numberOfLeasingPeriods),
  leasingCoefficient: maybeJust(leasingCategory.leasingCoefficient),
  purchaseOption: maybeJust(leasingCategory.purchaseOption),
  purchaseOptionUser: maybeJust(leasingCategory.purchaseOptionUser),
  normalLeasingPercentage: maybeJust(leasingCategory.normalLeasingPercentage),
  extensionLeasingPercentage: maybeJust(
    leasingCategory.extensionLeasingPercentage
  ),
  earlyPurchaseReductionPercentage: maybeJust(
    leasingCategory.earlyPurchaseReductionPercentage
  ),
  extensionPurchaseReductionPercentage: maybeJust(
    leasingCategory.extensionPurchaseReductionPercentage
  ),
  earlyReturnReductionPercentage: maybeJust(
    leasingCategory.earlyReturnReductionPercentage
  ),
  reportingCategory: leasingCategory.reportingCategory,
  billingCategory: leasingCategory.billingCategory,
  validFrom: leasingCategory.validFrom,
  validTo: leasingCategory.validTo,
  // financialRate: maybeJust(leasingCategory.financialRate),
  // endOfLifeProRation: maybeJust(leasingCategory.endOfLifeProRation),
  pao6Percentage: maybeJust(leasingCategory.pao6Percentage),
})

export default (
  state: LeasingCategoriesDialogState = defaultState(),
  action: Action
): LeasingCategoriesDialogState => {
  switch (action.type) {
    case 'OPEN_LEASING_CATEGORIES_DIALOG':
      return openState()
    case 'CLOSE_LEASING_CATEGORIES_DIALOG':
      return defaultState()
    case 'OPEN_LEASING_CATEGORY_FORM': {
      const formData = maybeSwitch(
        action.leasingCategory,
        formDataFromLeasingCategory,
        () => defaultFormData
      )
      return {
        ...state,
        viewOrForm: 'form',
        ...formData,
      }
    }
    case 'OPEN_LEASING_CATEGORY_VIEW':
      return {
        ...state,
        viewOrForm: 'view',
        ...formDataFromLeasingCategory(action.leasingCategory),
      }
    case 'CLOSE_LEASING_CATEGORY_FORM':
      return openState()
    case 'CREATE_LEASING_CATEGORY_REQUEST':
    case 'UPDATE_LEASING_CATEGORY_REQUEST':
      return savingRequest(state)
    case 'CREATE_LEASING_CATEGORY_SUCCESS':
    case 'CREATE_LEASING_CATEGORY_ERROR':
    case 'UPDATE_LEASING_CATEGORY_SUCCESS':
    case 'UPDATE_LEASING_CATEGORY_ERROR':
      return savingResponse(state)
    case 'SET_LEASING_CATEGORY_NAME':
      return {
        ...state,
        name: maybeJust(action.name),
      }
    case 'SET_LEASING_CATEGORY_DESCRIPTION':
      return {
        ...state,
        description: maybeJust(action.description),
      }
    case 'SET_LEASING_CATEGORY_NUMBER_OF_LEASING_PERIODS':
      return {
        ...state,
        numberOfLeasingPeriods: maybeJust(action.numberOfLeasingPeriods),
      }
    case 'SET_LEASING_CATEGORY_LEASING_COEFFICIENT':
      return {
        ...state,
        leasingCoefficient: maybeJust(action.leasingCoefficient),
      }
    case 'SET_LEASING_CATEGORY_NORMAL_LEASING_PERCENTAGE':
      return {
        ...state,
        normalLeasingPercentage: maybeJust(action.normalLeasingPercentage),
      }
    case 'SET_LEASING_CATEGORY_PURCHASE_OPTION':
      return {
        ...state,
        purchaseOption: maybeJust(action.purchaseOption),
      }
    case 'SET_LEASING_CATEGORY_PURCHASE_OPTION_USER':
      return {
        ...state,
        purchaseOptionUser: maybeJust(action.purchaseOptionUser),
      }
    case 'SET_LEASING_CATEGORY_EXTENSION_LEASING_PERCENTAGE':
      return {
        ...state,
        extensionLeasingPercentage: maybeJust(
          action.extensionLeasingPercentage
        ),
      }
    case 'SET_LEASING_CATEGORY_EARLY_PURCHASE_REDUCTION_PERCENTAGE':
      return {
        ...state,
        earlyPurchaseReductionPercentage: maybeJust(
          action.earlyPurchaseReductionPercentage
        ),
      }
    case 'SET_LEASING_CATEGORY_EXTENSION_PURCHASE_REDUCTION_PERCENTAGE':
      return {
        ...state,
        extensionPurchaseReductionPercentage: maybeJust(
          action.extensionPurchaseReductionPercentage
        ),
      }
    case 'SET_LEASING_CATEGORY_EARLY_RETURN_REDUCTION_PERCENTAGE':
      return {
        ...state,
        earlyReturnReductionPercentage: maybeJust(
          action.earlyReturnReductionPercentage
        ),
      }
    // case 'SET_LEASING_CATEGORY_END_OF_LIFE_PRO_RATION':
    //   return {
    //     ...state,
    //   endOfLifeProRation: maybeJust(action.endOfLifeProRation),
    // }
    case 'SET_LEASING_CATEGORY_PAO6_PERCENTAGE':
      return {
        ...state,
        pao6Percentage: maybeJust(action.pao6Percentage),
      }
    // case 'SET_LEASING_CATEGORY_FINANCIAL_RATE':
    //   return {
    //     ...state,
    //     financialRate: maybeJust(action.financialRate),
    //   }
    case 'SET_LEASING_CATEGORY_VALID_FROM':
      return {
        ...state,
        validFrom: maybeJust(action.validFrom),
      }
    case 'SET_LEASING_CATEGORY_VALID_TO':
      return {
        ...state,
        validTo: maybeJust(action.validTo),
      }
    case 'SET_LEASING_CATEGORY_REPORTING_CATEGORY':
      return {
        ...state,
        reportingCategory: maybeJust(action.reportingCategory),
      }
    case 'SET_LEASING_CATEGORY_BILLING_CATEGORY':
      return {
        ...state,
        billingCategory: maybeJust(action.billingCategory),
      }

    case 'CLEAR_LEASING_CATEGORY_NAME':
      return {
        ...state,
        name: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_DESCRIPTION':
      return {
        ...state,
        description: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_NUMBER_OF_LEASING_PERIODS':
      return {
        ...state,
        numberOfLeasingPeriods: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_LEASING_COEFFICIENT':
      return {
        ...state,
        leasingCoefficient: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_PURCHASE_OPTION':
      return {
        ...state,
        purchaseOption: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_PURCHASE_OPTION_USER':
      return {
        ...state,
        purchaseOptionUser: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_NORMAL_LEASING_PERCENTAGE':
      return {
        ...state,
        normalLeasingPercentage: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_EXTENSION_LEASING_PERCENTAGE':
      return {
        ...state,
        extensionLeasingPercentage: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_EARLY_PURCHASE_REDUCTION_PERCENTAGE':
      return {
        ...state,
        earlyPurchaseReductionPercentage: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_EXTENSION_PURCHASE_REDUCTION_PERCENTAGE':
      return {
        ...state,
        extensionPurchaseReductionPercentage: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_EARLY_RETURN_REDUCTION_PERCENTAGE':
      return {
        ...state,
        earlyReturnReductionPercentage: maybeNothing(),
      }
    // case 'CLEAR_LEASING_CATEGORY_END_OF_LIFE_PRO_RATION':
    //   return {
    //     ...state,
    //   endOfLifeProRation: maybeNothing(),
    // }
    case 'CLEAR_LEASING_CATEGORY_PAO6_PERCENTAGE':
      return {
        ...state,
        pao6Percentage: maybeNothing(),
      }
    // case 'CLEAR_LEASING_CATEGORY_FINANCIAL_RATE':
    //   return {
    //     ...state,
    //     financialRate: maybeNothing(),
    //   }
    case 'CLEAR_LEASING_CATEGORY_VALID_FROM':
      return {
        ...state,
        validFrom: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_VALID_TO':
      return {
        ...state,
        validTo: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_REPORTING_CATEGORY':
      return {
        ...state,
        reportingCategory: maybeNothing(),
      }
    case 'CLEAR_LEASING_CATEGORY_BILLING_CATEGORY':
      return {
        ...state,
        billingCategory: maybeNothing(),
      }
    case 'SET_LEASING_CATEGORY_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    case 'COPY_LEASING_CATEGORY_REQUEST':
      return { ...state, copying: true }
    case 'COPY_LEASING_CATEGORY_SUCCESS':
    case 'COPY_LEASING_CATEGORY_ERROR':
      return { ...state, copying: false }
    case 'DELETE_LEASING_CATEGORY_REQUEST':
      return deletingRequest(state)
    case 'DELETE_LEASING_CATEGORY_SUCCESS':
    case 'DELETE_LEASING_CATEGORY_ERROR':
      return deletingResponse(state)
    default:
      return state
  }
}
