import { Action } from '../actions'
import { FrameContract } from '../data/FrameContract'
import { Maybe, maybeNothing, maybeJust } from 'types/Maybe'
import {
  addItem,
  updateItem,
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export interface FrameContractsState {
  fetching: boolean
  data: FrameContract[]
  selectedFrameContractId: Maybe<number>
}

const defaultState = (): FrameContractsState =>
  fetchingDataDefault({
    selectedFrameContractId: maybeNothing(),
  })

export default (
  state: FrameContractsState = defaultState(),
  action: Action
): FrameContractsState => {
  switch (action.type) {
    case 'FETCH_FRAME_CONTRACTS_REQUEST':
      return fetchingData(state)
    case 'FETCH_FRAME_CONTRACTS_SUCCESS':
      return dataFetched(state, action.frameContracts)
    case 'FETCH_FRAME_CONTRACTS_ERROR':
      return fetchingDataError(state)
    case 'SELECT_FRAME_CONTRACT':
      return {
        ...state,
        selectedFrameContractId: maybeJust(action.frameContractId),
      }
    case 'CREATE_FRAME_CONTRACT_SUCCESS':
      return addItem(state, action.frameContract)
    case 'UPDATE_FRAME_CONTRACT_SUCCESS':
      return updateItem(state, action.frameContract)
    default:
      return state
  }
}
