import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Radio,
  Box,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../reducers'
import * as actions from '../actions/exportTemplateDialog'
import { RouteChildrenProps, withRouter } from 'react-router'
import { ExportTemplate } from 'data/ExportTemplate'
import LoadingIndicator from 'components/LoadingIndicator'
import ExportTemplateForm from './ExportTemplateForm'
import { ExportDevicesParams } from 'requests/Device'
import { pluralize } from '../utils'
import { DeviceSelection, selectedDevicesCount } from 'reducers/devices'
import { CountParams } from 'requests/endpoints'
import {
  Maybe,
  maybeFind,
  maybeCompare,
  maybeMap,
  maybeMap2,
  maybeSwitch,
  maybeNothing,
  maybeJust,
} from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  open: boolean
  fetchingExportTemplates: boolean
  exportTemplates: ExportTemplate[]
  formOpen: boolean
  selectedExportTemplate: Maybe<ExportTemplate>
  selection: DeviceSelection
  totalDataCount: number
  filterBy: Maybe<CountParams>
}

interface DispatchProps {
  closeDialog: () => void
  select: (exportTemplateId: number) => void
  openNewExportTemplateForm: () => void
  openExportTemplateForm: (exportTemplate: ExportTemplate) => void
  exportDevices: (params: ExportDevicesParams) => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class ExportTemplateDialog extends React.PureComponent<Props> {
  private handleExport = () => {
    const {
      selection,
      selectedExportTemplate,
      filterBy,
      exportDevices,
    } = this.props
    maybeMap2(selectedExportTemplate, filterBy, (set, fb) =>
      exportDevices({
        selection,
        exportTemplateId: set.id,
        filterBy: fb,
      })
    )
  }

  public render() {
    const {
      token,
      open,
      closeDialog,
      fetchingExportTemplates,
      exportTemplates,
      formOpen,
      select,
      openNewExportTemplateForm,
      openExportTemplateForm,
      selectedExportTemplate,
      selection,
      totalDataCount,
    } = this.props
    const devicesSelectedCount = selectedDevicesCount(selection, totalDataCount)
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={closeDialog}>
        {formOpen ? (
          <ExportTemplateForm token={token} />
        ) : fetchingExportTemplates ? (
          <Box p={5}>
            <LoadingIndicator size={maybeNothing()} />
          </Box>
        ) : (
          <React.Fragment>
            <DialogTitle>
              Choose export template ({devicesSelectedCount}{' '}
              {pluralize(devicesSelectedCount, 'asset', 'assets')} selected)
            </DialogTitle>

            <List>
              {exportTemplates.map(exportTemplate => {
                const isSelected = maybeCompare(
                  maybeMap(selectedExportTemplate, set => set.id),
                  exportTemplate.id
                )
                return (
                  <ListItem
                    key={exportTemplate.id}
                    button={true}
                    selected={isSelected}
                    onClick={() => select(exportTemplate.id)}
                  >
                    <Radio checked={isSelected} />
                    <ListItemText primary={exportTemplate.name} />
                    <IconButton
                      onClick={(event: any) => {
                        event.stopPropagation()
                        openExportTemplateForm(exportTemplate)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </ListItem>
                )
              })}
            </List>

            <DialogActions>
              <Button onClick={openNewExportTemplateForm}>
                New export template
              </Button>
              <Box flex={1} />
              <Button onClick={closeDialog} color="secondary">
                Cancel
              </Button>
              {maybeSwitch(
                selectedExportTemplate,
                template => (
                  <Button
                    onClick={(event: any) => this.handleExport()}
                    color="primary"
                  >
                    Export
                  </Button>
                ),
                () => (
                  <Button onClick={() => {}} color="primary" disabled={true}>
                    Export
                  </Button>
                )
              )}
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  open: state.exportTemplateDialog.open,
  fetchingExportTemplates: state.exportTemplates.fetching,
  exportTemplates: state.exportTemplates.data,
  formOpen: state.exportTemplateDialog.formOpen,
  selectedExportTemplate: maybeFind(
    state.exportTemplates.data,
    exportTemplate =>
      maybeCompare(state.exportTemplateDialog.selectedId, exportTemplate.id)
  ),
  selection: state.devices.selection,
  totalDataCount: state.devices.totalDataCount,
  filterBy: state.devices.filterBy,
})

const mapDispatchProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  select: (exportTemplateId: number) =>
    actions.select(dispatch, exportTemplateId),
  openNewExportTemplateForm: () =>
    actions.openExportTemplateForm(
      ownProps.token,
      dispatch,
      ownProps.history,
      maybeNothing()
    ),
  openExportTemplateForm: (exportTemplate: ExportTemplate) =>
    actions.openExportTemplateForm(
      ownProps.token,
      dispatch,
      ownProps.history,
      maybeJust(exportTemplate)
    ),
  exportDevices: (params: ExportDevicesParams) =>
    actions.exportDevices(ownProps.token, dispatch, ownProps.history, params),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchProps)(ExportTemplateDialog)
)
