import { CustomerEntity, decoder } from 'data/CustomerEntity'
import * as endpoints from './endpoints'
import { getRequest } from './base'
import { JsonDecoder } from 'ts.data.json'

export const fetchAll = (
  token: string,
  customerId: number
): Promise<CustomerEntity[]> =>
  getRequest(
    token,
    endpoints.customerEntities(customerId),
    JsonDecoder.array(decoder, 'CustomerEntity[]')
  )
