import { Action } from '../actions'
import { ExportTemplate } from '../data/ExportTemplate'
import {
  addItem,
  updateItem,
  deleteItem,
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export interface ExportTemplatesState {
  fetching: boolean
  data: ExportTemplate[]
}

const defaultState = (): ExportTemplatesState => fetchingDataDefault({})

export default (
  state: ExportTemplatesState = defaultState(),
  action: Action
): ExportTemplatesState => {
  switch (action.type) {
    case 'FETCH_EXPORT_TEMPLATES_REQUEST':
      return fetchingData(state)
    case 'FETCH_EXPORT_TEMPLATES_SUCCESS':
      return dataFetched(state, action.exportTemplates)
    case 'FETCH_EXPORT_TEMPLATES_ERROR':
      return fetchingDataError(state)
    case 'CREATE_EXPORT_TEMPLATE_SUCCESS':
      return addItem(state, action.exportTemplate)
    case 'UPDATE_EXPORT_TEMPLATE_SUCCESS':
      return updateItem(state, action.exportTemplate)
    case 'DELETE_EXPORT_TEMPLATE_SUCCESS':
      return deleteItem(state, action.exportTemplateId)
    default:
      return state
  }
}
