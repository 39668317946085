import * as endpoints from './endpoints'
import {
  ExportTemplate,
  decoder,
  ExportTemplateField,
} from 'data/ExportTemplate'
import { getRequest, postRequest, putRequest, deleteRequest } from './base'
import { JsonDecoder } from 'ts.data.json'
import { requireField, ValidationErrors } from './errors'
import { Maybe, maybeToNull } from 'types/Maybe'

export const fetchAll = async (token: string): Promise<ExportTemplate[]> =>
  getRequest(
    token,
    endpoints.exportTemplates(),
    JsonDecoder.array(decoder, 'ExportTemplate[]')
  )

export const fetchFields = async (token: string): Promise<string[]> =>
  getRequest(
    token,
    endpoints.exportFields(),
    JsonDecoder.array(JsonDecoder.string, 'string[]')
  )

export interface ExportTemplateParams {
  name: Maybe<string>
  fields: ExportTemplateField[]
}

type Mode = 'create' | 'update'

const encodeParams = (mode: Mode, params: ExportTemplateParams) => ({
  name: maybeToNull(params.name),
  fields: params.fields,
})

export const checkParams = (
  mode: Mode,
  params: ExportTemplateParams
): ValidationErrors => {
  const errors = {}
  requireField(errors, 'name', params.name)
  requireField(errors, 'fields', params.fields)
  return errors
}

export const create = async (
  token: string,
  params: ExportTemplateParams
): Promise<ExportTemplate> =>
  postRequest(
    token,
    endpoints.exportTemplates(),
    encodeParams('create', params),
    decoder
  )

export const update = async (
  token: string,
  exportTemplateId: number,
  params: ExportTemplateParams
): Promise<ExportTemplate> =>
  putRequest(
    token,
    endpoints.exportTemplate(exportTemplateId),
    { ...encodeParams('update', params), exportTemplate: exportTemplateId },
    decoder
  )

export const remove = async (
  token: string,
  exportTemplateId: number
): Promise<void> =>
  deleteRequest(token, endpoints.exportTemplate(exportTemplateId))
