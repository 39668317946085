import * as endpoints from './endpoints'
import { getRequest, postRequest, patchRequest, deleteRequest } from './base'
import {
  BillingPeriod,
  decoder,
  BillingPeriodValuesByCustomerEntity,
  BillingPeriodDetails,
  detailsDecoder,
} from 'data/BillingPeriod'
import { JsonDecoder } from 'ts.data.json'
import { ValidationErrors } from './errors'
import { omit, map } from 'lodash'
import { maybeToNull, maybeMap } from 'types/Maybe'

export const fetchByFrameContractId = async (
  token: string,
  frameContractId: number
): Promise<BillingPeriod[]> =>
  getRequest(
    token,
    endpoints.billingPeriodsByFrameContractId(frameContractId),
    JsonDecoder.array(decoder, 'BillingPeriod[]')
  )

export const get = async (
  token: string,
  billingPeriodId: number
): Promise<BillingPeriodDetails> =>
  getRequest(token, endpoints.billingPeriod(billingPeriodId), detailsDecoder)

export interface BillingPeriodParams {
  valuesByCustomerEntity: {
    [customerEntityId: number]: BillingPeriodValuesByCustomerEntity
  }
}

type Mode = 'create' | 'update'

const encodeParams = (mode: Mode, params: BillingPeriodParams) => {
  const fullParams = {
    valuesByCustomerEntity: map(
      params.valuesByCustomerEntity,
      (
        params: BillingPeriodValuesByCustomerEntity,
        customerEntityId: number
      ) => ({
        customerEntity: Number(customerEntityId),
        purchaseOrder: maybeToNull(params.purchaseOrder),
        rentInvoiceNumber: maybeToNull(params.rentInvoiceNumber),
        rentInvoiceDate: maybeToNull(
          maybeMap(params.rentInvoiceDate, date => date.format('YYYY-MM-DD'))
        ),
        exitInvoiceNumber: maybeToNull(params.exitInvoiceNumber),
        exitInvoiceDate: maybeToNull(
          maybeMap(params.exitInvoiceDate, date => date.format('YYYY-MM-DD'))
        ),
        damagesInvoiceNumber: maybeToNull(params.damagesInvoiceNumber),
        damagesInvoiceDate: maybeToNull(
          maybeMap(params.damagesInvoiceDate, date => date.format('YYYY-MM-DD'))
        ),
      })
    ),
  }
  switch (mode) {
    case 'create':
      return fullParams
    case 'update':
      return omit(fullParams, [])
  }
}

export const checkParams = (
  mode: Mode,
  params: BillingPeriodParams,
  customerEntityIds: number[]
): ValidationErrors => {
  const errors = {}
  customerEntityIds.forEach(customerEntityId => {
    // requireField(
    //   errors,
    //   'valuesByCustomerEntity.' +
    //     customerEntityId.toString() +
    //     '.purchaseOrder',
    //   params.valuesByCustomerEntity[customerEntityId] &&
    //     params.valuesByCustomerEntity[customerEntityId].purchaseOrder
    // )
    // requireField(
    //   errors,
    //   'valuesByCustomerEntity.' +
    //     customerEntityId.toString() +
    //     '.invoiceNumber',
    //   params.valuesByCustomerEntity[customerEntityId] &&
    //     params.valuesByCustomerEntity[customerEntityId].invoiceNumber
    // )
    // requireField(
    //   errors,
    //   'valuesByCustomerEntity.' + customerEntityId.toString() + '.invoiceDate',
    //   params.valuesByCustomerEntity[customerEntityId] &&
    //     params.valuesByCustomerEntity[customerEntityId].invoiceDate
    // )
  })
  return errors
}

export const create = async (
  token: string,
  frameContractId: number,
  params: BillingPeriodParams
): Promise<BillingPeriod> =>
  postRequest(
    token,
    endpoints.billingPeriods(),
    { ...encodeParams('create', params), frameContract: frameContractId },
    decoder
  )
export const update = async (
  token: string,
  billingPeriodId: number,
  frameContractId: number,
  params: BillingPeriodParams
): Promise<number> =>
  patchRequest<number>(
    token,
    endpoints.billingPeriod(billingPeriodId),
    {
      ...encodeParams('update', params),
      billingPeriod: billingPeriodId,
      frameContract: frameContractId,
    },
    JsonDecoder.number
  )

export const remove = async (
  token: string,
  billingPeriodId: number
): Promise<void> =>
  deleteRequest(token, endpoints.billingPeriod(billingPeriodId))

export const isDeletable = async (
  token: string,
  billingPeriodId: number
): Promise<boolean> =>
  getRequest(
    token,
    endpoints.billingPeriodIsDeletable(billingPeriodId),
    JsonDecoder.boolean
  )
