import { Moment } from 'moment'
import { JsonDecoder } from 'ts.data.json'
import { momentDecoder } from './decoderHelpers'
import { Maybe, maybeDecoder } from 'types/Maybe'

export interface LeasingContract {
  id: number
  frameContractId: Maybe<number>
  sni: Maybe<string>
  contractStartDate: Maybe<Moment>
  contractEndDate: Maybe<Moment>
}

export const decoder = JsonDecoder.object<LeasingContract>(
  {
    id: JsonDecoder.number,
    frameContractId: maybeDecoder(JsonDecoder.number),
    sni: maybeDecoder(JsonDecoder.string),
    contractStartDate: maybeDecoder(momentDecoder),
    contractEndDate: maybeDecoder(momentDecoder),
  },
  'LeasingContract',
  {
    id: 'leasingContract',
    frameContractId: 'frameContract',
    sni: 'sniContract',
  }
)
