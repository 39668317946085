import { Dispatch } from 'redux'
import { addSnackbar } from './snackbars'
import { Action } from 'actions'

const handleOnSuccess = (dispatch: Dispatch, onSuccess: (() => Action) | null) => {
  onSuccess && dispatch(onSuccess())
}
export const handleSuccess = (
  dispatch: Dispatch,
  message: string,
  onSuccess: (() => Action) | null
) => {
  addSnackbar(dispatch, 'success', message)
  handleOnSuccess(dispatch, onSuccess)
}

