import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from '@material-ui/core'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../reducers'
import * as actions from '../actions/frameContractDialog'
import {
  LeasingStartTrigger,
  leasingStartTriggers,
  formatLeasingStartTrigger,
  formatFrameContractType,
  frameContractTypes,
  FrameContractType,
  FrameContractCategory,
  frameContractCategories,
  formatFrameContractCategory,
} from 'data/FrameContract'
import FormNumberInput from 'components/FormNumberInput'
import FormTextInput from 'components/FormTextInput'
import FormBooleanInput from 'components/FormBooleanInput'
import FormSelect from 'components/FormSelect'
import { RouteChildrenProps, withRouter } from 'react-router'
import { FrameContractParams } from 'requests/FrameContract'
import Columns from 'components/Columns'
import { Moment } from 'moment'
import FormDateInput from 'components/FormDateInput'
import FormMultipleSelect from 'components/FormMultipleSelect'
import DeletableButton from 'components/DeletableButton'
import {
  Maybe,
  maybeNothing,
  maybeJust,
  maybeSwitch,
  isJust,
  maybeMap,
} from 'types/Maybe'
import { ValidationErrors } from 'requests/errors'
import { Customer } from 'data/Customer'
import { CustomerEntity } from 'data/CustomerEntity'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  open: boolean
  customers: Customer[]
  customerEntities: CustomerEntity[]
  updatingId: Maybe<number>
  name: Maybe<string>
  type: string
  isActive: boolean
  customerId: Maybe<number>
  customerEntityIds: number[]
  contractStartDate: Maybe<Moment>
  billingIntervalInMonths: Maybe<number>
  contractIntervalInMonths: Maybe<number>
  noticePeriodInMonths: Maybe<number>
  isProRataBilling: boolean
  leasingStartTrigger: LeasingStartTrigger
  billOnlyActiveAssets: boolean
  creditUnusedPeriods: boolean
  categories: FrameContractCategory[]
  validationErrors: ValidationErrors
  isDeletable: boolean
}

interface DispatchProps {
  closeDialog: () => void
  createFrameContract: (params: FrameContractParams) => void
  updateFrameContract: (
    frameContractId: number,
    params: FrameContractParams
  ) => void
  setName: (name: string) => void
  setType: (type: FrameContractType) => void
  setIsActive: (isActive: boolean) => void
  setCustomerId: (customerId: number) => void
  setCustomerEntityIds: (customerEntityIds: number[]) => void
  setContractStartDate: (contractStartDate: Moment) => void
  setBillingIntervalInMonths: (billingIntervalInMonths: number) => void
  setContractIntervalInMonths: (contractIntervalInMonths: number) => void
  setNoticePeriodInMonths: (noticePeriodInMonths: number) => void
  setIsProRataBilling: (isProRataBilling: boolean) => void
  setLeasingStartTrigger: (leasingStartTrigger: LeasingStartTrigger) => void
  setBillOnlyActiveAssets: (billOnlyActiveAssets: boolean) => void
  setCreditUnusedPeriods: (creditUnusedPeriods: boolean) => void
  setCategories: (categories: FrameContractCategory[]) => void
  clearName: () => void
  clearContractStartDate: () => void
  clearBillingIntervalInMonths: () => void
  clearContractIntervalInMonths: () => void
  clearNoticePeriodInMonths: () => void
  checkIfIsDeletable: (frameContractId: number) => void
  deleteFrameContract: (frameContractId: number) => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class FrameContractDialog extends React.PureComponent<Props> {
  public render() {
    const {
      open,
      customers,
      customerEntities,
      closeDialog,
      createFrameContract,
      updateFrameContract,
      updatingId,
      name,
      type,
      isActive,
      customerId,
      customerEntityIds,
      contractStartDate,
      billingIntervalInMonths,
      contractIntervalInMonths,
      noticePeriodInMonths,
      isProRataBilling,
      leasingStartTrigger,
      billOnlyActiveAssets,
      creditUnusedPeriods,
      categories,
      validationErrors,
      setName,
      setType,
      setIsActive,
      setCustomerId,
      setCustomerEntityIds,
      setContractStartDate,
      setBillingIntervalInMonths,
      setContractIntervalInMonths,
      setNoticePeriodInMonths,
      setIsProRataBilling,
      setLeasingStartTrigger,
      setBillOnlyActiveAssets,
      setCreditUnusedPeriods,
      setCategories,
      clearName,
      clearContractStartDate,
      clearBillingIntervalInMonths,
      clearContractIntervalInMonths,
      clearNoticePeriodInMonths,
      checkIfIsDeletable,
      deleteFrameContract,
      isDeletable,
    } = this.props
    const isEditing = isJust(updatingId)

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={closeDialog}>
        <DialogTitle>
          {isEditing ? 'Edit frame contract' : 'New frame contract'}
        </DialogTitle>

        <DialogContent>
          <Columns>
            <FormTextInput
              value={name}
              label="Name"
              required={true}
              onChange={setName}
              onClear={clearName}
              validationErrors={validationErrors['name']}
              disabled={isEditing}
            />
            <FormSelect
              value={maybeJust(type)}
              label="Type"
              items={Array.from(frameContractTypes).map(value => ({
                value,
                name: formatFrameContractType(value),
              }))}
              required={true}
              onChange={(v: string) => setType(v as FrameContractType)}
              onClear={maybeNothing()}
              validationErrors={validationErrors['type']}
              disabled={isEditing}
            />
          </Columns>
          <Columns>
            <FormSelect
              value={maybeMap(customerId, id => id.toString())}
              label="Customer"
              items={customers.map(customer => ({
                value: customer.id.toString(),
                name: customer.name,
              }))}
              required={true}
              onChange={customerId => setCustomerId(Number(customerId))}
              onClear={maybeNothing()}
              validationErrors={validationErrors['customerId']}
              disabled={isEditing}
            />
            <FormMultipleSelect
              value={maybeJust(customerEntityIds.map(id => id.toString()))}
              label="Entities"
              items={customerEntities.map(customerEntity => ({
                value: customerEntity.id.toString(),
                name: customerEntity.entityName,
              }))}
              required={true}
              onChange={ids => setCustomerEntityIds(ids.map(Number))}
              validationErrors={validationErrors['customerEntityIds']}
              disabled={isEditing}
            />
          </Columns>
          <Columns>
            <FormDateInput
              value={contractStartDate}
              label="Contract start date"
              required={true}
              onChange={setContractStartDate}
              onClear={clearContractStartDate}
              validationErrors={validationErrors['contractStartDate']}
              disabled={isEditing}
              format={maybeNothing()}
            />
            <FormSelect
              value={maybeJust(leasingStartTrigger)}
              label="Leasing start trigger"
              items={Array.from(leasingStartTriggers).map(value => ({
                value,
                name: formatLeasingStartTrigger(value),
              }))}
              required={true}
              onChange={(v: string) =>
                setLeasingStartTrigger(v as LeasingStartTrigger)
              }
              onClear={maybeNothing()}
              validationErrors={validationErrors['leasingStartTrigger']}
              disabled={isEditing}
            />
          </Columns>
          <Columns>
            <FormNumberInput
              value={billingIntervalInMonths}
              label="Billing interval"
              required={true}
              onChange={setBillingIntervalInMonths}
              onClear={clearBillingIntervalInMonths}
              validationErrors={validationErrors['billingIntervalInMonths']}
              unit={maybeJust('months')}
              disabled={isEditing}
            />
            <FormNumberInput
              value={contractIntervalInMonths}
              label="Contract interval"
              required={true}
              onChange={setContractIntervalInMonths}
              onClear={clearContractIntervalInMonths}
              validationErrors={validationErrors['contractIntervalInMonths']}
              unit={maybeJust('months')}
              disabled={isEditing}
            />
          </Columns>

          <Columns>
            <FormNumberInput
              value={noticePeriodInMonths}
              label="Notice period"
              required={true}
              onChange={setNoticePeriodInMonths}
              onClear={clearNoticePeriodInMonths}
              validationErrors={validationErrors['noticePeriodInMonths']}
              unit={maybeJust('months')}
              disabled={isEditing}
            />
            <FormMultipleSelect
              value={maybeJust(categories as string[])}
              label="Categories"
              items={Array.from(frameContractCategories).map(value => ({
                value: value as string,
                name: formatFrameContractCategory(value),
              }))}
              required={true}
              onChange={values =>
                setCategories(values as FrameContractCategory[])
              }
              validationErrors={validationErrors['categories']}
              disabled={isEditing}
            />
          </Columns>
          <Columns gridProps={{ alignItems: 'flex-end' }}>
            <FormBooleanInput
              value={maybeJust(billOnlyActiveAssets)}
              label="Bill only active assets?"
              required={true}
              onChange={setBillOnlyActiveAssets}
              validationErrors={validationErrors['billOnlyActiveAssets']}
              disabled={isEditing}
            />
            <FormBooleanInput
              value={maybeJust(creditUnusedPeriods)}
              label="Credit unused periods?"
              required={true}
              onChange={setCreditUnusedPeriods}
              validationErrors={validationErrors['creditUnusedPeriods']}
              disabled={isEditing}
            />
          </Columns>
          <Columns gridProps={{ alignItems: 'flex-end' }}>
            <FormBooleanInput
              value={maybeJust(isProRataBilling)}
              label="Is pro-rata billing?"
              required={true}
              onChange={setIsProRataBilling}
              validationErrors={validationErrors['isProRataBilling']}
              disabled={false}
            />
            <FormBooleanInput
              value={maybeJust(isActive)}
              label="Is active?"
              required={true}
              onChange={setIsActive}
              validationErrors={validationErrors['isActive']}
              disabled={false}
            />
          </Columns>
        </DialogContent>

        <DialogActions>
          {isEditing &&
            maybeSwitch(
              updatingId,
              id => (
                <React.Fragment>
                  <DeletableButton
                    name="frame contract"
                    checkIfIsDeletable={() => checkIfIsDeletable(id)}
                    isDeletable={isDeletable}
                    onConfirm={() => deleteFrameContract(id)}
                  />
                  <Box flex={1} />
                </React.Fragment>
              ),
              () => null
            )}

          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={(event: any) => {
              const params = {
                name,
                type,
                isActive,
                customerId,
                customerEntityIds,
                contractStartDate,
                billingIntervalInMonths,
                contractIntervalInMonths,
                noticePeriodInMonths,
                isProRataBilling,
                leasingStartTrigger,
                billOnlyActiveAssets,
                creditUnusedPeriods,
                categories,
              }
              maybeSwitch(
                updatingId,
                id => updateFrameContract(id, params),
                () => createFrameContract(params)
              )
            }}
            color="primary"
          >
            {isEditing ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  open: state.frameContractDialog.open,
  customers: state.customers.data,
  customerEntities: state.customerEntities.data,
  updatingId: state.frameContractDialog.updatingId,
  name: state.frameContractDialog.name,
  type: state.frameContractDialog.type,
  isActive: state.frameContractDialog.isActive,
  customerId: state.frameContractDialog.customerId,
  customerEntityIds: state.frameContractDialog.customerEntityIds,
  contractStartDate: state.frameContractDialog.contractStartDate,
  billingIntervalInMonths: state.frameContractDialog.billingIntervalInMonths,
  contractIntervalInMonths: state.frameContractDialog.contractIntervalInMonths,
  noticePeriodInMonths: state.frameContractDialog.noticePeriodInMonths,
  isProRataBilling: state.frameContractDialog.isProRataBilling,
  leasingStartTrigger: state.frameContractDialog.leasingStartTrigger,
  billOnlyActiveAssets: state.frameContractDialog.billOnlyActiveAssets,
  creditUnusedPeriods: state.frameContractDialog.creditUnusedPeriods,
  categories: state.frameContractDialog.categories,
  validationErrors: state.frameContractDialog.validationErrors,
  isDeletable: state.frameContractDialog.isDeletable,
})

const mapDispatchProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  createFrameContract: (params: FrameContractParams) =>
    actions.createFrameContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      params
    ),
  updateFrameContract: (frameContractId: number, params: FrameContractParams) =>
    actions.updateFrameContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId,
      params
    ),
  setName: (name: string) => actions.setName(dispatch, name),
  setType: (type: FrameContractType) => actions.setType(dispatch, type),
  setIsActive: (isActive: boolean) => actions.setIsActive(dispatch, isActive),
  setCustomerId: (customerId: number) =>
    actions.setCustomerId(
      ownProps.token,
      dispatch,
      ownProps.history,
      customerId
    ),
  setCustomerEntityIds: (customerEntityIds: number[]) =>
    actions.setCustomerEntityIds(dispatch, customerEntityIds),
  setContractStartDate: (contractStartDate: Moment) =>
    actions.setContractStartDate(dispatch, contractStartDate),
  setBillingIntervalInMonths: (billingIntervalInMonths: number) =>
    actions.setBillingIntervalInMonths(dispatch, billingIntervalInMonths),
  setContractIntervalInMonths: (contractIntervalInMonths: number) =>
    actions.setContractIntervalInMonths(dispatch, contractIntervalInMonths),
  setNoticePeriodInMonths: (noticePeriodInMonths: number) =>
    actions.setNoticePeriodInMonths(dispatch, noticePeriodInMonths),
  setIsProRataBilling: (isProRataBilling: boolean) =>
    actions.setIsProRataBilling(dispatch, isProRataBilling),
  setLeasingStartTrigger: (leasingStartTrigger: LeasingStartTrigger) =>
    actions.setLeasingStartTrigger(dispatch, leasingStartTrigger),
  setBillOnlyActiveAssets: (billOnlyActiveAssets: boolean) =>
    actions.setBillOnlyActiveAssets(dispatch, billOnlyActiveAssets),
  setCreditUnusedPeriods: (creditUnusedPeriods: boolean) =>
    actions.setCreditUnusedPeriods(dispatch, creditUnusedPeriods),
  setCategories: (categories: FrameContractCategory[]) =>
    actions.setCategories(dispatch, categories),
  clearName: () => actions.clearName(dispatch),
  clearContractStartDate: () => actions.clearContractStartDate(dispatch),
  clearBillingIntervalInMonths: () =>
    actions.clearBillingIntervalInMonths(dispatch),
  clearContractIntervalInMonths: () =>
    actions.clearContractIntervalInMonths(dispatch),
  clearNoticePeriodInMonths: () => actions.clearNoticePeriodInMonths(dispatch),
  checkIfIsDeletable: (frameContractId: number) =>
    actions.checkIfIsDeletable(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId
    ),
  deleteFrameContract: (frameContractId: number) =>
    actions.deleteFrameContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchProps)(FrameContractDialog)
)
