import { Dispatch } from 'redux'
import * as requests from '../requests/LeasingContract'
import { LeasingContract } from '../data/LeasingContract'
import { History } from 'history'
import { handleError } from './errors'
import { anyError, ValidationErrors } from 'requests/errors'
import { Moment } from 'moment'
import { Maybe } from 'types/Maybe'
import { handleSuccess } from './success'

interface OpenLeasingContractDialogAction {
  type: 'OPEN_LEASING_CONTRACT_DIALOG'
  leasingContract: Maybe<LeasingContract>
}
interface CloseLeasingContractDialogAction {
  type: 'CLOSE_LEASING_CONTRACT_DIALOG'
}
interface CreateLeasingContractRequestAction {
  type: 'CREATE_LEASING_CONTRACT_REQUEST'
}
interface CreateLeasingContractSuccessAction {
  type: 'CREATE_LEASING_CONTRACT_SUCCESS'
  leasingContract: LeasingContract
}
interface CreateLeasingContractErrorAction {
  type: 'CREATE_LEASING_CONTRACT_ERROR'
}
interface UpdateLeasingContractRequestAction {
  type: 'UPDATE_LEASING_CONTRACT_REQUEST'
}
interface UpdateLeasingContractSuccessAction {
  type: 'UPDATE_LEASING_CONTRACT_SUCCESS'
  leasingContract: LeasingContract
}
interface UpdateLeasingContractErrorAction {
  type: 'UPDATE_LEASING_CONTRACT_ERROR'
}
interface DeleteLeasingContractRequestAction {
  type: 'DELETE_LEASING_CONTRACT_REQUEST'
}
interface DeleteLeasingContractSuccessAction {
  type: 'DELETE_LEASING_CONTRACT_SUCCESS'
  leasingContractId: number
}
interface DeleteLeasingContractErrorAction {
  type: 'DELETE_LEASING_CONTRACT_ERROR'
}
interface SetLeasingContractSniAction {
  type: 'SET_LEASING_CONTRACT_SNI'
  sni: string
}
interface SetLeasingContractContractStartDateAction {
  type: 'SET_LEASING_CONTRACT_CONTRACT_START_DATE'
  contractStartDate: Moment
}
interface SetLeasingContractContractEndDateAction {
  type: 'SET_LEASING_CONTRACT_CONTRACT_END_DATE'
  contractEndDate: Moment
}
interface ClearLeasingContractSniAction {
  type: 'CLEAR_LEASING_CONTRACT_SNI'
}
interface ClearLeasingContractContractStartDateAction {
  type: 'CLEAR_LEASING_CONTRACT_CONTRACT_START_DATE'
}
interface ClearLeasingContractContractEndDateAction {
  type: 'CLEAR_LEASING_CONTRACT_CONTRACT_END_DATE'
}
interface SetLeasingContractValidationErrorsAction {
  type: 'SET_LEASING_CONTRACT_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}
interface SetLeasingContractIsDeletableAction {
  type: 'SET_LEASING_CONTRACT_IS_DELETABLE'
}

export type LeasingContractDialogAction =
  | OpenLeasingContractDialogAction
  | CloseLeasingContractDialogAction
  | CreateLeasingContractRequestAction
  | CreateLeasingContractSuccessAction
  | CreateLeasingContractErrorAction
  | UpdateLeasingContractRequestAction
  | UpdateLeasingContractSuccessAction
  | UpdateLeasingContractErrorAction
  | DeleteLeasingContractRequestAction
  | DeleteLeasingContractSuccessAction
  | DeleteLeasingContractErrorAction
  | SetLeasingContractSniAction
  | SetLeasingContractContractStartDateAction
  | SetLeasingContractContractEndDateAction
  | ClearLeasingContractSniAction
  | ClearLeasingContractContractStartDateAction
  | ClearLeasingContractContractEndDateAction
  | SetLeasingContractValidationErrorsAction
  | SetLeasingContractIsDeletableAction

const openLeasingContractDialogAction = (
  leasingContract: Maybe<LeasingContract>
): OpenLeasingContractDialogAction => ({
  type: 'OPEN_LEASING_CONTRACT_DIALOG',
  leasingContract,
})
const closeLeasingContractDialogAction = (): CloseLeasingContractDialogAction => ({
  type: 'CLOSE_LEASING_CONTRACT_DIALOG',
})
const createLeasingContractRequestAction = (): CreateLeasingContractRequestAction => ({
  type: 'CREATE_LEASING_CONTRACT_REQUEST',
})
const createLeasingContractSuccessAction = (
  leasingContract: LeasingContract
): CreateLeasingContractSuccessAction => ({
  type: 'CREATE_LEASING_CONTRACT_SUCCESS',
  leasingContract,
})
const createLeasingContractErrorAction = (): CreateLeasingContractErrorAction => ({
  type: 'CREATE_LEASING_CONTRACT_ERROR',
})
const updateLeasingContractRequestAction = (): UpdateLeasingContractRequestAction => ({
  type: 'UPDATE_LEASING_CONTRACT_REQUEST',
})
const updateLeasingContractSuccessAction = (
  leasingContract: LeasingContract
): UpdateLeasingContractSuccessAction => ({
  type: 'UPDATE_LEASING_CONTRACT_SUCCESS',
  leasingContract,
})
const updateLeasingContractErrorAction = (): UpdateLeasingContractErrorAction => ({
  type: 'UPDATE_LEASING_CONTRACT_ERROR',
})
const deleteLeasingContractRequestAction = (): DeleteLeasingContractRequestAction => ({
  type: 'DELETE_LEASING_CONTRACT_REQUEST',
})
const deleteLeasingContractSuccessAction = (
  leasingContractId: number
): DeleteLeasingContractSuccessAction => ({
  type: 'DELETE_LEASING_CONTRACT_SUCCESS',
  leasingContractId,
})
const deleteLeasingContractErrorAction = (): DeleteLeasingContractErrorAction => ({
  type: 'DELETE_LEASING_CONTRACT_ERROR',
})
const setSniAction = (sni: string): SetLeasingContractSniAction => ({
  type: 'SET_LEASING_CONTRACT_SNI',
  sni,
})
const setLeasingContractContractStartDateAction = (
  contractStartDate: Moment
): SetLeasingContractContractStartDateAction => ({
  type: 'SET_LEASING_CONTRACT_CONTRACT_START_DATE',
  contractStartDate,
})
const setLeasingContractContractEndDateAction = (
  contractEndDate: Moment
): SetLeasingContractContractEndDateAction => ({
  type: 'SET_LEASING_CONTRACT_CONTRACT_END_DATE',
  contractEndDate,
})
const clearSniAction = (): ClearLeasingContractSniAction => ({
  type: 'CLEAR_LEASING_CONTRACT_SNI',
})
const clearLeasingContractContractStartDateAction = (): ClearLeasingContractContractStartDateAction => ({
  type: 'CLEAR_LEASING_CONTRACT_CONTRACT_START_DATE',
})
const clearLeasingContractContractEndDateAction = (): ClearLeasingContractContractEndDateAction => ({
  type: 'CLEAR_LEASING_CONTRACT_CONTRACT_END_DATE',
})
const setLeasingContractValidationErrorsAction = (validationErrors: {
  [field: string]: string[]
}): SetLeasingContractValidationErrorsAction => ({
  type: 'SET_LEASING_CONTRACT_VALIDATION_ERRORS',
  validationErrors,
})
const setLeasingContractIsDeletableAction = (): SetLeasingContractIsDeletableAction => ({
  type: 'SET_LEASING_CONTRACT_IS_DELETABLE',
})

export const openDialog = (
  dispatch: Dispatch,
  leasingContract: Maybe<LeasingContract>
): void => {
  dispatch(openLeasingContractDialogAction(leasingContract))
}

export const closeDialog = (dispatch: Dispatch): void => {
  dispatch(closeLeasingContractDialogAction())
}

export const createLeasingContract = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: requests.LeasingContractParams
): Promise<void> => {
  dispatch(createLeasingContractRequestAction())

  const errors = requests.checkParams('create', params)
  if (anyError(errors)) {
    dispatch(setLeasingContractValidationErrorsAction(errors))
    dispatch(createLeasingContractErrorAction())
    return
  }

  try {
    const leasingContract = await requests.create(token, params)
    closeDialog(dispatch)
    dispatch(createLeasingContractSuccessAction(leasingContract))
    handleSuccess(dispatch, 'Leasing Contract Created',null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      createLeasingContractErrorAction,
      'display_error'
    )
  }
}

export const updateLeasingContract = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  leasingContractId: number,
  params: requests.LeasingContractParams
): Promise<void> => {
  dispatch(updateLeasingContractRequestAction())

  const errors = requests.checkParams('update', params)
  if (anyError(errors)) {
    dispatch(setLeasingContractValidationErrorsAction(errors))
    dispatch(updateLeasingContractErrorAction())
    return
  }

  try {
    const leasingContract = await requests.update(
      token,
      leasingContractId,
      params
    )
    closeDialog(dispatch)
    dispatch(updateLeasingContractSuccessAction(leasingContract))
    handleSuccess(dispatch, 'Leasing Contract Updated',null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      updateLeasingContractErrorAction,
      'display_error'
    )
  }
}

export const deleteLeasingContract = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  leasingContractId: number
): Promise<void> => {
  dispatch(deleteLeasingContractRequestAction())

  try {
    await requests.remove(token, leasingContractId)
    closeDialog(dispatch)
    dispatch(deleteLeasingContractSuccessAction(leasingContractId))
    handleSuccess(dispatch, 'Leasing Contract Deleted',null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      deleteLeasingContractErrorAction,
      'display_error'
    )
  }
}

export const setSni = (dispatch: Dispatch, leasingContractId: string): void => {
  dispatch(setSniAction(leasingContractId))
}
export const setContractStartDate = (
  dispatch: Dispatch,
  contractStartDate: Moment
): void => {
  dispatch(setLeasingContractContractStartDateAction(contractStartDate))
}
export const setContractEndDate = (
  dispatch: Dispatch,
  contractEndDate: Moment
): void => {
  dispatch(setLeasingContractContractEndDateAction(contractEndDate))
}

export const clearSni = (dispatch: Dispatch): void => {
  dispatch(clearSniAction())
}
export const clearContractStartDate = (dispatch: Dispatch): void => {
  dispatch(clearLeasingContractContractStartDateAction())
}
export const clearContractEndDate = (dispatch: Dispatch): void => {
  dispatch(clearLeasingContractContractEndDateAction())
}

export const checkIfIsDeletable = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  leasingContractId: number
): Promise<void> => {
  try {
    const isDeletable = await requests.isDeletable(token, leasingContractId)
    if (isDeletable) {
      dispatch(setLeasingContractIsDeletableAction())
    }
  } catch (error) {
    handleError(dispatch, history, error, null, 'display_error')
  }
}
