import { Moment } from 'moment'
import { JsonDecoder } from 'ts.data.json'
import { momentDecoder } from './decoderHelpers'
import { DeviceStatus, decoder as deviceStatusDecoder } from './DeviceStatus'
import { Maybe, maybeDecoder } from 'types/Maybe'
// import { BillingStatus, decoder as billingStatusDecoder } from './BillingStatus'

export interface Device {
  name: string
  clientId: number
  model: Maybe<string>
  deliveryDate: Maybe<Moment>
  closeDate: Maybe<Moment>
  closeReason: Maybe<string>
  warrantyStartDate: Maybe<Moment>
  warrantyEndDate: Maybe<Moment>
  leasingStartDate: Maybe<Moment>
  leasingEndDate: Maybe<Moment>
  ownerId: string
  ownerName: string
  deviceState: Maybe<string>
  status: DeviceStatus
  originalSNIContract: Maybe<string>
  currentSNIContract: Maybe<string>
  leasingRent: Maybe<number>
  durationInMonths: Maybe<number>
  userOrderHeader: Maybe<string>
  userOrderLine: Maybe<number>
  returnValue: Maybe<number>
  purchaseOption: Maybe<number>

  originalLeasingContract: Maybe<number>
  currentLeasingContract: Maybe<number>
  // leasingContractId: Maybe<number>
  // billingStatus: BillingStatus
  frameContract: number
  buybackChoice: Maybe<number>
  parameter2: Maybe<number>

}

export interface DeviceDetails extends Device {
  // status: Maybe<DeviceStatus>
  leasingRent: Maybe<number>
  purchaseOption: Maybe<number>
  purchaseOptionExtensionDiscount: Maybe<number>
  leasingRentExtensionDiscount: Maybe<number>
  leasingRentExtensionValue: Maybe<number>
  residualValue: Maybe<number>
  anticipatedRepurchaseValue: Maybe<number>
  anticipatedReturnValue: Maybe<number>
  // billingStatus: BillingStatus
  // originalSNIContract: Maybe<string>
  // currentSNIContract: Maybe<string>
}

const description = {
  name: JsonDecoder.string,
  clientId: JsonDecoder.number,
  model: maybeDecoder(JsonDecoder.string),
  deliveryDate: maybeDecoder(momentDecoder),
  closeDate: maybeDecoder(momentDecoder),
  closeReason: maybeDecoder(JsonDecoder.string),
  warrantyStartDate: maybeDecoder(momentDecoder),
  warrantyEndDate: maybeDecoder(momentDecoder),
  leasingStartDate: maybeDecoder(momentDecoder),
  leasingEndDate: maybeDecoder(momentDecoder),
  ownerId: JsonDecoder.string,
  ownerName: JsonDecoder.string,
  deviceState: maybeDecoder(JsonDecoder.string),
  status: deviceStatusDecoder,
  originalSNIContract: maybeDecoder(JsonDecoder.string),
  currentSNIContract: maybeDecoder(JsonDecoder.string),
  
  leasingRent: maybeDecoder(JsonDecoder.number),
  durationInMonths: maybeDecoder(JsonDecoder.number),
  userOrderHeader: maybeDecoder(JsonDecoder.string),
  userOrderLine: maybeDecoder(JsonDecoder.number),
  originalLeasingContract: maybeDecoder(JsonDecoder.number),
  currentLeasingContract: maybeDecoder(JsonDecoder.number),
  returnValue: maybeDecoder(JsonDecoder.number),
  purchaseOption: maybeDecoder(JsonDecoder.number),

  // leasingContractId: maybeDecoder(JsonDecoder.number),
  // billingStatus: billingStatusDecoder,
  frameContract: JsonDecoder.number,
  buybackChoice: maybeDecoder(JsonDecoder.number),
  parameter2: maybeDecoder(JsonDecoder.number),
}

const renaming = {
  // leasingContractId: 'leasingContract',
}

export const decoder = JsonDecoder.object<Device>(
  description,
  'Device',
  renaming
)

export const detailsDecoder = JsonDecoder.object<DeviceDetails>(
  {
    ...description,
    // status: maybeDecoder(deviceStatusDecoder),
    leasingRent: maybeDecoder(JsonDecoder.number),
    purchaseOption: maybeDecoder(JsonDecoder.number),
    purchaseOptionExtensionDiscount: maybeDecoder(JsonDecoder.number),
    leasingRentExtensionDiscount: maybeDecoder(JsonDecoder.number),
    leasingRentExtensionValue: maybeDecoder(JsonDecoder.number),
    residualValue: maybeDecoder(JsonDecoder.number),
    anticipatedRepurchaseValue: maybeDecoder(JsonDecoder.number),
    anticipatedReturnValue: maybeDecoder(JsonDecoder.number),
    // billingStatus: billingStatusDecoder,
    originalSNIContract: maybeDecoder(JsonDecoder.string),
    currentSNIContract: maybeDecoder(JsonDecoder.string),
  },
  'DeviceDetails',
  renaming
)
