import * as React from 'react'
import { Typography, Box, Theme, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { withRouter, RouteComponentProps } from 'react-router'
import * as routes from '../routes'

const styles = (theme: Theme) => ({
  root: {
    textAlign: 'center' as 'center',
    paddingTop: theme.spacing(8),
  },
  button: {
    marginTop: theme.spacing(4),
  },
})

interface Props extends RouteComponentProps {
  classes: any
}

class NotFound extends React.PureComponent<Props> {
  public render() {
    const { classes, history } = this.props
    return (
      <Box className={classes.root}>
        <Typography variant="h2" gutterBottom>
          Page not found{' '}
          <span role="img" aria-label="Sad">
            😢
          </span>
        </Typography>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => history.push(routes.home())}
        >
          Get back on track
        </Button>
      </Box>
    )
  }
}

export default withRouter(withStyles(styles)(NotFound))
