import { ExportTemplateField } from 'data/ExportTemplate'
import { Dispatch } from 'redux'
import { requireField, anyError, ValidationErrors } from 'requests/errors'

interface EditExportTemplateFieldAliasAction {
  type: 'EDIT_EXPORT_TEMPLATE_FIELD_ALIAS'
  field: ExportTemplateField
}
interface CloseExportTemplateFieldAliasDialogAction {
  type: 'CLOSE_EXPORT_TEMPLATE_FIELD_ALIAS_DIALOG'
}
interface SetExportTemplateFieldAliasAction {
  type: 'SET_EXPORT_TEMPLATE_FIELD_ALIAS'
  alias: string
}
interface SaveExportTemplateFieldAction {
  type: 'SAVE_EXPORT_TEMPLATE_FIELD'
  field: ExportTemplateField
}
interface SetExportTemplateFieldAliasValidationErrorsAction {
  type: 'SET_EXPORT_TEMPLATE_FIELD_ALIAS_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}

export type ExportTemplateFieldAliasDialogAction =
  | EditExportTemplateFieldAliasAction
  | CloseExportTemplateFieldAliasDialogAction
  | SetExportTemplateFieldAliasAction
  | SaveExportTemplateFieldAction
  | SetExportTemplateFieldAliasValidationErrorsAction

const editExportTemplateFieldAliasAction = (
  field: ExportTemplateField
): EditExportTemplateFieldAliasAction => ({
  type: 'EDIT_EXPORT_TEMPLATE_FIELD_ALIAS',
  field,
})
const closeExportTemplateFieldAliasDialogAction = (): CloseExportTemplateFieldAliasDialogAction => ({
  type: 'CLOSE_EXPORT_TEMPLATE_FIELD_ALIAS_DIALOG',
})
const setExportTemplateFieldAliasAction = (
  alias: string
): SetExportTemplateFieldAliasAction => ({
  type: 'SET_EXPORT_TEMPLATE_FIELD_ALIAS',
  alias,
})
const saveExportTemplateFieldAction = (
  field: ExportTemplateField
): SaveExportTemplateFieldAction => ({
  type: 'SAVE_EXPORT_TEMPLATE_FIELD',
  field,
})
const setExportTemplateFieldAliasValidationErrorsAction = (validationErrors: {
  [field: string]: string[]
}): SetExportTemplateFieldAliasValidationErrorsAction => ({
  type: 'SET_EXPORT_TEMPLATE_FIELD_ALIAS_VALIDATION_ERRORS',
  validationErrors,
})

export const editField = (dispatch: Dispatch, field: ExportTemplateField) => {
  dispatch(editExportTemplateFieldAliasAction(field))
}
export const closeDialog = (dispatch: Dispatch) => {
  dispatch(closeExportTemplateFieldAliasDialogAction())
}
export const setAlias = (dispatch: Dispatch, alias: string) => {
  dispatch(setExportTemplateFieldAliasAction(alias))
}

export const saveField = (dispatch: Dispatch, field: ExportTemplateField) => {
  const errors = {}
  requireField(errors, 'alias', field.alias)
  if (anyError(errors)) {
    dispatch(setExportTemplateFieldAliasValidationErrorsAction(errors))
    return
  }
  dispatch(saveExportTemplateFieldAction(field))
  closeDialog(dispatch)
}
