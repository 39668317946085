import { Action } from '../actions'
import { Customer } from '../data/Customer'
import {
  addItem,
  updateItem,
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export interface CustomersState {
  fetching: boolean
  data: Customer[]
}

const defaultState = (): CustomersState => fetchingDataDefault({})

export default (
  state: CustomersState = defaultState(),
  action: Action
): CustomersState => {
  switch (action.type) {
    case 'FETCH_CUSTOMERS_REQUEST':
      return fetchingData(state)
    case 'FETCH_CUSTOMERS_SUCCESS':
      return dataFetched(state, action.customers)
    case 'FETCH_CUSTOMERS_ERROR':
      return fetchingDataError(state)
    case 'CREATE_CUSTOMER_SUCCESS':
      return addItem(state, action.customer)
    case 'UPDATE_CUSTOMER_SUCCESS':
      return updateItem(state, action.customer)
    default:
      return state
  }
}
