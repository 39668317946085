import * as React from 'react'
import { WithStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Cancel'
import { InputBase, withStyles, Theme, InputAdornment } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import { State } from 'reducers'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import * as actions from '../actions/search'
import { throttle } from 'lodash'
import { History } from 'history'
import { RouteChildrenProps, withRouter } from 'react-router'
import SearchResults from 'components/SearchResults'
import { SearchResult } from 'data/SearchResult'
import * as deviceDetailsDialogActions from '../actions/deviceDetailsDialog'
import { Maybe, maybeWithDefault, isJust, maybeSwitch } from 'types/Maybe'

export const SEARCH_INPUT_ID = 'searchInput'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  selectedFrameContractId: Maybe<number>
  query: Maybe<string>
  querying: boolean
  results: SearchResult[]
}

interface DispatchProps {
  search: (frameContractId: number, query: string) => void
  clearSearch: () => void
  onResultClick: (result: SearchResult) => void
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    WithStyles<
      | 'search'
      | 'searchIcon'
      | 'searchInputRoot'
      | 'searchInputInput'
      | 'clearIcon'
    > {}

class SearchInput extends React.PureComponent<Props> {
  public render() {
    const {
      classes,
      selectedFrameContractId,
      query,
      querying,
      results,
      search,
      clearSearch,
      onResultClick,
    } = this.props
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          id={SEARCH_INPUT_ID}
          value={maybeWithDefault(query, '')}
          placeholder="Search device…"
          autoComplete="off"
          classes={{
            root: classes.searchInputRoot,
            input: classes.searchInputInput,
          }}
          onChange={maybeSwitch(
            selectedFrameContractId,
            id => e => search(id, e.target.value),
            () => e => {}
          )}
          endAdornment={
            isJust(query) && (
              <InputAdornment position="end">
                <ClearIcon
                  className={classes.clearIcon}
                  onClick={clearSearch}
                />
              </InputAdornment>
            )
          }
        />
        {isJust(query) && (
          <SearchResults
            querying={querying}
            results={results}
            clear={clearSearch}
            onResultClick={onResultClick}
          />
        )}
      </div>
    )
  }
}

const styles = (theme: Theme) => ({
  search: {
    position: 'relative' as 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute' as 'absolute',
    pointerEvents: 'none' as 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInputRoot: {
    color: 'inherit',
    width: '100%',
  },
  searchInputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 140,
      '&:focus': {
        width: 200,
      },
    },
  },
  clearIcon: {
    color: fade(theme.palette.common.white, 0.5),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer' as 'pointer',
  },
})

const mapStateToProps = (state: State): StateProps => ({
  selectedFrameContractId: state.frameContracts.selectedFrameContractId,
  query: state.search.query,
  querying: state.search.querying,
  results: state.search.results,
})

const throttledGetSearchResults = throttle(
  (
    token: string,
    dispatch: Dispatch,
    history: History,
    frameContractId: number,
    query: string
  ) =>
    actions.getSearchResults(token, dispatch, history, frameContractId, query),
  666
)

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  search: (frameContractId: number, query: string) => {
    if (!query) {
      actions.clearQuery(dispatch)
    } else {
      actions.setQuery(dispatch, query)
      throttledGetSearchResults(
        ownProps.token,
        dispatch,
        ownProps.history,
        frameContractId,
        query
      )
    }
  },
  clearSearch: () => actions.clearQuery(dispatch),
  onResultClick: (result: SearchResult) =>
    deviceDetailsDialogActions.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history,
      result.deviceName
    ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SearchInput))
)
