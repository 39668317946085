import { JsonDecoder } from 'ts.data.json'

export type BillingStatus =
  | 'Unbilled'
  | 'Pro Forma'
  | 'On-Hold'
  | 'Accepted'
  | 'Rejected'
  | 'Billed'

export const billingStatuses: Set<BillingStatus> = new Set<BillingStatus>([
  'Unbilled',
  'Pro Forma',
  'On-Hold',
  'Accepted',
  'Rejected',
  'Billed',
])

export const decoder: JsonDecoder.Decoder<
  BillingStatus
> = JsonDecoder.string.then((value: string) => {
  if ((billingStatuses as Set<string>).has(value)) {
    return JsonDecoder.constant(value as BillingStatus)
  }
  return JsonDecoder.fail('Invalid billing status "' + value + '"')
})
