import { Action } from '../actions'
import moment, { Moment } from 'moment'
import { maybeNothing, Maybe, maybeJust, maybeWithDefault } from 'types/Maybe'

export interface AppState {
  token: string | null
  loginError: string | null
  drawerOpen: boolean
  showLeasingContractsByYear: Set<number>
  showLeasingContractDetails: Set<number>
  showBillingPeriodsByYear: Set<number>
  showBillingPeriodDetails: Set<number>
  from: Maybe<Moment>
  to: Maybe<Moment>
  exportingBilling: Set<number>
}

const defaultAppState: AppState = {
  token: null,
  loginError: null,
  drawerOpen: true,
  showLeasingContractsByYear: new Set<number>(),
  showLeasingContractDetails: new Set<number>(),
  showBillingPeriodsByYear: new Set<number>(),
  showBillingPeriodDetails: new Set<number>(),
  from: maybeNothing(),
  to: maybeNothing(),
  exportingBilling: new Set<number>(),
}

export default (
  state: AppState = defaultAppState,
  action: Action
): AppState => {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.token, loginError: null }
    case 'SET_LOGIN_ERROR':
      return { ...state, token: null, loginError: action.error }
    case 'TOGGLE_DRAWER':
      return { ...state, drawerOpen: !state.drawerOpen }
    case 'TOGGLE_SHOW_LEASING_CONTRACTS_BY_YEAR': {
      const showLeasingContractsByYear = new Set(
        state.showLeasingContractsByYear
      )
      if (showLeasingContractsByYear.has(action.year)) {
        showLeasingContractsByYear.delete(action.year)
      } else {
        showLeasingContractsByYear.add(action.year)
      }
      return { ...state, showLeasingContractsByYear }
    }
    case 'TOGGLE_SHOW_LEASING_CONTRACT_DETAILS': {
      const showLeasingContractDetails = new Set(
        state.showLeasingContractDetails
      )
      if (showLeasingContractDetails.has(action.leasingContractId)) {
        showLeasingContractDetails.delete(action.leasingContractId)
      } else {
        showLeasingContractDetails.add(action.leasingContractId)
      }
      return { ...state, showLeasingContractDetails }
    }
    case 'TOGGLE_SHOW_BILLING_PERIODS_BY_YEAR': {
      const showBillingPeriodsByYear = new Set(state.showBillingPeriodsByYear)
      if (showBillingPeriodsByYear.has(action.year)) {
        showBillingPeriodsByYear.delete(action.year)
      } else {
        showBillingPeriodsByYear.add(action.year)
      }
      return { ...state, showBillingPeriodsByYear }
    }
    case 'TOGGLE_SHOW_BILLING_PERIOD_DETAILS': {
      const showBillingPeriodDetails = new Set(state.showBillingPeriodDetails)
      if (showBillingPeriodDetails.has(action.billingPeriodId)) {
        showBillingPeriodDetails.delete(action.billingPeriodId)
      } else {
        showBillingPeriodDetails.add(action.billingPeriodId)
      }
      return { ...state, showBillingPeriodDetails }
    }
    case 'FETCH_LEASING_CONTRACTS_REQUEST':
      return { ...state, showLeasingContractDetails: new Set<number>() }
    case 'SET_FROM_FILTER':
      return {
        ...state,
        from: maybeJust(action.from),
        to: maybeJust(
          maybeWithDefault(state.to, moment(action.from).endOf('month'))
        ),
      }
    case 'SET_TO_FILTER':
      return {
        ...state,
        to: maybeJust(action.to),
        from: maybeJust(
          maybeWithDefault(state.from, moment(action.to).startOf('month'))
        ),
      }
    case 'CLEAR_FROM_FILTER':
      return {
        ...state,
        from: maybeNothing(),
      }
    case 'CLEAR_TO_FILTER':
      return {
        ...state,
        to: maybeNothing(),
      }
    case 'EXPORT_BILLING_REQUEST': {
      const exportingBilling = new Set(state.exportingBilling)
      exportingBilling.add(action.billingPeriodId)
      return { ...state, exportingBilling }
    }
    case 'EXPORT_BILLING_SUCCESS':
    case 'EXPORT_BILLING_ERROR': {
      const exportingBilling = new Set(state.exportingBilling)
      exportingBilling.delete(action.billingPeriodId)
      return { ...state, exportingBilling }
    }
    case 'GENERATE_SNI_FILES_REQUEST': {
      const exportingBilling = new Set(state.exportingBilling)
      exportingBilling.add(action.billingPeriodId)
      return { ...state, exportingBilling }
    }
    case 'GENERATE_SNI_FILES_SUCCESS':
    case 'GENERATE_SNI_FILES_ERROR': {
      const exportingBilling = new Set(state.exportingBilling)
      exportingBilling.delete(action.billingPeriodId)
      return { ...state, exportingBilling }
    }
    default:
      return state
  }
}
