import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'reducers'
import { Dispatch } from 'redux'
import FormTextInput from 'components/FormTextInput'
import * as actions from '../actions/leasingCategoriesDialog'
import FormNumberInput from 'components/FormNumberInput'
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
} from '@material-ui/core'
import { LeasingCategoryParams } from 'requests/LeasingCategory'
import { withRouter, RouteChildrenProps } from 'react-router'
import Columns from 'components/Columns'
import { Moment } from 'moment'
import FormDateInput from 'components/FormDateInput'
import WithConfirmation from 'components/WithConfirmation'
import { ValidationErrors } from 'requests/errors'
import {
  maybeJust,
  maybeNothing,
  Maybe,
  maybeSwitch,
  isJust,
} from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  selectedFrameContractId: Maybe<number>
  editingId: Maybe<number>
  name: Maybe<string>
  description: Maybe<string>
  numberOfLeasingPeriods: Maybe<number>
  leasingCoefficient: Maybe<number>
  purchaseOption: Maybe<number>
  purchaseOptionUser: Maybe<number>
  normalLeasingPercentage: Maybe<number>
  extensionLeasingPercentage: Maybe<number>
  earlyPurchaseReductionPercentage: Maybe<number>
  extensionPurchaseReductionPercentage: Maybe<number>
  earlyReturnReductionPercentage: Maybe<number>
  reportingCategory: Maybe<string>
  billingCategory: Maybe<string>
  validFrom: Maybe<Moment>
  validTo: Maybe<Moment>
  // financialRate: Maybe<number>
  // endOfLifeProRation: Maybe<number>
  pao6Percentage: Maybe<number>
  saving: boolean
  deleting: boolean
  validationErrors: ValidationErrors
}

interface DispatchProps {
  closeForm: () => void
  setName: (name: string) => void
  setDescription: (description: string) => void
  setNumberOfLeasingPeriods: (numberOfLeasingPeriods: number) => void
  setLeasingCoefficient: (leasingCoefficient: number) => void
  setPurchaseOption: (purchaseOption: number) => void
  setPurchaseOptionUser: (purchaseOptionUser: number) => void
  setNormalLeasingPercentage: (normalLeasingPercentage: number) => void
  setExtensionLeasingPercentage: (extensionLeasingPercentage: number) => void
  setEarlyPurchaseReductionPercentage: (
    earlyPurchaseReductionPercentage: number
  ) => void
  setExtensionPurchaseReductionPercentage: (
    extensionPurchaseReductionPercentage: number
  ) => void
  setEarlyReturnReductionPercentage: (
    earlyReturnReductionPercentage: number
  ) => void
  // setEndOfLifeProRation: (endOfLifeProRation: number) => void
  setPao6Percentage: (pao6Percentage: number) => void
  // setFinancialRate: (financialRate: number) => void
  setValidFrom: (validFrom: Moment) => void
  setValidTo: (validTo: Moment) => void
  setReportingCategory: (reportingCategory: string) => void
  setBillingCategory: (billingCategory: string) => void
  clearName: () => void
  clearDescription: () => void
  clearNumberOfLeasingPeriods: () => void
  clearLeasingCoefficient: () => void
  clearPurchaseOption: () => void
  clearPurchaseOptionUser: () => void
  clearNormalLeasingPercentage: () => void
  clearExtensionLeasingPercentage: () => void
  clearEarlyPurchaseReductionPercentage: () => void
  clearExtensionPurchaseReductionPercentage: () => void
  clearEarlyReturnReductionPercentage: () => void
  // clearEndOfLifeProRation: () => void
  clearPao6Percentage: () => void
  // clearFinancialRate: () => void
  clearValidFrom: () => void
  clearValidTo: () => void
  clearReportingCategory: () => void
  clearBillingCategory: () => void
  createLeasingCategory: (params: LeasingCategoryParams) => void
  updateLeasingCategory: (
    leasingCategoryId: number,
    params: LeasingCategoryParams
  ) => void
  deleteLeasingCategory: (leasingCategoryId: number) => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class LeasingCategoryForm extends React.PureComponent<Props> {
  public render() {
    const {
      selectedFrameContractId,
      closeForm,
      editingId,
      name,
      description,
      numberOfLeasingPeriods,
      leasingCoefficient,
      purchaseOption,
      purchaseOptionUser,
      normalLeasingPercentage,
      extensionLeasingPercentage,
      earlyPurchaseReductionPercentage,
      extensionPurchaseReductionPercentage,
      earlyReturnReductionPercentage,
      reportingCategory,
      billingCategory,
      validFrom,
      validTo,
      // financialRate,
      // endOfLifeProRation,
      pao6Percentage,
      setName,
      setDescription,
      setNumberOfLeasingPeriods,
      setLeasingCoefficient,
      setPurchaseOption,
      setPurchaseOptionUser,
      setNormalLeasingPercentage,
      setExtensionLeasingPercentage,
      setEarlyPurchaseReductionPercentage,
      setExtensionPurchaseReductionPercentage,
      setEarlyReturnReductionPercentage,
      setReportingCategory,
      setBillingCategory,
      setValidFrom,
      setValidTo,
      // setFinancialRate,
      // setEndOfLifeProRation,
      setPao6Percentage,
      clearName,
      clearDescription,
      clearNumberOfLeasingPeriods,
      clearLeasingCoefficient,
      clearPurchaseOption,
      clearPurchaseOptionUser,
      clearNormalLeasingPercentage,
      clearExtensionLeasingPercentage,
      clearEarlyPurchaseReductionPercentage,
      clearExtensionPurchaseReductionPercentage,
      clearEarlyReturnReductionPercentage,
      clearReportingCategory,
      clearBillingCategory,
      clearValidFrom,
      clearValidTo,
      // clearFinancialRate,
      // clearEndOfLifeProRation,
      clearPao6Percentage,
      validationErrors,
      createLeasingCategory,
      updateLeasingCategory,
      deleting,
      deleteLeasingCategory,
    } = this.props
    const isEditing = isJust(editingId)
    return (
      <React.Fragment>
        <DialogTitle>
          {isEditing ? 'Edit leasing category' : 'New leasing category'}
        </DialogTitle>

        <DialogContent>
          <Columns>
            <FormTextInput
              value={name}
              label="Name"
              required={true}
              onChange={setName}
              onClear={clearName}
              validationErrors={validationErrors['name']}
              disabled={false}
            />
            <FormTextInput
              value={description}
              label="Description"
              required={true}
              onChange={setDescription}
              onClear={clearDescription}
              validationErrors={validationErrors['description']}
              disabled={false}
            />
          </Columns>
          <Columns>
            <FormNumberInput
              value={numberOfLeasingPeriods}
              label="Number of leasing periods"
              required={true}
              onChange={setNumberOfLeasingPeriods}
              onClear={clearNumberOfLeasingPeriods}
              validationErrors={validationErrors['numberOfLeasingPeriods']}
              unit={maybeNothing()}
              disabled={isEditing}
            />
            <FormNumberInput
              value={leasingCoefficient}
              label="Leasing Coefficient"
              required={true}
              onChange={setLeasingCoefficient}
              onClear={clearLeasingCoefficient}
              validationErrors={validationErrors['leasingCoefficient']}
              unit={maybeNothing()}
              disabled={false}
            />
          </Columns>
          <Columns>
            <FormNumberInput
              value={purchaseOption}
              label="Purchase Option"
              required={true}
              onChange={setPurchaseOption}
              onClear={clearPurchaseOption}
              validationErrors={validationErrors['purchaseOption']}
              unit={maybeJust('%')}
              disabled={false}
            />
            <FormNumberInput
              value={purchaseOptionUser}
              label="Purchase Option User"
              required={true}
              onChange={setPurchaseOptionUser}
              onClear={clearPurchaseOptionUser}
              validationErrors={validationErrors['purchaseOptionUser']}
              unit={maybeJust('%')}
              disabled={false}
            />
          </Columns>
          <Columns columns={2}>
            <FormNumberInput
              value={pao6Percentage}
              label="Purchase Option Company"
              required={false}
              onChange={setPao6Percentage}
              onClear={clearPao6Percentage}
              validationErrors={validationErrors['pao6Percentage']}
              unit={maybeJust('%')}
              disabled={false}
            />
          </Columns>
          <Columns>
            <FormNumberInput
              value={normalLeasingPercentage}
              label="Normal Leasing"
              required={true}
              onChange={setNormalLeasingPercentage}
              onClear={clearNormalLeasingPercentage}
              validationErrors={validationErrors['normalLeasingPercentage']}
              unit={maybeJust('%')}
              disabled={isEditing}
            />
            <FormNumberInput
              value={extensionLeasingPercentage}
              label="Extension Leasing"
              required={true}
              onChange={setExtensionLeasingPercentage}
              onClear={clearExtensionLeasingPercentage}
              validationErrors={validationErrors['extensionLeasingPercentage']}
              unit={maybeJust('%')}
              disabled={false}
            />
          </Columns>
          <Columns>
            <FormNumberInput
              value={earlyPurchaseReductionPercentage}
              label="Early Purchase Reduction"
              required={true}
              onChange={setEarlyPurchaseReductionPercentage}
              onClear={clearEarlyPurchaseReductionPercentage}
              validationErrors={
                validationErrors['earlyPurchaseReductionPercentage']
              }
              unit={maybeJust('%')}
              disabled={false}
            />
            <FormNumberInput
              value={earlyReturnReductionPercentage}
              label="Early Return Reduction Percentage"
              required={true}
              onChange={setEarlyReturnReductionPercentage}
              onClear={clearEarlyReturnReductionPercentage}
              validationErrors={
                validationErrors['earlyReturnReductionPercentage']
              }
              unit={maybeJust('%')}
              disabled={false}
            />
          </Columns>
          <Columns columns={2}>
            <FormNumberInput
              value={extensionPurchaseReductionPercentage}
              label="Extension Purchase Reduction Percentage"
              required={true}
              onChange={setExtensionPurchaseReductionPercentage}
              onClear={clearExtensionPurchaseReductionPercentage}
              validationErrors={
                validationErrors['extensionPurchaseReductionPercentage']
              }
              unit={maybeJust('%')}
              disabled={false}
            />
          </Columns>
          <Columns>
            <FormTextInput
              value={reportingCategory}
              label="Reporting category"
              onChange={setReportingCategory}
              onClear={clearReportingCategory}
              validationErrors={validationErrors['reportingCategory']}
              required={false}
              disabled={false}
            />
            <FormTextInput
              value={billingCategory}
              label="Billing category"
              onChange={setBillingCategory}
              onClear={clearBillingCategory}
              validationErrors={validationErrors['billingCategory']}
              required={false}
              disabled={false}
            />
          </Columns>
          {isEditing ? (
            <Columns>
              <FormDateInput
                value={validFrom}
                label="Valid from"
                required={false}
                onChange={setValidFrom}
                onClear={clearValidFrom}
                validationErrors={validationErrors['validFrom']}
                disabled={isEditing}
                format={maybeNothing()}
              />
              <FormDateInput
                value={validTo}
                label="Valid to"
                required={false}
                onChange={setValidTo}
                onClear={clearValidTo}
                validationErrors={validationErrors['validTo']}
                disabled={isEditing}
                format={maybeNothing()}
              />
            </Columns>
          ) : null}

          {/* <Columns>
            <FormNumberInput
              value={(endOfLifeProRation)}
              label="End Of Life Pro Ration"
              required={false}
              onChange={setEndOfLifeProRation}
              onClear={clearEndOfLifeProRation}
              validationErrors={validationErrors['endOfLifeProRation']}
              unit={maybeJust("%")}
              disabled={false}
            />
            <FormNumberInput
              value={(financialRate)}
              label="Financial rate"
              required={false}
              onChange={setFinancialRate}
              onClear={clearFinancialRate}
              validationErrors={validationErrors['financialRate']}
              unit={maybeNothing()}
              disabled={false}

            />
          </Columns> */}

          {/*<Columns
            columns={2}
            // children={[
            //   <FormNumberInput
            //     value={(servicingFee)}
            //     label="Servicing fee"
            //     required={true}
            //     onChange={setServicingFee}
            //     onClear={clearServicingFee}
            //     validationErrors={validationErrors['servicingFee']}
            //     unit={maybeNothing()}
            //     disabled={false}
            //   />,
            // ]}
          */}
        </DialogContent>

        <DialogActions>
          {maybeSwitch(
            editingId,
            id => (
              <WithConfirmation
                dialogTitle="Confirm delete leasing category?"
                onConfirm={() => deleteLeasingCategory(id)}
                render={onClick => (
                  <Button onClick={onClick} disabled={deleting}>
                    Delete
                  </Button>
                )}
              />
            ),
            () => null
          )}
          <Box flex={1} />
          <Button onClick={closeForm} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={(event: any) => {
              const params = {
                frameContractId: selectedFrameContractId,
                name,
                description,
                numberOfLeasingPeriods,
                leasingCoefficient,
                purchaseOption,
                purchaseOptionUser,
                normalLeasingPercentage,
                extensionLeasingPercentage,
                earlyPurchaseReductionPercentage,
                extensionPurchaseReductionPercentage,
                earlyReturnReductionPercentage,
                reportingCategory,
                billingCategory,
                validFrom,
                validTo,
                // financialRate,
                // endOfLifeProRation,
                pao6Percentage,
              }
              maybeSwitch(
                editingId,
                id => updateLeasingCategory(id, params),
                () => createLeasingCategory(params)
              )
            }}
            color="primary"
          >
            {isEditing ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  selectedFrameContractId: state.frameContracts.selectedFrameContractId,
  editingId: state.leasingCategoriesDialog.editingId,
  name: state.leasingCategoriesDialog.name,
  description: state.leasingCategoriesDialog.description,
  numberOfLeasingPeriods: state.leasingCategoriesDialog.numberOfLeasingPeriods,
  leasingCoefficient: state.leasingCategoriesDialog.leasingCoefficient,
  purchaseOption: state.leasingCategoriesDialog.purchaseOption,
  purchaseOptionUser: state.leasingCategoriesDialog.purchaseOptionUser,
  normalLeasingPercentage:
    state.leasingCategoriesDialog.normalLeasingPercentage,
  extensionLeasingPercentage:
    state.leasingCategoriesDialog.extensionLeasingPercentage,
  earlyPurchaseReductionPercentage:
    state.leasingCategoriesDialog.earlyPurchaseReductionPercentage,
  extensionPurchaseReductionPercentage:
    state.leasingCategoriesDialog.extensionPurchaseReductionPercentage,
  earlyReturnReductionPercentage:
    state.leasingCategoriesDialog.earlyReturnReductionPercentage,
  reportingCategory: state.leasingCategoriesDialog.reportingCategory,
  billingCategory: state.leasingCategoriesDialog.billingCategory,
  validFrom: state.leasingCategoriesDialog.validFrom,
  validTo: state.leasingCategoriesDialog.validTo,
  // financialRate: state.leasingCategoriesDialog.financialRate,
  // endOfLifeProRation: state.leasingCategoriesDialog.endOfLifeProRation,
  pao6Percentage: state.leasingCategoriesDialog.pao6Percentage,
  saving: state.leasingCategoriesDialog.saving,
  deleting: state.leasingCategoriesDialog.deleting,
  validationErrors: state.leasingCategoriesDialog.validationErrors,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeForm: () => actions.closeForm(dispatch),
  setName: (name: string) => actions.setName(dispatch, name),
  setDescription: (description: string) =>
    actions.setDescription(dispatch, description),
  setNumberOfLeasingPeriods: (numberOfLeasingPeriods: number) =>
    actions.setNumberOfLeasingPeriods(dispatch, numberOfLeasingPeriods),
  setLeasingCoefficient: (leasingCoefficient: number) =>
    actions.setLeasingCoefficient(dispatch, leasingCoefficient),
  setPurchaseOption: (purchaseOption: number) =>
    actions.setPurchaseOption(dispatch, purchaseOption),
  setPurchaseOptionUser: (purchaseOptionUser: number) =>
    actions.setPurchaseOptionUser(dispatch, purchaseOptionUser),
  setNormalLeasingPercentage: (normalLeasingPercentage: number) =>
    actions.setNormalLeasingPercentage(dispatch, normalLeasingPercentage),
  setExtensionLeasingPercentage: (extensionLeasingPercentage: number) =>
    actions.setExtensionLeasingPercentage(dispatch, extensionLeasingPercentage),
  setEarlyPurchaseReductionPercentage: (
    earlyPurchaseReductionPercentage: number
  ) =>
    actions.setEarlyPurchaseReductionPercentage(
      dispatch,
      earlyPurchaseReductionPercentage
    ),
  setExtensionPurchaseReductionPercentage: (
    extensionPurchaseReductionPercentage: number
  ) =>
    actions.setExtensionPurchaseReductionPercentage(
      dispatch,
      extensionPurchaseReductionPercentage
    ),
  setEarlyReturnReductionPercentage: (earlyReturnReductionPercentage: number) =>
    actions.setEarlyReturnReductionPercentage(
      dispatch,
      earlyReturnReductionPercentage
    ),
  // setEndOfLifeProRation: (endOfLifeProRation: number) =>
  //   actions.setEndOfLifeProRation(dispatch, endOfLifeProRation),
  setPao6Percentage: (pao6Percentage: number) =>
    actions.setPao6Percentage(dispatch, pao6Percentage),
  // setFinancialRate: (financialRate: number) =>
  //   actions.setFinancialRate(dispatch, financialRate),
  setValidFrom: (validFrom: Moment) =>
    actions.setValidFrom(dispatch, validFrom),
  setValidTo: (validTo: Moment) => actions.setValidTo(dispatch, validTo),
  setReportingCategory: (reportingCategory: string) =>
    actions.setReportingCategory(dispatch, reportingCategory),
  setBillingCategory: (billingCategory: string) =>
    actions.setBillingCategory(dispatch, billingCategory),
  clearName: () => actions.clearName(dispatch),
  clearDescription: () => actions.clearDescription(dispatch),
  clearNumberOfLeasingPeriods: () =>
    actions.clearNumberOfLeasingPeriods(dispatch),
  clearLeasingCoefficient: () => actions.clearLeasingCoefficient(dispatch),
  clearPurchaseOption: () => actions.clearPurchaseOption(dispatch),
  clearPurchaseOptionUser: () => actions.clearPurchaseOptionUser(dispatch),
  clearNormalLeasingPercentage: () =>
    actions.clearNormalLeasingPercentage(dispatch),
  clearExtensionLeasingPercentage: () =>
    actions.clearExtensionLeasingPercentage(dispatch),
  clearEarlyPurchaseReductionPercentage: () =>
    actions.clearEarlyPurchaseReductionPercentage(dispatch),
  clearExtensionPurchaseReductionPercentage: () =>
    actions.clearExtensionPurchaseReductionPercentage(dispatch),
  clearEarlyReturnReductionPercentage: () =>
    actions.clearEarlyReturnReductionPercentage(dispatch),
  // clearEndOfLifeProRation: () =>
  //   actions.clearEndOfLifeProRation(dispatch),
  clearPao6Percentage: () => actions.clearPao6Percentage(dispatch),
  // clearFinancialRate: () => actions.clearFinancialRate(dispatch),
  clearValidFrom: () => actions.clearValidFrom(dispatch),
  clearValidTo: () => actions.clearValidTo(dispatch),
  clearReportingCategory: () => actions.clearReportingCategory(dispatch),
  clearBillingCategory: () => actions.clearBillingCategory(dispatch),
  createLeasingCategory: (params: LeasingCategoryParams) =>
    actions.createLeasingCategory(
      ownProps.token,
      dispatch,
      ownProps.history,
      params
    ),
  updateLeasingCategory: (
    leasingCategoryId: number,
    params: LeasingCategoryParams
  ) =>
    actions.updateLeasingCategory(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingCategoryId,
      params
    ),
  deleteLeasingCategory: (leasingCategoryId: number) =>
    actions.deleteLeasingCategory(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingCategoryId
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeasingCategoryForm)
)
