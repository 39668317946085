import * as React from 'react'
import { FormHelperText } from '@material-ui/core'
import { capitalize } from 'utils'

interface Props {
  errors: string[]
}

export default class FormErrors extends React.PureComponent<Props> {
  render() {
    const { errors } = this.props
    const hasErrors = errors && errors.length > 0
    if (!hasErrors) {
      return null
    }
    return (
      <FormHelperText error={hasErrors} component="div">
        {errors.map((error: string, index: number) => (
          <div key={index}>{capitalize(error)}</div>
        ))}
      </FormHelperText>
    )
  }
}
