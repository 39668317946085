import { DeviceStatus } from '../data/DeviceStatus'
import { BillingStatus } from '../data/BillingStatus'
import * as requests from '../requests/Device'
import { Dispatch } from 'redux'
import { handleError } from './errors'
import { History } from 'history'
import { CountParams } from 'requests/endpoints'
import { Maybe } from 'types/Maybe'

interface SetDevicesCountReadyAction {
  type: 'SET_DEVICES_COUNT_READY'
}
interface FetchDevicesCountByLeasingContractRequestAction {
  type: 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_REQUEST'
  status: DeviceStatus
  leasingContractId: Maybe<number>
}
interface FetchDevicesCountByLeasingContractSuccessAction {
  type: 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_SUCCESS'
  count: number
  status: DeviceStatus
  leasingContractId: Maybe<number>
}
interface FetchDevicesCountByLeasingContractErrorAction {
  type: 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_ERROR'
  status: DeviceStatus
  leasingContractId: Maybe<number>
}
interface ClearDevicesCountByLeasingContractAction {
  type: 'CLEAR_DEVICES_COUNT_BY_LEASING_CONTRACT'
  status: DeviceStatus
  leasingContractId: Maybe<number>
}
interface FetchDevicesCountByBillingPeriodRequestAction {
  type: 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_REQUEST'
  billingPeriodId: number
  status: BillingStatus
}
interface FetchDevicesCountByBillingPeriodSuccessAction {
  type: 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_SUCCESS'
  count: number
  billingPeriodId: number
  status: BillingStatus
}
interface FetchDevicesCountByBillingPeriodErrorAction {
  type: 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_ERROR'
  billingPeriodId: number
  status: BillingStatus
}
interface ClearDevicesCountByBillingPeriodAction {
  type: 'CLEAR_DEVICES_COUNT_BY_BILLING_PERIOD'
  billingPeriodId: number
  status: BillingStatus
}

export type DevicesCountAction =
  | SetDevicesCountReadyAction
  | FetchDevicesCountByLeasingContractRequestAction
  | FetchDevicesCountByLeasingContractSuccessAction
  | FetchDevicesCountByLeasingContractErrorAction
  | ClearDevicesCountByLeasingContractAction
  | FetchDevicesCountByBillingPeriodRequestAction
  | FetchDevicesCountByBillingPeriodSuccessAction
  | FetchDevicesCountByBillingPeriodErrorAction
  | ClearDevicesCountByBillingPeriodAction

export const setDevicesCountReadyAction = (): SetDevicesCountReadyAction => ({type: 'SET_DEVICES_COUNT_READY'})
const fetchDevicesCountByLeasingContractRequestAction = (
  status: DeviceStatus,
  leasingContractId: Maybe<number>
): FetchDevicesCountByLeasingContractRequestAction => ({
  type: 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_REQUEST',
  status,
  leasingContractId,
})
const fetchDevicesCountByLeasingContractSuccessAction = (
  count: number,
  status: DeviceStatus,
  leasingContractId: Maybe<number>
): FetchDevicesCountByLeasingContractSuccessAction => ({
  type: 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_SUCCESS',
  count,
  status,
  leasingContractId,
})
const fetchDevicesCountByLeasingContractErrorAction = (
  status: DeviceStatus,
  leasingContractId: Maybe<number>
): FetchDevicesCountByLeasingContractErrorAction => ({
  type: 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_ERROR',
  status,
  leasingContractId,
})
const clearDevicesCountByLeasingContractAction = (
  status: DeviceStatus,
  leasingContractId: Maybe<number>
): ClearDevicesCountByLeasingContractAction => ({
  type: 'CLEAR_DEVICES_COUNT_BY_LEASING_CONTRACT',
  status,
  leasingContractId,
})
const fetchDevicesCountByBillingPeriodRequestAction = (
  billingPeriodId: number,
  status: BillingStatus
): FetchDevicesCountByBillingPeriodRequestAction => ({
  type: 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_REQUEST',
  billingPeriodId,
  status,
})
const fetchDevicesCountByBillingPeriodSuccessAction = (
  count: number,
  billingPeriodId: number,
  status: BillingStatus
): FetchDevicesCountByBillingPeriodSuccessAction => ({
  type: 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_SUCCESS',
  billingPeriodId,
  status,
  count,
})
const fetchDevicesCountByBillingPeriodErrorAction = (
  billingPeriodId: number,
  status: BillingStatus
): FetchDevicesCountByBillingPeriodErrorAction => ({
  type: 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_ERROR',
  billingPeriodId,
  status,
})
const clearDevicesCountByBillingPeriodAction = (
  billingPeriodId: number,
  status: BillingStatus
): ClearDevicesCountByBillingPeriodAction => ({
  type: 'CLEAR_DEVICES_COUNT_BY_BILLING_PERIOD',
  billingPeriodId,
  status,
})

export const fetchCount = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: CountParams
) => {
  switch (params.type) {
    case 'leasing':
      dispatch(
        fetchDevicesCountByLeasingContractRequestAction(
          params.status,
          params.leasingContractId
        )
      )
      break
    case 'billing':
      dispatch(
        fetchDevicesCountByBillingPeriodRequestAction(
          params.billingPeriodId,
          params.status
        )
      )
      break
  }
  try {
    const count = await requests.countByStatus(token, params)
    switch (params.type) {
      case 'leasing':
        dispatch(
          fetchDevicesCountByLeasingContractSuccessAction(
            count,
            params.status,
            params.leasingContractId
          )
        )
        break
      case 'billing':
        dispatch(
          fetchDevicesCountByBillingPeriodSuccessAction(
            count,
            params.billingPeriodId,
            params.status
          )
        )
        break
    }
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      () => {
        switch (params.type) {
          case 'leasing':
            return fetchDevicesCountByLeasingContractErrorAction(
              params.status,
              params.leasingContractId
            )
          case 'billing':
            return fetchDevicesCountByBillingPeriodErrorAction(
              params.billingPeriodId,
              params.status
            )
        }
      },
      'display_error'
    )
  }
}

export const clearByLeasingContract = (
  dispatch: Dispatch,
  status: DeviceStatus,
  leasingContractId: Maybe<number>
) => {
  dispatch(clearDevicesCountByLeasingContractAction(status, leasingContractId))
}

export const clearByBillingPeriod = (
  dispatch: Dispatch,
  billingPeriodId: number,
  status: BillingStatus
) => {
  dispatch(clearDevicesCountByBillingPeriodAction(billingPeriodId, status))
}
