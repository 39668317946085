import * as React from 'react'
import { Grid } from '@material-ui/core'
import { GridProps } from '@material-ui/core/Grid'

interface Props {
  children: JSX.Element | JSX.Element[]
  columns?: number
  gridProps?: GridProps
}

export default class Columns extends React.PureComponent<Props> {
  public columnCountToSm = (columnCount: number) => {
    if (columnCount === 1) {
      return 12
    }
    if (columnCount === 2) {
      return 6
    }
    if (columnCount === 3) {
      return 4
    }
    if (columnCount === 4) {
      return 3
    }
    return 12
  }

  public renderColumn = (
    key: number,
    columnCount: number,
    child: JSX.Element
  ) => (
    <Grid key={key} item xs={12} sm={this.columnCountToSm(columnCount)}>
      {child}
    </Grid>
  )

  public render() {
    const { children: childOrChildren, columns, gridProps } = this.props
    const children: JSX.Element[] = Array.isArray(childOrChildren)
      ? (childOrChildren as JSX.Element[])
      : [childOrChildren as JSX.Element]

    return (
      <Grid container spacing={3} {...gridProps}>
        {children.map((item: JSX.Element, index: number) =>
          this.renderColumn(index, columns || children.length, item)
        )}
      </Grid>
    )
  }
}
