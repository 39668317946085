import { Dispatch } from 'redux'
import * as requests from '../requests/Customer'
import { Customer } from '../data/Customer'
import { History } from 'history'
import { handleError } from './errors'

interface FetchCustomersRequestAction {
  type: 'FETCH_CUSTOMERS_REQUEST'
}
interface FetchCustomersSuccessAction {
  type: 'FETCH_CUSTOMERS_SUCCESS'
  customers: Customer[]
}
interface FetchCustomersErrorAction {
  type: 'FETCH_CUSTOMERS_ERROR'
}

export type CustomersAction =
  | FetchCustomersRequestAction
  | FetchCustomersSuccessAction
  | FetchCustomersErrorAction

const fetchCustomersRequestAction = (): FetchCustomersRequestAction => ({
  type: 'FETCH_CUSTOMERS_REQUEST',
})
const fetchCustomersSuccessAction = (
  customers: Customer[]
): FetchCustomersSuccessAction => ({
  type: 'FETCH_CUSTOMERS_SUCCESS',
  customers,
})
const fetchCustomersErrorAction = (): FetchCustomersErrorAction => ({
  type: 'FETCH_CUSTOMERS_ERROR',
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History
) => {
  dispatch(fetchCustomersRequestAction())
  try {
    const customers = await requests.fetchAll(token)
    dispatch(fetchCustomersSuccessAction(customers))
  } catch (error) {
    handleError(dispatch, history, error, fetchCustomersErrorAction, 'redirect')
  }
}
