import { Dispatch } from 'redux'
import { ErrorLog } from '../reducers/errorsHistory'

interface PushErrorLogToHistoryAction {
  type: 'PUSH_ERROR_LOG_TO_HISTORY'
  errorLog: ErrorLog
}

interface RemoveErrorLogAction {
  type: 'REMOVE_ERROR_LOG'
  index: number
}
interface ClearErrorsHistoryAction {
  type: 'CLEAR_ERRORS_HISTORY'
}

interface CopyErrorLogToClipboardAction {
  type: 'COPY_ERROR_LOG_TO_CLIPBOARD'
  index: number
}

export type ErrorsHistoryAction =
  | PushErrorLogToHistoryAction
  | RemoveErrorLogAction
  | ClearErrorsHistoryAction
  | CopyErrorLogToClipboardAction

const pushErrorLogToHistoryAction = (
  errorLog: ErrorLog
): PushErrorLogToHistoryAction => ({
  type: 'PUSH_ERROR_LOG_TO_HISTORY',
  errorLog,
})

const removeErrorLogAction = (index: number): RemoveErrorLogAction => ({
  type: 'REMOVE_ERROR_LOG',
  index,
})
const clearErrorsHistoryAction = (): ClearErrorsHistoryAction => ({
  type: 'CLEAR_ERRORS_HISTORY',
})

const copyErrorLogToClipboardAction = (
  index: number
): CopyErrorLogToClipboardAction => ({
  type: 'COPY_ERROR_LOG_TO_CLIPBOARD',
  index,
})

export const pushErrorLog = (dispatch: Dispatch, errorLog: ErrorLog) => {
  dispatch(pushErrorLogToHistoryAction(errorLog))
}

export const removeErrorLog = (dispatch: Dispatch, index: number) => {
  dispatch(removeErrorLogAction(index))
}
export const clearErrorsHistory = (dispatch: Dispatch) => {
  dispatch(clearErrorsHistoryAction())
}

export const copyErrorLogToClipboard = (dispatch: Dispatch, index: number) => {
  dispatch(copyErrorLogToClipboardAction(index))
}
