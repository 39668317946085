import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItemText,
  ListItem,
  Box,
  ListSubheader,
  WithStyles,
  Theme,
  IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import RedoIcon from '@material-ui/icons/Redo'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../reducers'
import * as actions from '../actions/leasingCategoriesDialog'
import { RouteChildrenProps, withRouter } from 'react-router'
import { LeasingCategory } from 'data/LeasingCategory'
import LeasingCategoryForm from './LeasingCategoryForm'
import LeasingCategoryView from './LeasingCategoryView'
import * as leasingCategoryActions from '../actions/leasingCategories'
import LoadingIndicator from 'components/LoadingIndicator'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'
import { ViewOrForm } from 'reducers/leasingCategoriesDialog'
import {
  maybeMap2,
  maybeWithDefault,
  maybeMap,
  Maybe,
  maybeNothing,
  maybeJust,
} from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  open: boolean
  selectedFrameContractId: Maybe<number>
  fetchingLeasingCategories: boolean
  leasingCategories: LeasingCategory[]
  viewOrForm: ViewOrForm
  copying: boolean
}

interface DispatchProps {
  closeDialog: () => void
  fetchLeasingCategories: (frameContractId: number) => void
  openNewLeasingCategoryForm: () => void
  openLeasingCategoryForm: (leasingCategory: LeasingCategory) => void
  openLeasingCategoryView: (leasingCategory: LeasingCategory) => void
  copyLeasingCategory: (leasingCategoryId: number) => void
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    WithStyles<'listSubheader'> {}

class LeasingCategoriesDialog extends React.PureComponent<Props> {
  public componentDidUpdate(prevProps: Props) {
    if (this.props.open !== prevProps.open && this.props.open) {
      maybeMap(this.props.selectedFrameContractId, id =>
        this.props.fetchLeasingCategories(id)
      )
    }
  }

  private renderLeasingCategories = (
    leasingCategories: LeasingCategory[],
    subtitle: string,
    isEditable: boolean
  ) => (
    <List
      subheader={
        <ListSubheader classes={{ root: this.props.classes.listSubheader }}>
          {subtitle}
        </ListSubheader>
      }
    >
      {leasingCategories.map(this.renderLeasingCategory(isEditable))}
    </List>
  )

  private renderLeasingCategory = (isEditable: boolean) => (
    leasingCategory: LeasingCategory
  ) => {
    const {
      openLeasingCategoryForm,
      openLeasingCategoryView,
      copyLeasingCategory,
      copying,
    } = this.props
    return (
      <ListItem key={leasingCategory.id} button={true}>
        <ListItemText
          primary={
            leasingCategory.name +
            (leasingCategory.description
              ? ' - ' + leasingCategory.description
              : '')
          }
        />
        <IconButton
          onClick={(event: any) => {
            event.stopPropagation()
            if (isEditable) {
              openLeasingCategoryForm(leasingCategory)
            } else {
              openLeasingCategoryView(leasingCategory)
            }
          }}
        >
          {isEditable ? <EditIcon /> : <VisibilityIcon />}
        </IconButton>
        <IconButton
          onClick={(event: any) => {
            event.stopPropagation()
            copyLeasingCategory(leasingCategory.id)
          }}
          disabled={copying}
        >
          <RedoIcon />
        </IconButton>
      </ListItem>
    )
  }

  private isCurrent = (leasingCategory: LeasingCategory): boolean =>
    maybeWithDefault(
      maybeMap2(
        leasingCategory.validFrom,
        leasingCategory.validTo,
        (validFrom, validTo) => moment().isBetween(validFrom, validTo)
      ),
      false
    )

  private isFuture = (leasingCategory: LeasingCategory): boolean =>
    maybeWithDefault(
      maybeMap(leasingCategory.validFrom, validFrom =>
        validFrom.isAfter(moment())
      ),
      false
    )

  private renderContent = () => {
    const {
      token,
      fetchingLeasingCategories,
      leasingCategories,
      closeDialog,
      viewOrForm,
      openNewLeasingCategoryForm,
    } = this.props
    const currentLeasingCategories = leasingCategories.filter(this.isCurrent)
    const futureLeasingCategories = leasingCategories.filter(this.isFuture)

    switch (viewOrForm) {
      case 'closed':
        if (fetchingLeasingCategories) {
          return (
            <Box p={5}>
              <LoadingIndicator size={maybeNothing()} />
            </Box>
          )
        } else {
          return (
            <React.Fragment>
              <DialogTitle>Leasing categories</DialogTitle>

              {this.renderLeasingCategories(
                currentLeasingCategories,
                'Current leasing categories',
                false
              )}
              {this.renderLeasingCategories(
                futureLeasingCategories,
                'Future leasing categories',
                true
              )}

              <DialogActions>
                <Button onClick={openNewLeasingCategoryForm}>
                  New leasing category
                </Button>
                <Box flex={1} />
                <Button onClick={closeDialog} color="secondary">
                  Close
                </Button>
              </DialogActions>
            </React.Fragment>
          )
        }
      case 'form':
        return <LeasingCategoryForm token={token} />
      case 'view':
        return <LeasingCategoryView token={token} />
    }
  }

  public render() {
    const { open, closeDialog } = this.props
    return (
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={closeDialog}>
        {this.renderContent()}
      </Dialog>
    )
  }
}

const styles = (theme: Theme) => ({
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
})

const mapStateToProps = (state: State): StateProps => ({
  open: state.leasingCategoriesDialog.open,
  selectedFrameContractId: state.frameContracts.selectedFrameContractId,
  fetchingLeasingCategories: state.leasingCategories.fetching,
  leasingCategories: state.leasingCategories.data,
  viewOrForm: state.leasingCategoriesDialog.viewOrForm,
  copying: state.leasingCategoriesDialog.copying,
})

const mapDispatchProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  fetchLeasingCategories: (frameContractId: number) =>
    leasingCategoryActions.fetchAll(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId
    ),
  openNewLeasingCategoryForm: () => actions.openForm(dispatch, maybeNothing()),
  openLeasingCategoryForm: (leasingCategory: LeasingCategory) =>
    actions.openForm(dispatch, maybeJust(leasingCategory)),
  openLeasingCategoryView: (leasingCategory: LeasingCategory) =>
    actions.openView(dispatch, leasingCategory),
  copyLeasingCategory: (leasingCategoryId: number) =>
    actions.copyLeasingCategory(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingCategoryId
    ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchProps
  )(withStyles(styles)(LeasingCategoriesDialog))
)
