import moment from 'moment'
import { toPairs } from 'lodash'
import { ERROR_MESSAGE_MAX_CHARACTERS } from './config/constants'
import { isMaybe, isNothing, Maybe, maybeToNull } from 'types/Maybe'

export const pluralize = (count: number, text: string, pluralText: string) =>
  count === 1 ? text : pluralText

export const capitalize = (content: string): string =>
  content.charAt(0).toUpperCase() + content.slice(1)

export const humanize = (value: string): string =>
  capitalize(value.replace(/([A-Z])/g, ' $1'))

export const toQueryParams = (params: {}): string =>
  '?' +
  toPairs(params)
    .filter(
      ([key, value]) =>
        value !== undefined &&
        value !== null &&
        (!isMaybe(value) || !isNothing(value as Maybe<any>))
    )
    .map(([key, value]) => {
      let formattedValue = value
      if (moment.isMoment(value)) {
        formattedValue = value.toISOString()
      } else if (isMaybe(value)) {
        formattedValue = maybeToNull(value as Maybe<any>)
      }
      return `${key}=${formattedValue}`
    })
    .join('&')

export const limitErrorMessage = (message: string): string =>
  message.length <= ERROR_MESSAGE_MAX_CHARACTERS
    ? message
    : message.substr(0, ERROR_MESSAGE_MAX_CHARACTERS) + '…'
