import { JsonDecoder } from 'ts.data.json'

export type Maybe<T> =
  | { type: 'MAYBE_JUST'; data: T }
  | { type: 'MAYBE_NOTHING' }

export const isMaybe = (value: any): boolean =>
  typeof value === 'object' &&
  (value.type === 'MAYBE_JUST' || value.type === 'MAYBE_NOTHING')

export const maybeSwitch = <T, J, N>(
  maybe: Maybe<T>,
  onJust: (data: T) => J,
  onNothing: () => N
): J | N => {
  switch (maybe.type) {
    case 'MAYBE_JUST':
      return onJust(maybe.data)
    case 'MAYBE_NOTHING':
      return onNothing()
  }
}

export const maybeSwitch2 = <T1, T2, J, N>(
  maybe1: Maybe<T1>,
  maybe2: Maybe<T2>,
  onAllJust: (data1: T1, data2: T2) => J,
  onNothing: () => N
): J | N =>
  maybeSwitch(
    maybe1,
    data1 =>
      maybeSwitch(
        maybe2,
        data2 => onAllJust(data1, data2),
        () => onNothing()
      ),
    () => onNothing()
  )

export const isJust = <T>(maybe: Maybe<T>): boolean =>
  maybeSwitch(
    maybe,
    data => true,
    () => false
  )
export const isNothing = <T>(maybe: Maybe<T>): boolean =>
  maybeSwitch(
    maybe,
    data => false,
    () => true
  )

export const maybeJust = <T>(data: T): Maybe<T> => ({
  type: 'MAYBE_JUST',
  data,
})
export const maybeNothing = <T>(): Maybe<T> => ({ type: 'MAYBE_NOTHING' })

export const maybeWithDefault = <T>(maybe: Maybe<T>, defaultValue: T): T =>
  maybeSwitch(
    maybe,
    data => data,
    () => defaultValue
  )

export const maybeMap = <T, J>(
  maybe: Maybe<T>,
  mapper: (data: T) => J
): Maybe<J> =>
  maybeSwitch(
    maybe,
    data => maybeJust(mapper(data)),
    () => maybeNothing()
  )

export const maybeMap2 = <T1, T2, J>(
  maybe1: Maybe<T1>,
  maybe2: Maybe<T2>,
  mapper: (data1: T1, data2: T2) => J
): Maybe<J> =>
  maybeSwitch2(
    maybe1,
    maybe2,
    (data1, data2) => maybeJust(mapper(data1, data2)),
    () => maybeNothing()
  )

export const maybeAndThen = <T, J>(
  maybe: Maybe<T>,
  func: (data: T) => Maybe<J>
): Maybe<J> =>
  maybeSwitch(
    maybe,
    data => func(data),
    () => maybeNothing()
  )

export const maybeToNull = <T>(maybe: Maybe<T>): T | null =>
  maybeSwitch(
    maybe,
    data => data,
    () => null
  )

export const maybeFromNull = <T>(dataOrNull: T | null): Maybe<T> =>
  dataOrNull === null ? maybeNothing() : maybeJust(dataOrNull)

export const maybeFromUndefined = <T>(
  dataOrUndefined: T | undefined
): Maybe<T> =>
  dataOrUndefined === undefined ? maybeNothing() : maybeJust(dataOrUndefined)

export const maybeDecoder = <T>(
  decoder: JsonDecoder.Decoder<T>
): JsonDecoder.Decoder<Maybe<T>> =>
  JsonDecoder.oneOf([decoder, JsonDecoder.isNull(null)], 'Maybe').map(
    maybeFromNull
  )

export const maybeFind = <T>(
  array: T[],
  cond: (value: T) => boolean
): Maybe<T> => {
  const res = array.find(cond)
  if (res === undefined) {
    return maybeNothing()
  } else {
    return maybeJust(res)
  }
}

export const maybeCompare = <T>(maybe: Maybe<T>, value: T): boolean =>
  maybeWithDefault(
    maybeMap(maybe, v => v === value),
    false
  )
