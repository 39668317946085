import * as React from 'react'
import { FormControl } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Moment } from 'moment'
import FormErrors from './FormErrors'
import { Maybe, maybeWithDefault, maybeToNull } from 'types/Maybe'

interface Props {
  value: Maybe<Moment>
  label: string
  required: boolean
  onChange: (value: Moment) => void
  onClear: () => void
  validationErrors: string[]
  disabled: boolean
  format: Maybe<string>
}

export default class FormDateInput extends React.PureComponent<Props> {
  render() {
    const {
      value,
      label,
      onChange,
      onClear,
      required,
      validationErrors,
      disabled,
      format,
    } = this.props
    const hasErrors = validationErrors && validationErrors.length > 0
    return (
      <FormControl fullWidth={true} error={hasErrors}>
        <DatePicker
          margin="normal"
          autoOk
          label={label}
          clearable
          value={maybeToNull(value)}
          format={maybeWithDefault(format, 'MMMM Do YYYY')}
          required={required}
          onAccept={(d: Moment) => {
            d ? onChange(d) : onClear()
          }}
          onChange={() => {}}
          error={hasErrors}
          disabled={disabled}
        />
        <FormErrors errors={validationErrors} />
      </FormControl>
    )
  }
}
