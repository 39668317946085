import * as React from 'react'
import DragList from 'react-drag-list'
import {
  ListItem,
  ListItemText,
  List,
  withStyles,
  Theme,
  ListItemIcon,
  IconButton,
} from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { WithStyles } from '@material-ui/styles'
import { ListProps } from '@material-ui/core/List'
import { ExportTemplateField } from 'data/ExportTemplate'

interface Props extends WithStyles<'row' | 'listItem'> {
  fields: ExportTemplateField[]
  onUpdate: (newFields: ExportTemplateField[]) => void
  onItemClick: (field: ExportTemplateField) => void
  onArrowClick: (field: ExportTemplateField) => void
  listProps: ListProps
}

class MaterialDraggableList extends React.PureComponent<Props> {
  public render() {
    const {
      fields,
      onUpdate,
      onItemClick,
      onArrowClick,
      listProps,
      classes,
    } = this.props
    return (
      <List {...listProps}>
        <DragList
          dataSource={fields}
          rowKey="field"
          row={(field: Object, index) => (
            <ListItem
              key={index}
              button={true}
              className={classes.listItem}
              onClick={() => onItemClick(field as ExportTemplateField)}
            >
              <ListItemIcon>
                <IconButton
                  size="small"
                  onClick={e => {
                    e.stopPropagation()
                    onArrowClick(field as ExportTemplateField)
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary={(field as ExportTemplateField).alias} />
              <DragIndicatorIcon color="disabled" />
            </ListItem>
          )}
          onUpdate={(event, newFields) =>
            onUpdate(newFields as ExportTemplateField[])
          }
          rowClassName={classes.row}
          handles={false}
        />
      </List>
    )
  }
}

const styles = (theme: Theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center' as 'center',
    cursor: 'move' as 'move',
  },
  listItem: {
    cursor: 'move' as 'move',
  },
})

export default withStyles(styles)(MaterialDraggableList)
