import { Action } from '../actions'
import { LeasingCategory } from '../data/LeasingCategory'
import {
  addItem,
  updateItem,
  deleteItem,
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export interface LeasingCategoriesState {
  fetching: boolean
  data: LeasingCategory[]
}

const defaultState = (): LeasingCategoriesState => fetchingDataDefault({})

export default (
  state: LeasingCategoriesState = defaultState(),
  action: Action
): LeasingCategoriesState => {
  switch (action.type) {
    case 'FETCH_LEASING_CATEGORIES_REQUEST':
      return fetchingData(state)
    case 'FETCH_LEASING_CATEGORIES_SUCCESS':
      return dataFetched(state, action.leasingCategories)
    case 'FETCH_LEASING_CATEGORIES_ERROR':
      return fetchingDataError(state)
    case 'CREATE_LEASING_CATEGORY_SUCCESS':
    case 'COPY_LEASING_CATEGORY_SUCCESS':
      return addItem(state, action.leasingCategory)
    case 'UPDATE_LEASING_CATEGORY_SUCCESS':
      return updateItem(state, action.leasingCategory)
    case 'DELETE_LEASING_CATEGORY_SUCCESS':
      return deleteItem(state, action.leasingCategoryId)
    default:
      return state
  }
}
