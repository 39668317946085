import * as app from './app'
import * as customers from './customers'
import * as customerDialog from './customerDialog'
import * as customerEntities from './customerEntities'
import * as frameContracts from './frameContracts'
import * as leasingContracts from './leasingContracts'
import * as leasingCategories from './leasingCategories'
import * as frameContractDialog from './frameContractDialog'
import * as leasingContractDialog from './leasingContractDialog'
import * as leasingCategoriesDialog from './leasingCategoriesDialog'
import * as devices from './devices'
import * as devicesCount from './devicesCount'
import * as deviceDetailsDialog from './deviceDetailsDialog'
import * as exportTemplates from './exportTemplates'
import * as exportFields from './exportFields'
import * as exportTemplateDialog from './exportTemplateDialog'
import * as exportTemplateFieldAliasDialog from './exportTemplateFieldAliasDialog'
import * as billingPeriods from './billingPeriods'
import * as billingPeriodDialog from './billingPeriodDialog'
import * as search from './search'
import * as snackbars from './snackbars'
import * as errorsHistory from './errorsHistory'

export type Action =
  | app.AppAction
  | customers.CustomersAction
  | customerDialog.CustomerDialogAction
  | customerEntities.CustomerEntitiesAction
  | devices.DevicesAction
  | devicesCount.DevicesCountAction
  | deviceDetailsDialog.DeviceDetailsDialogAction
  | frameContracts.FrameContractsAction
  | leasingContracts.LeasingContractsAction
  | leasingCategories.LeasingCategoriesAction
  | frameContractDialog.FrameContractDialogAction
  | leasingContractDialog.LeasingContractDialogAction
  | leasingCategoriesDialog.LeasingCategoriesDialogAction
  | exportTemplates.ExportTemplatesAction
  | exportFields.ExportFieldsAction
  | exportTemplateDialog.ExportTemplateDialogAction
  | exportTemplateFieldAliasDialog.ExportTemplateFieldAliasDialogAction
  | billingPeriods.BillingPeriodsAction
  | billingPeriodDialog.BillingPeriodDialogAction
  | search.SearchAction
  | snackbars.SnackbarsAction
  | errorsHistory.ErrorsHistoryAction

export default {
  app,
  customers,
  customerDialog,
  customerEntities,
  frameContracts,
  leasingContracts,
  leasingCategories,
  frameContractDialog,
  leasingContractDialog,
  leasingCategoriesDialog,
  devices,
  devicesCount,
  deviceDetailsDialog,
  exportTemplates,
  exportFields,
  exportTemplateDialog,
  exportTemplateFieldAliasDialog,
  billingPeriods,
  billingPeriodDialog,
  search,
  snackbars,
  errorsHistory,
}
