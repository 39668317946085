import moment, { Moment } from 'moment'
import { JsonDecoder } from 'ts.data.json'

export const optionalDecoder = <t>(
  decoder: JsonDecoder.Decoder<t>
): JsonDecoder.Decoder<t | null> =>
  JsonDecoder.oneOf(
    [decoder, JsonDecoder.isNull(null), JsonDecoder.isUndefined(null)],
    'optional'
  )

export const withDefault = <t>(
  decoder: JsonDecoder.Decoder<t>,
  defaultValue: t
): JsonDecoder.Decoder<t> =>
  optionalDecoder(decoder).map((value: t | null) =>
    value === null ? defaultValue : value
  )

export const momentDecoder: JsonDecoder.Decoder<
  Moment
> = JsonDecoder.string.then((value: string) => {
  const res = moment(value)
  if (res.isValid()) {
    return JsonDecoder.constant(res)
  } else {
    return JsonDecoder.fail('Invalid date or datetime "' + value + '"')
  }
})
