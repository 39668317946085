import { Action } from '../actions'
import { BillingPeriod } from 'data/BillingPeriod'
import {
  addItem,
  //updateItem,
  deleteItem,
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export interface BillingPeriodsState {
  fetching: boolean
  data: BillingPeriod[]
}

const defaultState = (): BillingPeriodsState => fetchingDataDefault({})

export default (
  state: BillingPeriodsState = defaultState(),
  action: Action
): BillingPeriodsState => {
  switch (action.type) {
    case 'FETCH_BILLING_PERIODS_REQUEST':
      return fetchingData(state)
    case 'FETCH_BILLING_PERIODS_SUCCESS':
      return dataFetched(state, action.billingPeriods)
    case 'FETCH_BILLING_PERIODS_ERROR':
      return fetchingDataError(state)
    case 'CREATE_BILLING_PERIOD_SUCCESS':
      return addItem(state, action.billingPeriod)
    case 'UPDATE_BILLING_PERIOD_SUCCESS':
      return state
    case 'DELETE_BILLING_PERIOD_SUCCESS':
      return deleteItem(state, action.billingPeriodId)
    default:
      return state
  }
}
