import { Action } from '../actions'
import { Maybe, maybeSwitch, maybeNothing, maybeJust } from 'types/Maybe'

export interface DevicesCount {
  fetching: boolean
  count: Maybe<number>
}

interface DevicesCountByStatus {
  [status: string]: DevicesCount
}
interface DevicesCountByLeasingContract {
  [leasingContractId: number]: DevicesCountByStatus
}
interface DevicesCountByBillingPeriod {
  [billingPeriod: string]: DevicesCountByStatus
}

export interface DevicesCountState {
  ready: boolean
  aggregated: DevicesCountByStatus
  byLeasingContract: DevicesCountByLeasingContract
  byBillingPeriod: DevicesCountByBillingPeriod
}

const defaultState = (): DevicesCountState => ({
  ready: false,
  aggregated: {},
  byLeasingContract: {},
  byBillingPeriod: {},
})

export default (
  state: DevicesCountState = defaultState(),
  action: Action
): DevicesCountState => {
  switch (action.type) {
    case 'SET_DEVICES_COUNT_READY':
      return { ...state, ready: true }
    case 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_REQUEST':
      return maybeSwitch(
        action.leasingContractId,
        id => ({
          ...state,
          byLeasingContract: {
            ...state.byLeasingContract,
            [id]: {
              ...state.byLeasingContract[id],
              [action.status]: { fetching: true, count: maybeNothing() },
            },
          },
        }),
        () => ({
          ...state,
          aggregated: {
            ...state.aggregated,
            [action.status]: { fetching: true, count: maybeNothing() },
          },
        })
      )
    case 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_SUCCESS':
      return maybeSwitch(
        action.leasingContractId,
        id => ({
          ...state,
          byLeasingContract: {
            ...state.byLeasingContract,
            [id]: {
              ...state.byLeasingContract[id],
              [action.status]: {
                fetching: false,
                count: maybeJust(action.count),
              },
            },
          },
        }),
        () => ({
          ...state,
          aggregated: {
            ...state.aggregated,
            [action.status]: {
              fetching: false,
              count: maybeJust(action.count),
            },
          },
        })
      )
    case 'FETCH_DEVICES_COUNT_BY_LEASING_CONTRACT_ERROR':
      return maybeSwitch(
        action.leasingContractId,
        id => ({
          ...state,
          byLeasingContract: {
            ...state.byLeasingContract,
            [id]: {
              ...state.byLeasingContract[id],
              [action.status]: { fetching: false, count: maybeNothing() },
            },
          },
        }),
        () => ({
          ...state,
          aggregated: {
            ...state.aggregated,
            [action.status]: { fetching: false, count: maybeNothing() },
          },
        })
      )
    case 'CLEAR_DEVICES_COUNT_BY_LEASING_CONTRACT':
      return maybeSwitch(
        action.leasingContractId,
        id => {
          const { [action.status]: val, ...rest } =
            state.byLeasingContract[id] || {}
          if (Object.keys(rest).length === 0) {
            const { [id]: val2, ...rest2 } = state.byLeasingContract
            return {
              ...state,
              byLeasingContract: rest2,
            }
          } else {
            return {
              ...state,
              byLeasingContract: {
                ...state.byLeasingContract,
                [id]: rest,
              },
            }
          }
        },
        () => {
          const { [action.status]: val, ...rest } = state.aggregated
          return {
            ...state,
            aggregated: rest,
          }
        }
      )
    case 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_REQUEST':
      return {
        ...state,
        byBillingPeriod: {
          ...state.byBillingPeriod,
          [action.billingPeriodId]: {
            ...state.byBillingPeriod[action.billingPeriodId],
            [action.status]: { fetching: true, count: maybeNothing() },
          },
        },
      }
    case 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_SUCCESS':
      return {
        ...state,
        byBillingPeriod: {
          ...state.byBillingPeriod,
          [action.billingPeriodId]: {
            ...state.byBillingPeriod[action.billingPeriodId],
            [action.status]: {
              fetching: false,
              count: maybeJust(action.count),
            },
          },
        },
      }
    case 'FETCH_DEVICES_COUNT_BY_BILLING_PERIOD_ERROR':
      return {
        ...state,
        byBillingPeriod: {
          ...state.byBillingPeriod,
          [action.billingPeriodId]: {
            ...state.byBillingPeriod[action.billingPeriodId],
            [action.status]: { fetching: false, count: maybeNothing() },
          },
        },
      }
    case 'CLEAR_DEVICES_COUNT_BY_BILLING_PERIOD': {
      const { [action.status]: val, ...rest } = state.byBillingPeriod[
        action.billingPeriodId
      ]
      if (Object.keys(rest).length === 0) {
        const {
          [action.billingPeriodId]: val2,
          ...rest2
        } = state.byBillingPeriod
        return {
          ...state,
          byBillingPeriod: rest2,
        }
      } else {
        return {
          ...state,
          byBillingPeriod: {
            ...state.byBillingPeriod,
            [action.billingPeriodId]: rest,
          },
        }
      }
    }
    default:
      return state
  }
}
