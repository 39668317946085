import { JsonDecoder } from 'ts.data.json'
import { API_URL } from 'config/constants'
import {
  Maybe,
  maybeAndThen,
  maybeWithDefault,
  maybeSwitch,
  maybeNothing,
  maybeMap,
  maybeJust,
} from 'types/Maybe'

export type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

const methods: Set<Method> = new Set<Method>([
  'GET',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
])

export const methodDecoder: JsonDecoder.Decoder<Method> = JsonDecoder.string.then(
  (value: string) => {
    if ((methods as Set<string>).has(value)) {
      return JsonDecoder.constant(value as Method)
    }
    return JsonDecoder.fail('Invalid method "' + value + '"')
  }
)

type Headers = { [key: string]: string }

export class ApiError {
  public message: string
  public method: Method
  public url: string
  public body: Maybe<string>
  constructor(
    message: string,
    method: Method,
    url: string,
    maybeBody: Maybe<string | FormData>
  ) {
    this.message = message
    this.method = method
    this.url = url
    // Discard FormData body
    this.body = maybeAndThen(maybeBody, body =>
      typeof body === 'string' ? maybeJust(body) : maybeNothing()
    )
  }
}
export class AuthenticationError {}
export class NotFoundError {}
export class DecoderError {}

const base = async (
  token: string,
  method: Method,
  path: string,
  maybeHeaders: Maybe<Headers>,
  maybeBody: Maybe<string | FormData>
): Promise<Response> => {
  const headers = maybeWithDefault(maybeHeaders, {})
  headers.Authorization = 'Bearer ' + token
  const body: string | FormData | undefined = maybeSwitch(
    maybeBody,
    b => b,
    () => undefined
  )

  const url = API_URL + path
  const options = {
    method,
    headers,
    body,
  }

  const response = await fetch(url, options)
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  if (response.status === 401) {
    throw new AuthenticationError()
  }
  if (response.status === 404) {
    throw new NotFoundError()
  }
  const error: string = await response.text()
  throw new ApiError(error, method, url, maybeBody)
}

const jsonBase = async <a>(
  token: string,
  method: Method,
  path: string,
  decoder: JsonDecoder.Decoder<a>,
  body: Maybe<any>
): Promise<a> => {
  const response = await base(
    token,
    method,
    path,
    maybeJust({ 'Content-Type': 'application/json' }),
    maybeMap(body, JSON.stringify)
  )
  const payload = await response.json()
  try {
    const decodedPayload = await decoder.decodePromise(payload)
    return decodedPayload
  } catch (e) {
    console.log(e)
    throw new DecoderError()
  }
}

export const getRequest = async <a>(
  token: string,
  path: string,
  decoder: JsonDecoder.Decoder<a>
): Promise<a> => jsonBase(token, 'GET', path, decoder, maybeNothing())

export const postRequest = async <a>(
  token: string,
  path: string,
  params: any,
  decoder: JsonDecoder.Decoder<a>
): Promise<a> => jsonBase(token, 'POST', path, decoder, maybeJust(params))

export const putRequest = async <a>(
  token: string,
  path: string,
  params: any,
  decoder: JsonDecoder.Decoder<a>
): Promise<a> => jsonBase(token, 'PUT', path, decoder, maybeJust(params))

export const patchRequest = async <a>(
  token: string,
  path: string,
  params: any,
  decoder: JsonDecoder.Decoder<a>
): Promise<a> => jsonBase(token, 'PATCH', path, decoder, maybeJust(params))

export const deleteRequest = async (
  token: string,
  path: string
): Promise<void> => {
  await base(token, 'DELETE', path, maybeNothing(), maybeNothing())
}

export const downloadFilePostRequest = async (
  token: string,
  path: string,
  params: any,
  filename: string,
  fileType: 'csv' | 'zip'
): Promise<void> => {
  const response = await base(
    token,
    'POST',
    path,
    maybeJust({ 'Content-Type': 'application/json' }),
    maybeJust(JSON.stringify(params))
  )
  const blob = await response.blob()
  const blobOptions =
    fileType === 'csv' ? undefined : { type: 'application/zip' }
  const url = window.URL.createObjectURL(new Blob([blob], blobOptions))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename + '.' + fileType)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export const uploadCsvPostRequest = async (
  token: string,
  path: string,
  file: FileList
): Promise<void> => {
  const formData = new FormData()
  var ins = file.length
  for (var x = 0; x < ins; x++) {
      formData.append('file', file[x])
  }
  
  await base(token, 'POST', path, maybeNothing(), maybeJust(formData))
}
