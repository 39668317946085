import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { State } from 'reducers'
import * as actions from '../actions/leasingCategoriesDialog'
import { Moment } from 'moment'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@material-ui/core'
import { withRouter, RouteChildrenProps } from 'react-router'
import WithConfirmation from 'components/WithConfirmation'
import { FieldValue } from 'components/FieldValue'
import Columns from 'components/Columns'
import { Maybe, maybeNothing, maybeJust, maybeSwitch } from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  editingId: Maybe<number>
  name: Maybe<string>
  description: Maybe<string>
  numberOfLeasingPeriods: Maybe<number>
  leasingCoefficient: Maybe<number>
  purchaseOption: Maybe<number>
  purchaseOptionUser: Maybe<number>
  normalLeasingPercentage: Maybe<number>
  extensionLeasingPercentage: Maybe<number>
  earlyPurchaseReductionPercentage: Maybe<number>
  extensionPurchaseReductionPercentage: Maybe<number>
  earlyReturnReductionPercentage: Maybe<number>
  reportingCategory: Maybe<string>
  billingCategory: Maybe<string>
  validFrom: Maybe<Moment>
  validTo: Maybe<Moment>
  //financialRate: Maybe<number>
  //endOfLifeProRation: Maybe<number>
  pao6Percentage: Maybe<number>
  deleting: boolean
}

interface DispatchProps {
  closeForm: () => void
  deleteLeasingCategory: (leasingCategoryId: number) => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class LeasingCategoryView extends React.PureComponent<Props> {
  public render() {
    const {
      closeForm,
      deleteLeasingCategory,
      editingId,
      deleting,
      name,
      description,
      numberOfLeasingPeriods,
      leasingCoefficient,
      purchaseOption,
      purchaseOptionUser,
      normalLeasingPercentage,
      extensionLeasingPercentage,
      earlyPurchaseReductionPercentage,
      extensionPurchaseReductionPercentage,
      earlyReturnReductionPercentage,
      reportingCategory,
      billingCategory,
      validFrom,
      validTo,
      //financialRate,
      //endOfLifeProRation,
      pao6Percentage,
    } = this.props
    return (
      <React.Fragment>
        <DialogTitle>Leasing category</DialogTitle>

        <DialogContent>
          <Columns>
            <FieldValue field="Name" value={{ type: 'string', value: name }} />
            <FieldValue
              field="Description"
              value={{ type: 'string', value: description }}
            />
          </Columns>
          <Columns>
            <FieldValue
              field="Number of leasing periods"
              value={{
                type: 'integer',
                value: numberOfLeasingPeriods,
                unit: maybeNothing(),
              }}
            />
            <FieldValue
              field="Leasing Coefficient"
              value={{
                type: 'integer',
                value: leasingCoefficient,
                unit: maybeNothing(),
              }}
            />
          </Columns>
          <Columns>
            <FieldValue
              field="Purchase Option"
              value={{
                type: 'integer',
                value: purchaseOption,
                unit: maybeJust('%'),
              }}
            />
            <FieldValue
              field="Purchase Option User"
              value={{
                type: 'integer',
                value: purchaseOptionUser,
                unit: maybeJust('%'),
              }}
            />
          </Columns>
          <Columns columns={2}>
            <FieldValue
              field="Purchase Option Company"
              value={{
                type: 'integer',
                value: pao6Percentage,
                unit: maybeJust('%'),
              }}
            />
          </Columns>
          <Columns>
            <FieldValue
              field="Normal Leasing"
              value={{
                type: 'integer',
                value: normalLeasingPercentage,
                unit: maybeJust('%'),
              }}
            />
            <FieldValue
              field="Extension Leasing"
              value={{
                type: 'integer',
                value: extensionLeasingPercentage,
                unit: maybeJust('%'),
              }}
            />
          </Columns>
          <Columns>
            <FieldValue
              field="Early Purchase Reduction"
              value={{
                type: 'integer',
                value: earlyPurchaseReductionPercentage,
                unit: maybeJust('%'),
              }}
            />
            <FieldValue
              field="Early Return Reduction"
              value={{
                type: 'integer',
                value: earlyReturnReductionPercentage,
                unit: maybeJust('%'),
              }}
            />
          </Columns>
          <Columns columns={2}>
            <FieldValue
              field="Extension Purchase Reduction"
              value={{
                type: 'integer',
                value: extensionPurchaseReductionPercentage,
                unit: maybeJust('%'),
              }}
            />
            {/* <FieldValue
              field="Financial rate"

              value={{
                type: 'integer',
                value: financialRate,
                unit: maybeNothing(),
              }}
            />  */}
          </Columns>
          <Columns>
            <FieldValue
              field="Reporting category"
              value={{ type: 'string', value: reportingCategory }}
            />
            <FieldValue
              field="Billing category"
              value={{ type: 'string', value: billingCategory }}
            />
          </Columns>
          <Columns>
            <FieldValue
              field="Valid from"
              value={{ type: 'date', value: validFrom }}
            />
            <FieldValue
              field="Valid to"
              value={{ type: 'date', value: validTo }}
            />
          </Columns>

          {/* <Columns>
            <FieldValue
              field="End Of Life Pro Ration"
              value={{
                type: 'integer',
                value: endOfLifeProRation,
                unit: maybeJust('%'),
              }}
            />
          </Columns>  */}
        </DialogContent>

        <DialogActions>
          <WithConfirmation
            dialogTitle="Confirm delete leasing category?"
            onConfirm={maybeSwitch(
              editingId,
              id => () => deleteLeasingCategory(id),
              () => () => {}
            )}
            render={onClick => (
              <Button onClick={onClick} disabled={deleting}>
                Delete
              </Button>
            )}
          />
          <Box flex={1} />
          <Button onClick={closeForm} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  editingId: state.leasingCategoriesDialog.editingId,
  name: state.leasingCategoriesDialog.name,
  description: state.leasingCategoriesDialog.description,
  numberOfLeasingPeriods: state.leasingCategoriesDialog.numberOfLeasingPeriods,
  leasingCoefficient: state.leasingCategoriesDialog.leasingCoefficient,
  purchaseOption: state.leasingCategoriesDialog.purchaseOption,
  purchaseOptionUser: state.leasingCategoriesDialog.purchaseOptionUser,
  normalLeasingPercentage:
    state.leasingCategoriesDialog.normalLeasingPercentage,
  extensionLeasingPercentage:
    state.leasingCategoriesDialog.extensionLeasingPercentage,
  earlyPurchaseReductionPercentage:
    state.leasingCategoriesDialog.earlyPurchaseReductionPercentage,
  extensionPurchaseReductionPercentage:
    state.leasingCategoriesDialog.extensionPurchaseReductionPercentage,
  earlyReturnReductionPercentage:
    state.leasingCategoriesDialog.earlyReturnReductionPercentage,
  reportingCategory: state.leasingCategoriesDialog.reportingCategory,
  billingCategory: state.leasingCategoriesDialog.billingCategory,
  validFrom: state.leasingCategoriesDialog.validFrom,
  validTo: state.leasingCategoriesDialog.validTo,
  //financialRate: state.leasingCategoriesDialog.financialRate,
  //endOfLifeProRation: state.leasingCategoriesDialog.endOfLifeProRation,
  pao6Percentage: state.leasingCategoriesDialog.pao6Percentage,
  deleting: state.leasingCategoriesDialog.deleting,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeForm: () => actions.closeForm(dispatch),
  deleteLeasingCategory: (leasingCategoryId: number) =>
    actions.deleteLeasingCategory(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingCategoryId
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeasingCategoryView)
)
