import { JsonDecoder } from 'ts.data.json'
import {
  FrameContract,
  decoder,
  LeasingStartTrigger,
  FrameContractCategory,
} from '../data/FrameContract'
import { getRequest, postRequest, patchRequest, deleteRequest } from './base'
import { requireField, ValidationErrors } from './errors'
import { omit } from 'lodash'
import * as endpoints from './endpoints'
import { Moment } from 'moment'
import { Maybe, maybeMap, maybeToNull } from 'types/Maybe'

export const fetchAll = async (token: string): Promise<FrameContract[]> =>
  getRequest(
    token,
    endpoints.frameContracts(),
    JsonDecoder.array(decoder, 'FrameContract[]')
  )

export interface FrameContractParams {
  name: Maybe<string>
  type: string
  isActive: boolean
  customerId: Maybe<number>
  customerEntityIds: number[]
  contractStartDate: Maybe<Moment>
  billingIntervalInMonths: Maybe<number>
  contractIntervalInMonths: Maybe<number>
  noticePeriodInMonths: Maybe<number>
  isProRataBilling: boolean
  leasingStartTrigger: LeasingStartTrigger
  billOnlyActiveAssets: boolean
  creditUnusedPeriods: boolean
  categories: FrameContractCategory[]
}

type Mode = 'create' | 'update'

const encodeParams = (mode: Mode, params: FrameContractParams) => {
  const fullParams = {
    name: maybeToNull(params.name),
    frameContractType: params.type,
    isActive: params.isActive,
    azureClientId: maybeToNull(params.customerId),
    entities: params.customerEntityIds,
    contractStartDate: maybeToNull(
      maybeMap(params.contractStartDate, date => date.format('YYYY-MM-DD'))
    ),
    billingIntervalInMonths: maybeToNull(params.billingIntervalInMonths),
    contractIntervalInMonths: maybeToNull(params.contractIntervalInMonths),
    noticePeriodInMonths: maybeToNull(params.noticePeriodInMonths),
    isProRataBilling: params.isProRataBilling,
    leasingStartTrigger: params.leasingStartTrigger as string,
    billOnlyActiveAssets: params.billOnlyActiveAssets,
    creditUnusedPeriods: params.creditUnusedPeriods,
    categories: params.categories,
  }
  switch (mode) {
    case 'create':
      return fullParams
    case 'update':
      return omit(fullParams, [
        'name',
        'frameContractType',
        'azureClientId',
        'entities',
        'contractStartDate',
        'billingIntervalInMonths',
        'contractIntervalInMonths',
        'noticePeriodInMonths',
        'leasingStartTrigger',
        'billOnlyActiveAssets',
        'creditUnusedPeriods',
        'categories',
      ])
  }
}

export const checkParams = (
  mode: Mode,
  params: FrameContractParams
): ValidationErrors => {
  const errors = {}
  if (mode === 'create') {
    requireField(errors, 'name', params.name)
    requireField(errors, 'type', params.type)
    requireField(errors, 'customerId', params.customerId)
    requireField(errors, 'customerEntityIds', params.customerEntityIds)
    requireField(errors, 'contractStartDate', params.contractStartDate)
    requireField(
      errors,
      'billingIntervalInMonths',
      params.billingIntervalInMonths
    )
    requireField(
      errors,
      'contractIntervalInMonths',
      params.contractIntervalInMonths
    )
    requireField(errors, 'noticePeriodInMonths', params.noticePeriodInMonths)
    requireField(errors, 'leasingStartTrigger', params.leasingStartTrigger)
    requireField(errors, 'billOnlyActiveAssets', params.billOnlyActiveAssets)
    requireField(errors, 'creditUnusedPeriods', params.creditUnusedPeriods)
    requireField(errors, 'categories', params.categories)
  }
  requireField(errors, 'isActive', params.isActive)
  requireField(errors, 'isProRataBilling', params.isProRataBilling)
  return errors
}

export const create = async (
  token: string,
  params: FrameContractParams
): Promise<FrameContract> =>
  postRequest(
    token,
    endpoints.frameContracts(),
    encodeParams('create', params),
    decoder
  )

export const update = async (
  token: string,
  frameContractId: number,
  params: FrameContractParams
): Promise<FrameContract> =>
  patchRequest(
    token,
    endpoints.frameContract(frameContractId),
    { ...encodeParams('update', params), frameContract: frameContractId },
    decoder
  )

export const remove = async (
  token: string,
  frameContractId: number
): Promise<void> =>
  deleteRequest(token, endpoints.frameContract(frameContractId))

export const isDeletable = async (
  token: string,
  frameContractId: number
): Promise<boolean> =>
  getRequest(
    token,
    endpoints.frameContractIsDeletable(frameContractId),
    JsonDecoder.boolean
  )
