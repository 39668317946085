import * as requests from '../requests/LeasingCategory'
import { LeasingCategory } from 'data/LeasingCategory'
import { Dispatch } from 'redux'
import { handleError } from './errors'
import { History } from 'history'

interface FetchLeasingCategoriesRequestAction {
  type: 'FETCH_LEASING_CATEGORIES_REQUEST'
}
interface FetchLeasingCategoriesSuccessAction {
  type: 'FETCH_LEASING_CATEGORIES_SUCCESS'
  leasingCategories: LeasingCategory[]
}
interface FetchLeasingCategoriesErrorAction {
  type: 'FETCH_LEASING_CATEGORIES_ERROR'
}

export type LeasingCategoriesAction =
  | FetchLeasingCategoriesRequestAction
  | FetchLeasingCategoriesSuccessAction
  | FetchLeasingCategoriesErrorAction

const fetchLeasingCategoriesRequestAction = (): FetchLeasingCategoriesRequestAction => ({
  type: 'FETCH_LEASING_CATEGORIES_REQUEST',
})
const fetchLeasingCategoriesSuccessAction = (
  leasingCategories: LeasingCategory[]
): FetchLeasingCategoriesSuccessAction => ({
  type: 'FETCH_LEASING_CATEGORIES_SUCCESS',
  leasingCategories,
})
const fetchLeasingCategoriesErrorAction = (): FetchLeasingCategoriesErrorAction => ({
  type: 'FETCH_LEASING_CATEGORIES_ERROR',
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number
) => {
  dispatch(fetchLeasingCategoriesRequestAction())
  try {
    const leasingCategories = await requests.fetchByFrameContractId(
      token,
      frameContractId
    )
    dispatch(fetchLeasingCategoriesSuccessAction(leasingCategories))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchLeasingCategoriesErrorAction,
      'redirect'
    )
  }
}
