import { Dispatch } from 'redux'
import { BillingPeriod, BillingPeriodDetails } from 'data/BillingPeriod'
import * as requests from '../requests/BillingPeriod'
import { History } from 'history'
import { handleError } from './errors'
import { Maybe, maybeSwitch, maybeJust, maybeNothing } from 'types/Maybe'
import { anyError, ValidationErrors } from 'requests/errors'
import * as customerEntitiesActions from './customerEntities'
import { handleSuccess } from './success'
import { Moment } from 'moment'

interface OpenBillingPeriodDialogAction {
  type: 'OPEN_BILLING_PERIOD_DIALOG'
  billingPeriod: Maybe<BillingPeriodDetails>
}
interface CloseBillingPeriodDialogAction {
  type: 'CLOSE_BILLING_PERIOD_DIALOG'
}
interface CreateBillingPeriodRequestAction {
  type: 'CREATE_BILLING_PERIOD_REQUEST'
}
interface CreateBillingPeriodSuccessAction {
  type: 'CREATE_BILLING_PERIOD_SUCCESS'
  billingPeriod: BillingPeriod
}
interface CreateBillingPeriodErrorAction {
  type: 'CREATE_BILLING_PERIOD_ERROR'
}
interface UpdateBillingPeriodRequestAction {
  type: 'UPDATE_BILLING_PERIOD_REQUEST'
}
interface UpdateBillingPeriodSuccessAction {
  type: 'UPDATE_BILLING_PERIOD_SUCCESS'
  billingPeriod: number
}
interface UpdateBillingPeriodErrorAction {
  type: 'UPDATE_BILLING_PERIOD_ERROR'
}
interface DeleteBillingPeriodRequestAction {
  type: 'DELETE_BILLING_PERIOD_REQUEST'
}
interface DeleteBillingPeriodSuccessAction {
  type: 'DELETE_BILLING_PERIOD_SUCCESS'
  billingPeriodId: number
}
interface DeleteBillingPeriodErrorAction {
  type: 'DELETE_BILLING_PERIOD_ERROR'
}
interface SetBillingPeriodIsDeletableAction {
  type: 'SET_BILLING_PERIOD_IS_DELETABLE'
}
interface SetBillingPeriodPurchaseOrderAction {
  type: 'SET_BILLING_PERIOD_PURCHASE_ORDER'
  customerEntityId: number
  purchaseOrder: string
}
interface SetBillingPeriodRentInvoiceNumberAction {
  type: 'SET_BILLING_PERIOD_RENT_INVOICE_NUMBER'
  customerEntityId: number
  rentInvoiceNumber: string
}
interface SetBillingPeriodRentInvoiceDateAction {
  type: 'SET_BILLING_PERIOD_RENT_INVOICE_DATE'
  customerEntityId: number
  rentInvoiceDate: Moment
}
interface SetBillingPeriodExitInvoiceNumberAction {
  type: 'SET_BILLING_PERIOD_EXIT_INVOICE_NUMBER'
  customerEntityId: number
  exitInvoiceNumber: string
}
interface SetBillingPeriodExitInvoiceDateAction {
  type: 'SET_BILLING_PERIOD_EXIT_INVOICE_DATE'
  customerEntityId: number
  exitInvoiceDate: Moment
}
interface SetBillingPeriodDamagesInvoiceNumberAction {
  type: 'SET_BILLING_PERIOD_DAMAGES_INVOICE_NUMBER'
  customerEntityId: number
  damagesInvoiceNumber: string
}
interface SetBillingPeriodDamagesInvoiceDateAction {
  type: 'SET_BILLING_PERIOD_DAMAGES_INVOICE_DATE'
  customerEntityId: number
  damagesInvoiceDate: Moment
}
interface ClearBillingPeriodPurchaseOrderAction {
  type: 'CLEAR_BILLING_PERIOD_PURCHASE_ORDER'
  customerEntityId: number
}
interface ClearBillingPeriodRentInvoiceNumberAction {
  type: 'CLEAR_BILLING_PERIOD_RENT_INVOICE_NUMBER'
  customerEntityId: number
}
interface ClearBillingPeriodRentInvoiceDateAction {
  type: 'CLEAR_BILLING_PERIOD_RENT_INVOICE_DATE'
  customerEntityId: number
}
interface ClearBillingPeriodExitInvoiceNumberAction {
  type: 'CLEAR_BILLING_PERIOD_EXIT_INVOICE_NUMBER'
  customerEntityId: number
}
interface ClearBillingPeriodExitInvoiceDateAction {
  type: 'CLEAR_BILLING_PERIOD_EXIT_INVOICE_DATE'
  customerEntityId: number
}
interface ClearBillingPeriodDamagesInvoiceNumberAction {
  type: 'CLEAR_BILLING_PERIOD_DAMAGES_INVOICE_NUMBER'
  customerEntityId: number
}
interface ClearBillingPeriodDamagesInvoiceDateAction {
  type: 'CLEAR_BILLING_PERIOD_DAMAGES_INVOICE_DATE'
  customerEntityId: number
}
interface SetBillingPeriodValidationErrorsAction {
  type: 'SET_BILLING_PERIOD_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}

export type BillingPeriodDialogAction =
  | OpenBillingPeriodDialogAction
  | CloseBillingPeriodDialogAction
  | CreateBillingPeriodRequestAction
  | CreateBillingPeriodSuccessAction
  | CreateBillingPeriodErrorAction
  | UpdateBillingPeriodRequestAction
  | UpdateBillingPeriodSuccessAction
  | UpdateBillingPeriodErrorAction
  | DeleteBillingPeriodRequestAction
  | DeleteBillingPeriodSuccessAction
  | DeleteBillingPeriodErrorAction
  | SetBillingPeriodIsDeletableAction
  | SetBillingPeriodPurchaseOrderAction
  | SetBillingPeriodRentInvoiceNumberAction
  | SetBillingPeriodRentInvoiceDateAction
  | SetBillingPeriodExitInvoiceNumberAction
  | SetBillingPeriodExitInvoiceDateAction
  | SetBillingPeriodDamagesInvoiceNumberAction
  | SetBillingPeriodDamagesInvoiceDateAction
  | ClearBillingPeriodPurchaseOrderAction
  | ClearBillingPeriodRentInvoiceNumberAction
  | ClearBillingPeriodRentInvoiceDateAction
  | ClearBillingPeriodExitInvoiceNumberAction
  | ClearBillingPeriodExitInvoiceDateAction
  | ClearBillingPeriodDamagesInvoiceNumberAction
  | ClearBillingPeriodDamagesInvoiceDateAction
  | SetBillingPeriodValidationErrorsAction

const openBillingPeriodDialogAction = (
  billingPeriod: Maybe<BillingPeriodDetails>
): OpenBillingPeriodDialogAction => ({
  type: 'OPEN_BILLING_PERIOD_DIALOG',
  billingPeriod,
})
const closeBillingPeriodDialogAction = (): CloseBillingPeriodDialogAction => ({
  type: 'CLOSE_BILLING_PERIOD_DIALOG',
})
const createBillingPeriodRequestAction = (): CreateBillingPeriodRequestAction => ({
  type: 'CREATE_BILLING_PERIOD_REQUEST',
})
const createBillingPeriodSuccessAction = (
  billingPeriod: BillingPeriod
): CreateBillingPeriodSuccessAction => ({
  type: 'CREATE_BILLING_PERIOD_SUCCESS',
  billingPeriod,
})
const createBillingPeriodErrorAction = (): CreateBillingPeriodErrorAction => ({
  type: 'CREATE_BILLING_PERIOD_ERROR',
})
const updateBillingPeriodRequestAction = (): UpdateBillingPeriodRequestAction => ({
  type: 'UPDATE_BILLING_PERIOD_REQUEST',
})
const updateBillingPeriodSuccessAction = (
  billingPeriod: number
): UpdateBillingPeriodSuccessAction => ({
  type: 'UPDATE_BILLING_PERIOD_SUCCESS',
  billingPeriod,
})
const updateBillingPeriodErrorAction = (): UpdateBillingPeriodErrorAction => ({
  type: 'UPDATE_BILLING_PERIOD_ERROR',
})
const deleteBillingPeriodRequestAction = (): DeleteBillingPeriodRequestAction => ({
  type: 'DELETE_BILLING_PERIOD_REQUEST',
})
const deleteBillingPeriodSuccessAction = (
  billingPeriodId: number
): DeleteBillingPeriodSuccessAction => ({
  type: 'DELETE_BILLING_PERIOD_SUCCESS',
  billingPeriodId,
})
const deleteBillingPeriodErrorAction = (): DeleteBillingPeriodErrorAction => ({
  type: 'DELETE_BILLING_PERIOD_ERROR',
})
const setBillingPeriodIsDeletableAction = (): SetBillingPeriodIsDeletableAction => ({
  type: 'SET_BILLING_PERIOD_IS_DELETABLE',
})
const setBillingPeriodPurchaseOrderAction = (
  customerEntityId: number,
  purchaseOrder: string
): SetBillingPeriodPurchaseOrderAction => ({
  type: 'SET_BILLING_PERIOD_PURCHASE_ORDER',
  customerEntityId,
  purchaseOrder,
})
const clearBillingPeriodPurchaseOrderAction = (
  customerEntityId: number
): ClearBillingPeriodPurchaseOrderAction => ({
  type: 'CLEAR_BILLING_PERIOD_PURCHASE_ORDER',
  customerEntityId,
})
const setBillingPeriodRentInvoiceNumberAction = (
  customerEntityId: number,
  rentInvoiceNumber: string
): SetBillingPeriodRentInvoiceNumberAction => ({
  type: 'SET_BILLING_PERIOD_RENT_INVOICE_NUMBER',
  customerEntityId,
  rentInvoiceNumber,
})
const clearBillingPeriodRentInvoiceNumberAction = (
  customerEntityId: number
): ClearBillingPeriodRentInvoiceNumberAction => ({
  type: 'CLEAR_BILLING_PERIOD_RENT_INVOICE_NUMBER',
  customerEntityId,
})
const setBillingPeriodRentInvoiceDateAction = (
  customerEntityId: number,
  rentInvoiceDate: Moment
): SetBillingPeriodRentInvoiceDateAction => ({
  type: 'SET_BILLING_PERIOD_RENT_INVOICE_DATE',
  customerEntityId,
  rentInvoiceDate,
})
const clearBillingPeriodRentInvoiceDateAction = (
  customerEntityId: number
): ClearBillingPeriodRentInvoiceDateAction => ({
  type: 'CLEAR_BILLING_PERIOD_RENT_INVOICE_DATE',
  customerEntityId,
})
const setBillingPeriodExitInvoiceNumberAction = (
  customerEntityId: number,
  exitInvoiceNumber: string
): SetBillingPeriodExitInvoiceNumberAction => ({
  type: 'SET_BILLING_PERIOD_EXIT_INVOICE_NUMBER',
  customerEntityId,
  exitInvoiceNumber,
})
const clearBillingPeriodExitInvoiceNumberAction = (
  customerEntityId: number
): ClearBillingPeriodExitInvoiceNumberAction => ({
  type: 'CLEAR_BILLING_PERIOD_EXIT_INVOICE_NUMBER',
  customerEntityId,
})
const setBillingPeriodExitInvoiceDateAction = (
  customerEntityId: number,
  exitInvoiceDate: Moment
): SetBillingPeriodExitInvoiceDateAction => ({
  type: 'SET_BILLING_PERIOD_EXIT_INVOICE_DATE',
  customerEntityId,
  exitInvoiceDate,
})
const clearBillingPeriodExitInvoiceDateAction = (
  customerEntityId: number
): ClearBillingPeriodExitInvoiceDateAction => ({
  type: 'CLEAR_BILLING_PERIOD_EXIT_INVOICE_DATE',
  customerEntityId,
})
const setBillingPeriodDamagesInvoiceNumberAction = (
  customerEntityId: number,
  damagesInvoiceNumber: string
): SetBillingPeriodDamagesInvoiceNumberAction => ({
  type: 'SET_BILLING_PERIOD_DAMAGES_INVOICE_NUMBER',
  customerEntityId,
  damagesInvoiceNumber,
})
const clearBillingPeriodDamagesInvoiceNumberAction = (
  customerEntityId: number
): ClearBillingPeriodDamagesInvoiceNumberAction => ({
  type: 'CLEAR_BILLING_PERIOD_DAMAGES_INVOICE_NUMBER',
  customerEntityId,
})
const setBillingPeriodDamagesInvoiceDateAction = (
  customerEntityId: number,
  damagesInvoiceDate: Moment
): SetBillingPeriodDamagesInvoiceDateAction => ({
  type: 'SET_BILLING_PERIOD_DAMAGES_INVOICE_DATE',
  customerEntityId,
  damagesInvoiceDate,
})
const clearBillingPeriodDamagesInvoiceDateAction = (
  customerEntityId: number
): ClearBillingPeriodDamagesInvoiceDateAction => ({
  type: 'CLEAR_BILLING_PERIOD_DAMAGES_INVOICE_DATE',
  customerEntityId,
})
const setBillingPeriodValidationErrorsAction = (
  validationErrors: ValidationErrors
): SetBillingPeriodValidationErrorsAction => ({
  type: 'SET_BILLING_PERIOD_VALIDATION_ERRORS',
  validationErrors,
})

export const openDialog = (
  token: string,
  dispatch: Dispatch,
  history: History,
  customerId: number,
  billingPeriod: Maybe<BillingPeriod>
): void => {
  maybeSwitch(
    billingPeriod,
    async ({ id }) => {
      const billingPeriodDetails = await requests.get(token, id)
      dispatch(openBillingPeriodDialogAction(maybeJust(billingPeriodDetails)))
    },
    () => {
      dispatch(openBillingPeriodDialogAction(maybeNothing()))
    }
  )
  customerEntitiesActions.fetchAll(token, dispatch, history, customerId)
}
export const closeDialog = (dispatch: Dispatch): void => {
  dispatch(closeBillingPeriodDialogAction())
}

export const createBillingPeriod = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number,
  params: requests.BillingPeriodParams,
  customerEntityIds: number[]
): Promise<void> => {
  dispatch(createBillingPeriodRequestAction())

  const errors = requests.checkParams('create', params, customerEntityIds)
  if (anyError(errors)) {
    dispatch(setBillingPeriodValidationErrorsAction(errors))
    dispatch(createBillingPeriodErrorAction())
    return
  }

  try {
    const billingPeriod = await requests.create(token, frameContractId, params)
    closeDialog(dispatch)
    dispatch(createBillingPeriodSuccessAction(billingPeriod))
    handleSuccess(dispatch, 'BillingPeriod Created', null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      createBillingPeriodErrorAction,
      'display_error'
    )
  }
}

export const updateBillingPeriod = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  billingPeriodId: number,
  frameContractId: number,
  params: requests.BillingPeriodParams,
  customerEntityIds: number[]
): Promise<void> => {
  dispatch(updateBillingPeriodRequestAction())

  const errors = requests.checkParams('update', params, customerEntityIds)
  if (anyError(errors)) {
    dispatch(setBillingPeriodValidationErrorsAction(errors))
    dispatch(updateBillingPeriodErrorAction())
    return
  }

  try {
    const billingPeriod = await requests.update(
      token,
      billingPeriodId,
      frameContractId,
      params
    )
    closeDialog(dispatch)
    dispatch(updateBillingPeriodSuccessAction(billingPeriod))
    handleSuccess(dispatch, 'BillingPeriod Updated', null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      updateBillingPeriodErrorAction,
      'display_error'
    )
  }
}

export const deleteBillingPeriod = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  billingPeriodId: number
): Promise<void> => {
  dispatch(deleteBillingPeriodRequestAction())

  try {
    await requests.remove(token, billingPeriodId)
    dispatch(deleteBillingPeriodSuccessAction(billingPeriodId))
    handleSuccess(dispatch, 'BillingPeriod Deleted', null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      deleteBillingPeriodErrorAction,
      'display_error'
    )
  }
}

export const checkIfIsDeletable = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number
): Promise<void> => {
  try {
    const isDeletable = await requests.isDeletable(token, frameContractId)
    if (isDeletable) {
      dispatch(setBillingPeriodIsDeletableAction())
    }
  } catch (error) {
    handleError(dispatch, history, error, null, 'display_error')
  }
}

export const setPurchaseOrder = (
  dispatch: Dispatch,
  customerEntityId: number,
  purchaseOrder: string
): void => {
  dispatch(setBillingPeriodPurchaseOrderAction(customerEntityId, purchaseOrder))
}
export const clearPurchaseOrder = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodPurchaseOrderAction(customerEntityId))
}

export const setRentInvoiceNumber = (
  dispatch: Dispatch,
  customerEntityId: number,
  rentInvoiceNumber: string
): void => {
  dispatch(
    setBillingPeriodRentInvoiceNumberAction(customerEntityId, rentInvoiceNumber)
  )
}
export const clearRentInvoiceNumber = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodRentInvoiceNumberAction(customerEntityId))
}

export const setRentInvoiceDate = (
  dispatch: Dispatch,
  customerEntityId: number,
  rentInvoiceDate: Moment
): void => {
  dispatch(
    setBillingPeriodRentInvoiceDateAction(customerEntityId, rentInvoiceDate)
  )
}
export const clearRentInvoiceDate = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodRentInvoiceDateAction(customerEntityId))
}

export const setExitInvoiceNumber = (
  dispatch: Dispatch,
  customerEntityId: number,
  exitInvoiceNumber: string
): void => {
  dispatch(
    setBillingPeriodExitInvoiceNumberAction(
      customerEntityId,
      exitInvoiceNumber
    )
  )
}
export const clearExitInvoiceNumber = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodExitInvoiceNumberAction(customerEntityId))
}

export const setExitInvoiceDate = (
  dispatch: Dispatch,
  customerEntityId: number,
  exitInvoiceDate: Moment
): void => {
  dispatch(
    setBillingPeriodExitInvoiceDateAction(
      customerEntityId,
      exitInvoiceDate
    )
  )
}
export const clearExitInvoiceDate = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodExitInvoiceDateAction(customerEntityId))
}

export const setDamagesInvoiceNumber = (
  dispatch: Dispatch,
  customerEntityId: number,
  damagesInvoiceNumber: string
): void => {
  dispatch(
    setBillingPeriodDamagesInvoiceNumberAction(
      customerEntityId,
      damagesInvoiceNumber
    )
  )
}
export const clearDamagesInvoiceNumber = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodDamagesInvoiceNumberAction(customerEntityId))
}

export const setDamagesInvoiceDate = (
  dispatch: Dispatch,
  customerEntityId: number,
  damagesInvoiceDate: Moment
): void => {
  dispatch(
    setBillingPeriodDamagesInvoiceDateAction(
      customerEntityId,
      damagesInvoiceDate
    )
  )
}
export const clearDamagesInvoiceDate = (
  dispatch: Dispatch,
  customerEntityId: number
): void => {
  dispatch(clearBillingPeriodDamagesInvoiceDateAction(customerEntityId))
}
