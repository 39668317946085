import { JsonDecoder } from 'ts.data.json'
import { Moment } from 'moment'
import { momentDecoder } from './decoderHelpers'
import { Maybe, maybeDecoder } from 'types/Maybe'

export interface LeasingCategory {
  id: number
  name: string
  description: string
  frameContractId: number
  numberOfLeasingPeriods: number
  leasingCoefficient: number
  purchaseOption: number
  purchaseOptionUser: number
  normalLeasingPercentage: number
  extensionLeasingPercentage: number
  earlyPurchaseReductionPercentage: number
  extensionPurchaseReductionPercentage: number
  earlyReturnReductionPercentage: number
  reportingCategory: Maybe<string>
  billingCategory: Maybe<string>
  validFrom: Maybe<Moment>
  validTo: Maybe<Moment>
  // financialRate: number
  // endOfLifeProRation: number
  pao6Percentage: number
}

export const decoder = JsonDecoder.object<LeasingCategory>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    description: JsonDecoder.string,
    frameContractId: JsonDecoder.number,
    numberOfLeasingPeriods: JsonDecoder.number,
    leasingCoefficient: JsonDecoder.number,
    purchaseOption: JsonDecoder.number,
    purchaseOptionUser: JsonDecoder.number,
    normalLeasingPercentage: JsonDecoder.number,
    extensionLeasingPercentage: JsonDecoder.number,
    earlyPurchaseReductionPercentage: JsonDecoder.number,
    extensionPurchaseReductionPercentage: JsonDecoder.number,
    earlyReturnReductionPercentage: JsonDecoder.number,
    reportingCategory: maybeDecoder(JsonDecoder.string),
    billingCategory: maybeDecoder(JsonDecoder.string),
    validFrom: maybeDecoder(momentDecoder),
    validTo: maybeDecoder(momentDecoder),
    // financialRate: JsonDecoder.number,
    // endOfLifeProRation: JsonDecoder.number,
    pao6Percentage: JsonDecoder.number,
  },
  'LeasingCategory',
  {
    id: 'leasingCategory',
    frameContractId: 'frameContract',
  }
)
