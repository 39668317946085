import { Dispatch } from 'redux'
import { Customer } from 'data/Customer'
import * as requests from '../requests/Customer'
import { History } from 'history'
import { handleError } from './errors'
import { Maybe } from 'types/Maybe'
import { anyError, ValidationErrors } from 'requests/errors'

interface OpenCustomerDialogAction {
  type: 'OPEN_CUSTOMER_DIALOG'
  customer: Maybe<Customer>
}
interface CloseCustomerDialogAction {
  type: 'CLOSE_CUSTOMER_DIALOG'
}
interface CreateCustomerRequestAction {
  type: 'CREATE_CUSTOMER_REQUEST'
}
interface CreateCustomerSuccessAction {
  type: 'CREATE_CUSTOMER_SUCCESS'
  customer: Customer
}
interface CreateCustomerErrorAction {
  type: 'CREATE_CUSTOMER_ERROR'
}
interface UpdateCustomerRequestAction {
  type: 'UPDATE_CUSTOMER_REQUEST'
}
interface UpdateCustomerSuccessAction {
  type: 'UPDATE_CUSTOMER_SUCCESS'
  customer: Customer
}
interface UpdateCustomerErrorAction {
  type: 'UPDATE_CUSTOMER_ERROR'
}
interface SetCustomerNameAction {
  type: 'SET_CUSTOMER_NAME'
  name: string
}
interface SetCustomerAddressAction {
  type: 'SET_CUSTOMER_ADDRESS'
  address: string
}
interface ClearCustomerNameAction {
  type: 'CLEAR_CUSTOMER_NAME'
}
interface ClearCustomerAddressAction {
  type: 'CLEAR_CUSTOMER_ADDRESS'
}
interface SetCustomerValidationErrorsAction {
  type: 'SET_CUSTOMER_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}

export type CustomerDialogAction =
  | OpenCustomerDialogAction
  | CloseCustomerDialogAction
  | CreateCustomerRequestAction
  | CreateCustomerSuccessAction
  | CreateCustomerErrorAction
  | UpdateCustomerRequestAction
  | UpdateCustomerSuccessAction
  | UpdateCustomerErrorAction
  | SetCustomerNameAction
  | SetCustomerAddressAction
  | ClearCustomerNameAction
  | ClearCustomerAddressAction
  | SetCustomerValidationErrorsAction

const openCustomerDialogAction = (
  customer: Maybe<Customer>
): OpenCustomerDialogAction => ({
  type: 'OPEN_CUSTOMER_DIALOG',
  customer,
})
const closeCustomerDialogAction = (): CloseCustomerDialogAction => ({
  type: 'CLOSE_CUSTOMER_DIALOG',
})
const createCustomerRequestAction = (): CreateCustomerRequestAction => ({
  type: 'CREATE_CUSTOMER_REQUEST',
})
const createCustomerSuccessAction = (
  customer: Customer
): CreateCustomerSuccessAction => ({
  type: 'CREATE_CUSTOMER_SUCCESS',
  customer,
})
const createCustomerErrorAction = (): CreateCustomerErrorAction => ({
  type: 'CREATE_CUSTOMER_ERROR',
})
const updateCustomerRequestAction = (): UpdateCustomerRequestAction => ({
  type: 'UPDATE_CUSTOMER_REQUEST',
})
const updateCustomerSuccessAction = (
  customer: Customer
): UpdateCustomerSuccessAction => ({
  type: 'UPDATE_CUSTOMER_SUCCESS',
  customer,
})
const updateCustomerErrorAction = (): UpdateCustomerErrorAction => ({
  type: 'UPDATE_CUSTOMER_ERROR',
})
const setCustomerNameAction = (name: string): SetCustomerNameAction => ({
  type: 'SET_CUSTOMER_NAME',
  name,
})
const setCustomerAddressAction = (
  address: string
): SetCustomerAddressAction => ({
  type: 'SET_CUSTOMER_ADDRESS',
  address,
})
const clearCustomerNameAction = (): ClearCustomerNameAction => ({
  type: 'CLEAR_CUSTOMER_NAME',
})
const clearCustomerAddressAction = (): ClearCustomerAddressAction => ({
  type: 'CLEAR_CUSTOMER_ADDRESS',
})
const setCustomerValidationErrorsAction = (
  validationErrors: ValidationErrors
): SetCustomerValidationErrorsAction => ({
  type: 'SET_CUSTOMER_VALIDATION_ERRORS',
  validationErrors,
})

export const openDialog = (
  dispatch: Dispatch,
  customer: Maybe<Customer>
): void => {
  dispatch(openCustomerDialogAction(customer))
}
export const closeDialog = (dispatch: Dispatch): void => {
  dispatch(closeCustomerDialogAction())
}

export const createCustomer = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: requests.CustomerParams
): Promise<void> => {
  dispatch(createCustomerRequestAction())

  const errors = requests.checkParams('create', params)
  if (anyError(errors)) {
    dispatch(setCustomerValidationErrorsAction(errors))
    dispatch(createCustomerErrorAction())
    return
  }

  try {
    const customer = await requests.create(token, params)
    dispatch(createCustomerSuccessAction(customer))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      createCustomerErrorAction,
      'display_error'
    )
  }
}

export const updateCustomer = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  customerId: number,
  params: requests.CustomerParams
): Promise<void> => {
  dispatch(updateCustomerRequestAction())

  const errors = requests.checkParams('update', params)
  if (anyError(errors)) {
    dispatch(setCustomerValidationErrorsAction(errors))
    dispatch(updateCustomerErrorAction())
    return
  }

  try {
    const customer = await requests.update(token, customerId, params)
    closeDialog(dispatch)
    dispatch(updateCustomerSuccessAction(customer))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      updateCustomerErrorAction,
      'display_error'
    )
  }
}

export const setName = (dispatch: Dispatch, name: string): void => {
  dispatch(setCustomerNameAction(name))
}
export const setAddress = (dispatch: Dispatch, address: string): void => {
  dispatch(setCustomerAddressAction(address))
}
export const clearName = (dispatch: Dispatch): void => {
  dispatch(clearCustomerNameAction())
}
export const clearAddress = (dispatch: Dispatch): void => {
  dispatch(clearCustomerAddressAction())
}
