import { decoder, Customer } from '../data/Customer'
import { getRequest, postRequest, patchRequest } from './base'
import * as endpoints from './endpoints'
import { JsonDecoder } from 'ts.data.json'
import { Maybe, maybeToNull } from 'types/Maybe'
import { omit } from 'lodash'
import { ValidationErrors, requireField } from './errors'

export const fetchAll = async (token: string): Promise<Customer[]> =>
  getRequest(
    token,
    endpoints.customers(),
    JsonDecoder.array(decoder, 'Customer[]')
  )

export interface CustomerParams {
  name: Maybe<string>
  address: Maybe<string>
}

type Mode = 'create' | 'update'

const encodeParams = (mode: Mode, params: CustomerParams) => {
  const fullParams = {
    name: maybeToNull(params.name),
    address: maybeToNull(params.address),
  }
  switch (mode) {
    case 'create':
      return fullParams
    case 'update':
      return omit(fullParams, ['name'])
  }
}

export const checkParams = (
  mode: Mode,
  params: CustomerParams
): ValidationErrors => {
  const errors = {}
  requireField(errors, 'name', params.name)
  requireField(errors, 'address', params.address)
  return errors
}

export const create = async (
  token: string,
  params: CustomerParams
): Promise<Customer> =>
  postRequest(
    token,
    endpoints.customers(),
    encodeParams('create', params),
    decoder
  )
export const update = async (
  token: string,
  customerId: number,
  params: CustomerParams
): Promise<Customer> =>
  patchRequest(
    token,
    endpoints.customer(customerId),
    { ...encodeParams('update', params), customer: customerId },
    decoder
  )
