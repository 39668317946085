import { ValidationErrors } from 'requests/errors'

export const fetchingDataDefault = <
  T,
  S extends { fetching: boolean; data: T[] }
>(
  state: any
): S => ({ ...state, fetching: false, data: [] })

export const fetchingData = <T, S extends { fetching: boolean; data: T[] }>(
  state: S
): S => ({ ...state, fetching: true, data: [] })

export const dataFetched = <T, S extends { fetching: boolean; data: T[] }>(
  state: S,
  data: T[]
): S => ({ ...state, fetching: false, data })

export const fetchingDataError = <
  T,
  S extends { fetching: boolean; data: T[] }
>(
  state: S
): S => ({ ...state, fetching: false })

export const addItem = <I, T extends { id: I }, S extends { data: T[] }>(
  state: S,
  newItem: T
): S => ({
  ...state,
  data: [...state.data, newItem],
})

export const updateItem = <I, T extends { id: I }, S extends { data: T[] }>(
  state: S,
  updatedItem: T
): S => ({
  ...state,
  data: state.data.map(item =>
    item.id === updatedItem.id ? updatedItem : item
  ),
})

export const deleteItem = <I, T extends { id: I }, S extends { data: T[] }>(
  state: S,
  deletedItemId: I
): S => ({
  ...state,
  data: state.data.filter(item => item.id !== deletedItemId),
})

export const savingRequest = <
  S extends { saving: boolean; validationErrors: ValidationErrors }
>(
  state: S
): S => ({ ...state, saving: true, validationErrors: {} })

export const savingResponse = <
  S extends { saving: boolean; validationErrors: ValidationErrors }
>(
  state: S
): S => ({ ...state, saving: false })

export const setValidationErrors = <
  S extends { validationErrors: ValidationErrors }
>(
  state: S,
  validationErrors: ValidationErrors
): S => ({ ...state, validationErrors })

export const deletingRequest = <S extends { deleting: boolean }>(
  state: S
): S => ({ ...state, deleting: true })

export const deletingResponse = <S extends { deleting: boolean }>(
  state: S
): S => ({ ...state, deleting: false })

export const setIsDeletable = <S extends { isDeletable: boolean }>(
  state: S
): S => ({ ...state, isDeletable: true })
