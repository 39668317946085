import { Device } from '../data/Device'
import * as requests from '../requests/Device'
import { Dispatch } from 'redux'
import { handleError } from './errors'
import { History } from 'history'
import { FilterDevicesParams, CountParams } from 'requests/endpoints'
import { setDevicesCountReadyAction } from './devicesCount'

interface FetchDevicesRequestAction {
  type: 'FETCH_DEVICES_REQUEST'
}
interface FetchDevicesSuccessAction {
  type: 'FETCH_DEVICES_SUCCESS'
  count: number
  devices: Device[]
  filterBy: CountParams
}
interface FetchDevicesErrorAction {
  type: 'FETCH_DEVICES_ERROR'
}
interface ToggleSelectDeviceAction {
  type: 'TOGGLE_SELECT_DEVICE'
  deviceId: string
}
interface SelectAllDevicesAction {
  type: 'SELECT_ALL_DEVICES'
}
interface UnselectAllDevicesAction {
  type: 'UNSELECT_ALL_DEVICES'
}
interface SortDevicesByAction {
  type: 'SORT_DEVICES_BY'
  column: string
}
interface SetPageAction {
  type: 'SET_PAGE'
  page: number
}
interface SetRowsPerPageAction {
  type: 'SET_ROWS_PER_PAGE'
  rowsPerPage: number
}

export type DevicesAction =
  | FetchDevicesRequestAction
  | FetchDevicesSuccessAction
  | FetchDevicesErrorAction
  | ToggleSelectDeviceAction
  | SelectAllDevicesAction
  | UnselectAllDevicesAction
  | SortDevicesByAction
  | SetPageAction
  | SetRowsPerPageAction

const fetchDevicesRequestAction = (): FetchDevicesRequestAction => ({
  type: 'FETCH_DEVICES_REQUEST',
})
const fetchDevicesSuccessAction = (
  count: number,
  devices: Device[],
  filterBy: CountParams
): FetchDevicesSuccessAction => ({
  type: 'FETCH_DEVICES_SUCCESS',
  count,
  devices,
  filterBy,
})
const fetchDevicesErrorAction = (): FetchDevicesErrorAction => ({
  type: 'FETCH_DEVICES_ERROR',
})
const toggleSelectDeviceAction = (
  deviceId: string
): ToggleSelectDeviceAction => ({
  type: 'TOGGLE_SELECT_DEVICE',
  deviceId,
})
const selectAllDevicesAction = (): SelectAllDevicesAction => ({
  type: 'SELECT_ALL_DEVICES',
})
const unselectAllDevicesAction = (): UnselectAllDevicesAction => ({
  type: 'UNSELECT_ALL_DEVICES',
})
const sortDevicesByAction = (column: string): SortDevicesByAction => ({
  type: 'SORT_DEVICES_BY',
  column,
})
const setPageAction = (page: number): SetPageAction => ({
  type: 'SET_PAGE',
  page,
})
const setRowsPerPageAction = (rowsPerPage: number): SetRowsPerPageAction => ({
  type: 'SET_ROWS_PER_PAGE',
  rowsPerPage,
})

export const fetchByStatus = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: FilterDevicesParams
) => {
  dispatch(fetchDevicesRequestAction())
  try {
    const devices = await requests.fetchByStatus(token, params)
    const count = await requests.countByStatus(token, params.filterBy)
    dispatch(fetchDevicesSuccessAction(count, devices, params.filterBy))
    dispatch(setDevicesCountReadyAction())
  } catch (error) {
    handleError(dispatch, history, error, fetchDevicesErrorAction, 'redirect')
  }
}

export const toggleSelectDevice = (dispatch: Dispatch, deviceId: string) => {
  dispatch(toggleSelectDeviceAction(deviceId))
}

export const selectAllDevices = (dispatch: Dispatch) => {
  dispatch(selectAllDevicesAction())
}

export const unselectAllDevices = (dispatch: Dispatch) => {
  dispatch(unselectAllDevicesAction())
}

export const sortDevicesBy = (dispatch: Dispatch, column: string) => {
  dispatch(sortDevicesByAction(column))
}

export const setPage = (dispatch: Dispatch, page: number) => {
  dispatch(setPageAction(page))
}

export const setRowsPerPage = (dispatch: Dispatch, rowsPerPage: number) => {
  dispatch(setRowsPerPageAction(rowsPerPage))
}
