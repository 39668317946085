import { Action } from '../actions'
import { Device } from '../data/Device'
import { Order } from '../components/DeviceTable'
import { CountParams } from 'requests/endpoints'
import { Maybe, maybeNothing, maybeJust } from 'types/Maybe'
import {
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export type DeviceSelection =
  | {
      type: 'selected'
      deviceIds: Set<string>
    }
  | {
      type: 'excluded'
      deviceIds: Set<string>
    }

export const isDeviceSelected = (
  selection: DeviceSelection,
  device: Device
) => {
  const hasKey = selection.deviceIds.has(device.name)
  switch (selection.type) {
    case 'selected':
      return hasKey
    case 'excluded':
      return !hasKey
  }
}

export const selectedDevicesCount = (
  selection: DeviceSelection,
  totalDataCount: number
) => {
  switch (selection.type) {
    case 'selected':
      return selection.deviceIds.size
    case 'excluded':
      return totalDataCount - selection.deviceIds.size
  }
}

export interface DevicesState {
  fetching: boolean
  data: Device[]
  selection: DeviceSelection
  order: Order
  orderBy: string
  page: number
  rowsPerPage: number
  totalDataCount: number
  filterBy: Maybe<CountParams>
}

const defaultState = (): DevicesState =>
  fetchingDataDefault({
    selection: { type: 'selected', deviceIds: new Set<string>() },
    order: 'asc',
    orderBy: 'deliveryDate',
    page: 0,
    rowsPerPage: 10,
    totalDataCount: 0,
    filterBy: maybeNothing(),
  })

export default (
  state: DevicesState = defaultState(),
  action: Action
): DevicesState => {
  switch (action.type) {
    case 'FETCH_DEVICES_REQUEST':
      return fetchingData(state)
    case 'FETCH_DEVICES_SUCCESS':
      return dataFetched(
        {
          ...state,
          totalDataCount: action.count,
          filterBy: maybeJust(action.filterBy),
        },
        action.devices
      )
    case 'FETCH_DEVICES_ERROR':
      return fetchingDataError(state)
    case 'TOGGLE_SELECT_DEVICE': {
      const deviceIds = new Set(state.selection.deviceIds)
      if (deviceIds.has(action.deviceId)) {
        deviceIds.delete(action.deviceId)
      } else {
        deviceIds.add(action.deviceId)
      }
      return { ...state, selection: { ...state.selection, deviceIds } }
    }
    case 'SELECT_ALL_DEVICES':
      return {
        ...state,
        selection: { type: 'excluded', deviceIds: new Set<string>() },
      }
    case 'UNSELECT_ALL_DEVICES':
      return {
        ...state,
        selection: { type: 'selected', deviceIds: new Set<string>() },
      }
    case 'SORT_DEVICES_BY':
      return {
        ...state,
        order:
          state.orderBy === action.column && state.order === 'desc'
            ? 'asc'
            : 'desc',
        orderBy: action.column,
        page: 0,
      }
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page,
      }
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        page: 0,
        rowsPerPage: action.rowsPerPage,
      }
    default:
      return state
  }
}
