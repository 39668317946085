import * as React from 'react'
import { State } from 'reducers'
import { Dispatch } from 'redux'
import * as actions from 'actions/customerDialog'
import { RouteChildrenProps, withRouter } from 'react-router'
import { Maybe, isJust, maybeSwitch } from 'types/Maybe'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { CustomerParams } from 'requests/Customer'
import FormTextInput from 'components/FormTextInput'
import { ValidationErrors } from 'requests/errors'
import Columns from 'components/Columns'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  open: boolean
  saving: boolean
  updatingId: Maybe<number>
  name: Maybe<string>
  address: Maybe<string>
  validationErrors: ValidationErrors
}

interface DispatchProps {
  closeDialog: () => void
  createCustomer: (params: CustomerParams) => void
  updateCustomer: (customerId: number, params: CustomerParams) => void
  setName: (name: string) => void
  setAddress: (address: string) => void
  clearName: () => void
  clearAddress: () => void
}

interface Props extends StateProps, DispatchProps {}

class CustomerDialog extends React.PureComponent<Props> {
  public render() {
    const {
      open,
      saving,
      closeDialog,
      updatingId,
      createCustomer,
      updateCustomer,
      name,
      address,
      setName,
      setAddress,
      clearName,
      clearAddress,
      validationErrors,
    } = this.props
    const isEditing = isJust(updatingId)

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={closeDialog}>
        <DialogTitle>
          {isEditing ? 'Edit customer' : 'New customer'}
        </DialogTitle>
        <DialogContent>
          <Columns>
            <FormTextInput
              value={name}
              label="Name"
              required={true}
              onChange={setName}
              onClear={clearName}
              validationErrors={validationErrors['name']}
              disabled={false}
            />
            <FormTextInput
              value={address}
              label="Address"
              required={true}
              onChange={setAddress}
              onClear={clearAddress}
              validationErrors={validationErrors['address']}
              disabled={false}
            />
          </Columns>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={(event: any) => {
              const params = {
                name,
                address,
              }
              maybeSwitch(
                updatingId,
                id => updateCustomer(id, params),
                () => createCustomer(params)
              )
            }}
            color="primary"
            disabled={saving}
          >
            {isEditing ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  open: state.customerDialog.open,
  saving: state.customerDialog.saving,
  updatingId: state.customerDialog.updatingId,
  name: state.customerDialog.name,
  address: state.customerDialog.address,
  validationErrors: state.customerDialog.validationErrors,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  createCustomer: (params: CustomerParams) =>
    actions.createCustomer(ownProps.token, dispatch, ownProps.history, params),
  updateCustomer: (customerId: number, params: CustomerParams) =>
    actions.updateCustomer(
      ownProps.token,
      dispatch,
      ownProps.history,
      customerId,
      params
    ),
  setName: (name: string) => actions.setName(dispatch, name),
  setAddress: (address: string) => actions.setAddress(dispatch, address),
  clearName: () => actions.clearName(dispatch),
  clearAddress: () => actions.clearAddress(dispatch),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerDialog)
)
