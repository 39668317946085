export type Result<T, E> =
  | { type: 'RESULT_OK'; data: T }
  | { type: 'RESULT_ERROR'; error: E }

export const resultSwitch = <T, E, T2, E2>(
  result: Result<T, E>,
  onOk: (data: T) => T2,
  onError: (error: E) => E2
): T2 | E2 => {
  switch (result.type) {
    case 'RESULT_OK':
      return onOk(result.data)
    case 'RESULT_ERROR':
      return onError(result.error)
  }
}

export const resultOk = <T, E>(data: T): Result<T, E> => ({
  type: 'RESULT_OK',
  data,
})
export const resultError = <T, E>(error: E): Result<T, E> => ({
  type: 'RESULT_ERROR',
  error,
})
