import { CLIENT_ID, TENANT } from './constants'
import { Result, resultError, resultOk } from 'types/Result'
import { AdalConfig, AuthenticationContext } from 'react-adal'
import jwtDecode from 'jwt-decode'

const tokenCallback = (
  errorDesc: string | null,
  token: string | null,
  error: any
) => {}

const adalConfig: AdalConfig = {
  tenant: TENANT,
  clientId: CLIENT_ID,
  endpoints: { api: 'https://login.microsoftonline.com/' + TENANT },
  cacheLocation: 'localStorage',
  redirectUri: window.location.origin,
  callback: tokenCallback,
}

const authContext: AuthenticationContext = new AuthenticationContext(adalConfig)

interface User {
  name: string
  email: string
  roles: string[]
}

const authorizedRoles: string[] = ['Admin', 'User']

const canLogin = (token: string): boolean => {
  const user = userFromToken(token)
  return user.roles.some(role => authorizedRoles.indexOf(role) !== -1)
}

export const userFromToken = (token: string): User => {
  const { name, email, roles } = jwtDecode(token)
  return { name, email, roles }
}

const redirectMessages = [
  'AADSTS16002', // old sid - https://github.com/salvoravida/react-adal/issues/46
  'AADSTS50076', // MFA support - https://github.com/salvoravida/react-adal/pull/45
  'AADSTS50079', // MFA support
]

const shouldAcquireNewToken = (errorDesc: string) =>
  redirectMessages.some(v => errorDesc.indexOf(v) !== -1)

const getToken = (): Promise<string | null> =>
  new Promise((resolve, reject) => {
    authContext.acquireToken(
      CLIENT_ID,
      (errorDesc: string | null, token: string | null, error: any) => {
        if (!error) {
          resolve(token)
        } else if (shouldAcquireNewToken(errorDesc)) {
          authContext.acquireTokenRedirect(CLIENT_ID, null, null)
        } else {
          console.log('error', errorDesc, error)
          resolve(null)
        }
      }
    )
  })

export const login = () => authContext.login()

export const logout = () => authContext.logOut()

export const clearCache = () => authContext.clearCacheForResource(CLIENT_ID)

export const start = async (): Promise<Result<string | null, string>> => {
  if (authContext.isCallback(window.location.hash)) {
    authContext.handleWindowCallback()
  }

  let token
  if (window === window.parent) {
    if (!authContext.isCallback(window.location.hash)) {
      // NOTE: important to call "getCachedUser" to refresh the data from the "id_token" and get a valid token
      authContext.getCachedUser()
      token = authContext.getCachedToken(CLIENT_ID)
      if (!token) {
        token = await getToken()
      }
    }
  }

  if (token) {
    if (canLogin(token)) {
      return resultOk(token)
    } else {
      return resultError('You do not have permissions to log in')
    }
  }
  return resultOk(null)
}
