import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from '@material-ui/core'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../reducers'
import * as actions from '../actions/exportTemplateFieldAliasDialog'
import { ExportTemplateField } from 'data/ExportTemplate'
import FormTextInput from 'components/FormTextInput'
import { ValidationErrors } from 'requests/errors'
import { Maybe, isNothing, maybeJust, maybeSwitch } from 'types/Maybe'

interface OwnProps {}

interface StateProps {
  open: boolean
  field: Maybe<ExportTemplateField>
  validationErrors: ValidationErrors
}

interface DispatchProps {
  closeDialog: () => void
  setAlias: (alias: string) => void
  saveField: (field: ExportTemplateField) => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class ExportTemplateFieldAliasDialog extends React.PureComponent<Props> {
  public render() {
    const {
      open,
      closeDialog,
      field,
      setAlias,
      saveField,
      validationErrors,
    } = this.props
    return (
      <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={closeDialog}>
        <DialogTitle>Field alias</DialogTitle>
        {open &&
          maybeSwitch(
            field,
            f => (
              <DialogContent>
                <FormTextInput
                  value={maybeJust(f.alias)}
                  label="Alias"
                  required={true}
                  onChange={setAlias}
                  onClear={() => setAlias('')}
                  validationErrors={validationErrors['alias']}
                  disabled={false}
                />
              </DialogContent>
            ),
            () => null
          )}

        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Close
          </Button>
          <Button
            onClick={maybeSwitch(
              field,
              f => (event: any) => saveField(f),
              () => (event: any) => {}
            )}
            color="primary"
            disabled={isNothing(field)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  open: state.exportTemplateFieldAliasDialog.open,
  field: state.exportTemplateFieldAliasDialog.field,
  validationErrors: state.exportTemplateFieldAliasDialog.validationErrors,
})

const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  setAlias: (alias: string) => actions.setAlias(dispatch, alias),
  saveField: (field: ExportTemplateField) => actions.saveField(dispatch, field),
})

export default connect(
  mapStateToProps,
  mapDispatchProps
)(ExportTemplateFieldAliasDialog)
