import { JsonDecoder } from 'ts.data.json'
import { LeasingContract, decoder } from '../data/LeasingContract'
import { getRequest, patchRequest, postRequest, deleteRequest } from './base'
// import { Moment } from 'moment'
import { requireField, ValidationErrors } from './errors'
import { omit } from 'lodash'
import * as endpoints from './endpoints'

export const fetchByFrameContractId = async (
  token: string,
  frameContractId: number
): Promise<LeasingContract[]> =>
  getRequest(
    token,
    endpoints.leasingContractsByFrameContractId(frameContractId),
    JsonDecoder.array(decoder, 'LeasingContract[]')
  )

export interface LeasingContractParams {
  frameContractId: number
  sni: string
  // contractStartDate: Maybe<Moment>
  // contractEndDate: Maybe<Moment>
}

type Mode = 'create' | 'update'

const encodeParams = (mode: Mode, params: LeasingContractParams) => {
  const fullParams = {
    frameContract: params.frameContractId,
    sniContract: params.sni,
    // contractStartDate: maybeToNull(params.contractStartDate.format('YYYY-MM-DD')),
    // contractEndDate: maybeToNull(params.contractEndDate.format('YYYY-MM-DD')),
  }
  if (mode === 'create') {
    return fullParams
  }
  return omit(fullParams, [
    'frameContract',
    // 'contractStartDate',
    // 'contractEndDate',
  ])
}

export const checkParams = (
  mode: Mode,
  params: LeasingContractParams
): ValidationErrors => {
  const errors = {}
  if (mode === 'create') {
    requireField(errors, 'frameContractId', params.frameContractId)
    // requireField(errors, 'contractStartDate', params.contractStartDate)
    // requireField(errors, 'contractEndDate', params.contractEndDate)
  }
  return errors
}

export const create = async (
  token: string,
  params: LeasingContractParams
): Promise<LeasingContract> =>
  postRequest(
    token,
    endpoints.leasingContracts(),
    encodeParams('create', params),
    decoder
  )

export const update = async (
  token: string,
  leasingContractId: number,
  params: LeasingContractParams
): Promise<LeasingContract> =>
  patchRequest(
    token,
    endpoints.leasingContract(leasingContractId),
    { ...encodeParams('update', params), leasingContract: leasingContractId },
    decoder
  )

export const remove = async (
  token: string,
  leasingContractId: number
): Promise<void> =>
  deleteRequest(token, endpoints.leasingContract(leasingContractId))

export const isDeletable = async (
  token: string,
  leasingContractId: number
): Promise<boolean> =>
  getRequest(
    token,
    endpoints.leasingContractIsDeletable(leasingContractId),
    JsonDecoder.boolean
  )
