import { Dispatch } from 'redux'
import * as requests from '../requests/ExportTemplate'
import { ExportTemplate } from '../data/ExportTemplate'
import { History } from 'history'
import { handleError } from './errors'

interface FetchExportTemplatesRequestAction {
  type: 'FETCH_EXPORT_TEMPLATES_REQUEST'
}
interface FetchExportTemplatesSuccessAction {
  type: 'FETCH_EXPORT_TEMPLATES_SUCCESS'
  exportTemplates: ExportTemplate[]
}
interface FetchExportTemplatesErrorAction {
  type: 'FETCH_EXPORT_TEMPLATES_ERROR'
}

export type ExportTemplatesAction =
  | FetchExportTemplatesRequestAction
  | FetchExportTemplatesSuccessAction
  | FetchExportTemplatesErrorAction

const fetchExportTemplatesRequestAction = (): FetchExportTemplatesRequestAction => ({
  type: 'FETCH_EXPORT_TEMPLATES_REQUEST',
})
const fetchExportTemplatesSuccessAction = (
  exportTemplates: ExportTemplate[]
): FetchExportTemplatesSuccessAction => ({
  type: 'FETCH_EXPORT_TEMPLATES_SUCCESS',
  exportTemplates,
})
const fetchExportTemplatesErrorAction = (): FetchExportTemplatesErrorAction => ({
  type: 'FETCH_EXPORT_TEMPLATES_ERROR',
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History
) => {
  dispatch(fetchExportTemplatesRequestAction())
  try {
    const exportTemplates = await requests.fetchAll(token)
    dispatch(fetchExportTemplatesSuccessAction(exportTemplates))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchExportTemplatesErrorAction,
      'display_error'
    )
  }
}
