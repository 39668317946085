import { Dispatch } from 'redux'
import * as requests from '../requests/FrameContract'
import { FrameContract } from '../data/FrameContract'
import * as leasingContractActions from './leasingContracts'
import * as billingPeriodActions from './billingPeriods'
import { History } from 'history'
import { handleError } from './errors'
import * as routes from '../routes'

interface FetchFrameContractsRequestAction {
  type: 'FETCH_FRAME_CONTRACTS_REQUEST'
}
interface FetchFrameContractsSuccessAction {
  type: 'FETCH_FRAME_CONTRACTS_SUCCESS'
  frameContracts: FrameContract[]
}
interface FetchFrameContractsErrorAction {
  type: 'FETCH_FRAME_CONTRACTS_ERROR'
}
interface SelectFrameContractAction {
  type: 'SELECT_FRAME_CONTRACT'
  frameContractId: number
}

export type FrameContractsAction =
  | FetchFrameContractsRequestAction
  | FetchFrameContractsSuccessAction
  | FetchFrameContractsErrorAction
  | SelectFrameContractAction

const fetchFrameContractsRequestAction = (): FetchFrameContractsRequestAction => ({
  type: 'FETCH_FRAME_CONTRACTS_REQUEST',
})
const fetchFrameContractsSuccessAction = (
  frameContracts: FrameContract[]
): FetchFrameContractsSuccessAction => ({
  type: 'FETCH_FRAME_CONTRACTS_SUCCESS',
  frameContracts,
})
const fetchFrameContractsErrorAction = (): FetchFrameContractsErrorAction => ({
  type: 'FETCH_FRAME_CONTRACTS_ERROR',
})
const selectFrameContractAction = (
  frameContractId: number
): SelectFrameContractAction => ({
  type: 'SELECT_FRAME_CONTRACT',
  frameContractId,
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History
) => {
  dispatch(fetchFrameContractsRequestAction())
  try {
    const frameContracts = await requests.fetchAll(token)
    dispatch(fetchFrameContractsSuccessAction(frameContracts))
    if (frameContracts.length > 0) {
      const selectedFrameContractId = frameContracts[0].id
      dispatch(selectFrameContractAction(selectedFrameContractId))
      leasingContractActions.fetchAll(
        token,
        dispatch,
        history,
        selectedFrameContractId
      )
      billingPeriodActions.fetchAll(
        token,
        dispatch,
        history,
        selectedFrameContractId
      )
    }
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchFrameContractsErrorAction,
      'redirect'
    )
  }
}

export const selectFrameContract = (
  token: string,
  dispatch: Dispatch,
  history: History,
  selectedFrameContractId: number
): void => {
  dispatch(selectFrameContractAction(selectedFrameContractId))
  leasingContractActions.fetchAll(
    token,
    dispatch,
    history,
    selectedFrameContractId
  )
  billingPeriodActions.fetchAll(
    token,
    dispatch,
    history,
    selectedFrameContractId
  )
  history.push(routes.home())
}
