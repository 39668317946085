import { Variant } from '../reducers/snackbars'
import { Dispatch } from 'redux'

interface AddSnackbarAction {
  type: 'ADD_SNACKBAR'
  variant: Variant
  message: string
}
interface RemoveSnackbarAction {
  type: 'REMOVE_SNACKBAR'
  key: string
}

export type SnackbarsAction = AddSnackbarAction | RemoveSnackbarAction

const addSnackbarAction = (
  variant: Variant,
  message: string
): AddSnackbarAction => ({
  type: 'ADD_SNACKBAR',
  variant,
  message,
})
const removeSnackbarAction = (key: string): RemoveSnackbarAction => ({
  type: 'REMOVE_SNACKBAR',
  key,
})

export const addSnackbar = (
  dispatch: Dispatch,
  variant: Variant,
  message: string
) => {
  dispatch(addSnackbarAction(variant, message))
}

export const removeSnackbar = (dispatch: Dispatch, key: string) => {
  dispatch(removeSnackbarAction(key))
}
