import { Action } from '../actions'
import {
  fetchingData,
  dataFetched,
  fetchingDataError,
  fetchingDataDefault,
} from './helpers'

export interface ExportFieldsState {
  fetching: boolean
  data: string[]
}

const defaultState = (): ExportFieldsState => fetchingDataDefault({})

export default (
  state: ExportFieldsState = defaultState(),
  action: Action
): ExportFieldsState => {
  switch (action.type) {
    case 'FETCH_EXPORT_FIELDS_REQUEST':
      return fetchingData(state)
    case 'FETCH_EXPORT_FIELDS_SUCCESS':
      return dataFetched(state, action.exportFields)
    case 'FETCH_EXPORT_FIELDS_ERROR':
      return fetchingDataError(state)
    default:
      return state
  }
}
