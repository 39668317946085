import { JsonDecoder } from 'ts.data.json'
import { Moment } from 'moment'
import { momentDecoder } from '../data/decoderHelpers'
import { Action } from '../actions'
import { Method, methodDecoder } from '../requests/base'
import { Maybe, maybeDecoder, maybeSwitch, maybeToNull } from '../types/Maybe'

const LOCAL_STORAGE_KEY = 'mars-leasing-errors'

export interface ErrorLog {
  createdAt: Moment
  message: string
  method: Method
  url: string
  body: Maybe<string>
}

const errorLogDecoder = JsonDecoder.object<ErrorLog>(
  {
    createdAt: momentDecoder,
    message: JsonDecoder.string,
    method: methodDecoder,
    url: JsonDecoder.string,
    body: maybeDecoder(JsonDecoder.string),
  },
  'ErrorLog'
)

export interface ErrorsHistory {
  errors: ErrorLog[]
}

const defaultErrorsHistory = (): ErrorsHistory => {
  const empty: ErrorsHistory = { errors: [] }

  const value = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!value) {
    return empty
  }

  const decoder = JsonDecoder.array<ErrorLog>(errorLogDecoder, 'ErrorLog[]')
  return decoder.onDecode(
    JSON.parse(value),
    errors => ({ errors }),
    () => {
      localStorage.removeItem(LOCAL_STORAGE_KEY)
      return empty
    }
  )
}

const updateLocalStorage = (getState: () => ErrorsHistory) => {
  const state = getState()
  if (state.errors.length > 0) {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(
        state.errors.map(error => ({
          createdAt: error.createdAt.format(),
          message: error.message,
          method: error.method,
          url: error.url,
          body: maybeToNull(error.body),
        }))
      )
    )
  } else {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  }
  return state
}

// Source: https://stackoverflow.com/a/30810322
const copyToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.style.position = 'fixed'
    textarea.style.top = '0'
    textarea.style.left = '0'
    textarea.style.width = '2em'
    textarea.style.height = '2em'
    textarea.style.padding = '0'
    textarea.style.border = 'none'
    textarea.style.outline = 'none'
    textarea.style.boxShadow = 'none'
    textarea.style.background = 'transparent'
    document.body.appendChild(textarea)
    textarea.focus()
    textarea.select()
    try {
      document.execCommand('copy')
    } finally {
      document.body.removeChild(textarea)
    }
  } else {
    navigator.clipboard.writeText(text)
  }
}

export default (
  state: ErrorsHistory = defaultErrorsHistory(),
  action: Action
): ErrorsHistory => {
  return updateLocalStorage(() => {
    switch (action.type) {
      case 'PUSH_ERROR_LOG_TO_HISTORY':
        return { ...state, errors: [action.errorLog, ...state.errors] }
      case 'REMOVE_ERROR_LOG': {
        const errors = [...state.errors]
        if (action.index >= 0 && action.index < errors.length) {
          errors.splice(action.index, 1)
        }
        return { ...state, errors }
      }
      case 'CLEAR_ERRORS_HISTORY':
        return { ...state, errors: [] }
      case 'COPY_ERROR_LOG_TO_CLIPBOARD': {
        if (action.index >= 0 && action.index < state.errors.length) {
          const errorLog = state.errors[action.index]
          const text = [
            'Date:   ' + errorLog.createdAt.format('MMMM D, YYYY, H:mm:ss'),
            `Method: ${errorLog.method}`,
            `URL:    ${errorLog.url}`,
            maybeSwitch(
              errorLog.body,
              body => `Body:   ${body}`,
              () => null
            ),
            `Error:  ${errorLog.message}`,
          ]
            .filter(v => v !== null)
            .join('\n')
          copyToClipboard(text)
        }
        return state
      }
      default:
        return state
    }
  })
}
