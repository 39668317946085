import { JsonDecoder } from 'ts.data.json'

export interface ExportTemplateField {
  field: string
  alias: string
}

export interface ExportTemplate {
  id: number
  name: string
  fields: ExportTemplateField[]
}

const fieldDecoder = JsonDecoder.object<ExportTemplateField>(
  {
    field: JsonDecoder.string,
    alias: JsonDecoder.string,
  },
  'ExportTemplateField'
)

export const decoder = JsonDecoder.object<ExportTemplate>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    fields: JsonDecoder.array(fieldDecoder, 'ExportTemplateField[]'),
  },
  'ExportTemplate',
  {
    id: 'exportTemplate',
  }
)
