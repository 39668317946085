import * as React from 'react'
import { CircularProgress } from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/styles'
import { Maybe, maybeSwitch } from 'types/Maybe'

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
}

interface Props extends WithStyles<'root'> {
  size: Maybe<number | string>
}

class LoadingIndicator extends React.PureComponent<Props> {
  public render() {
    const { classes, size } = this.props
    return (
      <div className={classes.root}>
        {maybeSwitch(
          size,
          s => (
            <CircularProgress size={s} />
          ),
          () => (
            <CircularProgress />
          )
        )}
      </div>
    )
  }
}

export default withStyles(styles)(LoadingIndicator)
