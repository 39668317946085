import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
} from '@material-ui/core'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../reducers'
import * as actions from '../actions/leasingContractDialog'
import { RouteChildrenProps, withRouter } from 'react-router'
import { LeasingContractParams } from 'requests/LeasingContract'
// import { Moment } from 'moment'
// import FormDateInput from 'components/FormDateInput'
import FormTextInput from 'components/FormTextInput'
import { ValidationErrors } from 'requests/errors'
import { Maybe, maybeSwitch, maybeSwitch2, isJust } from 'types/Maybe'
import DeletableButton from 'components/DeletableButton'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  selectedFrameContractId: Maybe<number>
  open: boolean
  updatingId: Maybe<number>
  sni: Maybe<string>
  // contractStartDate: Maybe<Moment>
  // contractEndDate: Maybe<Moment>
  validationErrors: ValidationErrors
  isDeletable: boolean
}

interface DispatchProps {
  closeDialog: () => void
  createLeasingContract: (params: LeasingContractParams) => void
  updateLeasingContract: (
    leasingContractId: number,
    params: LeasingContractParams
  ) => void
  setSni: (sni: string) => void
  clearSni: () => void
  // setContractStartDate: (contractStartDate: Moment) => void
  // setContractEndDate: (contractEndDate: Moment) => void
  // clearContractStartDate: () => void
  // clearContractEndDate: () => void
  checkIfIsDeletable: (leasingContractId: number) => void
  deleteLeasingContract: (leasingContractId: number) => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class LeasingContractDialog extends React.PureComponent<Props> {
  public render() {
    const {
      selectedFrameContractId,
      open,
      closeDialog,
      createLeasingContract,
      updateLeasingContract,
      updatingId,
      sni,
      // contractStartDate,
      // contractEndDate,
      validationErrors,
      setSni,
      clearSni,
      // setContractStartDate,
      // setContractEndDate,
      // clearContractStartDate,
      // clearContractEndDate,
      isDeletable,
      checkIfIsDeletable,
      deleteLeasingContract,
    } = this.props
    const isEditing = isJust(updatingId)

    return (
      <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={closeDialog}>
        <DialogTitle>
          {isEditing ? 'Edit leasing contract' : 'New leasing contract'}
        </DialogTitle>

        <DialogContent>
          <FormTextInput
            value={sni}
            label="SNI"
            onChange={setSni}
            onClear={clearSni}
            validationErrors={validationErrors['sni']}
            required={false}
            disabled={false}
          />
          {/* <FormDateInput
            value={contractStartDate}
            label="Contract start date"
            required={true}
            onChange={setContractStartDate}
            onClear={clearContractStartDate}
            validationErrors={validationErrors['contractStartDate']}
            disabled={isEditing}
          />
          <FormDateInput
            value={contractEndDate}
            label="Contract end date"
            required={true}
            onChange={setContractEndDate}
            onClear={clearContractEndDate}
            validationErrors={validationErrors['contractEndDate']}
            disabled={isEditing}
          /> */}
        </DialogContent>

        <DialogActions>
          {isEditing &&
            maybeSwitch(
              updatingId,
              id => (
                <React.Fragment>
                  <DeletableButton
                    name="leasing contract"
                    checkIfIsDeletable={() => checkIfIsDeletable(id)}
                    isDeletable={isDeletable}
                    onConfirm={() => deleteLeasingContract(id)}
                  />
                  <Box flex={1} />
                </React.Fragment>
              ),
              () => null
            )}

          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={maybeSwitch2(
              selectedFrameContractId,
              sni,
              (fci, s) => (event: any) => {
                const params = {
                  sni: s,
                  frameContractId: fci,
                  // contractStartDate,
                  // contractEndDate,
                }
                maybeSwitch(
                  updatingId,
                  id => updateLeasingContract(id, params),
                  () => createLeasingContract(params)
                )
              },
              () => (event: any) => {}
            )}
            color="primary"
          >
            {updatingId ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  selectedFrameContractId: state.frameContracts.selectedFrameContractId,
  open: state.leasingContractDialog.open,
  updatingId: state.leasingContractDialog.updatingId,
  sni: state.leasingContractDialog.sni,
  // contractStartDate: state.leasingContractDialog.contractStartDate,
  // contractEndDate: state.leasingContractDialog.contractEndDate,
  validationErrors: state.leasingContractDialog.validationErrors,
  isDeletable: state.leasingContractDialog.isDeletable,
})

const mapDispatchProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  createLeasingContract: (params: LeasingContractParams) =>
    actions.createLeasingContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      params
    ),
  updateLeasingContract: (
    leasingContractId: number,
    params: LeasingContractParams
  ) =>
    actions.updateLeasingContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingContractId,
      params
    ),
  setSni: (sni: string) => actions.setSni(dispatch, sni),
  clearSni: () => actions.clearSni(dispatch),
  // setContractStartDate: (contractStartDate: Moment) =>
  //   actions.setContractStartDate(dispatch, contractStartDate),
  // setContractEndDate: (contractEndDate: Moment) =>
  //   actions.setContractEndDate(dispatch, contractEndDate),
  // clearContractStartDate: () => actions.clearContractStartDate(dispatch),
  // clearContractEndDate: () => actions.clearContractEndDate(dispatch),
  checkIfIsDeletable: (leasingContractId: number) =>
    actions.checkIfIsDeletable(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingContractId
    ),
  deleteLeasingContract: (leasingContractId: number) =>
    actions.deleteLeasingContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      leasingContractId
    ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchProps)(LeasingContractDialog)
)
