import { Action } from '../actions'
import { ExportTemplateField } from 'data/ExportTemplate'
import { ValidationErrors } from 'requests/errors'
import { Maybe, maybeNothing, maybeJust, maybeSwitch } from 'types/Maybe'
import {
  savingRequest,
  deletingRequest,
  savingResponse,
  deletingResponse,
  setValidationErrors,
} from './helpers'

export interface ExportTemplateDialogState {
  open: boolean
  saving: boolean
  deleting: boolean
  exporting: boolean
  formOpen: boolean
  selectedId: Maybe<number>
  editingId: Maybe<number>
  name: Maybe<string>
  fields: ExportTemplateField[]
  validationErrors: ValidationErrors
}

const defaultState = (): ExportTemplateDialogState => ({
  open: false,
  saving: false,
  deleting: false,
  exporting: false,
  formOpen: false,
  selectedId: maybeNothing(),
  editingId: maybeNothing(),
  name: maybeNothing(),
  fields: [],
  validationErrors: {},
})

const openState = (): ExportTemplateDialogState => ({
  ...defaultState(),
  open: true,
})

export default (
  state: ExportTemplateDialogState = defaultState(),
  action: Action
): ExportTemplateDialogState => {
  switch (action.type) {
    case 'OPEN_EXPORT_TEMPLATE_DIALOG':
      return openState()
    case 'CLOSE_EXPORT_TEMPLATE_DIALOG':
      return defaultState()
    case 'SELECT_EXPORT_TEMPLATE':
      return { ...state, selectedId: maybeJust(action.exportTemplateId) }
    case 'OPEN_EXPORT_TEMPLATE_FORM': {
      const formData = maybeSwitch(
        action.exportTemplate,
        exportTemplate => ({
          editingId: maybeJust(exportTemplate.id),
          name: maybeJust(exportTemplate.name),
          fields: exportTemplate.fields,
        }),
        () => ({
          editingId: maybeNothing<number>(),
          name: maybeNothing<string>(),
          fields: [],
        })
      )
      return {
        ...state,
        formOpen: true,
        ...formData,
      }
    }
    case 'CLOSE_EXPORT_TEMPLATE_FORM':
      return openState()
    case 'SET_EXPORT_TEMPLATE_NAME':
      return {
        ...state,
        name: maybeJust(action.name),
      }
    case 'SET_EXPORT_TEMPLATE_FIELDS':
      return {
        ...state,
        fields: action.fields,
      }
    case 'ADD_EXPORT_TEMPLATE_FIELD':
      return {
        ...state,
        fields: [...state.fields, action.field],
      }
    case 'REMOVE_EXPORT_TEMPLATE_FIELD':
      return {
        ...state,
        fields: state.fields.filter(field => field.field !== action.field),
      }
    case 'SAVE_EXPORT_TEMPLATE_FIELD':
      return {
        ...state,
        fields: state.fields.map(field =>
          field.field === action.field.field ? action.field : field
        ),
      }
    case 'CLEAR_EXPORT_TEMPLATE_NAME':
      return {
        ...state,
        name: maybeNothing(),
      }
    case 'CREATE_EXPORT_TEMPLATE_REQUEST':
    case 'UPDATE_EXPORT_TEMPLATE_REQUEST':
      return savingRequest(state)
    case 'DELETE_EXPORT_TEMPLATE_REQUEST':
      return deletingRequest(state)
    case 'CREATE_EXPORT_TEMPLATE_SUCCESS':
    case 'UPDATE_EXPORT_TEMPLATE_SUCCESS':
    case 'CREATE_EXPORT_TEMPLATE_ERROR':
    case 'UPDATE_EXPORT_TEMPLATE_ERROR':
      return savingResponse(state)
    case 'DELETE_EXPORT_TEMPLATE_SUCCESS':
    case 'DELETE_EXPORT_TEMPLATE_ERROR':
      return deletingResponse(state)
    case 'SET_EXPORT_TEMPLATE_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    case 'EXPORT_DEVICES_REQUEST':
      return { ...state, exporting: true }
    case 'EXPORT_DEVICES_SUCCESS':
    case 'EXPORT_DEVICES_ERROR':
      return { ...state, exporting: false }
    default:
      return state
  }
}
