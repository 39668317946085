import * as React from 'react'
import { FormControl, TextField, InputAdornment } from '@material-ui/core'
import FormErrors from './FormErrors'
import { Maybe, maybeMap, maybeWithDefault, maybeSwitch } from 'types/Maybe'

interface Props {
  value: Maybe<number>
  label: string
  required: boolean
  onChange: (value: number) => void
  onClear: () => void
  validationErrors: string[]
  disabled: boolean
  unit: Maybe<string>
}

export default class FormNumberInput extends React.PureComponent<Props> {
  render() {
    const {
      value,
      label,
      onChange,
      onClear,
      required,
      validationErrors,
      disabled,
      unit,
    } = this.props
    const hasErrors = validationErrors && validationErrors.length > 0
    return (
      <FormControl fullWidth={true} error={hasErrors}>
        <TextField
          margin="normal"
          type="number"
          label={label}
          value={maybeSwitch(
            value,
            v => v,
            () => ''
          )}
          required={required}
          onChange={(e: any) => {
            const value = e.target.value
            !!value ? onChange(Number(value)) : onClear()
          }}
          error={hasErrors}
          disabled={disabled}
          InputProps={maybeWithDefault(
            maybeMap(
              unit,
              u =>
                ({
                  endAdornment: (
                    <InputAdornment position="end">{u}</InputAdornment>
                  ),
                } as any)
            ),
            {} as any
          )}
        />
        <FormErrors errors={validationErrors} />
      </FormControl>
    )
  }
}
