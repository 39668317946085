import { Action } from '../actions'
import { SearchResult } from 'data/SearchResult'
import { Maybe, maybeNothing, maybeJust } from 'types/Maybe'

export interface SearchState {
  query: Maybe<string>
  querying: boolean
  results: SearchResult[]
}

const defaultState = (): SearchState => ({
  query: maybeNothing(),
  querying: false,
  results: [],
})

export default (
  state: SearchState = defaultState(),
  action: Action
): SearchState => {
  switch (action.type) {
    case 'SET_SEARCH_QUERY':
      return { ...state, query: maybeJust(action.query) }
    case 'CLEAR_SEARCH_QUERY':
      return { ...state, query: maybeNothing() }
    case 'GET_SEARCH_RESULTS_REQUEST':
      return { ...state, querying: true }
    case 'GET_SEARCH_RESULTS_SUCCESS':
      return { ...state, querying: false, results: action.results }
    case 'GET_SEARCH_RESULTS_ERROR':
      return { ...state, querying: false }
    default:
      return state
  }
}
