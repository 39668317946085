import * as requests from '../requests/LeasingContract'
import { LeasingContract } from 'data/LeasingContract'
import { Dispatch } from 'redux'
import { handleError } from './errors'
import { History } from 'history'

interface FetchLeasingContractsRequestAction {
  type: 'FETCH_LEASING_CONTRACTS_REQUEST'
}
interface FetchLeasingContractsSuccessAction {
  type: 'FETCH_LEASING_CONTRACTS_SUCCESS'
  frameContracts: LeasingContract[]
}
interface FetchLeasingContractsErrorAction {
  type: 'FETCH_LEASING_CONTRACTS_ERROR'
}

export type LeasingContractsAction =
  | FetchLeasingContractsRequestAction
  | FetchLeasingContractsSuccessAction
  | FetchLeasingContractsErrorAction

const fetchLeasingContractsRequestAction = (): FetchLeasingContractsRequestAction => ({
  type: 'FETCH_LEASING_CONTRACTS_REQUEST',
})
const fetchLeasingContractsSuccessAction = (
  frameContracts: LeasingContract[]
): FetchLeasingContractsSuccessAction => ({
  type: 'FETCH_LEASING_CONTRACTS_SUCCESS',
  frameContracts,
})
const fetchLeasingContractsErrorAction = (): FetchLeasingContractsErrorAction => ({
  type: 'FETCH_LEASING_CONTRACTS_ERROR',
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number
) => {
  dispatch(fetchLeasingContractsRequestAction())
  try {
    const frameContracts = await requests.fetchByFrameContractId(
      token,
      frameContractId
    )
    dispatch(fetchLeasingContractsSuccessAction(frameContracts))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchLeasingContractsErrorAction,
      'redirect'
    )
  }
}
