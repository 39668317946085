import * as React from 'react'
import { Chip, withTheme, Theme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import { green, red, orange, blue } from '@material-ui/core/colors'
import { DeviceStatus } from '../data/DeviceStatus'
import { BillingStatus } from '../data/BillingStatus'
import { capitalize } from '../utils'

type StatusInfo =
  | {
      type: 'deviceStatus'
      status: DeviceStatus
    }
  | {
      type: 'billingStatus'
      status: BillingStatus
    }

const deviceStatusColor = (status: DeviceStatus): string => {
  switch (status) {
    case 'Leased':
      return green[500]
    case 'Extended':
    case 'Repurchased':
      return blue[500]
    case 'Disposed':
    case 'Retired':
      return orange[500]
    case 'Stolen':
    case 'Lost':
    case 'Broken':
      return red[500]
  }
}
const billingStatusColor = (status: BillingStatus): string => {
  switch (status) {
    case 'Unbilled':
    case 'On-Hold':
    case 'Rejected':
      return red[500]
    case 'Pro Forma':
    case 'Accepted':
      return blue[500]
    case 'Billed':
      return green[500]
  }
}

const statusColor = (statusInfo: StatusInfo): string => {
  switch (statusInfo.type) {
    case 'deviceStatus':
      return deviceStatusColor(statusInfo.status)
    case 'billingStatus':
      return billingStatusColor(statusInfo.status)
  }
}

interface OwnProps {
  statusInfo: StatusInfo
}

interface Props extends OwnProps {
  theme: Theme
}

const StatusChip = (props: Props) => (
  <ThemeProvider
    theme={{
      ...props.theme,
      palette: {
        ...props.theme.palette,
        primary: {
          ...props.theme.palette.primary,
          main: statusColor(props.statusInfo),
        },
      },
    }}
  >
    <Chip color="primary" label={capitalize(props.statusInfo.status)} />
  </ThemeProvider>
)

export default withTheme(StatusChip)
