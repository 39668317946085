import { Dispatch } from 'redux'
import * as requests from '../requests/CustomerEntity'
import { CustomerEntity } from '../data/CustomerEntity'
import { History } from 'history'
import { handleError } from './errors'

interface FetchCustomerEntitiesRequestAction {
  type: 'FETCH_CUSTOMER_ENTITIES_REQUEST'
}
interface FetchCustomerEntitiesSuccessAction {
  type: 'FETCH_CUSTOMER_ENTITIES_SUCCESS'
  customerEntities: CustomerEntity[]
}
interface FetchCustomerEntitiesErrorAction {
  type: 'FETCH_CUSTOMER_ENTITIES_ERROR'
}

export type CustomerEntitiesAction =
  | FetchCustomerEntitiesRequestAction
  | FetchCustomerEntitiesSuccessAction
  | FetchCustomerEntitiesErrorAction

const fetchCustomerEntitiesRequestAction = (): FetchCustomerEntitiesRequestAction => ({
  type: 'FETCH_CUSTOMER_ENTITIES_REQUEST',
})
export const fetchCustomerEntitiesSuccessAction = (
  customerEntities: CustomerEntity[]
): FetchCustomerEntitiesSuccessAction => ({
  type: 'FETCH_CUSTOMER_ENTITIES_SUCCESS',
  customerEntities,
})
const fetchCustomerEntitiesErrorAction = (): FetchCustomerEntitiesErrorAction => ({
  type: 'FETCH_CUSTOMER_ENTITIES_ERROR',
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  customerId: number
) => {
  dispatch(fetchCustomerEntitiesRequestAction())
  try {
    const customerEntities = await requests.fetchAll(token, customerId)
    dispatch(fetchCustomerEntitiesSuccessAction(customerEntities))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchCustomerEntitiesErrorAction,
      'redirect'
    )
  }
}
