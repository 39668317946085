import * as React from 'react'
import { FormControl, TextField } from '@material-ui/core'
import FormErrors from './FormErrors'
import { Maybe, maybeWithDefault } from 'types/Maybe'

interface Props {
  value: Maybe<string>
  label: string
  required: boolean
  onChange: (value: string) => void
  onClear: () => void
  validationErrors: string[]
  disabled: boolean
}

export default class FormTextInput extends React.PureComponent<Props> {
  render() {
    const {
      value,
      label,
      onChange,
      onClear,
      required,
      validationErrors,
      disabled,
    } = this.props
    const hasErrors = validationErrors && validationErrors.length > 0
    return (
      <FormControl fullWidth={true} error={hasErrors}>
        <TextField
          margin="normal"
          label={label}
          value={maybeWithDefault(value, '')}
          required={required}
          onChange={(e: any) => {
            const value = e.target.value
            !!value ? onChange(value) : onClear()
          }}
          error={hasErrors}
          disabled={disabled}
        />
        <FormErrors errors={validationErrors} />
      </FormControl>
    )
  }
}
