import { Action } from '../actions'
import { variantIcon } from '../containers/Snackbars'

export type Variant = keyof typeof variantIcon

export interface SnackbarData {
  variant: Variant
  message: string
}

export interface SnackbarsState {
  [key: string]: SnackbarData
}

const defaultState = (): SnackbarsState => ({})

export default (
  state: SnackbarsState = defaultState(),
  action: Action
): SnackbarsState => {
  switch (action.type) {
    case 'ADD_SNACKBAR':
      const key = Math.random()
        .toString(36)
        .substr(2, 6)
      return {
        ...state,
        [key]: { variant: action.variant, message: action.message },
      }
    case 'REMOVE_SNACKBAR':
      const { [action.key]: s, ...rest } = state
      return rest
    default:
      return state
  }
}
