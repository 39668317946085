import { JsonDecoder } from 'ts.data.json'
import { momentDecoder } from './decoderHelpers'
import { Moment } from 'moment'
import { Maybe, maybeDecoder } from 'types/Maybe'

export type LeasingStartTrigger = 'delivery' | 'shipping' | 'order'

export const leasingStartTriggers: Set<LeasingStartTrigger> = new Set([
  'delivery',
  'shipping',
  'order',
] as LeasingStartTrigger[])

export const formatLeasingStartTrigger = (
  leasingStartTrigger: LeasingStartTrigger
): string => {
  switch (leasingStartTrigger) {
    case 'delivery':
      return 'Delivery'
    case 'shipping':
      return 'Shipping'
    case 'order':
      return 'Order'
  }
}

const leasingStartTriggerDecoder: JsonDecoder.Decoder<
  LeasingStartTrigger
> = JsonDecoder.string.then((value: string) => {
  if ((leasingStartTriggers as Set<string>).has(value)) {
    return JsonDecoder.constant(value as LeasingStartTrigger)
  }
  return JsonDecoder.fail('Invalid leasing start trigger "' + value + '"')
})

export type FrameContractType = 'Annule et Remplace' | 'Aggregated'

export const frameContractTypes: Set<FrameContractType> = new Set([
  'Annule et Remplace',
  'Aggregated',
] as FrameContractType[])

export const formatFrameContractType = (
  frameContractType: FrameContractType
): string => frameContractType as string

const frameContractTypeDecoder: JsonDecoder.Decoder<
  FrameContractType
> = JsonDecoder.string.then(value => {
  if ((frameContractTypes as Set<string>).has(value)) {
    return JsonDecoder.constant(value as FrameContractType)
  }
  return JsonDecoder.fail('Invalid frame contract type "' + value + '"')
})

export type FrameContractCategory = 'Standard' | 'Shared' | 'Counter'

export const frameContractCategories: Set<FrameContractCategory> = new Set([
  'Standard',
  'Shared',
  'Counter',
] as FrameContractCategory[])

const frameContractCategoryDecoder: JsonDecoder.Decoder<
  FrameContractCategory
> = JsonDecoder.string.then(value => {
  if ((frameContractCategories as Set<string>).has(value)) {
    return JsonDecoder.constant(value as FrameContractCategory)
  }
  return JsonDecoder.fail('Invalid frame contract category "' + value + '"')
})

export const formatFrameContractCategory = (
  category: FrameContractCategory
): string => category as string

export interface FrameContract {
  id: number
  name: string
  type: FrameContractType
  isActive: boolean
  customerId: number
  customerEntityIds: number[]
  contractStartDate: Moment
  billingIntervalInMonths: number
  contractIntervalInMonths: number
  noticePeriodInMonths: number
  isProRataBilling: boolean
  leasingStartTrigger: LeasingStartTrigger
  contractId: Maybe<string>
  billOnlyActiveAssets: boolean
  creditUnusedPeriods: boolean
  categories: FrameContractCategory[]
}

export const decoder = JsonDecoder.object<FrameContract>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    type: frameContractTypeDecoder,
    isActive: JsonDecoder.boolean,
    customerId: JsonDecoder.number,
    customerEntityIds: JsonDecoder.array(JsonDecoder.number, 'number[]'),
    contractStartDate: momentDecoder,
    billingIntervalInMonths: JsonDecoder.number,
    contractIntervalInMonths: JsonDecoder.number,
    noticePeriodInMonths: JsonDecoder.number,
    isProRataBilling: JsonDecoder.boolean,
    leasingStartTrigger: leasingStartTriggerDecoder,
    contractId: maybeDecoder(JsonDecoder.string),
    billOnlyActiveAssets: JsonDecoder.boolean,
    creditUnusedPeriods: JsonDecoder.boolean,
    categories: JsonDecoder.array(
      frameContractCategoryDecoder,
      'FrameContractCategory[]'
    ),
  },
  'FrameContract',
  {
    id: 'frameContract',
    type: 'frameContractType',
    customerId: 'azureClientId',
    customerEntityIds: 'entities',
  }
)
