import * as React from 'react'
import LoadingIndicator from './LoadingIndicator'
import {
  ListItem,
  ListItemText,
  Paper,
  List,
  Theme,
  WithStyles,
} from '@material-ui/core'
import { SEARCH_INPUT_ID } from 'containers/SearchInput'
import { withStyles } from '@material-ui/styles'
import { SearchResult } from 'data/SearchResult'
import { maybeNothing } from 'types/Maybe'

interface Props extends WithStyles<'menu'> {
  querying: boolean
  results: SearchResult[]
  clear: () => void
  onResultClick: (result: SearchResult) => void
}

class SearchResults extends React.PureComponent<Props> {
  private renderResults() {
    const { querying, results, clear, onResultClick } = this.props
    if (querying) {
      return <LoadingIndicator size={maybeNothing()} />
    }
    if (!results || results.length === 0) {
      return (
        <ListItem>
          <ListItemText>No device found</ListItemText>
        </ListItem>
      )
    }
    return (
      <React.Fragment>
        {results.map((result: SearchResult, index: number) => (
          <ListItem
            key={index}
            button={true}
            onClick={() => {
              onResultClick(result)
              clear()
            }}
          >
            <ListItemText>{result.deviceName}</ListItemText>
          </ListItem>
        ))}
      </React.Fragment>
    )
  }

  public render() {
    const { classes } = this.props

    const anchorEl = document.getElementById(SEARCH_INPUT_ID)
    if (!anchorEl) return null

    return (
      <Paper className={classes.menu}>
        <List>{this.renderResults()}</List>
      </Paper>
    )
  }
}

const styles = (theme: Theme) => ({
  menu: {
    position: 'absolute' as 'absolute',
    marginTop: theme.spacing(1),
    right: 0,
    minWidth: '100%',
    maxHeight: '70vh',
    overflow: 'auto',
  },
  // link: {
  //   textDecoration: 'none',
  //   color: 'inherit',
  // },
  // subHeader: {
  //   backgroundColor: theme.palette.background.paper,
  // },
})

export default withStyles(styles)(SearchResults)
