import * as React from 'react'
import { FormControl, FormControlLabel, Switch } from '@material-ui/core'
import FormErrors from './FormErrors'
import { Maybe, maybeWithDefault } from 'types/Maybe'

interface Props {
  value: Maybe<boolean>
  label: string
  required: boolean
  onChange: (value: boolean) => void
  validationErrors: string[]
  disabled: boolean
}

export default class FormBooleanInput extends React.PureComponent<Props> {
  render() {
    const {
      value,
      label,
      onChange,
      required,
      validationErrors,
      disabled,
    } = this.props
    const hasErrors = validationErrors && validationErrors.length > 0
    return (
      <FormControl
        fullWidth={true}
        required={required}
        margin="normal"
        error={hasErrors}
        disabled={disabled}
      >
        <FormControlLabel
          control={
            <Switch
              checked={maybeWithDefault(value, false)}
              onChange={e => onChange(e.target.checked)}
            />
          }
          label={label}
          disabled={disabled}
        />
        <FormErrors errors={validationErrors} />
      </FormControl>
    )
  }
}
