import { combineReducers } from 'redux'
import app, { AppState } from './app'
import customers, { CustomersState } from './customers'
import customerDialog, { CustomerDialogState } from './customerDialog'
import customerEntities, { CustomerEntitiesState } from './customerEntities'
import devices, { DevicesState } from './devices'
import devicesCount, { DevicesCountState } from './devicesCount'
import deviceDetailsDialog, {
  DeviceDetailsDialogState,
} from './deviceDetailsDialog'
import frameContracts, { FrameContractsState } from './frameContracts'
import leasingContracts, { LeasingContractsState } from './leasingContracts'
import leasingCategories, { LeasingCategoriesState } from './leasingCategories'
import frameContractDialog, {
  FrameContractDialogState,
} from './frameContractDialog'
import leasingContractDialog, {
  LeasingContractDialogState,
} from './leasingContractDialog'
import leasingCategoriesDialog, {
  LeasingCategoriesDialogState,
} from './leasingCategoriesDialog'
import exportTemplates, { ExportTemplatesState } from './exportTemplates'
import exportFields, { ExportFieldsState } from './exportFields'
import exportTemplateDialog, {
  ExportTemplateDialogState,
} from './exportTemplateDialog'
import exportTemplateFieldAliasDialog, {
  ExportTemplateFieldAliasDialogState,
} from './exportTemplateFieldAliasDialog'
import billingPeriods, { BillingPeriodsState } from './billingPeriods'
import billingPeriodDialog, {
  BillingPeriodDialogState,
} from './billingPeriodDialog'
import search, { SearchState } from './search'
import snackbars, { SnackbarsState } from './snackbars'
import errorsHistory, { ErrorsHistory } from './errorsHistory'

export interface State {
  app: AppState
  customers: CustomersState
  customerDialog: CustomerDialogState
  customerEntities: CustomerEntitiesState
  frameContracts: FrameContractsState
  leasingContracts: LeasingContractsState
  leasingCategories: LeasingCategoriesState
  frameContractDialog: FrameContractDialogState
  leasingContractDialog: LeasingContractDialogState
  leasingCategoriesDialog: LeasingCategoriesDialogState
  devices: DevicesState
  devicesCount: DevicesCountState
  deviceDetailsDialog: DeviceDetailsDialogState
  exportTemplates: ExportTemplatesState
  exportFields: ExportFieldsState
  exportTemplateDialog: ExportTemplateDialogState
  exportTemplateFieldAliasDialog: ExportTemplateFieldAliasDialogState
  billingPeriods: BillingPeriodsState
  billingPeriodDialog: BillingPeriodDialogState
  search: SearchState
  snackbars: SnackbarsState
  errorsHistory: ErrorsHistory
}

export default combineReducers({
  app,
  customers,
  customerDialog,
  customerEntities,
  frameContracts,
  leasingContracts,
  leasingCategories,
  frameContractDialog,
  leasingContractDialog,
  leasingCategoriesDialog,
  devices,
  devicesCount,
  deviceDetailsDialog,
  exportTemplates,
  exportFields,
  exportTemplateDialog,
  exportTemplateFieldAliasDialog,
  billingPeriods,
  billingPeriodDialog,
  search,
  snackbars,
  errorsHistory,
})
