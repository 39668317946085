import { Action } from '../actions'
import {
  LeasingStartTrigger,
  FrameContractType,
  FrameContractCategory,
} from 'data/FrameContract'
import { Moment } from 'moment'
import { Maybe, maybeNothing, maybeJust, maybeSwitch } from 'types/Maybe'
import { ValidationErrors } from 'requests/errors'
import {
  savingRequest,
  savingResponse,
  deletingRequest,
  deletingResponse,
  setIsDeletable,
  setValidationErrors,
} from './helpers'

export interface FrameContractDialogState {
  open: boolean
  isDeletable: boolean
  saving: boolean
  deleting: boolean
  updatingId: Maybe<number>
  name: Maybe<string>
  type: FrameContractType
  isActive: boolean
  customerId: Maybe<number>
  customerEntityIds: number[]
  contractStartDate: Maybe<Moment>
  billingIntervalInMonths: Maybe<number>
  contractIntervalInMonths: Maybe<number>
  noticePeriodInMonths: Maybe<number>
  isProRataBilling: boolean
  leasingStartTrigger: LeasingStartTrigger
  billOnlyActiveAssets: boolean
  creditUnusedPeriods: boolean
  categories: FrameContractCategory[]
  validationErrors: ValidationErrors
}

const defaultFrameContractData = {
  updatingId: maybeNothing<number>(),
  name: maybeNothing<string>(),
  type: 'Annule et Remplace' as FrameContractType,
  isActive: true,
  customerId: maybeNothing<number>(),
  customerEntityIds: [] as number[],
  contractStartDate: maybeNothing<Moment>(),
  billingIntervalInMonths: maybeJust(1),
  contractIntervalInMonths: maybeJust(1),
  noticePeriodInMonths: maybeJust(1),
  isProRataBilling: false,
  leasingStartTrigger: 'delivery' as LeasingStartTrigger,
  billOnlyActiveAssets: false,
  creditUnusedPeriods: false,
  categories: [] as FrameContractCategory[],
}

const defaultState = (): FrameContractDialogState => ({
  open: false,
  isDeletable: false,
  saving: false,
  deleting: false,
  ...defaultFrameContractData,
  validationErrors: {},
})

export default (
  state: FrameContractDialogState = defaultState(),
  action: Action
): FrameContractDialogState => {
  switch (action.type) {
    case 'OPEN_FRAME_CONTRACT_DIALOG': {
      const frameContractData = maybeSwitch(
        action.frameContract,
        frameContract => ({
          updatingId: maybeJust(frameContract.id),
          name: maybeJust(frameContract.name),
          type: frameContract.type,
          isActive: frameContract.isActive,
          customerId: maybeJust(frameContract.customerId),
          customerEntityIds: frameContract.customerEntityIds,
          contractStartDate: maybeJust(frameContract.contractStartDate),
          billingIntervalInMonths: maybeJust(
            frameContract.billingIntervalInMonths
          ),
          contractIntervalInMonths: maybeJust(
            frameContract.contractIntervalInMonths
          ),
          noticePeriodInMonths: maybeJust(frameContract.noticePeriodInMonths),
          isProRataBilling: frameContract.isProRataBilling,
          leasingStartTrigger: frameContract.leasingStartTrigger,
          billOnlyActiveAssets: frameContract.billOnlyActiveAssets,
          creditUnusedPeriods: frameContract.creditUnusedPeriods,
          categories: frameContract.categories,
        }),
        () => defaultFrameContractData
      )
      return {
        ...state,
        open: true,
        ...frameContractData,
      }
    }
    case 'CLOSE_FRAME_CONTRACT_DIALOG':
      return defaultState()
    case 'CREATE_FRAME_CONTRACT_REQUEST':
    case 'UPDATE_FRAME_CONTRACT_REQUEST':
      return savingRequest(state)
    case 'CREATE_FRAME_CONTRACT_SUCCESS':
    case 'UPDATE_FRAME_CONTRACT_SUCCESS':
    case 'CREATE_FRAME_CONTRACT_ERROR':
    case 'UPDATE_FRAME_CONTRACT_ERROR':
      return savingResponse(state)
    case 'DELETE_FRAME_CONTRACT_REQUEST':
      return deletingRequest(state)
    case 'DELETE_FRAME_CONTRACT_ERROR':
      return deletingResponse(state)
    case 'SET_FRAME_CONTRACT_IS_DELETABLE':
      return setIsDeletable(state)
    case 'SET_FRAME_CONTRACT_NAME':
      return { ...state, name: maybeJust(action.name) }
    case 'SET_FRAME_CONTRACT_TYPE':
      return { ...state, type: action.frameContractType }
    case 'SET_FRAME_CONTRACT_IS_ACTIVE':
      return { ...state, isActive: action.isActive }
    case 'SET_FRAME_CONTRACT_CUSTOMER_ID':
      return { ...state, customerId: maybeJust(action.customerId) }
    case 'SET_FRAME_CONTRACT_CUSTOMER_ENTITY_IDS':
      return { ...state, customerEntityIds: action.customerEntityIds }
    case 'SET_FRAME_CONTRACT_CONTRACT_START_DATE':
      return {
        ...state,
        contractStartDate: maybeJust(action.contractStartDate),
      }
    case 'SET_FRAME_CONTRACT_BILLING_INTERVAL_IN_MONTHS':
      return {
        ...state,
        billingIntervalInMonths: maybeJust(action.billingIntervalInMonths),
      }
    case 'SET_FRAME_CONTRACT_CONTRACT_INTERVAL_IN_MONTHS':
      return {
        ...state,
        contractIntervalInMonths: maybeJust(action.contractIntervalInMonths),
      }
    case 'SET_FRAME_CONTRACT_NOTICE_PERIOD_IN_MONTHS':
      return {
        ...state,
        noticePeriodInMonths: maybeJust(action.noticePeriodInMonths),
      }
    case 'SET_FRAME_CONTRACT_IS_PRO_RATA_BILLING':
      return { ...state, isProRataBilling: action.isProRataBilling }
    case 'SET_FRAME_CONTRACT_LEASING_START_TRIGGER':
      return { ...state, leasingStartTrigger: action.leasingStartTrigger }
    case 'SET_FRAME_CONTRACT_BILL_ONLY_ACTIVE_ASSETS':
      return { ...state, billOnlyActiveAssets: action.billOnlyActiveAssets }
    case 'SET_FRAME_CONTRACT_CREDIT_UNUSED_PERIODS':
      return { ...state, creditUnusedPeriods: action.creditUnusedPeriods }
    case 'SET_FRAME_CONTRACT_CATEGORIES':
      return { ...state, categories: action.categories }
    case 'CLEAR_FRAME_CONTRACT_NAME':
      return { ...state, name: maybeNothing() }
    case 'CLEAR_FRAME_CONTRACT_CONTRACT_START_DATE':
      return { ...state, contractStartDate: maybeNothing() }
    case 'CLEAR_FRAME_CONTRACT_BILLING_INTERVAL_IN_MONTHS':
      return { ...state, billingIntervalInMonths: maybeNothing() }
    case 'CLEAR_FRAME_CONTRACT_CONTRACT_INTERVAL_IN_MONTHS':
      return { ...state, contractIntervalInMonths: maybeNothing() }
    case 'CLEAR_FRAME_CONTRACT_NOTICE_PERIOD_IN_MONTHS':
      return { ...state, noticePeriodInMonths: maybeNothing() }
    case 'SET_FRAME_CONTRACT_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    default:
      return state
  }
}
