import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'reducers'
import { Dispatch } from 'redux'
import LoadingIndicator from 'components/LoadingIndicator'
import * as actions from 'actions/customers'
import * as customerDialogActions from 'actions/customerDialog'
import { RouteChildrenProps, withRouter } from 'react-router'
import { Customer } from 'data/Customer'
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  WithStyles,
  Theme,
  TableHead,
  Toolbar,
  Fab,
  IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { withStyles } from '@material-ui/styles'
import { maybeNothing, maybeJust } from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  fetching: boolean
  data: Customer[]
}

interface DispatchProps {
  fetchCustomers: () => void
  openNewCustomerDialog: () => void
  editCustomer: (customer: Customer) => void
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    WithStyles<'emptyStateRoot' | 'root' | 'table' | 'tableWrapper'> {}

class Customers extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.fetchCustomers()
  }

  public render() {
    const {
      classes,
      fetching,
      data,
      openNewCustomerDialog,
      editCustomer,
    } = this.props

    if (fetching) {
      return <LoadingIndicator size={maybeNothing()} />
    }

    if (data.length === 0) {
      return (
        <Box className={classes.emptyStateRoot}>
          <Typography variant="h5" gutterBottom>
            Oops… There are no customers{' '}
            <span role="img" aria-label="Sad">
              😢
            </span>
          </Typography>
        </Box>
      )
    }

    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <Toolbar>
          <Typography color="inherit" variant="h6">
            Customers
          </Typography>
        </Toolbar>
        <Box
          flex={1}
          borderTop={1}
          borderColor="grey.300"
          className={classes.tableWrapper}
        >
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((customer: Customer, index: number) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <IconButton onClick={() => editCustomer(customer)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box position="fixed" mb={2} mr={2} bottom={0} right={0}>
            <Fab
              onClick={openNewCustomerDialog}
              color="primary"
              aria-label="Add"
            >
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      </Paper>
    )
  }
}

const styles = (theme: Theme) => ({
  emptyStateRoot: {
    textAlign: 'center' as 'center',
    paddingTop: theme.spacing(8),
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto' as 'auto',
    overflowY: 'scroll' as 'scroll',
  },
})

const mapStateToProps = (state: State): StateProps => ({
  fetching: state.customers.fetching,
  data: state.customers.data,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  fetchCustomers: () =>
    actions.fetchAll(ownProps.token, dispatch, ownProps.history),
  openNewCustomerDialog: () =>
    customerDialogActions.openDialog(dispatch, maybeNothing()),
  editCustomer: (customer: Customer) =>
    customerDialogActions.openDialog(dispatch, maybeJust(customer)),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Customers))
)
