import { Action } from '../actions'
import { Moment } from 'moment'
import moment from 'moment'
import { ValidationErrors } from 'requests/errors'
import {
  Maybe,
  maybeNothing,
  maybeJust,
  maybeWithDefault,
  maybeSwitch,
} from 'types/Maybe'
import {
  savingResponse,
  deletingRequest,
  deletingResponse,
  savingRequest,
  setIsDeletable,
  setValidationErrors,
} from './helpers'

export interface LeasingContractDialogState {
  open: boolean
  saving: boolean
  deleting: boolean
  updatingId: Maybe<number>
  sni: Maybe<string>
  contractStartDate: Maybe<Moment>
  contractEndDate: Maybe<Moment>
  validationErrors: ValidationErrors
  isDeletable: boolean
}

const defaultFormData = {
  updatingId: maybeNothing<number>(),
  sni: maybeNothing<string>(),
  contractStartDate: maybeNothing<Moment>(),
  contractEndDate: maybeNothing<Moment>(),
}

const defaultState = (): LeasingContractDialogState => ({
  open: false,
  saving: false,
  deleting: false,
  ...defaultFormData,
  validationErrors: {},
  isDeletable: false,
})

export default (
  state: LeasingContractDialogState = defaultState(),
  action: Action
): LeasingContractDialogState => {
  switch (action.type) {
    case 'OPEN_LEASING_CONTRACT_DIALOG': {
      const formData = maybeSwitch(
        action.leasingContract,
        leasingContract => ({
          updatingId: maybeJust(leasingContract.id),
          sni: leasingContract.sni,
          contractStartDate: leasingContract.contractStartDate,
          contractEndDate: leasingContract.contractEndDate,
        }),
        () => defaultFormData
      )
      return {
        ...state,
        open: true,
        ...formData,
      }
    }
    case 'CLOSE_LEASING_CONTRACT_DIALOG':
      return defaultState()
    case 'CREATE_LEASING_CONTRACT_REQUEST':
    case 'UPDATE_LEASING_CONTRACT_REQUEST':
      return savingRequest(state)
    case 'CREATE_LEASING_CONTRACT_SUCCESS':
    case 'UPDATE_LEASING_CONTRACT_SUCCESS':
    case 'CREATE_LEASING_CONTRACT_ERROR':
    case 'UPDATE_LEASING_CONTRACT_ERROR':
      return savingResponse(state)
    case 'DELETE_LEASING_CONTRACT_REQUEST':
      return deletingRequest(state)
    case 'DELETE_LEASING_CONTRACT_SUCCESS':
    case 'DELETE_LEASING_CONTRACT_ERROR':
      return deletingResponse(state)
    case 'SET_LEASING_CONTRACT_SNI':
      return { ...state, sni: maybeJust(action.sni) }
    case 'SET_LEASING_CONTRACT_CONTRACT_START_DATE':
      return {
        ...state,
        contractStartDate: maybeJust(action.contractStartDate),
        contractEndDate: maybeJust(
          maybeWithDefault(
            state.contractEndDate,
            moment(action.contractStartDate).endOf('month')
          )
        ),
      }
    case 'SET_LEASING_CONTRACT_CONTRACT_END_DATE':
      return {
        ...state,
        contractEndDate: maybeJust(action.contractEndDate),
        contractStartDate: maybeJust(
          maybeWithDefault(
            state.contractStartDate,
            moment(action.contractEndDate).startOf('month')
          )
        ),
      }
    case 'CLEAR_LEASING_CONTRACT_SNI':
      return {
        ...state,
        sni: maybeNothing(),
      }
    case 'CLEAR_LEASING_CONTRACT_CONTRACT_START_DATE':
      return {
        ...state,
        contractStartDate: maybeNothing(),
      }
    case 'CLEAR_LEASING_CONTRACT_CONTRACT_END_DATE':
      return {
        ...state,
        contractEndDate: maybeNothing(),
      }
    case 'SET_LEASING_CONTRACT_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    case 'SET_LEASING_CONTRACT_IS_DELETABLE':
      return setIsDeletable(state)
    default:
      return state
  }
}
