import React from 'react'
import { DialogTitle, DialogActions, Dialog, Button } from '@material-ui/core'

interface Action {
  title: string
  onClick: () => void
}

interface Props {
  dialogTitle: string
  onConfirm: (() => void) | Action[]
  render: (onClick: () => void) => any
}

interface State {
  open: boolean
}

export default class WithConfirmation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { open: false }
  }

  setOpen = (open: boolean) => this.setState({ open })

  close = () => this.setOpen(false)

  public render() {
    const { dialogTitle, onConfirm, render } = this.props
    const { open } = this.state
    const actions: Action[] = Array.isArray(onConfirm)
      ? onConfirm
      : [
          {
            title: 'Ok',
            onClick: onConfirm,
          },
        ]
    return (
      <React.Fragment>
        {render(() => this.setOpen(true))}
        <div
          onClick={(event: any) => {
            event.stopPropagation()
          }}
        >
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={open}
          >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                Cancel
              </Button>
              {actions.map((action: Action, index: number) => (
                <Button
                  key={index}
                  onClick={() => {
                    action.onClick()
                    this.close()
                  }}
                >
                  {action.title}
                </Button>
              ))}
            </DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    )
  }
}
