import { isMaybe, isNothing } from 'types/Maybe'

export type ValidationErrors = { [field: string]: string[] }

export const addError = (
  errors: ValidationErrors,
  field: string,
  error: string
) => {
  if (errors[field] === undefined) {
    errors[field] = []
  }
  errors[field].push(error)
}

export const requireField = (
  errors: ValidationErrors,
  field: string,
  value: any
) => {
  const error = 'cannot be blank'
  switch (typeof value) {
    case 'undefined':
      addError(errors, field, error)
      break
    case 'string': {
      if (value.trim() === '') addError(errors, field, error)
      break
    }
    case 'object': {
      if (
        (Array.isArray(value) && value.length === 0) ||
        value === null ||
        (isMaybe(value) && isNothing(value))
      ) {
        addError(errors, field, error)
      }
      break
    }
  }
}

export const anyError = (errors: ValidationErrors) =>
  Object.keys(errors).length > 0
