import * as React from 'react'
import classNames from 'classnames'
import {
  Route,
  withRouter,
  Switch,
  Redirect,
  RouteChildrenProps,
} from 'react-router'
import {
  Theme,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  withStyles,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Select,
  MenuItem,
  InputBase,
  FormControl,
  Menu,
  ListSubheader,
  Collapse,
  Box,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ErrorIcon from '@material-ui/icons/Error'
import DeleteIcon from '@material-ui/icons/Delete'
import ContentCopyIcon from '@material-ui/icons/ContentCopy'
import PeopleIcon from '@material-ui/icons/People'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import EuroIcon from '@material-ui/icons/EuroSymbol'
import CategoryIcon from '@material-ui/icons/Category'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SettingsIcon from '@material-ui/icons/Settings'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { State } from '../reducers'
import { deviceStatuses } from '../data/DeviceStatus'
import { billingStatuses } from '../data/BillingStatus'
import { FrameContract } from '../data/FrameContract'
import Devices from '../pages/Devices'
import NotFound from '../pages/NotFound'
import actions from '../actions'
import FrameContractDialog from './FrameContractDialog'
import LeasingContractDialog from './LeasingContractDialog'
import { LeasingContract } from '../data/LeasingContract'
import { capitalize, pluralize } from '../utils'
import * as routes from '../routes'
import Snackbars from './Snackbars'
import { WithStyles } from '@material-ui/styles'
import LeasingCategoriesDialog from './LeasingCategoriesDialog'
import { sortBy, groupBy, map } from 'lodash'
import ExportTemplateDialog from './ExportTemplateDialog'
import CustomerDialog from './CustomerDialog'
import LoadingIndicator from 'components/LoadingIndicator'
import BillingPeriodDialog from './BillingPeriodDialog'
import DeviceDetailsDialog from './DeviceDetailsDialog'
import DevicesCount from './DevicesCount'
import { Moment } from 'moment'
import FormDateInput from 'components/FormDateInput'
import WithConfirmation from 'components/WithConfirmation'
import ExportTemplateFieldAliasDialog from './ExportTemplateFieldAliasDialog'
import { BillingPeriod } from 'data/BillingPeriod'
import SearchInput from './SearchInput'
import { userFromToken } from 'config/session'
import Customers from 'pages/Customers'
import {
  Maybe,
  maybeSwitch,
  maybeJust,
  maybeNothing,
  maybeMap,
  maybeToNull,
  maybeWithDefault,
} from 'types/Maybe'
import * as selectors from '../selectors'
import { ErrorLog } from '../reducers/errorsHistory'
import { limitErrorMessage } from '../utils'

const CustomSelectInput = withStyles(theme => ({
  input: {
    border: '0',
    color: theme.palette.primary.contrastText,
    ...theme.typography.h6,
    fontWeight: 'bold',
  },
}))(InputBase)

const CustomArrowDropDownIcon = (props: any) => (
  <ArrowDropDownIcon style={{ color: 'white' }} {...props} />
)

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface LeasingContractsByYear {
  year: number
  leasingContracts: LeasingContract[]
}
interface BillingPeriodsByYear {
  year: number
  billingPeriods: BillingPeriod[]
}

interface StateProps {
  drawerOpen: boolean
  showLeasingContractsByYear: (year: number) => boolean
  showLeasingContractDetails: (leasingContractId: number) => boolean
  showBillingPeriodsByYear: (year: number) => boolean
  showBillingPeriodDetails: (billingPeriodId: number) => boolean
  from: Maybe<Moment>
  to: Maybe<Moment>
  fetchingFrameContracts: boolean
  frameContracts: FrameContract[]
  fetchingLeasingContracts: boolean
  leasingContracts: LeasingContract[]
  leasingContractsByYear: LeasingContractsByYear[]
  fetchingBillingPeriods: boolean
  billingPeriods: BillingPeriod[]
  billingPeriodsByYear: BillingPeriodsByYear[]
  currentFrameContract: Maybe<FrameContract>
  exportingBilling: (billingPeriodId: number) => boolean
  errorLogs: ErrorLog[]
}

interface DispatchProps {
  toggleDrawer: () => void
  toggleShowLeasingContractsByYear: (year: number) => void
  toggleShowLeasingContractDetails: (leasingContractId: number) => void
  toggleShowBillingPeriodsByYear: (year: number) => void
  toggleShowBillingPeriodDetails: (billingPeriodId: number) => void
  setFrom: (from: Moment) => void
  setTo: (to: Moment) => void
  clearFrom: () => void
  clearTo: () => void
  fetchFrameContracts: () => void
  selectFrameContract: (selectedFrameContractId: number) => void
  openFrameContractDialog: () => void
  openLeasingContractDialog: () => void
  editFrameContract: (frameContract: FrameContract) => void
  editLeasingContract: (leasingContract: LeasingContract) => void
  editLeasingCategories: () => void
  openBillingPeriodDialog: (customerId: number) => void
  editBillingPeriod: (customerId: number, billingPeriod: BillingPeriod) => void
  exportBilling: (
    frameContractId: number,
    billingPeriodId: number,
    isFinal: boolean
  ) => void
  generateInvestmentFiles: (
    frameContractId: number,
    billingPeriodId: number
  ) => void
  uploadFile: (frameContractId: number, file: FileList) => void
  logout: () => void
  removeErrorLog: (index: number) => void
  clearErrorsHistory: () => void
  copyErrorLogToClipboard: (index: number) => void
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    WithStyles<
      | 'root'
      | 'appBar'
      | 'menuButton'
      | 'drawer'
      | 'drawerPaper'
      | 'scrollY'
      | 'drawerHeader'
      | 'content'
      | 'contentShift'
      | 'link'
      | 'select'
      | 'grow'
      | 'nested'
      | 'doubleNested'
      | 'menu'
      | 'listItemContainer'
      | 'listItem'
      | 'listSubheader'
      | 'listTwoActions'
    > {}

interface LayoutState {
  menuEl: any
  errorsHistoryEl: any
}

class Layout extends React.Component<Props> {
  public state: LayoutState = {
    menuEl: null,
    errorsHistoryEl: null,
  }

  public componentDidMount() {
    this.props.fetchFrameContracts()
  }

  private openMenu = (event: any) => {
    this.setState({ menuEl: event.currentTarget, errorsHistoryEl: null })
  }

  private openErrorsHistory = (event: any) => {
    this.setState({ menuEl: null, errorsHistoryEl: event.currentTarget })
  }

  private closeMenu = () => {
    this.setState({ menuEl: null })
  }

  private closeErrorsHistory = () => {
    this.setState({ errorsHistoryEl: null })
  }

  private renderAppBar() {
    const {
      token,
      classes,
      fetchingFrameContracts,
      frameContracts,
      currentFrameContract,
      selectFrameContract,
      openFrameContractDialog,
      toggleDrawer,
      logout,
      errorLogs,
      removeErrorLog,
      clearErrorsHistory,
      copyErrorLogToClipboard,
    } = this.props
    const { menuEl, errorsHistoryEl } = this.state
    const user = userFromToken(token)
    return (
      <React.Fragment>
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap={true}>
              econocom · MARS Lease
            </Typography>

            {!fetchingFrameContracts && (
              <FormControl className={classes.select}>
                <Select
                  value={maybeWithDefault(
                    maybeMap(currentFrameContract, fc => fc.id.toString()),
                    ''
                  )}
                  onChange={event => {
                    if (event.target.value === 'new-frame-contract') {
                      openFrameContractDialog()
                    } else {
                      selectFrameContract(Number(event.target.value))
                    }
                  }}
                  input={<CustomSelectInput />}
                  IconComponent={CustomArrowDropDownIcon}
                >
                  {frameContracts.map(frameContract => (
                    <MenuItem key={frameContract.id} value={frameContract.id}>
                      {frameContract.name}
                    </MenuItem>
                  ))}

                  <Divider />
                  <MenuItem value="new-frame-contract">
                    <ListItemText>New frame contract</ListItemText>
                  </MenuItem>
                </Select>
              </FormControl>
            )}

            <div className={classes.grow} />

            <SearchInput token={token} />
            <IconButton color="inherit" onClick={this.openErrorsHistory}>
              <ErrorIcon />
            </IconButton>
            <IconButton color="inherit" onClick={this.openMenu}>
              <AccountCircleIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Menu
          anchorEl={errorsHistoryEl}
          open={Boolean(errorsHistoryEl)}
          onClose={this.closeErrorsHistory}
          className={classes.menu}
        >
          {errorLogs.length === 0 && (
            <ListItem>
              <ListItemText primary="No errors" />
            </ListItem>
          )}
          {errorLogs.map((errorLog: ErrorLog, index: number) => (
            <ListItem key={index}>
              <ListItemText
                primary={limitErrorMessage(errorLog.message)}
                secondary={errorLog.createdAt.format('MMM D, YYYY, H:mm:ss')}
                className={classes.listTwoActions}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="copy"
                  onClick={() => copyErrorLogToClipboard(index)}
                >
                  <ContentCopyIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeErrorLog(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {errorLogs.length > 0 && <Divider light={true} />}
          {errorLogs.length > 0 && (
            <MenuItem onClick={() => clearErrorsHistory()}>
              <ListItemText>Clear all errors</ListItemText>
            </MenuItem>
          )}
        </Menu>
        <Menu
          anchorEl={menuEl}
          open={Boolean(menuEl)}
          onClose={this.closeMenu}
          className={classes.menu}
        >
          <ListItem>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary={user.name + ' ' + user.email}
              secondary={
                pluralize(user.roles.length, 'Role', 'Roles') +
                ': ' +
                user.roles.join(', ')
              }
            />
          </ListItem>
          <Divider light={true} />
          <MenuItem onClick={logout}>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }

  private renderDrawer() {
    const {
      token,
      from,
      to,
      setFrom,
      setTo,
      clearFrom,
      clearTo,
      location,
      classes,
      drawerOpen,
      fetchingFrameContracts,
      fetchingLeasingContracts,
      leasingContractsByYear,
      fetchingBillingPeriods,
      billingPeriodsByYear,
      showLeasingContractsByYear,
      showLeasingContractDetails,
      showBillingPeriodsByYear,
      showBillingPeriodDetails,
      toggleShowLeasingContractsByYear,
      toggleShowLeasingContractDetails,
      toggleShowBillingPeriodsByYear,
      toggleShowBillingPeriodDetails,
      openLeasingContractDialog,
      currentFrameContract,
      editFrameContract,
      editLeasingContract,
      editLeasingCategories,
      openBillingPeriodDialog,
      editBillingPeriod,
      exportingBilling,
      exportBilling,
      generateInvestmentFiles
    } = this.props

    return (
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box flex={1} className={classes.scrollY}>
          <ListSubheader classes={{ root: classes.listSubheader }}>
            Assets
          </ListSubheader>
          <Box display="flex">
            <Box p={1}>
              <FormDateInput
                value={from}
                label="From"
                onChange={setFrom}
                onClear={clearFrom}
                format={maybeJust('MMM Do YYYY')}
                required={false}
                disabled={false}
                validationErrors={[]}
              />
            </Box>
            <Box p={1}>
              <FormDateInput
                value={to}
                label="To"
                onChange={setTo}
                onClear={clearTo}
                format={maybeJust('MMM Do YYYY')}
                required={false}
                disabled={false}
                validationErrors={[]}
              />
            </Box>
          </Box>
          <List>
            {Array.from(deviceStatuses).map(status => {
              const path = routes.aggregated(status)
              return (
                <NavLink
                  key={status}
                  exact={true}
                  to={path}
                  className={classes.link}
                >
                  <ListItem button={true} selected={location.pathname === path}>
                    <ListItemText primary={capitalize(status)} />
                    {maybeSwitch(
                      currentFrameContract,
                      fc => (
                        <DevicesCount
                          token={token}
                          frameContractId={fc.id}
                          countInfo={{
                            type: 'leasing',
                            status,
                            leasingContractId: maybeNothing(),
                            from,
                            to,
                          }}
                        />
                      ),
                      () => null
                    )}
                  </ListItem>
                </NavLink>
              )
            })}
          </List>

          <Divider />

          <ListSubheader classes={{ root: classes.listSubheader }}>
            Leasing contracts
          </ListSubheader>
          {(fetchingFrameContracts || fetchingLeasingContracts) && (
            <div>
              <LoadingIndicator size={maybeJust(40)} />
            </div>
          )}
          {!fetchingFrameContracts && !fetchingLeasingContracts && (
            <List>
              {leasingContractsByYear.map(({ year, leasingContracts }) => (
                <React.Fragment key={year}>
                  <ListItem
                    button={true}
                    onClick={() => toggleShowLeasingContractsByYear(year)}
                  >
                    <ListItemIcon>
                      {showLeasingContractsByYear(year) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={year} />
                  </ListItem>
                  <Collapse
                    in={showLeasingContractsByYear(year)}
                    timeout="auto"
                    unmountOnExit={true}
                  >
                    {leasingContracts.map(
                      (leasingContract: LeasingContract) => (
                        <React.Fragment key={leasingContract.id}>
                          <ListItem
                            button={true}
                            className={classNames(
                              classes.listItemContainer,
                              classes.nested
                            )}
                            onClick={() =>
                              toggleShowLeasingContractDetails(
                                leasingContract.id
                              )
                            }
                          >
                            <div className={classes.listItem}>
                              <ListItemIcon>
                                {showLeasingContractDetails(
                                  leasingContract.id
                                ) ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  'SNI ' +
                                  maybeWithDefault(leasingContract.sni, '?')
                                }
                                secondary={maybeToNull(
                                  maybeMap(
                                    leasingContract.contractStartDate,
                                    date => date.format('MMMM YYYY')
                                  )
                                )}
                              />
                            </div>
                            <IconButton
                              onClick={(event: any) => {
                                event.stopPropagation()
                                editLeasingContract(leasingContract)
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </ListItem>
                          <Collapse
                            in={showLeasingContractDetails(leasingContract.id)}
                            timeout="auto"
                            unmountOnExit={true}
                          >
                            <List>
                              {Array.from(deviceStatuses).map(status => {
                                const path = routes.leasingContractDetails(
                                  maybeJust(leasingContract.id),
                                  maybeJust(status)
                                )
                                return (
                                  <NavLink
                                    key={status}
                                    exact={true}
                                    to={path}
                                    className={classes.link}
                                  >
                                    <ListItem
                                      button={true}
                                      selected={location.pathname === path}
                                      className={classes.doubleNested}
                                    >
                                      <ListItemText
                                        primary={capitalize(status)}
                                      />
                                      {maybeSwitch(
                                        currentFrameContract,
                                        fc => (
                                          <DevicesCount
                                            token={token}
                                            frameContractId={fc.id}
                                            countInfo={{
                                              type: 'leasing' as 'leasing',
                                              status,
                                              leasingContractId: maybeJust(
                                                leasingContract.id
                                              ),
                                              from: maybeNothing(),
                                              to: maybeNothing(),
                                            }}
                                          />
                                        ),
                                        () => null
                                      )}
                                    </ListItem>
                                  </NavLink>
                                )
                              })}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      )
                    )}
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          )}

          <Divider />

          <ListSubheader classes={{ root: classes.listSubheader }}>
            Billing periods
          </ListSubheader>
          {(fetchingFrameContracts || fetchingBillingPeriods) && (
            <div>
              <LoadingIndicator size={maybeJust(40)} />
            </div>
          )}
          {!fetchingFrameContracts && !fetchingBillingPeriods && (
            <List>
              {billingPeriodsByYear.map(({ year, billingPeriods }) => (
                <React.Fragment key={year}>
                  <ListItem
                    button={true}
                    onClick={() => toggleShowBillingPeriodsByYear(year)}
                  >
                    <ListItemIcon>
                      {showBillingPeriodsByYear(year) ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={year} />
                  </ListItem>
                  <Collapse
                    in={showBillingPeriodsByYear(year)}
                    timeout="auto"
                    unmountOnExit={true}
                  >
                    {billingPeriods.map(billingPeriod => (
                      <React.Fragment key={billingPeriod.id}>
                        <ListItem
                          button={true}
                          className={classNames(
                            classes.listItemContainer,
                            classes.nested
                          )}
                          onClick={() =>
                            toggleShowBillingPeriodDetails(billingPeriod.id)
                          }
                        >
                          <div className={classes.listItem}>
                            <ListItemIcon>
                              {showBillingPeriodDetails(billingPeriod.id) ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={billingPeriod.startDate.format(
                                'MMMM YYYY'
                              )}
                            />
                          </div>
                          <Box>
                            <IconButton
                              onClick={(event: any) => {
                                event.stopPropagation()
                                maybeSwitch(
                                  currentFrameContract,
                                  fc =>
                                    editBillingPeriod(
                                      fc.customerId,
                                      billingPeriod
                                    ),
                                  () => {}
                                )
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <WithConfirmation
                              dialogTitle="Generate Files"
                              onConfirm={[
                                {
                                  title: 'Pro Forma',
                                  onClick: maybeSwitch(
                                    currentFrameContract,
                                    fc => () =>
                                      exportBilling(
                                        fc.id,
                                        billingPeriod.id,
                                        false
                                      ),
                                    () => () => {}
                                  ),
                                },
                                {
                                  title: 'Investment Files',
                                  onClick: maybeSwitch(
                                    currentFrameContract,
                                    fc => () =>
                                      generateInvestmentFiles(
                                        fc.id,
                                        billingPeriod.id
                                      ),
                                    () => () => {}
                                  ),
                                },
                                {
                                  title: 'Final Billing',
                                  onClick: maybeSwitch(
                                    currentFrameContract,
                                    fc => () =>
                                      exportBilling(
                                        fc.id,
                                        billingPeriod.id,
                                        true
                                      ),
                                    () => () => {}
                                  ),
                                },
                              ]}
                              render={onClick => {
                                const isExporting = exportingBilling(
                                  billingPeriod.id
                                )
                                return (
                                  <IconButton
                                    onClick={(event: any) => {
                                      event.stopPropagation()
                                      onClick()
                                    }}
                                    disabled={isExporting}
                                  >
                                    {isExporting ? (
                                      <LoadingIndicator size={maybeJust(18)} />
                                    ) : (
                                      <EuroIcon />
                                    )}
                                  </IconButton>
                                )
                              }}
                            />
                          </Box>
                        </ListItem>
                        <Collapse
                          in={showBillingPeriodDetails(billingPeriod.id)}
                          timeout="auto"
                          unmountOnExit={true}
                        >
                          <List>
                            {Array.from(billingStatuses).map(status => {
                              const path = routes.billingPeriodDetails(
                                maybeJust(billingPeriod.id),
                                maybeJust(status)
                              )
                              return (
                                <NavLink
                                  key={status}
                                  exact={true}
                                  to={path}
                                  className={classes.link}
                                >
                                  <ListItem
                                    button={true}
                                    selected={location.pathname === path}
                                    className={classes.doubleNested}
                                  >
                                    <ListItemText
                                      primary={capitalize(status)}
                                    />
                                    {maybeSwitch(
                                      currentFrameContract,
                                      fc => (
                                        <DevicesCount
                                          token={token}
                                          frameContractId={fc.id}
                                          countInfo={{
                                            type: 'billing',
                                            billingPeriodId: billingPeriod.id,
                                            status,
                                          }}
                                        />
                                      ),
                                      () => null
                                    )}
                                  </ListItem>
                                </NavLink>
                              )
                            })}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>

        <Box flex={0}>
          <Divider />
          <ListSubheader classes={{ root: classes.listSubheader }}>
            Frame contract
          </ListSubheader>
          <List>
            <NavLink
              exact={true}
              to={routes.customers()}
              className={classes.link}
            >
              <ListItem
                button={true}
                selected={location.pathname === routes.customers()}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" />
              </ListItem>
            </NavLink>

            <ListItem button={true} onClick={openLeasingContractDialog}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="New leasing contract" />
            </ListItem>

            <ListItem
              button={true}
              onClick={maybeSwitch(
                currentFrameContract,
                fc => () => openBillingPeriodDialog(fc.customerId),
                () => () => {}
              )}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="New billing period" />
            </ListItem>

            <ListItem
              button={true}
              onClick={maybeSwitch(
                currentFrameContract,
                fc => () => editLeasingCategories(),
                () => () => {}
              )}
            >
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary="Leasing categories" />
            </ListItem>

            <ListItem
              button={true}
              onClick={() => {
                document.getElementById('answerFileInput').click()
              }}
            >
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText primary="Upload answer file" />
            </ListItem>

            <ListItem
              button={true}
              onClick={maybeSwitch(
                currentFrameContract,
                fc => () => editFrameContract(fc),
                () => () => {}
              )}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Frame contract settings" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    )
  }

  public render() {
    const {
      token,
      from,
      to,
      classes,
      drawerOpen,
      fetchingFrameContracts,
      fetchingLeasingContracts,
      fetchingBillingPeriods,
      leasingContracts,
      billingPeriods,
      currentFrameContract,
      uploadFile,
    } = this.props
    return (
      <div className={classes.root}>
        {this.renderAppBar()}
        {this.renderDrawer()}

        <main
          className={classNames(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          {fetchingFrameContracts && <LoadingIndicator size={maybeNothing()} />}
          {maybeSwitch(
            currentFrameContract,
            fc => (
              <Switch>
                <Redirect from="/" exact={true} to={routes.home()} />
                <Route
                  path={routes.customers()}
                  exact={true}
                  render={() => <Customers token={token} />}
                />
                {Array.from(deviceStatuses).map(status => (
                  <Route
                    key={status}
                    path={routes.aggregated(status)}
                    exact={true}
                    render={() => (
                      <Devices
                        token={token}
                        filterBy={{
                          type: 'leasing',
                          frameContractId: fc.id,
                          status: status,
                          leasingContractId: maybeNothing(),
                          from: from,
                          to: to,
                        }}
                      />
                    )}
                  />
                ))}
                {leasingContracts.map(leasingContract =>
                  Array.from(deviceStatuses).map(status => (
                    <Route
                      key={leasingContract.id.toString() + '-' + status}
                      path={routes.leasingContractDetails(
                        maybeJust(leasingContract.id),
                        maybeJust(status)
                      )}
                      exact={true}
                      render={() => (
                        <Devices
                          token={token}
                          filterBy={{
                            type: 'leasing',
                            frameContractId: fc.id,
                            status,
                            leasingContractId: maybeJust(leasingContract.id),
                            from: maybeNothing(),
                            to: maybeNothing(),
                          }}
                        />
                      )}
                    />
                  ))
                )}
                {(fetchingFrameContracts || fetchingLeasingContracts) && (
                  <Route
                    path={routes.leasingContractDetails(
                      maybeNothing(),
                      maybeNothing()
                    )}
                    render={() => <LoadingIndicator size={maybeNothing()} />}
                  />
                )}
                {billingPeriods.map(billingPeriod =>
                  Array.from(billingStatuses).map(status => (
                    <Route
                      key={billingPeriod.id.toString() + '-' + status}
                      path={routes.billingPeriodDetails(
                        maybeJust(billingPeriod.id),
                        maybeJust(status)
                      )}
                      exact={true}
                      render={() => (
                        <Devices
                          token={token}
                          filterBy={{
                            type: 'billing',
                            frameContractId: fc.id,
                            billingPeriodId: billingPeriod.id,
                            status,
                          }}
                        />
                      )}
                    />
                  ))
                )}
                {(fetchingFrameContracts || fetchingBillingPeriods) && (
                  <Route
                    path={routes.billingPeriodDetails(
                      maybeNothing(),
                      maybeNothing()
                    )}
                    render={() => <LoadingIndicator size={maybeNothing()} />}
                  />
                )}
                <Route render={() => <NotFound />} />
              </Switch>
            ),
            () => null
          )}
        </main>
        <FrameContractDialog token={token} />
        <LeasingContractDialog token={token} />
        <LeasingCategoriesDialog token={token} />
        <ExportTemplateDialog token={token} />
        <ExportTemplateFieldAliasDialog />
        <BillingPeriodDialog token={token} />
        <CustomerDialog token={token} />
        <DeviceDetailsDialog />
        <Snackbars />
        <input
          id="answerFileInput"
          type="file"
          name="answerFile"
          accept=".csv"
          multiple={true}
          style={{ visibility: 'hidden' }}
          onChange={e => {
            const file = e.target.files
            maybeSwitch(
              currentFrameContract,
              frameContract => {
                uploadFile(frameContract.id, file)
                e.target.value = ''
              },
              () => {}
            )
          }}
        />
      </div>
    )
  }
}

const drawerWidth = 350
const appBarHeight = 64

const styles = (theme: Theme) => ({
  root: {
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(3),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    top: 0,
    paddingTop: appBarHeight,
    width: drawerWidth,
    overflowY: 'hidden' as 'hidden',
  },
  scrollY: {
    overflowY: 'scroll' as 'scroll',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    position: 'fixed' as 'fixed',
    top: appBarHeight,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'scroll',
  },
  contentShift: {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: drawerWidth,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  select: {
    marginLeft: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  doubleNested: {
    paddingLeft: theme.spacing(8),
  },
  menu: {
    marginTop: 44,
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  listTwoActions: {
    paddingRight: 48,
  },
})

const mapStateToProps = (state: State): StateProps => {
  const leasingContracts = sortBy(
    state.leasingContracts.data,
    (leasingContract: LeasingContract): number =>
      maybeSwitch(
        leasingContract.contractStartDate,
        date => date.unix(),
        () => 0
      )
  ).reverse()
  const leasingContractsByYear = sortBy(
    map(
      groupBy(leasingContracts, (leasingContract: LeasingContract): number =>
        maybeSwitch(
          leasingContract.contractStartDate,
          date => date.year(),
          () => 0
        )
      ),
      (leasingContracts, year) => ({
        year: Number(year),
        leasingContracts,
      })
    ),
    ({ year }) => year
  ).reverse()

  const billingPeriods = sortBy(
    state.billingPeriods.data,
    (billingPeriod: BillingPeriod): number => billingPeriod.startDate.unix()
  ).reverse()
  const billingPeriodsByYear = sortBy(
    map(
      groupBy(billingPeriods, (billingPeriod: BillingPeriod): number =>
        billingPeriod.startDate.year()
      ),
      (billingPeriods, year) => ({ year: Number(year), billingPeriods })
    ),
    ({ year }) => year
  ).reverse()

  return {
    drawerOpen: state.app.drawerOpen,
    showLeasingContractsByYear: (year: number) =>
      state.app.showLeasingContractsByYear.has(year),
    showLeasingContractDetails: (leasingContractId: number) =>
      state.app.showLeasingContractDetails.has(leasingContractId),
    showBillingPeriodsByYear: (year: number) =>
      state.app.showBillingPeriodsByYear.has(year),
    showBillingPeriodDetails: (billingPeriodId: number) =>
      state.app.showBillingPeriodDetails.has(billingPeriodId),
    from: state.app.from,
    to: state.app.to,
    fetchingFrameContracts: state.frameContracts.fetching,
    frameContracts: state.frameContracts.data,
    fetchingLeasingContracts: state.leasingContracts.fetching,
    leasingContracts,
    leasingContractsByYear,
    fetchingBillingPeriods: state.billingPeriods.fetching,
    billingPeriods,
    billingPeriodsByYear,
    currentFrameContract: selectors.currentFrameContract(state),
    exportingBilling: (billingPeriodId: number) =>
      state.app.exportingBilling.has(billingPeriodId),
    errorLogs: state.errorsHistory.errors,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  toggleDrawer: () => actions.app.toggleDrawer(dispatch),
  toggleShowLeasingContractsByYear: (year: number) =>
    actions.app.toggleShowLeasingContractsByYear(dispatch, year),
  toggleShowLeasingContractDetails: (leasingContractId: number) =>
    actions.app.toggleShowLeasingContractDetails(dispatch, leasingContractId),
  toggleShowBillingPeriodsByYear: (year: number) =>
    actions.app.toggleShowBillingPeriodsByYear(dispatch, year),
  toggleShowBillingPeriodDetails: (billingPeriodId: number) =>
    actions.app.toggleShowBillingPeriodDetails(dispatch, billingPeriodId),
  setFrom: (from: Moment) => actions.app.setFrom(dispatch, from),
  setTo: (to: Moment) => actions.app.setTo(dispatch, to),
  clearFrom: () => actions.app.clearFrom(dispatch),
  clearTo: () => actions.app.clearTo(dispatch),
  fetchFrameContracts: () =>
    actions.frameContracts.fetchAll(ownProps.token, dispatch, ownProps.history),
  selectFrameContract: (selectedFrameContractId: number) =>
    actions.frameContracts.selectFrameContract(
      ownProps.token,
      dispatch,
      ownProps.history,
      selectedFrameContractId
    ),
  openFrameContractDialog: () =>
    actions.frameContractDialog.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history,
      maybeNothing()
    ),
  openLeasingContractDialog: () =>
    actions.leasingContractDialog.openDialog(dispatch, maybeNothing()),
  editFrameContract: (frameContract: FrameContract) =>
    actions.frameContractDialog.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history,
      maybeJust(frameContract)
    ),
  editLeasingContract: (leasingContract: LeasingContract) =>
    actions.leasingContractDialog.openDialog(
      dispatch,
      maybeJust(leasingContract)
    ),
  editLeasingCategories: () =>
    actions.leasingCategoriesDialog.openDialog(dispatch),
  openBillingPeriodDialog: (customerId: number) =>
    actions.billingPeriodDialog.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history,
      customerId,
      maybeNothing()
    ),
  editBillingPeriod: (customerId: number, billingPeriod: BillingPeriod) =>
    actions.billingPeriodDialog.openDialog(
      ownProps.token,
      dispatch,
      ownProps.history,
      customerId,
      maybeJust(billingPeriod)
    ),
  exportBilling: (
    frameContractId: number,
    billingPeriodId: number,
    isFinal: boolean
  ) =>
    actions.app.exportBilling(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId,
      billingPeriodId,
      isFinal
    ),
  generateInvestmentFiles: (
    frameContractId: number,
    billingPeriodId: number
  ) =>
    actions.app.generateInvestmentFiles(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId,
      billingPeriodId
    ),
  uploadFile: (frameContractId: number, file: FileList) =>
    actions.app.uploadAnswerFile(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId,
      file
    ),
  logout: () => actions.app.logout(dispatch),
  removeErrorLog: (index: number) =>
    actions.errorsHistory.removeErrorLog(dispatch, index),
  clearErrorsHistory: () => actions.errorsHistory.clearErrorsHistory(dispatch),
  copyErrorLogToClipboard: (index: number) =>
    actions.errorsHistory.copyErrorLogToClipboard(dispatch, index),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout))
)
