import * as React from 'react'
import { State } from 'reducers'
import { Dispatch } from 'redux'
import * as actions from 'actions/billingPeriodDialog'
import { RouteChildrenProps, withRouter } from 'react-router'
import {
  Maybe,
  isJust,
  maybeSwitch,
  isNothing,
  maybeNothing,
  maybeFind,
} from 'types/Maybe'
import { connect } from 'react-redux'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
} from '@material-ui/core'
import DeletableButton from 'components/DeletableButton'
import { BillingPeriodParams } from 'requests/BillingPeriod'
import * as selectors from '../selectors'
import { FrameContract } from 'data/FrameContract'
import FormTextInput from 'components/FormTextInput'
import { CustomerEntity } from 'data/CustomerEntity'
import LoadingIndicator from 'components/LoadingIndicator'
import { ValidationErrors } from 'requests/errors'
import { BillingPeriodValuesByCustomerEntity } from 'data/BillingPeriod'
import { Moment } from 'moment'
import Columns from 'components/Columns'
import FormDateInput from 'components/FormDateInput'
import { getValuesOrDefault } from 'reducers/billingPeriodDialog'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  fetchingCustomerEntities: boolean
  customerEntities: CustomerEntity[]
  open: boolean
  saving: boolean
  isDeletable: boolean
  currentFrameContract: Maybe<FrameContract>
  updatingId: Maybe<number>
  valuesByCustomerEntity: {
    [customerEntityId: number]: BillingPeriodValuesByCustomerEntity
  }
  validationErrors: ValidationErrors
}

interface DispatchProps {
  closeDialog: () => void
  createBillingPeriod: (
    frameContractId: number,
    params: BillingPeriodParams,
    customerEntityIds: number[]
  ) => void
  updateBillingPeriod: (
    billingPeriodId: number,
    frameContractId: number,
    params: BillingPeriodParams,
    customerEntityIds: number[]
  ) => void
  checkIfIsDeletable: (billingPeriodId: number) => void
  deleteBillingPeriod: (billingPeriodId: number) => void
  setPurchaseOrder: (customerEntityId: number, purchaseOrder: string) => void
  setRentInvoiceNumber: (
    customerEntityId: number,
    rentInvoiceNumber: string
  ) => void
  setRentInvoiceDate: (
    customerEntityId: number,
    rentInvoiceDate: Moment
  ) => void
  setExitInvoiceNumber: (
    customerEntityId: number,
    exitInvoiceNumber: string
  ) => void
  setExitInvoiceDate: (
    customerEntityId: number,
    exitInvoiceDate: Moment
  ) => void
  setDamagesInvoiceNumber: (
    customerEntityId: number,
    damagesInvoiceNumber: string
  ) => void
  setDamagesInvoiceDate: (
    customerEntityId: number,
    damagesInvoiceDate: Moment
  ) => void
  clearPurchaseOrder: (customerEntityId: number) => void
  clearRentInvoiceNumber: (customerEntityId: number) => void
  clearRentInvoiceDate: (customerEntityId: number) => void
  clearExitInvoiceNumber: (customerEntityId: number) => void
  clearExitInvoiceDate: (customerEntityId: number) => void
  clearDamagesInvoiceNumber: (customerEntityId: number) => void
  clearDamagesInvoiceDate: (customerEntityId: number) => void
}

interface Props extends StateProps, DispatchProps {}

class BillingPeriodDialog extends React.PureComponent<Props> {
  public render() {
    const {
      fetchingCustomerEntities,
      customerEntities,
      open,
      saving,
      closeDialog,
      currentFrameContract,
      updatingId,
      createBillingPeriod,
      updateBillingPeriod,
      isDeletable,
      checkIfIsDeletable,
      deleteBillingPeriod,
      valuesByCustomerEntity,
      setPurchaseOrder,
      setRentInvoiceNumber,
      setRentInvoiceDate,
      setExitInvoiceNumber,
      setExitInvoiceDate,
      setDamagesInvoiceNumber,
      setDamagesInvoiceDate,
      clearPurchaseOrder,
      clearRentInvoiceNumber,
      clearRentInvoiceDate,
      clearExitInvoiceNumber,
      clearExitInvoiceDate,
      clearDamagesInvoiceNumber,
      clearDamagesInvoiceDate,
      validationErrors,
    } = this.props
    const isEditing = isJust(updatingId)

    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={closeDialog}>
        {fetchingCustomerEntities ? (
          <Box p={5}>
            <LoadingIndicator size={maybeNothing()} />
          </Box>
        ) : (
          <React.Fragment>
            <DialogTitle>
              {isEditing ? 'Edit billing period' : 'New billing period'}
            </DialogTitle>
            <DialogContent>
              {maybeSwitch(
                currentFrameContract,
                frameContract => (
                  <React.Fragment>
                    {frameContract.customerEntityIds.map(customerEntityId =>
                      maybeSwitch(
                        maybeFind(
                          customerEntities,
                          customerEntity =>
                            customerEntity.id === customerEntityId
                        ),
                        customerEntity => {
                          const {
                            purchaseOrder,
                            rentInvoiceNumber,
                            rentInvoiceDate,
                            exitInvoiceNumber,
                            exitInvoiceDate,
                            damagesInvoiceNumber,
                            damagesInvoiceDate,
                          } = getValuesOrDefault(
                            valuesByCustomerEntity[customerEntity.id]
                          )
                          return (
                            <React.Fragment key={customerEntity.id}>
                              <Typography variant="subtitle2">
                                {customerEntity.entityName}
                              </Typography>
                              <Columns columns={2}>
                                <FormTextInput
                                  value={purchaseOrder}
                                  label="Purchase Order"
                                  required={true}
                                  onChange={(value: string) =>
                                    setPurchaseOrder(customerEntity.id, value)
                                  }
                                  onClear={() =>
                                    clearPurchaseOrder(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.purchaseOrder'
                                    ]
                                  }
                                  disabled={false}
                                />
                              </Columns>
                              <Columns>
                                <FormTextInput
                                  value={rentInvoiceNumber}
                                  label="Rent Invoice Number"
                                  required={false}
                                  onChange={(value: string) =>
                                    setRentInvoiceNumber(
                                      customerEntity.id,
                                      value
                                    )
                                  }
                                  onClear={() =>
                                    clearRentInvoiceNumber(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.rentInvoiceNumber'
                                    ]
                                  }
                                  disabled={false}
                                />
                                <FormDateInput
                                  value={rentInvoiceDate}
                                  label="Rent Invoice Date"
                                  required={false}
                                  onChange={(value: Moment) =>
                                    setRentInvoiceDate(customerEntity.id, value)
                                  }
                                  onClear={() =>
                                    clearRentInvoiceDate(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.rentInvoiceDate'
                                    ]
                                  }
                                  disabled={false}
                                  format={maybeNothing()}
                                />
                              </Columns>
                              <Columns>
                                <FormTextInput
                                  value={exitInvoiceNumber}
                                  label="Exit Invoice Number"
                                  required={false}
                                  onChange={(value: string) =>
                                    setExitInvoiceNumber(
                                      customerEntity.id,
                                      value
                                    )
                                  }
                                  onClear={() =>
                                    clearExitInvoiceNumber(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.exitInvoiceNumber'
                                    ]
                                  }
                                  disabled={false}
                                />
                                <FormDateInput
                                  value={exitInvoiceDate}
                                  label="Exit Invoice Date"
                                  required={false}
                                  onChange={(value: Moment) =>
                                    setExitInvoiceDate(
                                      customerEntity.id,
                                      value
                                    )
                                  }
                                  onClear={() =>
                                    clearExitInvoiceDate(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.exitInvoiceDate'
                                    ]
                                  }
                                  disabled={false}
                                  format={maybeNothing()}
                                />
                              </Columns>
                              <Columns>
                                <FormTextInput
                                  value={damagesInvoiceNumber}
                                  label="Damages Invoice Number"
                                  required={false}
                                  onChange={(value: string) =>
                                    setDamagesInvoiceNumber(
                                      customerEntity.id,
                                      value
                                    )
                                  }
                                  onClear={() =>
                                    clearDamagesInvoiceNumber(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.damagesInvoiceNumber'
                                    ]
                                  }
                                  disabled={false}
                                />
                                <FormDateInput
                                  value={damagesInvoiceDate}
                                  label="Damages Invoice Date"
                                  required={false}
                                  onChange={(value: Moment) =>
                                    setDamagesInvoiceDate(
                                      customerEntity.id,
                                      value
                                    )
                                  }
                                  onClear={() =>
                                    clearDamagesInvoiceDate(customerEntity.id)
                                  }
                                  validationErrors={
                                    validationErrors[
                                      'valuesByCustomerEntity.' +
                                        customerEntity.id.toString() +
                                        '.damagesInvoiceDate'
                                    ]
                                  }
                                  disabled={false}
                                  format={maybeNothing()}
                                />
                              </Columns>
                            </React.Fragment>
                          )
                        },
                        () => null
                      )
                    )}
                  </React.Fragment>
                ),
                () => null
              )}
            </DialogContent>
            <DialogActions>
              {isEditing &&
                maybeSwitch(
                  updatingId,
                  id => (
                    <React.Fragment>
                      <DeletableButton
                        name="billing dialog"
                        checkIfIsDeletable={() => checkIfIsDeletable(id)}
                        isDeletable={isDeletable}
                        onConfirm={() => deleteBillingPeriod(id)}
                      />
                      <Box flex={1} />
                    </React.Fragment>
                  ),
                  () => null
                )}

              <Button onClick={closeDialog} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={maybeSwitch(
                  currentFrameContract,
                  fc => (event: any) => {
                    const params = {
                      valuesByCustomerEntity,
                    }
                    maybeSwitch(
                      updatingId,
                      id =>
                        updateBillingPeriod(
                          id,
                          fc.id,
                          params,
                          fc.customerEntityIds
                        ),
                      () =>
                        createBillingPeriod(fc.id, params, fc.customerEntityIds)
                    )
                  },
                  () => (event: any) => {}
                )}
                color="primary"
                disabled={isNothing(currentFrameContract) || saving}
              >
                {isEditing ? 'Save' : 'Create'}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  fetchingCustomerEntities: state.customerEntities.fetching,
  customerEntities: state.customerEntities.data,
  open: state.billingPeriodDialog.open,
  saving: state.billingPeriodDialog.saving,
  isDeletable: state.billingPeriodDialog.isDeletable,
  currentFrameContract: selectors.currentFrameContract(state),
  updatingId: state.billingPeriodDialog.updatingId,
  valuesByCustomerEntity: state.billingPeriodDialog.valuesByCustomerEntity,
  validationErrors: state.billingPeriodDialog.validationErrors,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
  createBillingPeriod: (
    frameContractId: number,
    params: BillingPeriodParams,
    customerEntityIds: number[]
  ) =>
    actions.createBillingPeriod(
      ownProps.token,
      dispatch,
      ownProps.history,
      frameContractId,
      params,
      customerEntityIds
    ),
  updateBillingPeriod: (
    billingPeriodId: number,
    frameContractId: number,
    params: BillingPeriodParams,
    customerEntityIds: number[]
  ) =>
    actions.updateBillingPeriod(
      ownProps.token,
      dispatch,
      ownProps.history,
      billingPeriodId,
      frameContractId,
      params,
      customerEntityIds
    ),
  checkIfIsDeletable: (billingPeriodId: number) =>
    actions.checkIfIsDeletable(
      ownProps.token,
      dispatch,
      ownProps.history,
      billingPeriodId
    ),
  deleteBillingPeriod: (billingPeriodId: number) =>
    actions.deleteBillingPeriod(
      ownProps.token,
      dispatch,
      ownProps.history,
      billingPeriodId
    ),
  setPurchaseOrder: (customerEntityId: number, purchaseOrder: string) =>
    actions.setPurchaseOrder(dispatch, customerEntityId, purchaseOrder),
  clearPurchaseOrder: (customerEntityId: number) =>
    actions.clearPurchaseOrder(dispatch, customerEntityId),
  setRentInvoiceNumber: (customerEntityId: number, rentInvoiceNumber: string) =>
    actions.setRentInvoiceNumber(dispatch, customerEntityId, rentInvoiceNumber),
  clearRentInvoiceNumber: (customerEntityId: number) =>
    actions.clearRentInvoiceNumber(dispatch, customerEntityId),
  setRentInvoiceDate: (customerEntityId: number, rentInvoiceDate: Moment) =>
    actions.setRentInvoiceDate(dispatch, customerEntityId, rentInvoiceDate),
  clearRentInvoiceDate: (customerEntityId: number) =>
    actions.clearRentInvoiceDate(dispatch, customerEntityId),
  setExitInvoiceNumber: (
    customerEntityId: number,
    exitInvoiceNumber: string
  ) =>
    actions.setExitInvoiceNumber(
      dispatch,
      customerEntityId,
      exitInvoiceNumber
    ),
  clearExitInvoiceNumber: (customerEntityId: number) =>
    actions.clearExitInvoiceNumber(dispatch, customerEntityId),
  setExitInvoiceDate: (
    customerEntityId: number,
    exitInvoiceDate: Moment
  ) =>
    actions.setExitInvoiceDate(
      dispatch,
      customerEntityId,
      exitInvoiceDate
    ),
  clearExitInvoiceDate: (customerEntityId: number) =>
    actions.clearExitInvoiceDate(dispatch, customerEntityId),
  setDamagesInvoiceNumber: (
    customerEntityId: number,
    damagesInvoiceNumber: string
  ) =>
    actions.setDamagesInvoiceNumber(
      dispatch,
      customerEntityId,
      damagesInvoiceNumber
    ),
  clearDamagesInvoiceNumber: (customerEntityId: number) =>
    actions.clearDamagesInvoiceNumber(dispatch, customerEntityId),
  setDamagesInvoiceDate: (
    customerEntityId: number,
    damagesInvoiceDate: Moment
  ) =>
    actions.setDamagesInvoiceDate(
      dispatch,
      customerEntityId,
      damagesInvoiceDate
    ),
  clearDamagesInvoiceDate: (customerEntityId: number) =>
    actions.clearDamagesInvoiceDate(dispatch, customerEntityId),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BillingPeriodDialog)
)
