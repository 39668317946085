import * as React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Moment } from 'moment'
import { DeviceStatus } from '../data/DeviceStatus'
import { BillingStatus } from '../data/BillingStatus'
import StatusChip from './StatusChip'
import { Maybe, maybeWithDefault, maybeMap, maybeSwitch } from 'types/Maybe'

export type Value =
  | { type: 'string'; value: Maybe<string> }
  | { type: 'integer'; value: Maybe<number>; unit: Maybe<string> }
  | { type: 'euro'; value: Maybe<number> }
  | { type: 'date'; value: Maybe<Moment> }
  | { type: 'status'; value: Maybe<DeviceStatus> }
  | { type: 'billingStatus'; value: Maybe<BillingStatus> }

interface OwnProps {
  field: string
  value: Value
}

const formatEuro = (value: number): string => {
  const res = value.toFixed(2)

  let [n, d] = res.split('.')
  const regex = /(\d+)(\d{3})/
  while (regex.test(n)) {
    n = n.replace(regex, '$1,$2')
  }
  return '€' + n.toString() + '.' + d.toString()
}

const DEFAULT_VALUE = '?'

const formatValue = (value: Value): string | React.ReactElement => {
  switch (value.type) {
    case 'string':
      return maybeWithDefault(value.value, DEFAULT_VALUE)
    case 'integer':
      return maybeWithDefault(
        maybeMap(
          value.value,
          v => v.toString() + maybeWithDefault(value.unit, '')
        ),
        DEFAULT_VALUE
      )
    case 'euro':
      return maybeWithDefault(maybeMap(value.value, formatEuro), DEFAULT_VALUE)
    case 'date':
      return maybeWithDefault(
        maybeMap(value.value, v => v.format('MMMM Do YYYY')),
        DEFAULT_VALUE
      )
    case 'status':
      return maybeSwitch(
        value.value,
        status =>
          <StatusChip statusInfo={{ type: 'deviceStatus', status }} /> as any,
        () => DEFAULT_VALUE
      )
    case 'billingStatus':
      return maybeSwitch(
        value.value,
        status => <StatusChip statusInfo={{ type: 'billingStatus', status }} />,
        () => DEFAULT_VALUE
      )
  }
}

export class FieldValue extends React.PureComponent<OwnProps> {
  public render() {
    const { field, value } = this.props
    return (
      <Box mb={3} display="flex" flex={1} flexDirection="column">
        <Typography variant="subtitle1" color="textSecondary">
          {field}
        </Typography>
        <Typography variant="body1" component="div">
          {formatValue(value)}
        </Typography>
      </Box>
    )
  }
}
