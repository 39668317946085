import { Dispatch } from 'redux'
import * as requests from '../requests/ExportTemplate'
import { History } from 'history'
import { handleError } from './errors'

interface FetchExportFieldsRequestAction {
  type: 'FETCH_EXPORT_FIELDS_REQUEST'
}
interface FetchExportFieldsSuccessAction {
  type: 'FETCH_EXPORT_FIELDS_SUCCESS'
  exportFields: string[]
}
interface FetchExportFieldsErrorAction {
  type: 'FETCH_EXPORT_FIELDS_ERROR'
}

export type ExportFieldsAction =
  | FetchExportFieldsRequestAction
  | FetchExportFieldsSuccessAction
  | FetchExportFieldsErrorAction

const fetchExportFieldsRequestAction = (): FetchExportFieldsRequestAction => ({
  type: 'FETCH_EXPORT_FIELDS_REQUEST',
})
const fetchExportFieldsSuccessAction = (
  exportFields: string[]
): FetchExportFieldsSuccessAction => ({
  type: 'FETCH_EXPORT_FIELDS_SUCCESS',
  exportFields,
})
const fetchExportFieldsErrorAction = (): FetchExportFieldsErrorAction => ({
  type: 'FETCH_EXPORT_FIELDS_ERROR',
})

export const fetchAll = async (token: string,dispatch: Dispatch, history: History) => {
  dispatch(fetchExportFieldsRequestAction())
  try {
    const exportFields = await requests.fetchFields(token)
    dispatch(fetchExportFieldsSuccessAction(exportFields))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchExportFieldsErrorAction,
      'display_error'
    )
  }
}
