import { DeviceStatus } from '../data/DeviceStatus'
import { BillingStatus } from '../data/BillingStatus'
import { Order } from 'components/DeviceTable'
import { Moment } from 'moment'
import { toQueryParams } from 'utils'
import { Maybe, maybeToNull } from 'types/Maybe'

export const customers = () => '/customers'
export const customer = (customerId: number) =>
  customers() + '/' + customerId.toString()
export const customerEntities = (customerId: number) =>
  customer(customerId) + '/entities'

export const frameContracts = () => '/frame-contracts'
export const frameContract = (frameContractId: number) =>
  frameContracts() + '/' + frameContractId.toString()
export const frameContractIsDeletable = (frameContractId: number) =>
  frameContracts() + '/isDeletable?frameContract=' + frameContractId.toString()

export const leasingContracts = () => '/leasing-contracts'
export const leasingContractsByFrameContractId = (frameContractId: number) =>
  leasingContracts() + toQueryParams({ frameContract: frameContractId })
export const leasingContract = (leasingContractId: number) =>
  leasingContracts() + '/' + leasingContractId.toString()
export const leasingContractIsDeletable = (leasingContractId: number) =>
  leasingContracts() +
  '/isDeletable?leasingContract=' +
  leasingContractId.toString()

export const leasingCategories = () => '/leasing-categories'
export const leasingCategoriesByFrameContractId = (frameContractId: number) =>
  leasingCategories() + toQueryParams({ frameContract: frameContractId })
export const leasingCategory = (leasingCategoryId: number) =>
  leasingCategories() + '/' + leasingCategoryId.toString()
export const copyLeasingCategory = (leasingCategoryId: number) =>
  leasingCategory(leasingCategoryId) + '/copy'

export const billingPeriods = () => '/billing-periods'
export const billingPeriod = (billingPeriodId: number) =>
  billingPeriods() + '/' + billingPeriodId.toString()
export const billingPeriodsByFrameContractId = (frameContractId: number) =>
  billingPeriods() + toQueryParams({ frameContract: frameContractId })
export const billingPeriodIsDeletable = (billingPeriodId: number) =>
  billingPeriods() + '/isDeletable?billingPeriod=' + billingPeriodId.toString()

export const devices = () => '/devices'
export const deviceDetails = (id: string) => devices()  + '/' + id
export const deviceSearch = (frameContractId: number, query: string) =>
  devices() + toQueryParams({ frameContract: frameContractId, search: query })

export type CountParams = CountDevicesParams | CountBillingParams

interface CountDevicesParams {
  type: 'leasing'
  frameContractId: number
  status: DeviceStatus
  leasingContractId: Maybe<number>
  from: Maybe<Moment>
  to: Maybe<Moment>
}

interface CountBillingParams {
  type: 'billing'
  frameContractId: number
  billingPeriodId: number
  status: BillingStatus
}

export interface FilterDevicesParams {
  filterBy: CountParams
  page: number
  rowsPerPage: number
  order: Order
  orderBy: string
}

export const filterDevices = (params: FilterDevicesParams) =>
  devices() +
  toQueryParams({
    ...getParams(params.filterBy),
    count: params.rowsPerPage,
    page: params.page,
    orderBy: params.orderBy,
    order: params.order,
  })

const getParams = (params: CountParams) => {
  switch (params.type) {
    case 'leasing':
      return {
        frameContract: params.frameContractId,
        status: params.status,
        originalLeasingContract: maybeToNull(params.leasingContractId),
        from: maybeToNull(params.from),
        to: maybeToNull(params.to),
      }
    case 'billing':
      return {
        frameContract: params.frameContractId,
        billingPeriod: params.billingPeriodId,
        billingStatus: params.status,
      }
  }
}

export const countDevices = (params: CountParams) =>
  devices() + '/count' + toQueryParams(getParams(params))

export const exportTemplates = () => '/ExportTemplates'
export const exportTemplate = (exportTemplateId: number) =>
  exportTemplates() + '/' + exportTemplateId.toString()
export const exportView = () => '/export'
export const exportFields = () => exportView() + '/fields'
export const exportBilling = () => exportView() + '/billing'
export const generateInvestmentFiles = () => exportView() + '/investment'
export const uploadAnswerFile = (frameContractId: number) => '/import/billing' + toQueryParams({frameContract: frameContractId})