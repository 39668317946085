import { downloadFilePostRequest, uploadCsvPostRequest } from './base'
import * as endpoints from './endpoints'

export const generateInvestmentFiles = async (
  token: string,
  frameContractId: number,
  billingPeriodId: number
) =>
  downloadFilePostRequest(
    token,
    endpoints.generateInvestmentFiles(),
    { frameContract: frameContractId, billingPeriod: billingPeriodId },
    `sni-fc${frameContractId}-billedAt${billingPeriodId}`,
    'zip'
  )

export const exportBilling = async (
  token: string,
  frameContractId: number,
  billingPeriodId: number,
  isFinal: boolean
) =>
  downloadFilePostRequest(
    token,
    endpoints.exportBilling(),
    { frameContract: frameContractId, billingPeriod: billingPeriodId, isFinal },
    `${isFinal ? 'final-' : ''}billing-fc${frameContractId}-billedAt${billingPeriodId}`,
    'zip'
  )

export const uploadAnswerFile = async (
  token: string,
  frameContractId: number,
  file: FileList
) =>
  uploadCsvPostRequest(token, endpoints.uploadAnswerFile(frameContractId), file)
