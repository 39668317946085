import * as React from 'react'
import {
  Dialog,
  Box,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from '@material-ui/core'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { State } from '../reducers'
import * as actions from '../actions/deviceDetailsDialog'
import { DeviceDetails } from 'data/Device'
import LoadingIndicator from 'components/LoadingIndicator'
import { FieldValue } from 'components/FieldValue'
import Columns from 'components/Columns'
import { maybeJust, Maybe, maybeSwitch, maybeNothing } from 'types/Maybe'

interface OwnProps {}

interface StateProps {
  open: boolean
  fetchingDetails: boolean
  deviceDetails: Maybe<DeviceDetails>
}

interface DispatchProps {
  closeDialog: () => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class DeviceDetailsDialog extends React.PureComponent<Props> {
  public render() {
    const { open, closeDialog, fetchingDetails, deviceDetails } = this.props
    return (
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={closeDialog}>
        {fetchingDetails ? (
          <Box p={5}>
            <LoadingIndicator size={maybeNothing()} />
          </Box>
        ) : (
          <React.Fragment>
            <DialogTitle>Device details</DialogTitle>

            {open &&
              maybeSwitch(
                deviceDetails,
                dd => (
                  <DialogContent>
                    <Columns>
                      <FieldValue
                        field="Name"
                        value={{
                          type: 'string',
                          value: maybeJust(dd.name),
                        }}
                      />
                      <FieldValue
                        field="Model"
                        value={{ type: 'string', value: dd.model }}
                      />
                      <FieldValue
                        field="Status"
                        value={{
                          type: 'status',
                          value: maybeJust(dd.status),
                        }}
                      />
                    </Columns>
                    <Columns columns={3}>
                      <FieldValue
                        field="Delivery date"
                        value={{ type: 'date', value: dd.deliveryDate }}
                      />
                      <FieldValue
                        field="Close date"
                        value={{ type: 'date', value: dd.closeDate }}
                      />
                    </Columns>
                    <Columns columns={3}>
                      <FieldValue
                        field="Warranty start date"
                        value={{
                          type: 'date',
                          value: dd.warrantyStartDate,
                        }}
                      />
                      <FieldValue
                        field="Warranty end date"
                        value={{
                          type: 'date',
                          value: dd.warrantyEndDate,
                        }}
                      />
                    </Columns>
                    <Columns columns={3}>
                      <FieldValue
                        field="Contract start date"
                        value={{
                          type: 'date',
                          value: dd.leasingStartDate,
                        }}
                      />
                      <FieldValue
                        field="Contract end date"
                        value={{
                          type: 'date',
                          value: dd.leasingEndDate,
                        }}
                      />
                    </Columns>
                    <Columns>
                      <FieldValue
                        field="Original SNI"
                        value={{
                          type: 'string',
                          value: dd.originalSNIContract,
                        }}
                      />
                      <FieldValue
                        field="Current SNI"
                        value={{
                          type: 'string',
                          value: dd.currentSNIContract,
                        }}
                      />
                      <FieldValue
                        field="Owner name"
                        value={{
                          type: 'string',
                          value: maybeJust(dd.ownerName),
                        }}
                      />
                    </Columns>
                    <Columns columns={3}>
                      <FieldValue
                        field="Purchase option"
                        value={{
                          type: 'euro',
                          value: dd.purchaseOption,
                        }}
                      />
                      <FieldValue
                        field="Purchase option extension discount"
                        value={{
                          type: 'euro',
                          value: dd.purchaseOptionExtensionDiscount,
                        }}
                      />
                    </Columns>
                    <Columns>
                      <FieldValue
                        field="Leasing rent"
                        value={{
                          type: 'euro',
                          value: dd.leasingRent,
                        }}
                      />
                      <FieldValue
                        field="Leasing rent extension discount"
                        value={{
                          type: 'euro',
                          value: dd.leasingRentExtensionDiscount,
                        }}
                      />
                      <FieldValue
                        field="Leasing rent extension value"
                        value={{
                          type: 'euro',
                          value: dd.leasingRentExtensionValue,
                        }}
                      />
                    </Columns>
                    <Columns>
                      <FieldValue
                        field="Residual value"
                        value={{
                          type: 'euro',
                          value: dd.residualValue,
                        }}
                      />
                      <FieldValue
                        field="Anticipated repurchase value"
                        value={{
                          type: 'euro',
                          value: dd.anticipatedRepurchaseValue,
                        }}
                      />
                      <FieldValue
                        field="Anticipated return value"
                        value={{
                          type: 'euro',
                          value: dd.anticipatedReturnValue,
                        }}
                      />
                    </Columns>
                  </DialogContent>
                ),
                () => null
              )}

            <DialogActions>
              <Button onClick={closeDialog} color="secondary">
                Close
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = (state: State): StateProps => ({
  open: state.deviceDetailsDialog.open,
  fetchingDetails: state.deviceDetailsDialog.fetching,
  deviceDetails: state.deviceDetailsDialog.data,
})

const mapDispatchProps = (dispatch: Dispatch): DispatchProps => ({
  closeDialog: () => actions.closeDialog(dispatch),
})

export default connect(mapStateToProps, mapDispatchProps)(DeviceDetailsDialog)
