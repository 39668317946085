import { JsonDecoder } from 'ts.data.json'
import { LeasingCategory, decoder } from '../data/LeasingCategory'
import { getRequest, patchRequest, postRequest, deleteRequest } from './base'
import { omit } from 'lodash'
import { requireField, ValidationErrors } from './errors'
import * as endpoints from './endpoints'
import { Moment } from 'moment'
import { Maybe, maybeToNull } from 'types/Maybe'

export const fetchByFrameContractId = async (
  token: string,
  frameContractId: number
): Promise<LeasingCategory[]> =>
  getRequest(
    token,
    endpoints.leasingCategoriesByFrameContractId(frameContractId),
    JsonDecoder.array(decoder, 'LeasingCategory[]')
  )

export interface LeasingCategoryParams {
  name: Maybe<string>
  description: Maybe<string>
  frameContractId: Maybe<number>
  numberOfLeasingPeriods: Maybe<number>
  leasingCoefficient: Maybe<number>
  purchaseOption: Maybe<number>
  purchaseOptionUser: Maybe<number>
  normalLeasingPercentage: Maybe<number>
  extensionLeasingPercentage: Maybe<number>
  earlyPurchaseReductionPercentage: Maybe<number>
  extensionPurchaseReductionPercentage: Maybe<number>
  earlyReturnReductionPercentage: Maybe<number>
  reportingCategory: Maybe<string>
  billingCategory: Maybe<string>
  validFrom: Maybe<Moment>
  validTo: Maybe<Moment>
  // financialRate: Maybe<number>
  // endOfLifeProRation: Maybe<number>
  pao6Percentage: Maybe<number>
}

type Mode = 'create' | 'update'

const encodeParams = (mode: Mode, params: LeasingCategoryParams) => {
  const fullParams = {
    name: maybeToNull(params.name),
    description: maybeToNull(params.description),
    frameContract: maybeToNull(params.frameContractId),
    numberOfLeasingPeriods: maybeToNull(params.numberOfLeasingPeriods),
    leasingCoefficient: maybeToNull(params.leasingCoefficient),
    purchaseOption: maybeToNull(params.purchaseOption),
    purchaseOptionUser: maybeToNull(params.purchaseOptionUser),
    normalLeasingPercentage: maybeToNull(params.normalLeasingPercentage),
    extensionLeasingPercentage: maybeToNull(params.extensionLeasingPercentage),
    earlyPurchaseReductionPercentage: maybeToNull(
      params.earlyPurchaseReductionPercentage
    ),
    extensionPurchaseReductionPercentage: maybeToNull(
      params.extensionPurchaseReductionPercentage
    ),
    earlyReturnReductionPercentage: maybeToNull(
      params.earlyReturnReductionPercentage
    ),
    reportingCategory: maybeToNull(params.reportingCategory),
    billingCategory: maybeToNull(params.billingCategory),
    validFrom: maybeToNull(params.validFrom),
    validTo: maybeToNull(params.validTo),
    // financialRate: maybeToNull(params.financialRate),
    // endOfLifeProRation: maybeToNull(params.endOfLifeProRation),
    pao6Percentage: maybeToNull(params.pao6Percentage),
  }
  if (mode === 'create') {
    return fullParams
  }
  return omit(fullParams, ['frameContract', 'numberOfLeasingPeriods'])
}

export const checkParams = (
  mode: Mode,
  params: LeasingCategoryParams
): ValidationErrors => {
  const errors = {}
  if (mode === 'create') {
    requireField(errors, 'name', params.name)

    requireField(errors, 'frameContractId', params.frameContractId)
    requireField(
      errors,
      'numberOfLeasingPeriods',
      params.numberOfLeasingPeriods
    )
  }
  requireField(errors, 'leasingCoefficient', params.leasingCoefficient)
  requireField(errors, 'purchaseOption', params.purchaseOption)
  requireField(errors, 'purchaseOptionUser', params.purchaseOptionUser)
  requireField(
    errors,
    'normalLeasingPercentage',
    params.normalLeasingPercentage
  )
  requireField(
    errors,
    'extensionLeasingPercentage',
    params.extensionLeasingPercentage
  )
  requireField(
    errors,
    'earlyPurchaseReductionPercentage',
    params.earlyPurchaseReductionPercentage
  )
  requireField(
    errors,
    'extensionPurchaseReductionPercentage',
    params.extensionPurchaseReductionPercentage
  )
  requireField(
    errors,
    'earlyReturnReductionPercentage',
    params.earlyReturnReductionPercentage
  )
  requireField(errors, 'reportingCategory', params.reportingCategory)
  requireField(errors, 'billingCategory', params.billingCategory)
  //requireField(errors, 'validFrom', params.validFrom)
  //requireField(errors, 'validTo', params.validTo)
  // requireField(errors, 'financialRate', params.financialRate)
  // requireField(errors, 'endOfLifeProRation', params.endOfLifeProRation)
  requireField(errors, 'pao6Percentage', params.pao6Percentage)
  return errors
}

export const create = async (
  token: string,
  params: LeasingCategoryParams
): Promise<LeasingCategory> =>
  postRequest(
    token,
    endpoints.leasingCategories(),
    encodeParams('create', params),
    decoder
  )

export const update = async (
  token: string,
  leasingCategoryId: number,
  params: LeasingCategoryParams
): Promise<LeasingCategory> =>
  patchRequest(
    token,
    endpoints.leasingCategory(leasingCategoryId),
    { ...encodeParams('update', params), leasingCategory: leasingCategoryId },
    decoder
  )

export const copy = async (
  token: string,
  leasingCategoryId: number
): Promise<LeasingCategory> =>
  postRequest(
    token,
    endpoints.copyLeasingCategory(leasingCategoryId),
    {},
    decoder
  )

export const remove = async (
  token: string,
  leasingCategoryId: number
): Promise<void> =>
  deleteRequest(token, endpoints.leasingCategory(leasingCategoryId))
