import { Dispatch } from 'redux'
import { History } from 'history'
import { handleError } from './errors'
import * as requests from '../requests/Device'
import { SearchResult } from 'data/SearchResult'

interface SetSearchQueryAction {
  type: 'SET_SEARCH_QUERY'
  query: string
}
interface ClearSearchQueryAction {
  type: 'CLEAR_SEARCH_QUERY'
}
interface GetSearchResultsRequestAction {
  type: 'GET_SEARCH_RESULTS_REQUEST'
}
interface GetSearchResultsSuccessAction {
  type: 'GET_SEARCH_RESULTS_SUCCESS'
  results: SearchResult[]
}
interface GetSearchResultsErrorAction {
  type: 'GET_SEARCH_RESULTS_ERROR'
}

export type SearchAction =
  | SetSearchQueryAction
  | ClearSearchQueryAction
  | GetSearchResultsRequestAction
  | GetSearchResultsSuccessAction
  | GetSearchResultsErrorAction

const setSearchQueryAction = (query: string): SetSearchQueryAction => ({
  type: 'SET_SEARCH_QUERY',
  query,
})
const clearSearchQueryAction = (): ClearSearchQueryAction => ({
  type: 'CLEAR_SEARCH_QUERY',
})
const getSearchResultsRequestAction = (): GetSearchResultsRequestAction => ({
  type: 'GET_SEARCH_RESULTS_REQUEST',
})
const getSearchResultsSuccessAction = (
  results: SearchResult[]
): GetSearchResultsSuccessAction => ({
  type: 'GET_SEARCH_RESULTS_SUCCESS',
  results,
})
const getSearchResultsErrorAction = (): GetSearchResultsErrorAction => ({
  type: 'GET_SEARCH_RESULTS_ERROR',
})

export const setQuery = (dispatch: Dispatch, query: string) => {
  dispatch(setSearchQueryAction(query))
}
export const clearQuery = (dispatch: Dispatch) => {
  dispatch(clearSearchQueryAction())
}
export const getSearchResults = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number,
  query: string
) => {
  dispatch(getSearchResultsRequestAction())
  try {
    const results: SearchResult[] = await requests.search(token, frameContractId, query)
    dispatch(getSearchResultsSuccessAction(results))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      getSearchResultsErrorAction,
      'display_error'
    )
  }
}
