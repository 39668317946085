import * as React from 'react'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { Theme, withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { SnackbarsState } from '../reducers/snackbars'
import { State } from '../reducers'
import * as actions from '../actions/snackbars'
import { Dispatch } from 'redux'
import { WithStyles } from '@material-ui/styles'
import { limitErrorMessage } from '../utils'

export const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const snackbarWrapperStyles = (theme: Theme) => ({
  root: { marginTop: theme.spacing(2), position: 'static' as 'static' },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

export interface SnackbarWrapperProps
  extends WithStyles<
    | 'root'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'icon'
    | 'iconVariant'
    | 'message'
  > {
  message: string
  onClose: () => void
  variant: keyof typeof variantIcon
}

class SnackbarWrapper extends React.PureComponent<SnackbarWrapperProps> {
  public render() {
    const { classes, message, onClose, variant } = this.props
    const Icon = variantIcon[variant]

    return (
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={true}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <SnackbarContent
          className={classes[variant]}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
              {limitErrorMessage(message)}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

const StyledSnackbarWrapper = withStyles(snackbarWrapperStyles)(SnackbarWrapper)

interface StateProps {
  snackbars: SnackbarsState
}

interface DispatchProps {
  removeSnackbar: (key: string) => void
}

interface Props extends StateProps, DispatchProps {
  classes: any
}

const styles = (theme: Theme) => ({
  root: {
    position: 'fixed' as 'fixed',
    top: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: 9999, // Ensure the snackbars are above the modals
  },
})

class Snackbars extends React.PureComponent<Props> {
  public render() {
    const { snackbars, removeSnackbar, classes } = this.props
    return (
      <div className={classes.root}>
        {Object.keys(snackbars).map((key: string) => {
          const { variant, message } = snackbars[key]
          return (
            <StyledSnackbarWrapper
              key={key}
              variant={variant}
              message={message}
              onClose={() => removeSnackbar(key)}
            />
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state: State) => ({
  snackbars: state.snackbars,
})

const mapDispactToProps = (dispatch: Dispatch) => ({
  removeSnackbar: (key: string) => actions.removeSnackbar(dispatch, key),
})

export default connect(
  mapStateToProps,
  mapDispactToProps
)(withStyles(styles)(Snackbars))
