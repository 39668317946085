import { DeviceDetails } from 'data/Device'
import { Dispatch } from 'redux'
import * as requests from '../requests/Device'
import { handleError } from './errors'
import { History } from 'history'

interface OpenDeviceDetailsDialogAction {
  type: 'OPEN_DEVICE_DETAILS_DIALOG'
}
interface CloseDeviceDetailsDialogAction {
  type: 'CLOSE_DEVICE_DETAILS_DIALOG'
}

interface FetchDeviceDetailsRequestAction {
  type: 'FETCH_DEVICE_DETAILS_REQUEST'
}
interface FetchDeviceDetailsSuccessAction {
  type: 'FETCH_DEVICE_DETAILS_SUCCESS'
  deviceDetails: DeviceDetails
}
interface FetchDeviceDetailsErrorAction {
  type: 'FETCH_DEVICE_DETAILS_ERROR'
}

export type DeviceDetailsDialogAction =
  | OpenDeviceDetailsDialogAction
  | CloseDeviceDetailsDialogAction
  | FetchDeviceDetailsRequestAction
  | FetchDeviceDetailsSuccessAction
  | FetchDeviceDetailsErrorAction

const openDeviceDetailsDialogAction = (): OpenDeviceDetailsDialogAction => ({
  type: 'OPEN_DEVICE_DETAILS_DIALOG',
})
const closeDeviceDetailsDialogAction = (): CloseDeviceDetailsDialogAction => ({
  type: 'CLOSE_DEVICE_DETAILS_DIALOG',
})

const fetchDeviceDetailsRequestAction = (): FetchDeviceDetailsRequestAction => ({
  type: 'FETCH_DEVICE_DETAILS_REQUEST',
})
const fetchDeviceDetailsSuccessAction = (
  deviceDetails: DeviceDetails
): FetchDeviceDetailsSuccessAction => ({
  type: 'FETCH_DEVICE_DETAILS_SUCCESS',
  deviceDetails,
})
const fetchDeviceDetailsErrorAction = (): FetchDeviceDetailsErrorAction => ({
  type: 'FETCH_DEVICE_DETAILS_ERROR',
})

export const openDialog = (
  token: string,
  dispatch: Dispatch,
  history: History,
  deviceId: string
) => {
  dispatch(openDeviceDetailsDialogAction())
  fetchDeviceDetails(token, dispatch, history, deviceId)
}
export const closeDialog = (dispatch: Dispatch) => {
  dispatch(closeDeviceDetailsDialogAction())
}

const fetchDeviceDetails = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  deviceId: string
) => {
  dispatch(fetchDeviceDetailsRequestAction())
  try {
    const deviceDetails = await requests.getDetails(token, deviceId)
    dispatch(fetchDeviceDetailsSuccessAction(deviceDetails))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchDeviceDetailsErrorAction,
      'display_error'
    )
  }
}
