import { Dispatch } from 'redux'
import { History } from 'history'
import moment from 'moment'
import {
  ApiError,
  AuthenticationError,
  NotFoundError,
  DecoderError,
} from '../requests/base'
import { addSnackbar } from './snackbars'
import { pushErrorLog } from './errorsHistory'
import * as routes from '../routes'
import { Action } from 'actions'
import { ErrorLog } from '../reducers/errorsHistory'

const handleOnError = (dispatch: Dispatch, onError: (() => Action) | null) => {
  onError && dispatch(onError())
}

export const handleError = (
  dispatch: Dispatch,
  history: History,
  error: any,
  onError: (() => Action) | null,
  onNotFound: 'redirect' | 'display_error'
) => {
  if (error instanceof ApiError) {
    addSnackbar(dispatch, 'error', error.message)
    const errorLog: ErrorLog = {
      createdAt: moment(),
      message: error.message,
      method: error.method,
      url: error.url,
      body: error.body,
    }
    pushErrorLog(dispatch, errorLog)
    handleOnError(dispatch, onError)
  } else if (error instanceof AuthenticationError) {
    // TODO: retry the request with new token
    window.location.reload()
  } else if (error instanceof NotFoundError) {
    switch (onNotFound) {
      case 'redirect': {
        history.replace(routes.notFound())
        break
      }
      case 'display_error': {
        const errorMessage = 'Data not found'
        addSnackbar(dispatch, 'error', errorMessage)
        handleOnError(dispatch, onError)
        break
      }
    }
  } else if (error instanceof DecoderError) {
    addSnackbar(dispatch, 'error', 'Failed to decode data')
    handleOnError(dispatch, onError)
  } else {
    addSnackbar(dispatch, 'error', error.message)
    handleOnError(dispatch, onError)
  }
}
