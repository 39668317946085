import * as React from 'react'
import { State } from 'reducers'
import { connect } from 'react-redux'
import Layout from './Layout'
import { Dispatch } from 'redux'
import * as actions from '../actions/app'
import { Box, Typography, Button } from '@material-ui/core'

interface StateProps {
  token: string | null
  loginError: string | null
}

interface DispatchProps {
  login: () => void
}

interface Props extends StateProps, DispatchProps {}

class LoginCheck extends React.PureComponent<Props> {
  public render() {
    const { token, loginError, login } = this.props

    if (token === null) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={12}
        >
          <Box mb={2}>
            <Typography variant="h4">
              Welcome on MARS Lease{' '}
              <span role="img" aria-label="Welcome">
                🙌
              </span>
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle2">
              Please login to continue
            </Typography>
          </Box>
          {loginError && (
            <Box mb={4}>
              <Typography variant="subtitle2" color="error">
                {loginError}
              </Typography>
            </Box>
          )}
          <Button
            onClick={login}
            variant="contained"
            color="primary"
            size="large"
          >
            Connect with Azure AD
          </Button>
        </Box>
      )
    }
    return <Layout token={token} />
  }
}

const mapStateToProps = (state: State): StateProps => ({
  token: state.app.token,
  loginError: state.app.loginError,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  login: () => actions.login(dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginCheck)
