import { JsonDecoder } from 'ts.data.json'
import { Maybe, maybeDecoder } from 'types/Maybe'

export interface CustomerEntity {
  id: number
  customerId: number
  entityName: string
  epsCustomerId: string
  entityCustomerReference: string
  isActiveSyncEps: Maybe<boolean>
  isActiveSyncItsm: Maybe<boolean>
  epsSyncStartDate: string
}

export const decoder: JsonDecoder.Decoder<CustomerEntity> = JsonDecoder.object<
  CustomerEntity
>(
  {
    id: JsonDecoder.number,
    customerId: JsonDecoder.number,
    entityName: JsonDecoder.string,
    epsCustomerId: JsonDecoder.string,
    entityCustomerReference: JsonDecoder.string,
    isActiveSyncEps: maybeDecoder(JsonDecoder.boolean),
    isActiveSyncItsm: maybeDecoder(JsonDecoder.boolean),
    epsSyncStartDate: JsonDecoder.string,
  },
  'CustomerEntity',
  {
    id: 'customerEntity',
  }
)
