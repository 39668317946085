import { Action } from '../actions'
import { LeasingContract } from '../data/LeasingContract'
import {
  addItem,
  updateItem,
  deleteItem,
  fetchingData,
  fetchingDataError,
  dataFetched,
  fetchingDataDefault,
} from './helpers'

export interface LeasingContractsState {
  fetching: boolean
  data: LeasingContract[]
}

const defaultState = (): LeasingContractsState => fetchingDataDefault({})

export default (
  state: LeasingContractsState = defaultState(),
  action: Action
): LeasingContractsState => {
  switch (action.type) {
    case 'FETCH_LEASING_CONTRACTS_REQUEST':
      return fetchingData(state)
    case 'FETCH_LEASING_CONTRACTS_SUCCESS':
      return dataFetched(state, action.frameContracts)
    case 'FETCH_LEASING_CONTRACTS_ERROR':
      return fetchingDataError(state)
    case 'CREATE_LEASING_CONTRACT_SUCCESS':
      return addItem(state, action.leasingContract)
    case 'UPDATE_LEASING_CONTRACT_SUCCESS':
      return updateItem(state, action.leasingContract)
    case 'DELETE_LEASING_CONTRACT_SUCCESS':
      return deleteItem(state, action.leasingContractId)
    default:
      return state
  }
}
