import { Dispatch } from 'redux'
import { fetchAll } from './exportTemplates'
import { History } from 'history'
import { ExportTemplate, ExportTemplateField } from 'data/ExportTemplate'
import * as requests from '../requests/ExportTemplate'
import * as deviceRequests from '../requests/Device'
import { handleError } from './errors'
import { anyError, ValidationErrors } from 'requests/errors'
import * as exportFieldsActions from './exportFields'
import { Maybe } from 'types/Maybe'
import { handleSuccess } from './success'

interface OpenExportTemplateDialogAction {
  type: 'OPEN_EXPORT_TEMPLATE_DIALOG'
}
interface CloseExportTemplateDialogAction {
  type: 'CLOSE_EXPORT_TEMPLATE_DIALOG'
}
interface SelectExportTemplateAction {
  type: 'SELECT_EXPORT_TEMPLATE'
  exportTemplateId: number
}
interface OpenExportTemplateFormAction {
  type: 'OPEN_EXPORT_TEMPLATE_FORM'
  exportTemplate: Maybe<ExportTemplate>
}
interface CloseExportTemplateFormAction {
  type: 'CLOSE_EXPORT_TEMPLATE_FORM'
}
interface SetExportTemplateNameAction {
  type: 'SET_EXPORT_TEMPLATE_NAME'
  name: string
}
interface ClearExportTemplateNameAction {
  type: 'CLEAR_EXPORT_TEMPLATE_NAME'
}
interface SetExportTemplateFieldsAction {
  type: 'SET_EXPORT_TEMPLATE_FIELDS'
  fields: ExportTemplateField[]
}
interface AddExportTemplateFieldAction {
  type: 'ADD_EXPORT_TEMPLATE_FIELD'
  field: ExportTemplateField
}
interface RemoveExportTemplateFieldAction {
  type: 'REMOVE_EXPORT_TEMPLATE_FIELD'
  field: string
}
interface CreateExportTemplateRequestAction {
  type: 'CREATE_EXPORT_TEMPLATE_REQUEST'
}
interface CreateExportTemplateSuccessAction {
  type: 'CREATE_EXPORT_TEMPLATE_SUCCESS'
  exportTemplate: ExportTemplate
}
interface CreateExportTemplateErrorAction {
  type: 'CREATE_EXPORT_TEMPLATE_ERROR'
}
interface UpdateExportTemplateRequestAction {
  type: 'UPDATE_EXPORT_TEMPLATE_REQUEST'
}
interface UpdateExportTemplateSuccessAction {
  type: 'UPDATE_EXPORT_TEMPLATE_SUCCESS'
  exportTemplate: ExportTemplate
}
interface UpdateExportTemplateErrorAction {
  type: 'UPDATE_EXPORT_TEMPLATE_ERROR'
}
interface DeleteExportTemplateRequestAction {
  type: 'DELETE_EXPORT_TEMPLATE_REQUEST'
}
interface DeleteExportTemplateSuccessAction {
  type: 'DELETE_EXPORT_TEMPLATE_SUCCESS'
  exportTemplateId: number
}
interface DeleteExportTemplateErrorAction {
  type: 'DELETE_EXPORT_TEMPLATE_ERROR'
}
interface SetExportTemplateValidationErrorsAction {
  type: 'SET_EXPORT_TEMPLATE_VALIDATION_ERRORS'
  validationErrors: ValidationErrors
}
interface ExportDevicesRequestAction {
  type: 'EXPORT_DEVICES_REQUEST'
}
interface ExportDevicesSuccessAction {
  type: 'EXPORT_DEVICES_SUCCESS'
}
interface ExportDevicesErrorAction {
  type: 'EXPORT_DEVICES_ERROR'
}

export type ExportTemplateDialogAction =
  | OpenExportTemplateDialogAction
  | CloseExportTemplateDialogAction
  | SelectExportTemplateAction
  | OpenExportTemplateFormAction
  | CloseExportTemplateFormAction
  | SetExportTemplateNameAction
  | ClearExportTemplateNameAction
  | SetExportTemplateFieldsAction
  | AddExportTemplateFieldAction
  | RemoveExportTemplateFieldAction
  | CreateExportTemplateRequestAction
  | CreateExportTemplateSuccessAction
  | CreateExportTemplateErrorAction
  | UpdateExportTemplateRequestAction
  | UpdateExportTemplateSuccessAction
  | UpdateExportTemplateErrorAction
  | DeleteExportTemplateRequestAction
  | DeleteExportTemplateSuccessAction
  | DeleteExportTemplateErrorAction
  | SetExportTemplateValidationErrorsAction
  | ExportDevicesRequestAction
  | ExportDevicesSuccessAction
  | ExportDevicesErrorAction

const openExportTemplateDialogAction = (): OpenExportTemplateDialogAction => ({
  type: 'OPEN_EXPORT_TEMPLATE_DIALOG',
})
const closeExportTemplateDialogAction = (): CloseExportTemplateDialogAction => ({
  type: 'CLOSE_EXPORT_TEMPLATE_DIALOG',
})
const selectExportTemplateAction = (
  exportTemplateId: number
): SelectExportTemplateAction => ({
  type: 'SELECT_EXPORT_TEMPLATE',
  exportTemplateId,
})
const openExportTemplateFormAction = (
  exportTemplate: Maybe<ExportTemplate>
): OpenExportTemplateFormAction => ({
  type: 'OPEN_EXPORT_TEMPLATE_FORM',
  exportTemplate,
})
const closeExportTemplateFormAction = (): CloseExportTemplateFormAction => ({
  type: 'CLOSE_EXPORT_TEMPLATE_FORM',
})
const setExportTemplateNameAction = (
  name: string
): SetExportTemplateNameAction => ({
  type: 'SET_EXPORT_TEMPLATE_NAME',
  name,
})
const clearExportTemplateNameAction = (): ClearExportTemplateNameAction => ({
  type: 'CLEAR_EXPORT_TEMPLATE_NAME',
})

const setExportTemplateFieldsAction = (
  fields: ExportTemplateField[]
): SetExportTemplateFieldsAction => ({
  type: 'SET_EXPORT_TEMPLATE_FIELDS',
  fields,
})
const addExportTemplateFieldAction = (
  field: ExportTemplateField
): AddExportTemplateFieldAction => ({
  type: 'ADD_EXPORT_TEMPLATE_FIELD',
  field,
})
const removeExportTemplateFieldAction = (
  field: string
): RemoveExportTemplateFieldAction => ({
  type: 'REMOVE_EXPORT_TEMPLATE_FIELD',
  field,
})

const createExportTemplateRequestAction = (): CreateExportTemplateRequestAction => ({
  type: 'CREATE_EXPORT_TEMPLATE_REQUEST',
})
const createExportTemplateSuccessAction = (
  exportTemplate: ExportTemplate
): CreateExportTemplateSuccessAction => ({
  type: 'CREATE_EXPORT_TEMPLATE_SUCCESS',
  exportTemplate,
})
const createExportTemplateErrorAction = (): CreateExportTemplateErrorAction => ({
  type: 'CREATE_EXPORT_TEMPLATE_ERROR',
})
const updateExportTemplateRequestAction = (): UpdateExportTemplateRequestAction => ({
  type: 'UPDATE_EXPORT_TEMPLATE_REQUEST',
})
const updateExportTemplateSuccessAction = (
  exportTemplate: ExportTemplate
): UpdateExportTemplateSuccessAction => ({
  type: 'UPDATE_EXPORT_TEMPLATE_SUCCESS',
  exportTemplate,
})
const updateExportTemplateErrorAction = (): UpdateExportTemplateErrorAction => ({
  type: 'UPDATE_EXPORT_TEMPLATE_ERROR',
})
const deleteExportTemplateRequestAction = (): DeleteExportTemplateRequestAction => ({
  type: 'DELETE_EXPORT_TEMPLATE_REQUEST',
})
const deleteExportTemplateSuccessAction = (
  exportTemplateId: number
): DeleteExportTemplateSuccessAction => ({
  type: 'DELETE_EXPORT_TEMPLATE_SUCCESS',
  exportTemplateId,
})
const deleteExportTemplateErrorAction = (): DeleteExportTemplateErrorAction => ({
  type: 'DELETE_EXPORT_TEMPLATE_ERROR',
})

const setExportTemplateValidationErrorsAction = (validationErrors: {
  [field: string]: string[]
}): SetExportTemplateValidationErrorsAction => ({
  type: 'SET_EXPORT_TEMPLATE_VALIDATION_ERRORS',
  validationErrors,
})

const exportDevicesRequestAction = (): ExportDevicesRequestAction => ({
  type: 'EXPORT_DEVICES_REQUEST',
})
const exportDevicesSuccessAction = (): ExportDevicesSuccessAction => ({
  type: 'EXPORT_DEVICES_SUCCESS',
})
const exportDevicesErrorAction = (): ExportDevicesErrorAction => ({
  type: 'EXPORT_DEVICES_ERROR',
})

export const openDialog = (
  token: string,
  dispatch: Dispatch,
  history: History
): void => {
  dispatch(openExportTemplateDialogAction())
  fetchAll(token, dispatch, history)
}
export const closeDialog = (dispatch: Dispatch): void => {
  dispatch(closeExportTemplateDialogAction())
}

export const select = (dispatch: Dispatch, exportTemplateId: number): void => {
  dispatch(selectExportTemplateAction(exportTemplateId))
}

export const openExportTemplateForm = (
  token: string,
  dispatch: Dispatch,
  history: History,
  exportTemplate: Maybe<ExportTemplate>
) => {
  dispatch(openExportTemplateFormAction(exportTemplate))
  exportFieldsActions.fetchAll(token, dispatch, history)
}
export const closeExportTemplateForm = (dispatch: Dispatch) => {
  dispatch(closeExportTemplateFormAction())
}

export const setName = (dispatch: Dispatch, name: string) => {
  dispatch(setExportTemplateNameAction(name))
}
export const clearName = (dispatch: Dispatch) => {
  dispatch(clearExportTemplateNameAction())
}

export const setFields = (
  dispatch: Dispatch,
  fields: ExportTemplateField[]
) => {
  dispatch(setExportTemplateFieldsAction(fields))
}
export const addField = (dispatch: Dispatch, field: ExportTemplateField) => {
  dispatch(addExportTemplateFieldAction(field))
}
export const removeField = (dispatch: Dispatch, field: string) => {
  dispatch(removeExportTemplateFieldAction(field))
}

export const createExportTemplate = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: requests.ExportTemplateParams
): Promise<void> => {
  dispatch(createExportTemplateRequestAction())

  const errors = requests.checkParams('create', params)
  if (anyError(errors)) {
    dispatch(setExportTemplateValidationErrorsAction(errors))
    dispatch(createExportTemplateErrorAction())
    return
  }

  try {
    const exportTemplate = await requests.create(token, params)
    closeExportTemplateForm(dispatch)
    handleSuccess(dispatch, 'Export Template created',null)
    dispatch(createExportTemplateSuccessAction(exportTemplate))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      createExportTemplateErrorAction,
      'display_error'
    )
  }
}

export const updateExportTemplate = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  exportTemplateId: number,
  params: requests.ExportTemplateParams
): Promise<void> => {
  dispatch(updateExportTemplateRequestAction())

  const errors = requests.checkParams('update', params)
  if (anyError(errors)) {
    dispatch(setExportTemplateValidationErrorsAction(errors))
    dispatch(updateExportTemplateErrorAction())
    return
  }

  try {
    const exportTemplate = await requests.update(
      token,
      exportTemplateId,
      params
    )
    closeExportTemplateForm(dispatch)
    dispatch(updateExportTemplateSuccessAction(exportTemplate))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      updateExportTemplateErrorAction,
      'display_error'
    )
  }
}

export const deleteExportTemplate = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  exportTemplateId: number
): Promise<void> => {
  dispatch(deleteExportTemplateRequestAction())
  try {
    await requests.remove(token, exportTemplateId)
    closeExportTemplateForm(dispatch)
    handleSuccess(dispatch, 'Export Template deleted',null)
    dispatch(deleteExportTemplateSuccessAction(exportTemplateId))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      deleteExportTemplateErrorAction,
      'display_error'
    )
  }
}

export const exportDevices = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  params: deviceRequests.ExportDevicesParams
): Promise<void> => {
  dispatch(exportDevicesRequestAction())

  try {
    closeDialog(dispatch)
    handleSuccess(dispatch, 'Generating export, download will start automatically',null)
    await deviceRequests.exportDevices(token, params)
    dispatch(exportDevicesSuccessAction())
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      exportDevicesErrorAction,
      'display_error'
    )
  }
}
