import { Dispatch } from 'redux'
import * as requests from '../requests/BillingPeriod'
import { History } from 'history'
import { handleError } from './errors'
import { BillingPeriod } from 'data/BillingPeriod'

interface FetchBillingPeriodsRequestAction {
  type: 'FETCH_BILLING_PERIODS_REQUEST'
}
interface FetchBillingPeriodsSuccessAction {
  type: 'FETCH_BILLING_PERIODS_SUCCESS'
  billingPeriods: BillingPeriod[]
}
interface FetchBillingPeriodsErrorAction {
  type: 'FETCH_BILLING_PERIODS_ERROR'
}

export type BillingPeriodsAction =
  | FetchBillingPeriodsRequestAction
  | FetchBillingPeriodsSuccessAction
  | FetchBillingPeriodsErrorAction

const fetchBillingPeriodsRequestAction = (): FetchBillingPeriodsRequestAction => ({
  type: 'FETCH_BILLING_PERIODS_REQUEST',
})
const fetchBillingPeriodsSuccessAction = (
  billingPeriods: BillingPeriod[]
): FetchBillingPeriodsSuccessAction => ({
  type: 'FETCH_BILLING_PERIODS_SUCCESS',
  billingPeriods,
})
const fetchBillingPeriodsErrorAction = (): FetchBillingPeriodsErrorAction => ({
  type: 'FETCH_BILLING_PERIODS_ERROR',
})

export const fetchAll = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number
) => {
  dispatch(fetchBillingPeriodsRequestAction())
  try {
    const billingPeriods = await requests.fetchByFrameContractId(
      token,
      frameContractId
    )
    dispatch(fetchBillingPeriodsSuccessAction(billingPeriods))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      fetchBillingPeriodsErrorAction,
      'display_error'
    )
  }
}
