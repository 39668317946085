import * as React from 'react'
import { DeviceStatus } from '../data/DeviceStatus'
import { BillingStatus } from '../data/BillingStatus'
import { connect } from 'react-redux'
import { State } from 'reducers'
import { Dispatch } from 'redux'
import * as actions from '../actions/devicesCount'
import { withRouter, RouteChildrenProps } from 'react-router'
import { Chip } from '@material-ui/core'
import LoadingIndicator from 'components/LoadingIndicator'
import { Moment } from 'moment'
import { isEqual } from 'lodash'
import {
  Maybe,
  maybeSwitch,
  maybeWithDefault,
  maybeJust,
  maybeNothing,
} from 'types/Maybe'

type CountInfo = LeasingCount | BillingCount

interface LeasingCount {
  type: 'leasing'
  status: DeviceStatus
  leasingContractId: Maybe<number>
  from: Maybe<Moment>
  to: Maybe<Moment>
}

interface BillingCount {
  type: 'billing'
  status: BillingStatus
  billingPeriodId: number
}

interface OwnProps extends RouteChildrenProps {
  token: string
  frameContractId: number
  countInfo: CountInfo
}

interface StateProps {
  ready: boolean
  fetching: boolean
  count: Maybe<number>
}

interface DispatchProps {
  countDevices: () => void
  clearCount: () => void
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class DevicesCount extends React.PureComponent<Props> {
  public componentDidMount() {
    if (this.props.ready) {
      this.props.countDevices()
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.ready && this.props.ready) {
      this.props.countDevices()
    } else if (
      prevProps.frameContractId !== this.props.frameContractId ||
      !isEqual(prevProps.countInfo, this.props.countInfo)
    ) {
      this.props.clearCount()
      this.props.countDevices()
    }
  }

  public componentWillUnmount() {
    this.props.clearCount()
  }

  public render() {
    const { fetching, count } = this.props
    if (fetching) {
      return <Chip label={<LoadingIndicator size={maybeJust(18)} />} />
    }
    return <Chip label={maybeWithDefault(count, 0)} />
  }
}

const defaultState = { fetching: false, count: maybeNothing<number>() }

const getCountProps = (state: State, countInfo: CountInfo) => {
  switch (countInfo.type) {
    case 'leasing': {
      return maybeSwitch(
        countInfo.leasingContractId,
        id => {
          const res = state.devicesCount.byLeasingContract[id] || {}
          return res[countInfo.status] || defaultState
        },
        () => {
          return state.devicesCount.aggregated[countInfo.status] || defaultState
        }
      )
    }
    case 'billing': {
      const res =
        state.devicesCount.byBillingPeriod[countInfo.billingPeriodId] || {}
      return res[countInfo.status] || defaultState
    }
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  ...getCountProps(state, ownProps.countInfo),
  ready: state.devicesCount.ready,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  countDevices: () => {
    switch (ownProps.countInfo.type) {
      case 'leasing':
        return actions.fetchCount(ownProps.token, dispatch, ownProps.history, {
          type: 'leasing',
          frameContractId: ownProps.frameContractId,
          status: ownProps.countInfo.status,
          leasingContractId: ownProps.countInfo.leasingContractId,
          from: ownProps.countInfo.from,
          to: ownProps.countInfo.to,
        })
      case 'billing':
        return actions.fetchCount(ownProps.token, dispatch, ownProps.history, {
          type: 'billing',
          frameContractId: ownProps.frameContractId,
          billingPeriodId: ownProps.countInfo.billingPeriodId,
          status: ownProps.countInfo.status,
        })
    }
  },
  clearCount: () => {
    switch (ownProps.countInfo.type) {
      case 'leasing':
        const { status } = ownProps.countInfo
        return maybeSwitch(
          ownProps.countInfo.leasingContractId,
          id => actions.clearByLeasingContract(dispatch, status, maybeJust(id)),
          () => {}
        )
      case 'billing':
        return actions.clearByBillingPeriod(
          dispatch,
          ownProps.countInfo.billingPeriodId,
          ownProps.countInfo.status
        )
    }
  },
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DevicesCount)
)
