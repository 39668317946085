import * as React from 'react'
import Select from '@material-ui/core/Select'
import { MenuItem, FormControl, InputLabel } from '@material-ui/core'
import FormErrors from './FormErrors'
import { Maybe, maybeWithDefault, isJust } from 'types/Maybe'

interface Item {
  value: string
  name: string
}

export interface Props {
  value: Maybe<string>
  label: string
  items: Item[]
  required: boolean
  onChange: (value: string) => void
  onClear: Maybe<() => void>
  validationErrors: string[]
  disabled: boolean
}

export default class FormSelect extends React.PureComponent<Props> {
  render() {
    const {
      value,
      label,
      items,
      onChange,
      onClear,
      required,
      validationErrors,
      disabled,
    } = this.props
    const hasErrors = validationErrors && validationErrors.length > 0
    return (
      <FormControl
        fullWidth={true}
        required={required}
        margin="normal"
        error={hasErrors}
      >
        <InputLabel error={hasErrors}>{label}</InputLabel>

        <Select
          value={maybeWithDefault(value, '')}
          onChange={(e: any) => {
            const value = e.target.value
            value ? onChange(value) : maybeWithDefault(onClear, () => {})()
          }}
          error={hasErrors}
          disabled={disabled}
        >
          {isJust(onClear) && (
            <MenuItem value="">
              <em>Undefined</em>
            </MenuItem>
          )}
          {items.map(({ value, name }: Item) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <FormErrors errors={validationErrors} />
      </FormControl>
    )
  }
}
