import { JsonDecoder } from 'ts.data.json'

export interface Customer {
  id: number
  name: string
  address: string
}

export const decoder: JsonDecoder.Decoder<Customer> = JsonDecoder.object<
  Customer
>(
  {
    id: JsonDecoder.number,
    name: JsonDecoder.string,
    address: JsonDecoder.string,
  },
  'Customer',
  {
    id: 'customer',
  }
)
