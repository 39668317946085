import { Device, DeviceDetails, detailsDecoder, decoder } from '../data/Device'
import { getRequest, downloadFilePostRequest } from './base'
import * as endpoints from './endpoints'
import { JsonDecoder } from 'ts.data.json'
import { DeviceSelection } from 'reducers/devices'
import { SearchResult, decoder as searchResultDecoder } from 'data/SearchResult'
import { maybeToNull } from 'types/Maybe'

export const search = async (
  token: string,
  frameContractId: number,
  query: string
): Promise<SearchResult[]> =>
  getRequest(
    token,
    endpoints.deviceSearch(frameContractId, query),
    JsonDecoder.array(searchResultDecoder, 'SearchResult[]')
  )

export const fetchByStatus = async (
  token: string,
  params: endpoints.FilterDevicesParams
): Promise<Device[]> =>
  getRequest(
    token,
    endpoints.filterDevices(params),
    JsonDecoder.array(decoder, 'Device[]')
  )

export const countByStatus = async (
  token: string,
  params: endpoints.CountParams
): Promise<number> =>
  getRequest(token, endpoints.countDevices(params), JsonDecoder.number)

export const getDetails = async (
  token: string,
  deviceId: string
): Promise<DeviceDetails> =>
  getRequest(token, endpoints.deviceDetails(deviceId), detailsDecoder)

export interface ExportDevicesParams {
  selection: DeviceSelection
  exportTemplateId: number
  filterBy: endpoints.CountParams
}

const encodeParams = (params: ExportDevicesParams) => {
  switch (params.selection.type) {
    case 'selected': {
      return {
        exportType: 'Include',
        serialNumbers: Array.from(params.selection.deviceIds),
        template: params.exportTemplateId,
      }
    }
    case 'excluded': {
      const base = {
        exportType: 'Exclude',
        serialNumbers: Array.from(params.selection.deviceIds),
        template: params.exportTemplateId,
      }
      switch (params.filterBy.type) {
        case 'leasing':
          return {
            ...base,
            filter: {
              frameContract: params.filterBy.frameContractId,
              status: params.filterBy.status,
              originalLeasingContract: maybeToNull(
                params.filterBy.leasingContractId
              ),
              from: maybeToNull(params.filterBy.from),
              to: maybeToNull(params.filterBy.to),
            },
          }
        case 'billing':
          return {
            ...base,
            filter: {
              frameContract: params.filterBy.frameContractId,
              billingPeriod: params.filterBy.billingPeriodId,
              billingStatus: params.filterBy.status,
            },
          }
        default:
          // NOTE: should not be called
          return {}
      }
    }
  }
}

export const exportDevices = async (
  token: string,
  params: ExportDevicesParams
) =>
  downloadFilePostRequest(
    token,
    endpoints.exportView(),
    encodeParams(params),
    'assets',
    'csv'
  )
