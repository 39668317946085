import { JsonDecoder } from 'ts.data.json'

export interface SearchResult {
  deviceName: string
}

export const decoder = JsonDecoder.object<SearchResult>(
  {
    deviceName: JsonDecoder.string,
  },
  'SearchResult',
  { deviceName: 'name' }
)
