import * as React from 'react'
import classNames from 'classnames'
import { withStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Box from '@material-ui/core/Box'
import { capitalize } from '../utils'
import { Device } from '../data/Device'
import { WithStyles } from '@material-ui/styles'
import {
  DeviceSelection,
  isDeviceSelected,
  selectedDevicesCount,
} from 'reducers/devices'

export type Order = 'asc' | 'desc'

interface DeviceTableHeadProps {
  columns: DeviceTableColumn[]
  numSelected: number
  onRequestSort: (column: string) => void
  onSelectAllClick: () => void
  order: Order
  orderBy: string
  totalDataCount: number
}

class DeviceTableHead extends React.PureComponent<DeviceTableHeadProps> {
  private createSortHandler = (column: string) => (event: any) => {
    this.props.onRequestSort(column)
  }

  public render() {
    const {
      columns,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      totalDataCount,
    } = this.props

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < totalDataCount}
              checked={numSelected !== 0 && numSelected === totalDataCount}
              onChange={() => onSelectAllClick()}
            />
          </TableCell>
          {columns.map((column: DeviceTableColumn) => (
            <TableCell
              key={column.id}
              sortDirection={orderBy === column.id ? order : false}
            >
              <Tooltip title="Sort" enterDelay={300}>
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={order}
                  onClick={this.createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

const toolbarStyles = (theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: { flex: '0 0 auto' },
})

interface DeviceTableToolbarProps {
  classes: any
  numSelected: number
  status: string
  exportDevices: () => void
}

const DeviceTableToolbarBase = (props: DeviceTableToolbarProps) => {
  const { numSelected, classes, status, exportDevices } = props

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Typography color="inherit" variant="h6">
          {capitalize(status)} assets
          {numSelected > 0 ? ' | ' + numSelected.toString() + ' selected' : ''}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && (
          <Tooltip title="Export">
            <IconButton aria-label="Export" onClick={exportDevices}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  )
}

const DeviceTableToolbar = withStyles(toolbarStyles)(DeviceTableToolbarBase)

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto' as 'auto',
    overflowY: 'scroll' as 'scroll',
  },
  tableAndPagination: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
  },
  emptyStateRoot: {
    textAlign: 'center' as 'center',
    paddingTop: theme.spacing(8),
  },
})

export interface DeviceTableColumn {
  id: string
  label: string
  render: (device: Device) => JSX.Element | string
}

interface DeviceTableOwnProps {
  data: Device[]
  columns: DeviceTableColumn[]
  selection: DeviceSelection
  onRowClick: (device: Device) => void
  toggleSelect: (device: Device) => void
  selectAll: () => void
  unselectAll: () => void
  order: Order
  orderBy: string
  sortBy: (column: string) => void
  page: number
  rowsPerPage: number
  setPage: (page: number) => void
  setRowsPerPage: (rowsPerPage: number) => void
  totalDataCount: number
  exportDevices: () => void
}

interface DeviceTableProps
  extends DeviceTableOwnProps,
    WithStyles<
      | 'root'
      | 'table'
      | 'tableWrapper'
      | 'tableAndPagination'
      | 'emptyStateRoot'
    > {
  status: string
}

class DeviceTable extends React.PureComponent<DeviceTableProps> {
  private handleSelectAllClick = () => {
    if (
      (this.props.selection.type === 'selected' &&
        this.props.selection.deviceIds.size > 0) ||
      this.props.selection.type === 'excluded'
    ) {
      this.props.unselectAll()
    } else {
      this.props.selectAll()
    }
  }

  private handleChangePage = (event: any, page: number) => {
    this.props.setPage(page)
  }

  private handleChangeRowsPerPage = (event: any) => {
    this.props.setRowsPerPage(event.target.value)
  }

  private isSelected = (device: Device) =>
    isDeviceSelected(this.props.selection, device)

  private numSelected = () =>
    selectedDevicesCount(this.props.selection, this.props.totalDataCount)

  public render() {
    const {
      columns,
      data,
      classes,
      onRowClick,
      toggleSelect,
      order,
      orderBy,
      sortBy,
      status,
      rowsPerPage,
      page,
      totalDataCount,
      exportDevices,
    } = this.props
    if (data.length === 0) {
      return (
        <Box className={classes.emptyStateRoot}>
          <Typography variant="h5" gutterBottom>
            Oops… There are no assets{' '}
            <span role="img" aria-label="Sad">
              😢
            </span>
          </Typography>
        </Box>
      )
    }
    const numSelected = this.numSelected()
    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <DeviceTableToolbar
          status={status}
          numSelected={numSelected}
          exportDevices={exportDevices}
        />
        <div className={classes.tableAndPagination}>
          <Box
            flex={1}
            borderTop={1}
            borderColor="grey.300"
            className={classes.tableWrapper}
          >
            <Table className={classes.table} aria-labelledby="tableTitle">
              <DeviceTableHead
                columns={columns}
                numSelected={numSelected}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={sortBy}
                totalDataCount={totalDataCount}
              />
              <TableBody>
                {data.map((device: Device) => {
                  const isSelected = this.isSelected(device)
                  return (
                    <TableRow
                      hover={true}
                      onClick={() => onRowClick(device)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={device.name}
                      selected={isSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(event: any) => {
                          event.stopPropagation()
                          toggleSelect(device)
                        }}
                      >
                        <Checkbox checked={isSelected} />
                      </TableCell>
                      {columns.map((column: DeviceTableColumn) => (
                        <TableCell key={column.id}>
                          {column.render(device)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
          <Box flex={0}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalDataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Box>
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(DeviceTable)
