import { DeviceStatus } from './data/DeviceStatus'
import { BillingStatus } from './data/BillingStatus'
import { Maybe, maybeMap, maybeWithDefault } from 'types/Maybe'

export const home = () => aggregated('Leased')

export const customers = () => '/customers'

export const aggregated = (status: DeviceStatus) => '/aggregated/' + status

export const leasingContractDetails = (
  leasingContractId: Maybe<number>,
  status: Maybe<DeviceStatus>
) =>
  '/leasing-contracts/' + maybeWithDefault(
    maybeMap(leasingContractId, id => id.toString()),
    ':id'
  ) + '/' + maybeWithDefault(status, ':status')

export const billingPeriodDetails = (
  billingPeriodId: Maybe<number>,
  status: Maybe<BillingStatus>
) =>
  '/billing-periods/' + maybeWithDefault(
    maybeMap(billingPeriodId, id => id.toString()),
    ':id'
  ) + '/' + maybeWithDefault(status, ':status')

export const notFound = () => '/not-found'
