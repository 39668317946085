import { Action } from 'actions'
import { DeviceDetails } from 'data/Device'
import { Maybe, maybeNothing, maybeJust } from 'types/Maybe'

export interface DeviceDetailsDialogState {
  open: boolean
  fetching: boolean
  data: Maybe<DeviceDetails>
}

const defaultState = (): DeviceDetailsDialogState => ({
  open: false,
  fetching: false,
  data: maybeNothing(),
})

export default (
  state: DeviceDetailsDialogState = defaultState(),
  action: Action
) => {
  switch (action.type) {
    case 'OPEN_DEVICE_DETAILS_DIALOG':
      return { ...state, open: true }
    case 'CLOSE_DEVICE_DETAILS_DIALOG':
      return defaultState()
    case 'FETCH_DEVICE_DETAILS_REQUEST':
      return { ...state, fetching: true, data: maybeNothing<DeviceDetails>() }
    case 'FETCH_DEVICE_DETAILS_SUCCESS':
      return {
        ...state,
        fetching: false,
        data: maybeJust(action.deviceDetails),
      }
    case 'FETCH_DEVICE_DETAILS_ERROR':
      return defaultState()
    default:
      return state
  }
}
