import * as React from 'react'
import WithConfirmation from './WithConfirmation'
import { Button } from '@material-ui/core'

interface OwnProps {
  name: string
  checkIfIsDeletable: () => void
  isDeletable: boolean
  onConfirm: () => void
}

export default class DeletableButton extends React.PureComponent<OwnProps> {
  public componentDidMount() {
    this.props.checkIfIsDeletable()
  }

  public render() {
    const { name, isDeletable, onConfirm } = this.props
    return (
      <WithConfirmation
        dialogTitle={'Confirm delete ' + name + '?'}
        onConfirm={onConfirm}
        render={onClick => (
          <Button onClick={onClick} disabled={!isDeletable}>
            Delete
          </Button>
        )}
      />
    )
  }
}
