import { Dispatch } from 'redux'
import { Moment } from 'moment'
import * as requests from '../requests/Billing'
import { History } from 'history'
import { handleError } from './errors'
import * as session from '../config/session'
import { resultSwitch } from 'types/Result'
import { handleSuccess } from './success'


interface SetTokenAction {
  type: 'SET_TOKEN'
  token: string
}
interface SetLoginErrorAction {
  type: 'SET_LOGIN_ERROR'
  error: string
}
interface ToggleDrawerAction {
  type: 'TOGGLE_DRAWER'
}
interface ToggleShowLeasingContractsByYearAction {
  type: 'TOGGLE_SHOW_LEASING_CONTRACTS_BY_YEAR'
  year: number
}
interface ToggleShowLeasingContractDetailsAction {
  type: 'TOGGLE_SHOW_LEASING_CONTRACT_DETAILS'
  leasingContractId: number
}
interface ToggleShowBillingPeriodsByYearAction {
  type: 'TOGGLE_SHOW_BILLING_PERIODS_BY_YEAR'
  year: number
}
interface ToggleShowBillingPeriodDetailsAction {
  type: 'TOGGLE_SHOW_BILLING_PERIOD_DETAILS'
  billingPeriodId: number
}
interface SetFromFilterAction {
  type: 'SET_FROM_FILTER'
  from: Moment
}
interface SetToFilterAction {
  type: 'SET_TO_FILTER'
  to: Moment
}
interface ClearFromFilterAction {
  type: 'CLEAR_FROM_FILTER'
}
interface ClearToFilterAction {
  type: 'CLEAR_TO_FILTER'
}

interface ExportBillingRequestAction {
  type: 'EXPORT_BILLING_REQUEST'
  billingPeriodId: number
}
interface ExportBillingSuccessAction {
  type: 'EXPORT_BILLING_SUCCESS'
  billingPeriodId: number
}
interface ExportBillingErrorAction {
  type: 'EXPORT_BILLING_ERROR'
  billingPeriodId: number
}
interface UploadAnswerFileRequestAction {
  type: 'UPLOAD_ANSWER_FILE_REQUEST'
  frameContractId: number
}
interface UploadAnswerFileSuccessAction {
  type: 'UPLOAD_ANSWER_FILE_SUCCESS'
  frameContractId: number
}
interface UploadAnswerFileErrorAction {
  type: 'UPLOAD_ANSWER_FILE_ERROR'
  frameContractId: number
}

interface GenerateInvestmentFilesRequestAction {
  type: 'GENERATE_SNI_FILES_REQUEST'
  billingPeriodId: number
}
interface GenerateInvestmentFilesSuccessAction {
  type: 'GENERATE_SNI_FILES_SUCCESS'
  billingPeriodId: number
}
interface GenerateInvestmentFilesErrorAction {
  type: 'GENERATE_SNI_FILES_ERROR'
  billingPeriodId: number
}

export type AppAction =
  | SetTokenAction
  | SetLoginErrorAction
  | ToggleDrawerAction
  | ToggleShowLeasingContractsByYearAction
  | ToggleShowLeasingContractDetailsAction
  | ToggleShowBillingPeriodsByYearAction
  | ToggleShowBillingPeriodDetailsAction
  | SetFromFilterAction
  | SetToFilterAction
  | ClearFromFilterAction
  | ClearToFilterAction
  | ExportBillingRequestAction
  | ExportBillingSuccessAction
  | ExportBillingErrorAction
  | UploadAnswerFileRequestAction
  | UploadAnswerFileSuccessAction
  | UploadAnswerFileErrorAction
  | GenerateInvestmentFilesRequestAction
  | GenerateInvestmentFilesSuccessAction
  | GenerateInvestmentFilesErrorAction

const setTokenAction = (token: string): SetTokenAction => ({
  type: 'SET_TOKEN',
  token,
})
const setLoginErrorAction = (error: string): SetLoginErrorAction => ({
  type: 'SET_LOGIN_ERROR',
  error,
})
const toggleDrawerAction = (): ToggleDrawerAction => ({ type: 'TOGGLE_DRAWER' })
const toggleShowLeasingContractsByYearAction = (
  year: number
): ToggleShowLeasingContractsByYearAction => ({
  type: 'TOGGLE_SHOW_LEASING_CONTRACTS_BY_YEAR',
  year,
})
const toggleShowLeasingContractDetailsAction = (
  leasingContractId: number
): ToggleShowLeasingContractDetailsAction => ({
  type: 'TOGGLE_SHOW_LEASING_CONTRACT_DETAILS',
  leasingContractId,
})
const toggleShowBillingPeriodsByYearAction = (
  year: number
): ToggleShowBillingPeriodsByYearAction => ({
  type: 'TOGGLE_SHOW_BILLING_PERIODS_BY_YEAR',
  year,
})
const toggleShowBillingPeriodDetailsAction = (
  billingPeriodId: number
): ToggleShowBillingPeriodDetailsAction => ({
  type: 'TOGGLE_SHOW_BILLING_PERIOD_DETAILS',
  billingPeriodId,
})
const setFromFilterAction = (from: Moment): SetFromFilterAction => ({
  type: 'SET_FROM_FILTER',
  from,
})
const setToFilterAction = (to: Moment): SetToFilterAction => ({
  type: 'SET_TO_FILTER',
  to,
})
const clearFromFilterAction = (): ClearFromFilterAction => ({
  type: 'CLEAR_FROM_FILTER',
})
const clearToFilterAction = (): ClearToFilterAction => ({
  type: 'CLEAR_TO_FILTER',
})

const exportBillingRequestAction = (
  billingPeriodId: number
): ExportBillingRequestAction => ({
  type: 'EXPORT_BILLING_REQUEST',
  billingPeriodId,
})
const exportBillingSuccessAction = (
  billingPeriodId: number
): ExportBillingSuccessAction => ({
  type: 'EXPORT_BILLING_SUCCESS',
  billingPeriodId,
})
const exportBillingErrorAction = (
  billingPeriodId: number
): ExportBillingErrorAction => ({
  type: 'EXPORT_BILLING_ERROR',
  billingPeriodId,
})
const uploadAnswerFileRequestAction = (
  frameContractId: number
): UploadAnswerFileRequestAction => ({
  type: 'UPLOAD_ANSWER_FILE_REQUEST',
  frameContractId,
})
const uploadAnswerFileSuccessAction = (
  frameContractId: number
): UploadAnswerFileSuccessAction => ({
  type: 'UPLOAD_ANSWER_FILE_SUCCESS',
  frameContractId,
})
const uploadAnswerFileErrorAction = (
  frameContractId: number
): UploadAnswerFileErrorAction => ({
  type: 'UPLOAD_ANSWER_FILE_ERROR',
  frameContractId,
})
const generateInvestmentFilesRequestAction = (
  billingPeriodId: number
): GenerateInvestmentFilesRequestAction => ({
  type: 'GENERATE_SNI_FILES_REQUEST',
  billingPeriodId,
})
const generateInvestmentFilesSuccessAction = (
  billingPeriodId: number
): GenerateInvestmentFilesSuccessAction => ({
  type: 'GENERATE_SNI_FILES_SUCCESS',
  billingPeriodId,
})
const generateInvestmentFilesErrorAction = (
  billingPeriodId: number
): GenerateInvestmentFilesErrorAction => ({
  type: 'GENERATE_SNI_FILES_ERROR',
  billingPeriodId,
})

export const checkLoginState = async (dispatch: Dispatch) => {
  const result = await session.start()
  resultSwitch(
    result,
    data => {
      if (data !== null) {
        dispatch(setTokenAction(data))
      }
    },
    error => {
      dispatch(setLoginErrorAction(error))
      session.clearCache()
    }
  )
}

export const login = (dispatch: Dispatch) => {
  session.login()
}

export const logout = (dispatch: Dispatch) => {
  session.logout()
}

export const toggleDrawer = (dispatch: Dispatch): void => {
  dispatch(toggleDrawerAction())
}

export const toggleShowLeasingContractsByYear = (
  dispatch: Dispatch,
  year: number
): void => {
  dispatch(toggleShowLeasingContractsByYearAction(year))
}
export const toggleShowLeasingContractDetails = (
  dispatch: Dispatch,
  leasingContractId: number
): void => {
  dispatch(toggleShowLeasingContractDetailsAction(leasingContractId))
}
export const toggleShowBillingPeriodsByYear = (
  dispatch: Dispatch,
  year: number
): void => {
  dispatch(toggleShowBillingPeriodsByYearAction(year))
}
export const toggleShowBillingPeriodDetails = (
  dispatch: Dispatch,
  billingPeriodId: number
): void => {
  dispatch(toggleShowBillingPeriodDetailsAction(billingPeriodId))
}

export const setFrom = (dispatch: Dispatch, from: Moment): void => {
  dispatch(setFromFilterAction(from))
}
export const setTo = (dispatch: Dispatch, to: Moment): void => {
  dispatch(setToFilterAction(to))
}
export const clearFrom = (dispatch: Dispatch): void => {
  dispatch(clearFromFilterAction())
}
export const clearTo = (dispatch: Dispatch): void => {
  dispatch(clearToFilterAction())
}

export const generateInvestmentFiles = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number,
  billingPeriodId: number
) => {
  dispatch(generateInvestmentFilesRequestAction(billingPeriodId))
  try {
    handleSuccess(dispatch, 'Generating Investment Files, download will start automatically', null)
    await requests.generateInvestmentFiles(token, frameContractId, billingPeriodId)
    dispatch(generateInvestmentFilesSuccessAction(billingPeriodId))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      () => generateInvestmentFilesErrorAction(billingPeriodId),
      'display_error'
    )
  }
}

export const exportBilling = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number,
  billingPeriodId: number,
  isFinal: boolean
) => {
  dispatch(exportBillingRequestAction(billingPeriodId))
  try {
    handleSuccess(dispatch, 'Generating Billing, download will start automatically', null)
    await requests.exportBilling(token, frameContractId, billingPeriodId, isFinal)
    dispatch(exportBillingSuccessAction(billingPeriodId))
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      () => exportBillingErrorAction(billingPeriodId),
      'display_error'
    )
  }
}

export const uploadAnswerFile = async (
  token: string,
  dispatch: Dispatch,
  history: History,
  frameContractId: number,
  file: FileList
) => {
  dispatch(uploadAnswerFileRequestAction(frameContractId))
  handleSuccess(dispatch, 'Import Started',null)
  try {
    await requests.uploadAnswerFile(token, frameContractId, file)
    dispatch(uploadAnswerFileSuccessAction(frameContractId))
    handleSuccess(dispatch, 'File was imported successfully',null)
  } catch (error) {
    handleError(
      dispatch,
      history,
      error,
      () => uploadAnswerFileErrorAction(frameContractId),
      'display_error'
    )
  }
}
