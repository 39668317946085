import { Moment } from 'moment'
import { JsonDecoder } from 'ts.data.json'
import { momentDecoder } from './decoderHelpers'
import { Maybe } from 'types/Maybe'
import { maybeDecoder } from 'types/Maybe'

export interface BillingPeriod {
  id: number
  frameContractId: number
  startDate: Moment
  endDate: Moment
}

export interface BillingPeriodDetails extends BillingPeriod {
  valuesByCustomerEntity: {
    [customerEntityId: number]: BillingPeriodValuesByCustomerEntity
  }
}

export interface BillingPeriodValuesByCustomerEntity {
  purchaseOrder: Maybe<string>
  rentInvoiceNumber: Maybe<string>
  rentInvoiceDate: Maybe<Moment>
  exitInvoiceNumber: Maybe<string>
  exitInvoiceDate: Maybe<Moment>
  damagesInvoiceNumber: Maybe<string>
  damagesInvoiceDate: Maybe<Moment>
}

const valuesByCustomerEntityDecoder = JsonDecoder.array(
  JsonDecoder.object(
    {
      customerEntity: JsonDecoder.number,
      purchaseOrder: maybeDecoder(JsonDecoder.string),
      rentInvoiceNumber: maybeDecoder(JsonDecoder.string),
      rentInvoiceDate: maybeDecoder(momentDecoder),
      exitInvoiceNumber: maybeDecoder(JsonDecoder.string),
      exitInvoiceDate: maybeDecoder(momentDecoder),
      damagesInvoiceNumber: maybeDecoder(JsonDecoder.string),
      damagesInvoiceDate: maybeDecoder(momentDecoder),
    },
    'BillingPeriodValuesInArray'
  ),
  'BillingPeriodValuesInArray[]'
).map(array => {
  const res: {
    [customerEntityId: number]: BillingPeriodValuesByCustomerEntity
  } = {}
  array.forEach(
    params =>
      (res[params.customerEntity] = {
        purchaseOrder: params.purchaseOrder,
        rentInvoiceNumber: params.rentInvoiceNumber,
        rentInvoiceDate: params.rentInvoiceDate,
        exitInvoiceNumber: params.exitInvoiceNumber,
        exitInvoiceDate: params.exitInvoiceDate,
        damagesInvoiceNumber: params.damagesInvoiceNumber,
        damagesInvoiceDate: params.damagesInvoiceDate,
      })
  )
  return res
})

const commonFields = {
  id: JsonDecoder.number,
  frameContractId: JsonDecoder.number,
  startDate: momentDecoder,
  endDate: momentDecoder,
}

const commonMap = {
  id: 'billingPeriod',
  frameContractId: 'frameContract',
}

export const decoder = JsonDecoder.object<BillingPeriod>(
  commonFields,
  'BillingPeriod',
  commonMap
)

export const detailsDecoder = JsonDecoder.object<BillingPeriodDetails>(
  {
    ...commonFields,
    valuesByCustomerEntity: valuesByCustomerEntityDecoder,
  },
  'BillingPeriodDetails',
  commonMap
)

export const BILLING_PERIOD_FORMAT = 'YYYY-MM-DD'
