import { Action } from '../actions'
import {
  Maybe,
  maybeNothing,
  maybeSwitch,
  maybeJust,
  maybeWithDefault,
} from 'types/Maybe'
import { ValidationErrors } from 'requests/errors'
import {
  savingRequest,
  setValidationErrors,
  savingResponse,
  deletingRequest,
  deletingResponse,
  setIsDeletable,
} from './helpers'
import { BillingPeriodValuesByCustomerEntity } from 'data/BillingPeriod'

export interface BillingPeriodDialogState {
  open: boolean
  saving: boolean
  deleting: boolean
  isDeletable: boolean
  updatingId: Maybe<number>
  valuesByCustomerEntity: {
    [customerEntityId: number]: BillingPeriodValuesByCustomerEntity
  }
  validationErrors: ValidationErrors
}

const defaultFormData = {
  updatingId: maybeNothing<number>(),
  valuesByCustomerEntity: {},
}

const defaultState = (): BillingPeriodDialogState => ({
  open: false,
  saving: false,
  deleting: false,
  isDeletable: false,
  validationErrors: {},
  ...defaultFormData,
})

export const getValuesOrDefault = (
  previous: BillingPeriodValuesByCustomerEntity | null
): BillingPeriodValuesByCustomerEntity =>
  previous || {
    purchaseOrder: maybeNothing(),
    rentInvoiceNumber: maybeNothing(),
    rentInvoiceDate: maybeNothing(),
    exitInvoiceNumber: maybeNothing(),
    exitInvoiceDate: maybeNothing(),
    damagesInvoiceNumber: maybeNothing(),
    damagesInvoiceDate: maybeNothing(),
  }

export default (
  state: BillingPeriodDialogState = defaultState(),
  action: Action
): BillingPeriodDialogState => {
  switch (action.type) {
    case 'OPEN_BILLING_PERIOD_DIALOG':
      const formData = maybeSwitch(
        action.billingPeriod,
        billingPeriod => ({
          updatingId: maybeJust(billingPeriod.id),
          valuesByCustomerEntity: billingPeriod.valuesByCustomerEntity,
        }),
        () => defaultFormData
      )
      return { ...state, open: true, ...formData }
    case 'CLOSE_BILLING_PERIOD_DIALOG':
      return defaultState()
    case 'CREATE_BILLING_PERIOD_REQUEST':
    case 'UPDATE_BILLING_PERIOD_REQUEST':
      return savingRequest(state)
    case 'CREATE_BILLING_PERIOD_SUCCESS':
    case 'CREATE_BILLING_PERIOD_ERROR':
    case 'UPDATE_BILLING_PERIOD_SUCCESS':
    case 'UPDATE_BILLING_PERIOD_ERROR':
      return savingResponse(state)
    case 'DELETE_BILLING_PERIOD_REQUEST':
      return deletingRequest(state)
    case 'DELETE_BILLING_PERIOD_SUCCESS':
    case 'DELETE_BILLING_PERIOD_ERROR':
      return deletingResponse(state)
    case 'SET_BILLING_PERIOD_IS_DELETABLE':
      return setIsDeletable(state)
    case 'SET_BILLING_PERIOD_PURCHASE_ORDER':
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            purchaseOrder: maybeJust(action.purchaseOrder),
          },
        },
      }
    case 'SET_BILLING_PERIOD_RENT_INVOICE_NUMBER':
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            rentInvoiceNumber: maybeJust(action.rentInvoiceNumber),
          },
        },
      }
    case 'SET_BILLING_PERIOD_RENT_INVOICE_DATE': {
      const values = {
        ...getValuesOrDefault(
          state.valuesByCustomerEntity[action.customerEntityId]
        ),
        rentInvoiceDate: maybeJust(action.rentInvoiceDate),
      }
      values.exitInvoiceDate = maybeJust(
        maybeWithDefault(values.exitInvoiceDate, action.rentInvoiceDate)
      )
      values.damagesInvoiceDate = maybeJust(
        maybeWithDefault(values.damagesInvoiceDate, action.rentInvoiceDate)
      )
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: values,
        },
      }
    }
    case 'SET_BILLING_PERIOD_EXIT_INVOICE_NUMBER':
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            exitInvoiceNumber: maybeJust(action.exitInvoiceNumber),
          },
        },
      }
    case 'SET_BILLING_PERIOD_EXIT_INVOICE_DATE':
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            exitInvoiceDate: maybeJust(action.exitInvoiceDate),
          },
        },
      }
    case 'SET_BILLING_PERIOD_DAMAGES_INVOICE_NUMBER':
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            damagesInvoiceNumber: maybeJust(action.damagesInvoiceNumber),
          },
        },
      }
    case 'SET_BILLING_PERIOD_DAMAGES_INVOICE_DATE':
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            damagesInvoiceDate: maybeJust(action.damagesInvoiceDate),
          },
        },
      }
    case 'CLEAR_BILLING_PERIOD_PURCHASE_ORDER': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            purchaseOrder: maybeNothing(),
          },
        },
      }
    }
    case 'CLEAR_BILLING_PERIOD_RENT_INVOICE_NUMBER': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            rentInvoiceNumber: maybeNothing(),
          },
        },
      }
    }
    case 'CLEAR_BILLING_PERIOD_RENT_INVOICE_DATE': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            rentInvoiceDate: maybeNothing(),
          },
        },
      }
    }
    case 'CLEAR_BILLING_PERIOD_EXIT_INVOICE_NUMBER': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            exitInvoiceNumber: maybeNothing(),
          },
        },
      }
    }
    case 'CLEAR_BILLING_PERIOD_EXIT_INVOICE_DATE': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            exitInvoiceDate: maybeNothing(),
          },
        },
      }
    }
    case 'CLEAR_BILLING_PERIOD_DAMAGES_INVOICE_NUMBER': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            damagesInvoiceNumber: maybeNothing(),
          },
        },
      }
    }
    case 'CLEAR_BILLING_PERIOD_DAMAGES_INVOICE_DATE': {
      return {
        ...state,
        valuesByCustomerEntity: {
          ...state.valuesByCustomerEntity,
          [action.customerEntityId]: {
            ...getValuesOrDefault(
              state.valuesByCustomerEntity[action.customerEntityId]
            ),
            damagesInvoiceDate: maybeNothing(),
          },
        },
      }
    }
    case 'SET_BILLING_PERIOD_VALIDATION_ERRORS':
      return setValidationErrors(state, action.validationErrors)
    default:
      return state
  }
}
