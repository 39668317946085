import { Action } from '../actions'
import { CustomerEntity } from '../data/CustomerEntity'
import {
  fetchingDataDefault,
  fetchingData,
  dataFetched,
  fetchingDataError,
} from './helpers'

export interface CustomerEntitiesState {
  fetching: boolean
  data: CustomerEntity[]
}

const defaultState = (): CustomerEntitiesState => fetchingDataDefault({})

export default (
  state: CustomerEntitiesState = defaultState(),
  action: Action
): CustomerEntitiesState => {
  switch (action.type) {
    case 'FETCH_CUSTOMER_ENTITIES_REQUEST':
      return fetchingData(state)
    case 'FETCH_CUSTOMER_ENTITIES_SUCCESS':
      return dataFetched(state, action.customerEntities)
    case 'FETCH_CUSTOMER_ENTITIES_ERROR':
      return fetchingDataError(state)
    default:
      return state
  }
}
