import * as React from 'react'
import { connect } from 'react-redux'
import { State } from 'reducers'
import { Dispatch } from 'redux'
import * as actions from '../actions/exportTemplateDialog'
import * as fieldAliasActions from '../actions/exportTemplateFieldAliasDialog'
import {
  Button,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  ListSubheader,
  Theme,
  withStyles,
  IconButton,
} from '@material-ui/core'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { withRouter, RouteChildrenProps } from 'react-router'
import FormTextInput from 'components/FormTextInput'
import MaterialDraggableList from 'components/MaterialDraggableList'
import { humanize } from '../utils'
import { ExportTemplateParams } from 'requests/ExportTemplate'
import FormErrors from 'components/FormErrors'
import WithConfirmation from 'components/WithConfirmation'
import { WithStyles } from '@material-ui/styles'
import LoadingIndicator from 'components/LoadingIndicator'
import { ExportTemplateField } from 'data/ExportTemplate'
import { ValidationErrors } from 'requests/errors'
import { Maybe, isJust, maybeSwitch, maybeNothing } from 'types/Maybe'

interface OwnProps extends RouteChildrenProps {
  token: string
}

interface StateProps {
  fetchingFields: boolean
  allFields: string[]
  editingId: Maybe<number>
  name: Maybe<string>
  fields: ExportTemplateField[]
  saving: boolean
  validationErrors: ValidationErrors
}

interface DispatchProps {
  closeForm: () => void
  setName: (name: string) => void
  clearName: () => void
  setFields: (fields: ExportTemplateField[]) => void
  addField: (field: ExportTemplateField) => void
  removeField: (field: string) => void
  editField: (field: ExportTemplateField) => void
  createExportTemplate: (params: ExportTemplateParams) => void
  updateExportTemplate: (
    exportTemplateId: number,
    params: ExportTemplateParams
  ) => void
  deleteExportTemplate: (exportTemplateId: number) => void
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    WithStyles<'fieldList' | 'listSubheader' | 'scrollY'> {}

class ExportTemplateForm extends React.PureComponent<Props> {
  public render() {
    const {
      fetchingFields,
      allFields,
      closeForm,
      editingId,
      name,
      fields,
      setName,
      clearName,
      setFields,
      addField,
      removeField,
      editField,
      validationErrors,
      createExportTemplate,
      updateExportTemplate,
      deleteExportTemplate,
      classes,
    } = this.props
    if (fetchingFields) {
      return (
        <Box p={5}>
          <LoadingIndicator size={maybeNothing()} />
        </Box>
      )
    }

    const isEditing = isJust(editingId)

    const availableFields = allFields.filter(
      field => fields.find(f => f.field === field) === undefined
    )
    return (
      <React.Fragment>
        <DialogTitle>
          {isEditing ? 'Edit export template' : 'New export template'}
        </DialogTitle>

        <Box display="flex" flexDirection="column">
          <Box flex={1} className={classes.scrollY}>
            <Box py={1} px={3}>
              <FormTextInput
                value={name}
                label="Name"
                required={true}
                onChange={setName}
                onClear={clearName}
                validationErrors={validationErrors['name']}
                disabled={false}
              />
            </Box>

            <Box display="flex" flex={1} px={1}>
              <List
                subheader={
                  <ListSubheader classes={{ root: classes.listSubheader }}>
                    Available fields
                  </ListSubheader>
                }
                className={classes.fieldList}
              >
                {availableFields.map(field => (
                  <ListItem
                    key={field}
                    button={true}
                    onClick={() => addField({ field, alias: humanize(field) })}
                  >
                    <ListItemText primary={humanize(field)} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() =>
                          addField({ field, alias: humanize(field) })
                        }
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <MaterialDraggableList
                fields={fields}
                onUpdate={setFields}
                onItemClick={editField}
                onArrowClick={field => removeField(field.field)}
                listProps={{
                  className: classes.fieldList,
                  subheader: (
                    <ListSubheader classes={{ root: classes.listSubheader }}>
                      Selected fields
                    </ListSubheader>
                  ),
                }}
              />
            </Box>
            <Box py={1} px={3}>
              <FormErrors errors={validationErrors['fields']} />
            </Box>
          </Box>

          <Box flex={0}>
            <DialogActions>
              {maybeSwitch(
                editingId,
                id => (
                  <WithConfirmation
                    dialogTitle="Confirm delete export template?"
                    onConfirm={() => deleteExportTemplate(id)}
                    render={onClick => (
                      <Button onClick={onClick}>Delete</Button>
                    )}
                  />
                ),
                () => null
              )}
              <Box flex={1} />
              <Button onClick={closeForm} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={(event: any) => {
                  const params = {
                    name,
                    fields,
                  }
                  maybeSwitch(
                    editingId,
                    id => updateExportTemplate(id, params),
                    () => createExportTemplate(params)
                  )
                }}
                color="primary"
              >
                {isEditing ? 'Save' : 'Create'}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </React.Fragment>
    )
  }
}

const styles = (theme: Theme) => ({
  fieldList: {
    flex: 1,
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  scrollY: {
    overflowY: 'scroll' as 'scroll',
  },
})

const mapStateToProps = (state: State): StateProps => ({
  fetchingFields: state.exportFields.fetching,
  allFields: state.exportFields.data,
  editingId: state.exportTemplateDialog.editingId,
  name: state.exportTemplateDialog.name,
  fields: state.exportTemplateDialog.fields,
  saving: state.exportTemplateDialog.saving,
  validationErrors: state.exportTemplateDialog.validationErrors,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps => ({
  closeForm: () => actions.closeExportTemplateForm(dispatch),
  setName: (name: string) => actions.setName(dispatch, name),
  clearName: () => actions.clearName(dispatch),
  setFields: (fields: ExportTemplateField[]) =>
    actions.setFields(dispatch, fields),
  addField: (field: ExportTemplateField) => actions.addField(dispatch, field),
  removeField: (field: string) => actions.removeField(dispatch, field),
  editField: (field: ExportTemplateField) =>
    fieldAliasActions.editField(dispatch, field),
  createExportTemplate: (params: ExportTemplateParams) =>
    actions.createExportTemplate(
      ownProps.token,
      dispatch,
      ownProps.history,
      params
    ),
  updateExportTemplate: (
    exportTemplateId: number,
    params: ExportTemplateParams
  ) =>
    actions.updateExportTemplate(
      ownProps.token,
      dispatch,
      ownProps.history,
      exportTemplateId,
      params
    ),
  deleteExportTemplate: (exportTemplateId: number) =>
    actions.deleteExportTemplate(
      ownProps.token,
      dispatch,
      ownProps.history,
      exportTemplateId
    ),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExportTemplateForm))
)
